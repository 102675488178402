import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const TrackingCodesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-tracking-codes-customers' */ './TrackingCodesCustomers'),
  {
    fallback: <LoadingComponent />,
  }
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TrackingCodes = (prop: any): any => <TrackingCodesLoadable {...prop} />;

export default TrackingCodes;
