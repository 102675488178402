import { memo, forwardRef } from 'react';
// import SnackbarContent from '../../SnackbarContent';
import { Alert } from '@mui/material';
import { CustomContentProps } from '../../types';

const MaterialDesignContent = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, forwardedRef) => {
    const {
      id,
      message,
      action: componentOrFunctionAction,
      iconVariant,
      severity,
      variant,
      hideIconVariant,
      style,
    } = props;

    const icon = iconVariant[severity];

    let action = componentOrFunctionAction;
    if (typeof action === 'function') {
      action = action(id);
    }

    return (
      <Alert
        ref={forwardedRef}
        role="alert"
        style={style}
        severity={severity}
        variant={variant}
        action={action}
        icon={!hideIconVariant ? icon : false}
      >
        {message}
      </Alert>
    );
  }
);

MaterialDesignContent.displayName = 'MaterialDesignContent';

export default memo(MaterialDesignContent);
