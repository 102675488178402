import { FormHelperText, FormHelperTextProps } from '@mui/material';
import { FieldMetaState, useField, FieldRenderProps } from 'react-final-form';

export interface ErrorMessageProps {
  showError: boolean;
  meta: FieldMetaState<any>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: string;
}

export function ErrorMessage({
  showError,
  meta,
  formHelperTextProps,
  helperText,
}: ErrorMessageProps): React.ReactElement | null {
  const isObject = meta.error && typeof meta.error === 'object';
  if (isObject) {
    console.log(meta.error, 'meta.error');
  }
  if (showError) {
    return (
      <FormHelperText {...formHelperTextProps}>
        {(!isObject ? meta.error : 'Error Object') || meta.submitError}
      </FormHelperText>
    );
  }
  if (helperText) {
    return <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>;
  }
  return null;
}

export interface ShowErrorProps {
  meta: FieldMetaState<any>;
}

export type ShowErrorFunc = (props: ShowErrorProps) => boolean;

const config = {
  subscription: {
    error: true,
    submitError: true,
    dirtySinceLastSubmit: true,
    touched: true,
    modified: true,
  },
};

export const useFieldForErrors = (name: string): FieldRenderProps<any, HTMLElement> =>
  useField(name, config);

export const showErrorOnChange: ShowErrorFunc = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}: ShowErrorProps) =>
  !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified));

export const showErrorOnBlur: ShowErrorFunc = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched },
}: ShowErrorProps) => !!(((submitError && !dirtySinceLastSubmit) || error) && touched);
