import { createSvgIcon } from '@mui/material';

export default (props: any) => {
  const Icon = createSvgIcon(
    <g style={{ isolation: 'isolate' }}>
      <g>
        <g>
          <path
            d="M8.46,8.47a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-3a5,5,0,1,0,5,5,5,5,0,0,0-5-5Z"
            style={{ fill: '#5333ed' }}
          />
          <line
            x1="26.65"
            y1="10.47"
            x2="16.94"
            y2="10.47"
            style={{
              fill: 'none',
              stroke: '#5333ed',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: 3,
            }}
          />
          <g style={{ mixBlendMode: 'multiply' }}>
            <path
              d="M23.54,19.53a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-3a5,5,0,1,0,5,5,5,5,0,0,0-5-5Z"
              style={{ fill: '#2cd4d9' }}
            />
          </g>
          <line
            x1="5.35"
            y1="21.53"
            x2="15.06"
            y2="21.53"
            style={{
              fill: 'none',
              stroke: '#2cd4d9',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: 3,
              mixBlendMode: 'multiply',
            }}
          />
        </g>
      </g>
    </g>,
    'Managed'
  );
  return <Icon {...props} viewBox="0 0 32 32" />;
};
