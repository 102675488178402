/* eslint-disable max-len */

import { makeStyles } from '@mui/styles';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';

import { Link } from 'react-router-dom';

import WarningIcon from '@mui/icons-material/Warning';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'auto... Remove this comment to see the full error message
import match from 'autosuggest-highlight/match';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'auto... Remove this comment to see the full error message
import parse from 'autosuggest-highlight/parse';

import clsx from 'clsx';
import Wizard from 'components/Forms/Wizard';
import {
  Autocomplete,
  Select as SelectReduxNew,
  TextField as TextFieldReduxNew,
} from 'components/MaterialFinalFormFields';

import arrayMutators from 'final-form-arrays';

import capitalize from 'lodash.capitalize';
import get from 'lodash.get';

import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { find as findAccountUsers } from 'redux/modules/accountUsers';
import { find as findHarmonizedCodes } from 'redux/modules/harmonizedCodes';

import { find as findStates } from 'redux/modules/states';
import { countryToFlag } from 'utils/helpers';
import {
  Tooltip,
  Grid,
  IconButton,
  SnackbarContent,
  alpha,
  Typography,
  Alert,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  LinearProgress,
  TextField,
  Button,
  ListItemText,
  ListItem,
  DialogActions,
} from '@mui/material';
import { amber, green } from '@mui/material/colors';

import { normalizeFloat, normalizeHarmonizer } from 'utils/inputNormalization';
import type { InternalService, Service } from '../../../../api/database/models';
import { DutiesTaxes } from './DutiesCostCalculator';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(
  (theme) => ({
    success: {
      backgroundColor: green[600],
    },
    _error: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: '#e9f7fd',
      color: '#000',
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    textField: {
      backgroundColor: '#fff',
      border: '1px solid #ced4da',
      borderRadius: 4,
      '&:hover': {
        // backgroundColor: darken('#fff', 0.1),
      },
    },
    selectField: {
      padding: '27px 12px 10px',
      paddingTop: 23,
      paddingBottom: 6,
      height: '3.4em',
    },
    selectFieldPlaceholder: {
      padding: '27px 12px 10px',
      paddingTop: 23,
      paddingBottom: 6,
      bottom: 0,
      left: 0,
      visibility: 'hidden',
      opacity: 0,
      transition: 'opacity .6s',
    },
    '@global': {
      '.focused': {
        '& $selectFieldPlaceholder': {
          visibility: 'visible',
          opacity: 1,
        },
      },
    },
    textFieldInput: {},
    textFieldInputUnderline: {
      '&:hover:before': {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        // borderBottom: `4px solid ${lighten('#fdea04', 0.6)}`
      },
      '&:before': {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderBottom: '4px solid #fdea04',
      },
      '&:after': {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderBottom: '4px solid #000000',
      },
    },
    containerListHarminizedSearch: {
      overflow: 'auto',
    },
  }),
  { name: 'BringerDutiesCostCalculatorFormForm' }
);

function MySnackbarContentWrapper(props: any) {
  const classes = useStyles1();
  const { className, message, onClose, variant, noAction, ...other } = props;
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const Icon = variantIcon[variant];

  const finalVariantForClass = variant === 'error' ? '_error' : variant;

  return (
    <SnackbarContent
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      className={clsx(classes[finalVariantForClass], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={
        !noAction && [
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]
      }
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  noAction: PropTypes.bool,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const useStyles = makeStyles(
  (theme) => ({
    vehiclesContainer: {
      padding: theme.spacing(2),
      backgroundColor: '#efefef',
    },
    buttonMarginLeft: {
      marginLeft: theme.spacing(1),
    },
    buttonLogin: {
      marginBottom: 10,
      marginTop: 10,
    },
    gridWithMarginSection: {
      marginTop: theme.spacing(3),
    },
    partContainer: {
      padding: theme.spacing(2),
    },
    containerError: {
      marginTop: 10,
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    buttonAdd: {
      borderWidth: 1,
      borderColor: green[600],
      borderStyle: 'solid',
      marginRight: theme.spacing(1),
      '&:hover': {
        backgroundColor: alpha(green[600], 0.3),
        color: '#fff',
      },
    },
    buttonRemove: {
      color: '#d32f2f',
      borderColor: '#d32f2f',
      '&:hover': {
        backgroundColor: alpha('#d32f2f', 0.6),
        color: '#fff',
      },
    },
    paper: {
      padding: theme.spacing(2),
      overflowX: 'hidden',
    },
    link: {
      textDecoration: 'underline',
    },
    stepper: {
      background: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
    },
    progress: {
      width: `calc(100% + ${theme.spacing(4)})`,
      position: 'relative',
      left: `-${theme.spacing(2)}`,
      top: `-${theme.spacing(2)}`,
    },
    progressFinal: {
      width: `calc(100% + ${theme.spacing(4)})`,
      position: 'relative',
      left: `-${theme.spacing(2)}`,
      bottom: `-${theme.spacing(2)}`,
    },
    formGroup: {
      flexDirection: 'row',
    },
    dialogHarmonized: {
      width: '100%',
      maxWidth: 600,
      margin: '0 auto',
    },
    '@keyframes blinkloading': {
      '0%': {
        opacity: 0.2,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0.2,
      },
    },
    validatingContFloat: {
      position: 'fixed',
      bottom: 40,
      left: 0,
      right: 0,
      textAlign: 'center',
      '& svg': {
        animationName: '$blinkloading',
        animationDuration: '1.4s',
        animationIterationCount: 'infinite',
        animationFillMode: 'both',
      },
    },
    validatingContFloat240: {
      position: 'fixed',
      bottom: 40,
      left: 240,
      right: 0,
      textAlign: 'center',
      '& svg': {
        animationName: '$blinkloading',
        animationDuration: '1.4s',
        animationIterationCount: 'infinite',
        animationFillMode: 'both',
      },
    },
    chipHumanitarian: {
      float: 'right',
    },
    buttonDevAutoFill: {
      float: 'right',
    },
    paperDevOptions: {
      maxHeight: '50vh',
      overflow: 'scroll',
      margin: 10,
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    fixTransformInSelectMini: {
      transform: 'translate(14px, 9px) scale(1)',
    },
    fixTransformInSelectMiniFilled: {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  }),
  { name: 'DutiesCostCalculatorForm' }
);

const DutiesCostCalculatorForm = memo(
  ({
    onSubmit,
    recipientCountries,
    senderCountries,
    userAccount,
    countries, // new
    services, // new
    internalServices, // new
    editMyParcel,
    serviceCountryAddressTypes,
    dutiesTaxes,
    error,
  }: {
    [x: string]: any;
    internalServices: InternalService[];
    dutiesTaxes: DutiesTaxes;
  }) => {
    const classes = useStyles();

    let timeout = false;

    const [countryStates, setCountryStates] = useState([]);

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'auth' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    const user = useSelector((state) => state.auth.user);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'accounts' does not exist on type 'Defaul... Remove this comment to see the full error message
    const allAccounts = useSelector((state) => state.accounts.result.allAccounts);

    const harmonizedCodeResult = useSelector(
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'harmonizedCodes' does not exist on type ... Remove this comment to see the full error message
      (state) => state.harmonizedCodes.result.harmonizedCodeSearch
    );
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'harmonizedCodes' does not exist on type ... Remove this comment to see the full error message
    const loadingSearchHarmonizedCode = useSelector((state) => state.harmonizedCodes.loading);

    const isAdminUser = user.roles.some(
      (r: any) => r.role.role === 'admin' || r.role.role === 'warehouse'
    );

    const userAccounts = isAdminUser ? allAccounts : user.accounts.map((ua: any) => ua.account);

    const ressellersRoles = {
      wholesaler: true,
      reseller: true,
    };

    const userAccountData =
      userAccount &&
      userAccounts &&
      userAccounts.find((accountUser: any) => accountUser.id === userAccount.id);

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const isReseller = ressellersRoles[get(userAccountData, 'account_role.role')];

    const disabledRedirectServices = Boolean(get(userAccountData, 'disabledRedirectServices'));

    const formRef = useRef();

    const harmonizedInputRef = useRef();

    const [harmonizedModalStatus, setHarmonizedModalStatus] = useState({});

    const servicesByCountryOrigDest = {};
    let serviceType = get(editMyParcel, 'serviceId');

    const { countriesById, countriesByIsoCode } = countries.reduce(
      (acc: any, curr: any) => {
        acc.countriesById[curr.id] = curr;
        acc.countriesByIsoCode[curr.isoCode] = curr;

        return acc;
      },
      { countriesById: {}, countriesByIsoCode: {} }
    );

    const serviceRulesByServiceId = {};
    const externalServicesById = {};
    const servicesById: { [x: string]: Service } = {};
    services?.forEach((service: any) => {
      const {
        serviceCountries,
        externalServiceRules,
        externalServiceId,
        external_service: externalService,
      } = service;

      servicesById[service.id] = service;

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      externalServicesById[externalServiceId] = externalService;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      serviceRulesByServiceId[externalServiceId] = externalServiceRules.reduce(
        (acc: any, curr: any) => {
          acc[curr._key] = curr;
          return acc;
        },
        {}
      );

      serviceCountries?.forEach((item: any) => {
        const destinationCountry = countriesById[item.destinationCountryId];
        const originCountry = countriesById[item.originCountryId];
        if (
          service.active &&
          (isAdminUser || (service.integrationStage && service.integrationStage === 'pro'))
        ) {
          const key = `${originCountry.isoCode}-${destinationCountry.isoCode}`;
          const dataSource = {
            value: `${service.id}-${service.externalServiceId}`,
            label: service.name,
          };
          if (parseInt(serviceType, 10) === parseInt(service.id, 10)) {
            serviceType = `${service.id}-${service.externalServiceId}`;
          }
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (servicesByCountryOrigDest[key]) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            servicesByCountryOrigDest[key].push(dataSource);
          } else {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            servicesByCountryOrigDest[key] = [dataSource];
          }
        }
      });
    });

    const addressTypesByIdCountry = {};
    const addressTypesById = {};
    serviceCountryAddressTypes?.forEach((countryAddressType: any) => {
      const { countryId, address_type: addressType } = countryAddressType;
      const country = countriesById[countryId];
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!addressTypesById[`${addressType.name}`]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        addressTypesById[`${addressType.name}`] = {};
      }
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      addressTypesById[`${addressType.name}`].name = capitalize(addressType.name);

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!addressTypesByIdCountry[`${country.isoCode}-${addressType.name}`]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        addressTypesByIdCountry[`${country.isoCode}-${addressType.name}`] = {};
      }
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      addressTypesByIdCountry[`${country.isoCode}-${addressType.name}`].name = capitalize(
        addressType.name
      );
      if (countryAddressType.fieldName === 'tax_id') {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        addressTypesByIdCountry[`${country.isoCode}-${addressType.name}`].taxIdName =
          countryAddressType.name.toUpperCase();
      }
    });

    const dispatch = useDispatch();

    const isRestrictedUser = user.roles.some(
      (r: any) => r.role.role === 'reseller_customer' || r.role.role === 'wholesaler_customer'
    );

    const handleClickHarmonizedSearchButton = (name: any) => () => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      setHarmonizedModalStatus((prev) => ({ ...prev, [name]: !prev[name] }));
    };

    const hnadleChangeHarmonizedCode = (e: any) => {
      if (timeout) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        clearTimeout(timeout);
      }
      const { value } = e.target;
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'boolean'... Remove this comment to see the full error message
      timeout = setTimeout(() => {
        dispatch(
          findHarmonizedCodes('harmonizedCodeSearch', {
            query: {
              $or: [
                {
                  description: {
                    $like: `%${value}%`,
                  },
                },
                {
                  code: {
                    $like: `%${value}%`,
                  },
                },
              ],
              $sort: { countShipments: -1 },
              $paginate: false,
              $limit: 20,
            },
          })
        );
      }, 500);
    };

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const fetchStates = useCallback(async (country, key) => {
      if (country) {
        const states = await dispatch(
          findStates(key, {
            query: {
              $paginate: false,
              countryId: countriesByIsoCode[country].id,
            },
          })
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'catch' does not exist on type '{ key: an... Remove this comment to see the full error message
        ).catch(() => null);

        // @ts-expect-error ts-migrate
        setCountryStates(states.sort((a, b) => (a.isoCode >= b.isoCode ? 1 : -1)));
      }
    });

    const selectHarmonizedCode = (form: any, name: any, code: any) => {
      form.change(`harmonizedCodes`, code.code.slice(0, 10));
      form.change(`productDescription`, code.description);
      handleClickHarmonizedSearchButton(name)();
    };

    useEffect(() => {
      if (isReseller && !isRestrictedUser) {
        dispatch(
          findAccountUsers('usersInAccount', {
            query: {
              $paginate: true,
              $limit: 200,
              accountId: userAccount.accountId,
            },
          })
        );
      }
    }, [userAccount && userAccount.id]);

    return (
      <Wizard
        formRef={formRef}
        initialValues={{}}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        mode="accordion"
        hideBodyIfHeaderHasErrors
        hideSteps
        showSubmitButton
      >
        <Wizard.Page placeInHead label="Header" validate={() => null}>
          {({ values, form }) => {
            const servicesInCountryIds: number[] =
              // @ts-expect-error hdie this
              servicesByCountryOrigDest?.[
                `${values.senderCountry}-${values.recipientCountry}`
              ]?.map((item: any) => {
                return Number(item.value.split('-')[0]);
              }) || [];

            const internalServicesAvailable = internalServices.filter((internalService) => {
              return internalService.services.some((service) => {
                return servicesInCountryIds.includes(service.id);
              });
            });
            const internalServicesOptions = internalServicesAvailable.map((internalService) => {
              return {
                value: internalService.code,
                label: internalService.name,
              };
            });

            const { recipientCountry } = values;

            return (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Tooltip placement="top" title="From where you are sending the package">
                    <Autocomplete
                      size="small"
                      required
                      name="senderCountry"
                      classes={{
                        option: classes.option,
                      }}
                      options={senderCountries}
                      label="Country of Origin"
                      placeholder="Type Country"
                      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                      getOptionValue={(v) => v.value || v}
                      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => {
                        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                        const country = countriesByIsoCode[option.value];
                        return (
                          <li {...props}>
                            <span>{countryToFlag(country.isoCode)}</span>
                            {/* @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'. */}
                            {option.label} ({country.isoCode}){' '}
                            {country.phoneNumberPrefix ? `+${country.phoneNumberPrefix}` : ''}
                          </li>
                        );
                      }}
                      textFieldProps={{
                        inputProps: {
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        },
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Tooltip placement="top" title="Where you are sending the package">
                    <Autocomplete
                      size="small"
                      required
                      name="recipientCountry"
                      classes={{
                        option: classes.option,
                      }}
                      options={recipientCountries || [{ value: 'BR', label: 'Brazil' }]}
                      label="Country of Destination"
                      placeholder="Type Country"
                      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                      getOptionLabel={(option) => option.label}
                      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                      getOptionValue={(v) => v.value}
                      // @ts-expect-error ts-migrate(6133) FIXME: 'event' is declared but its value is never read.
                      onChange={(event, v) => {
                        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                        fetchStates(v.value, 'statesRecipient');
                      }}
                      renderOption={(props, option) => {
                        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                        const country = countriesByIsoCode[option.value];
                        return (
                          <li {...props}>
                            <span>{countryToFlag(country.isoCode)}</span>
                            {/* @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'. */}
                            {option.label} ({country.isoCode}){' '}
                            {country.phoneNumberPrefix ? `+${country.phoneNumberPrefix}` : ''}
                          </li>
                        );
                      }}
                      textFieldProps={{
                        inputProps: {
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        },
                      }}
                    />
                  </Tooltip>
                </Grid>
                {['BR'].includes(recipientCountry) && (
                  <Grid item xs={12} sm={6}>
                    <Tooltip placement="top" title="Where state are you sending the package">
                      <Autocomplete
                        size="small"
                        required
                        name="recipientState"
                        options={countryStates}
                        classes={{
                          option: classes.option,
                        }}
                        label="State"
                        placeholder="State"
                        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                        getOptionLabel={(option) => option.isoCode}
                        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                        getOptionValue={(v) => v.isoCode}
                        renderOption={(props, option) => {
                          // @ts-expect-error ts-migrate
                          const state = option.isoCode as string;

                          return <li {...props}>{state}</li>;
                        }}
                        textFieldProps={{
                          inputProps: {
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          },
                        }}
                      />
                    </Tooltip>
                  </Grid>
                )}
                {!disabledRedirectServices && (
                  <Grid item xs={12} sm={6}>
                    <SelectReduxNew
                      size="small"
                      fullWidth
                      required
                      variant="outlined"
                      name="service"
                      inputLabelProps={{
                        className: classes.fixTransformInSelectMini,
                        classes: {
                          filled: classes.fixTransformInSelectMiniFilled,
                        },
                      }}
                      getOptionLabel={(option) => option.name}
                      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                      getOptionValue={(v) => v.code}
                      data={
                        internalServicesOptions.length !== 0
                          ? internalServicesOptions
                          : [
                              {
                                value: null,
                                label: 'No service available',
                                disabled: true,
                              },
                            ]
                      }
                      type="select"
                      label="Select service"
                    />

                    <input required name="service" type="hidden" hidden />
                  </Grid>
                )}
                <Grid item xs={6} sm={6}>
                  <TextFieldReduxNew
                    size="small"
                    fullWidth
                    required
                    variant="outlined"
                    name="cost"
                    type="text"
                    label="Total Cost in ($)"
                    placeholder="Value in Dollars"
                    fieldProps={{ parse: normalizeFloat }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextFieldReduxNew
                    size="small"
                    fullWidth
                    required
                    variant="outlined"
                    name="freight"
                    type="text"
                    label="Total Freight in ($)"
                    placeholder="Value in Dollars"
                    fieldProps={{ parse: normalizeFloat }}
                  />
                </Grid>

                {['MX'].includes(recipientCountry) && (
                  <Grid item xs={6} sm={6}>
                    <TextFieldReduxNew
                      fullWidth
                      required
                      variant="outlined"
                      name="harmonizedCodes"
                      type="text"
                      size="small"
                      label="Harmonized Code"
                      placeholder="Type Harmonized Code"
                      fieldProps={{
                        parse: normalizeHarmonizer,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickHarmonizedSearchButton('harmonizedCodes')}
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Dialog
                      onClose={handleClickHarmonizedSearchButton('harmonizedCodes')}
                      classes={{ paper: classes.dialogHarmonized }}
                      open={harmonizedModalStatus.harmonizedCodes}
                    >
                      {loadingSearchHarmonizedCode && <LinearProgress variant="indeterminate" />}
                      <DialogTitle id="dialog-title" disableTypography>
                        <Typography component="h2" variant="h6" sx={{ marginBottom: 1 }}>
                          Search Harmonized Code
                        </Typography>
                        <TextField
                          inputRef={harmonizedInputRef}
                          onChange={hnadleChangeHarmonizedCode}
                          fullWidth
                          placeholder="Search by Keyword"
                          autoFocus
                          size="small"
                        />
                      </DialogTitle>
                      <DialogContent>
                        <List style={{ cursor: 'pointer' }}>
                          {harmonizedCodeResult &&
                            harmonizedCodeResult.map((code: any) => {
                              const text = `${code.code} - ${code.description}`;
                              const { value: inputValue } = harmonizedInputRef.current || {};
                              const matches = match(text, String(inputValue));
                              const parts = parse(text, matches);

                              return (
                                <ListItem
                                  divider
                                  onClick={() =>
                                    selectHarmonizedCode(form, `${'harmonizedCodes'}`, code)
                                  }
                                  key={code.code}
                                >
                                  <ListItemText>
                                    {parts.map((part: any, idx: any) => (
                                      <span
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={idx}
                                        style={{
                                          fontWeight: part.highlight ? 700 : 400,
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}
                                  </ListItemText>
                                </ListItem>
                              );
                            })}
                        </List>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleClickHarmonizedSearchButton('harmonizedCodes')}
                          color="primary"
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                )}

                <Grid item xs={12} sm={12}>
                  {(dutiesTaxes.federalTax === 0 || !!dutiesTaxes.federalTax) && (
                    <Typography variant="h4">
                      Federal Taxes: ${dutiesTaxes.federalTax.toFixed(2)}
                    </Typography>
                  )}
                  {(dutiesTaxes.stateTax === 0 || !!dutiesTaxes.stateTax) && (
                    <Typography variant="h4">
                      State Taxes: ${dutiesTaxes.stateTax.toFixed(2)}
                    </Typography>
                  )}
                  {(dutiesTaxes.dutiesTaxes === 0 || !!dutiesTaxes.dutiesTaxes) && (
                    <Typography variant="h4">
                      Duties and Taxes: ${dutiesTaxes.dutiesTaxes.toFixed(2)}
                    </Typography>
                  )}
                  {(dutiesTaxes.destinationFee === 0 || !!dutiesTaxes.destinationFee) && (
                    <Typography variant="h4">
                      Destination Fee: ${dutiesTaxes.destinationFee.toFixed(2)}
                    </Typography>
                  )}
                  {(dutiesTaxes.administrationFee === 0 || !!dutiesTaxes.administrationFee) && (
                    <Typography variant="h4">
                      Administration Fee: ${dutiesTaxes.administrationFee.toFixed(2)}
                    </Typography>
                  )}
                  {(dutiesTaxes.totalDutiesTaxes === 0 || !!dutiesTaxes.totalDutiesTaxes) && (
                    <Typography variant="h4" fontWeight="700">
                      Total Duties and Taxes: ${dutiesTaxes.totalDutiesTaxes.toFixed(2)}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  {!!error && (
                    <Alert variant="filled" severity="warning">
                      <Typography variant="h4">{error}</Typography>
                    </Alert>
                  )}
                </Grid>

                {['BR'].includes(recipientCountry) && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h4">
                        Download Brazil Duties and Taxes Ecommerce Calculation
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button size="small" variant="contained" startIcon={<DownloadIcon />}>
                        <Link
                          to="/docs/Brazil Duties and Taxes Ecommerce Calculation - Packet Standard & Packet Express & Prime & Tracked Parcel.pdf"
                          target="_blank"
                        >
                          Packet Standard | Packet Express | Prime | Tracked Parcel | Express Plus |
                          Express Plus XP | Express Plus RX
                        </Link>
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button size="small" variant="contained" startIcon={<DownloadIcon />}>
                        <Link
                          to="/docs/Brazil Duties and Taxes Ecommerce Calculation - Packet Standard PRC & Express Plus.pdf"
                          target="_blank"
                        >
                          Packet Standard PRC | Express Plus PRC
                        </Link>
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            );
          }}
        </Wizard.Page>
      </Wizard>
    );
  }
);

export default DutiesCostCalculatorForm;
