import { makeStyles } from '@mui/styles';
import Container from 'components/Container/Container';
import { memo } from 'react';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube';
import { Link } from 'react-router-dom';
import LogoSymbolWhite from 'components/Img/bpswhite2.png';
import BacLogoSymbolWhite from 'components/Img/BAC-Logo-WHITE.png';
import { useAppSelector } from 'hooks/redux';

import { Button, Grid, Typography } from '@mui/material';

const useStyles = makeStyles(
  {
    footer: {
      backgroundColor: '#150773',
      paddingTop: 60,
      paddingBottom: 60,
      color: '#fff',
      position: 'relative',
    },
    ul: {
      padding: 0,
    },
    li: {
      listStyle: 'none',
      fontSize: 16,
    },
    logo: {
      width: 200,
    },
    backLogo: {
      width: '150px',
      float: 'right',
    },
    backLogoLink: {
      marginTop: -50,
      marginRight: 70,
      width: '150px',
      float: 'right',
    },
    '@media (max-width: 990px)': {
      backLogoLink: {
        marginRight: 0,
      },
    },
  },
  { name: 'BringerFooter' }
);

export default memo(() => {
  const classes = useStyles();
  const accountByDomain = useAppSelector((rstate) => rstate.accounts.result.accountByDomain?.[0]);
  return (
    <footer className={classes.footer}>
      <Container size="publicationSize">
        <Grid className="footer-nav">
          <Grid className="container">
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <img
                  className={classes.logo}
                  src={accountByDomain?.themeLogo || LogoSymbolWhite}
                  alt="BPS"
                />
              </Grid>

              {!accountByDomain && (
                <>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6">Bringer Parcel Services</Typography>
                    <ul className={classes.ul}>
                      <li className={classes.li}>1-888-327-4643</li>
                      <li className={classes.li}>305-592-5427</li>
                      <li className={classes.li}>bps@bringer.com</li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6">
                      Company
                      <span />
                    </Typography>
                    <ul className={classes.ul}>
                      <li className={classes.li}>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li className={classes.li}>
                        <Link to="/privacy">Privacy</Link>
                      </li>
                      <li className={classes.li}>
                        <Link to="/terms">Terms</Link>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6">
                      Shipping
                      <span />
                    </Typography>
                    <ul className={classes.ul}>
                      <li className={classes.li}>
                        <Link to="/prohibited">Prohibited Items</Link>
                      </li>
                      <li className={classes.li}>
                        <Link to="/perfumes">Perfumes</Link>
                      </li>
                      <li className={classes.li}>
                        <Link to="/lithium-batteries">Lithium Batteries</Link>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6">
                      Help
                      <span />
                    </Typography>
                    <ul className={classes.ul}>
                      <li className={classes.li}>
                        <Link to="/login">Start Shipping</Link>
                      </li>
                      <li className={classes.li}>
                        <Link to="/tracking">Tracking</Link>
                      </li>
                      <li className={classes.li}>
                        <Link to="/claims">Claims</Link>
                      </li>
                    </ul>
                  </Grid>

                  <Grid item xs={6}>
                    <Button>
                      <FaFacebook color="#fff" size={30} />
                    </Button>
                    <Button>
                      <FaTwitter color="#fff" size={30} />
                    </Button>
                    <Button>
                      <FaInstagram color="#fff" size={30} />
                    </Button>
                    <Button>
                      <FaYoutube color="#fff" size={30} />
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <a
                      className={classes.backLogoLink}
                      target="_blank"
                      href="https://www.bringeraircargo.com/"
                      rel="noreferrer"
                    >
                      <Typography>BPS is a division of</Typography>
                      <img src={BacLogoSymbolWhite} className={classes.backLogo} alt="Bac Logo" />
                    </a>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
});
