import type {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridColDef,
  GridSortModel,
  GridPinnedColumns,
  GridValueGetterParams,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import get from 'lodash.get';
import { GridColDefGenerator } from './services/gridColDefGenerator';

export type GridColDefCustom<R extends GridValidRowModel = any, V = any, F = V> = GridColDef<
  R,
  V,
  F
> & {
  defaultHide?: boolean;
  placeholder?: string;
  status?: string | string[];
  getActions?: () => any;
  getApplyIsAnyFilterFn?: (string: string) => string[] | string;
  type?: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions';
};

export type DemoDataReturnType = {
  loading: boolean;
  setRowLength: (count: number) => void;
  loadNewData: () => void;
};

type DataSet = 'Commodity' | 'Employee';

export interface UseDemoDataOptions {
  dataSet: DataSet;
  rowLength: number;
  rows?: any[];
  maxColumns?: number;
  visibleFields?: string[];
  editable?: boolean;
  columns: GridColDef[];
  sortModel?: GridSortModel;
  filterModel?: GridFilterModel;
  columnVisibilityModel?: GridColumnVisibilityModel;
  storageKey: string;
  columnsOrder?: string[];
  pinnedColumns?: GridPinnedColumns;
  columnsSize?: {
    [x: string]: number;
  };
}
export type ColumnsOptions = Pick<
  UseDemoDataOptions,
  | 'dataSet'
  | 'editable'
  | 'maxColumns'
  | 'visibleFields'
  | 'columns'
  | 'columnsOrder'
  | 'columnsSize'
>;

const valueGetter = (params: GridValueGetterParams<any, any>) => {
  let value: any = '';
  if (params.field.slice(0, 1) === '$' && params.field.slice(-1) === '$') {
    const field = params.field.slice(1, -1);
    value = get(params.row, field) || params.colDef?.placeholder || '';
    if (params.colDef && (params.colDef.type === 'date' || params.colDef.type === 'dateTime')) {
      value = value ? new Date(value) : '';
    }
  } else {
    const { field } = params;
    value = get(params.row, field) || params.colDef?.placeholder || '';
    if (params.colDef && (params.colDef.type === 'date' || params.colDef.type === 'dateTime')) {
      value = value ? new Date(value) : '';
    }
  }

  return value;
};

export const getColumnsFromOptions = (options: ColumnsOptions): GridColDefGenerator[] => {
  if (options?.columns?.length) {
    const columns = [...options.columns].map((column) => {
      const newValueGetter = column.valueGetter || valueGetter;
      const newGroupingValueGetter = column.groupingValueGetter || valueGetter;
      return {
        ...column,
        valueGetter: newValueGetter,
        groupingValueGetter: newGroupingValueGetter,
      };
    });
    if (options.columnsOrder || options?.columnsSize) {
      const newColumns: typeof options.columns = [];
      options.columnsOrder?.forEach((field) => {
        const index = columns.findIndex((col) => {
          if (col) {
            return col.field === field;
          }
          return false;
        });
        if (index !== -1) {
          if (options?.columnsSize?.[columns[index].field]) {
            const newColumn = { ...columns[index] };
            newColumn.width = options.columnsSize[columns[index].field];
            newColumns.push(newColumn);
          } else {
            newColumns.push(columns[index]);
          }

          delete columns[index];
        }
      });
      columns.forEach((column) => {
        if (column) {
          if (options?.columnsSize?.[column.field]) {
            column.width = options.columnsSize[column.field];
            if (column.width < column.minWidth) {
              column.width = column.minWidth;
            }
          }
          newColumns.push(column);
        }
      });
      return newColumns;
    }
    return columns;
  }

  const columns = [];
  return columns;
};

export const getInitialState = (
  options: UseDemoDataOptions,
  columns: GridColDefGenerator[],
  _columnVisibilityModel: GridColumnVisibilityModel
) => {
  // TODO v6: Stop using `GridColDef.hide`
  const columnVisibilityModel: GridColumnVisibilityModel = {};
  columns.forEach((col) => {
    if (col.defaultHide) {
      if (!_columnVisibilityModel?.[col.field]) {
        columnVisibilityModel[col.field] = false;
      }
    }
  });

  const groupingField = options.treeData?.groupingField;
  if (groupingField) {
    columnVisibilityModel[groupingField] = false;
  }

  return {
    columns: { columnVisibilityModel: { ...columnVisibilityModel, ..._columnVisibilityModel } },
  };
};
