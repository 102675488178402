import LocaleContext from 'components/Context/LocaleContext';
import { PageContext } from 'components/Context/pageContext';
import RouterContext from 'components/Context/RouterContext';
import { Provider as AppProvider } from 'contexts/App';
import { Provider as RestAppProvider } from 'contexts/RestApp';
import PropTypes from 'prop-types';
import { StrictMode, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as FigbirdProvider } from 'store';

const Provider = ({
  app,
  restApp,
  intl,
  store,
  pageContext,
  children,
  helmetContext,
  routerContext,
}: any) => {
  const localeContext = useMemo(() => {
    return { localeContext: intl };
  }, [intl]);
  return (
    <StrictMode>
      <RouterContext.Provider value={routerContext}>
        <HelmetProvider context={helmetContext}>
          <LocaleContext.Provider value={localeContext}>
            <PageContext.Provider value={pageContext}>
              <AppProvider value={app}>
                <RestAppProvider value={restApp}>
                  <ReduxProvider store={store}>
                    <FigbirdProvider store={store} feathers={app}>
                      {children}
                    </FigbirdProvider>
                  </ReduxProvider>
                </RestAppProvider>
              </AppProvider>
            </PageContext.Provider>
          </LocaleContext.Provider>
        </HelmetProvider>
      </RouterContext.Provider>
    </StrictMode>
  );
};

Provider.defaultProps = {
  helmetContext: {},
  intl: {
    lang: 'en',
    messages: {},
  },
};

Provider.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  app: PropTypes.objectOf(PropTypes.any).isRequired,
  restApp: PropTypes.objectOf(PropTypes.any).isRequired,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  intl: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node.isRequired,
  helmetContext: PropTypes.objectOf(PropTypes.any),
  routerContext: PropTypes.objectOf(PropTypes.any),
};

export default Provider;
