/* eslint-disable max-len */
import loadable from '@loadable/component';
import { find as findTrackingCodes } from 'redux/modules/trackingCodes';
import { find as findTrackingCodeVendors } from 'redux/modules/trackingCodeVendors';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';
import { batch } from 'react-redux';

const TrackingEventsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-tracking-events' */ './TrackingEvents'),
  {
    fallback: <LoadingComponent />,
  }
);

const TrackingCodes = (prop: any) => <TrackingEventsLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises: any = [];

    const state = getState();
    const trackingCodes = state.trackingCodes.result.all;
    const trackingCodeVendors = state.trackingCodeVendors.result.all;

    if (!trackingCodes) {
      promises.push(() =>
        dispatch(
          findTrackingCodes('all', {
            query: {
              $paginate: false,
              $limit: 1000,
            },
          })
        ).catch(console.error)
      );
    }
    if (!trackingCodeVendors) {
      promises.push(() =>
        dispatch(
          findTrackingCodeVendors('all', {
            query: {
              $paginate: false,
              $limit: 1000,
            },
          })
        ).catch(console.error)
      );
    }

    // just one reflow call
    await new Promise((resolve) => {
      batch(async () => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
        const finalPromises = promises.map((func) => func());
        await Promise.all(finalPromises);
        // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      });
    });
  },
})(TrackingCodes);
