import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ParcelsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-parcels-v2' */ './Parcels'),
  {
    fallback: <LoadingComponent />,
  }
);

const Parcels = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <ParcelsLoadable {...prop} />
);

export default Parcels;
