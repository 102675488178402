import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Autocomplete, Popper, PopperProps, TextField } from '@mui/material';
import deepEqual from 'deep-equal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from 'redux/modules/settings';
import { useFind } from 'store';
import type { Account } from '../../../api/database/models';

const getOptionSelected = (option: any, value: any): boolean => {
  return option?.value === value?.value;
};

const PopperComponent = ({ children, ...rest }: PopperProps) => {
  return (
    <Popper {...rest} style={{ width: 400, maxWidth: '80vw' }} placement="bottom-start">
      {children}
    </Popper>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default ({ black = false, sx = {} }: { black?: boolean; sx?: any }): React.ReactElement => {
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const {
    set,
    state: { account: userAccount },
  } = useSettings({
    shouldUpdate: {
      toJSON: () => 'SelectorAccount',
      test: (prev, next) => {
        return !deepEqual(next.account, prev.account);
      },
    },
  });
  useEffect(() => {
    setSelectedValue(
      userAccount && {
        label: userAccount.name || 'All Accounts',
        value: userAccount.id || 'all',
        account: userAccount,
      }
    );
  }, [userAccount]);

  const textSearch = inputValue.replace('All Accounts', '');

  const { data, isFetching } = useFind<Account>('accounts', {
    // skip: !textSearch,
    keepBeforeDataUntileFetchFinish: true,
    query: {
      $limit: 20,
      $paginate: false,
      ...(textSearch && {
        name: {
          $like: `%${textSearch}%`,
        },
      }),
    },
  });

  const options = useMemo(() => {
    if (!data && !isFetching) {
      return [];
    }
    if (!data) {
      return [];
    }

    const _option: {
      label: string;
      value: string | number;
      account: Account | string;
    }[] = [
      {
        label: 'All Accounts',
        value: 'all',
        account: 'all',
      },
    ].concat(
      data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          account: item,
        };
      })
    );
    return _option;
  }, [isFetching]);

  const handleAccountMenuItemClick = useCallback((_event: React.SyntheticEvent, account: any) => {
    const { account: accountRoot } = account;
    const accountSelected = accountRoot?.uid
      ? { id: accountRoot.id, uid: accountRoot.uid, name: accountRoot.name }
      : 'all';
    set({ account: accountSelected });
  }, []);

  return (
    <Autocomplete
      value={selectedValue as any}
      size="small"
      style={{ width: 240 }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
        '& .MuiAutocomplete-input': { color: 'white' },
        '& .MuiAutocomplete-endAdornment .MuiIconButton-sizeMedium': { padding: 0 },
        '& .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator': {
          padding: 0.1,
          bgcolor: 'transparent',
        },
        ...sx,
      }}
      options={options}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      popupIcon={<KeyboardArrowDownIcon />}
      isOptionEqualToValue={getOptionSelected}
      PopperComponent={PopperComponent}
      autoHighlight
      getOptionLabel={(option) => option.label || ''}
      onChange={handleAccountMenuItemClick}
      renderInput={(params) => {
        return (
          <TextField
            sx={{
              '& .MuiAutocomplete-endAdornment button': {
                marginLeft: (theme) => theme.spacing(0.5),
              },
            }}
            {...params}
            value={inputValue || ''}
            placeholder="Choose a account"
            // eslint-disable-next-line react/jsx-no-duplicate-props
          />
        );
      }}
    />
  );
};
