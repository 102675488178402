import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const CN33ReceptacleListLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-CN33ReceptacleList-v2' */ './CN33ReceptacleList'),
  {
    fallback: <LoadingComponent />,
  }
);

const Parcels = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <CN33ReceptacleListLoadable {...prop} />
);

export default Parcels;
