import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(6133) FIXME: 'LoadingComponent' is declared but its value is ne... Remove this comment to see the full error message
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { find as findStoresPlatforms } from 'redux/modules/storesPlatforms';
import { find as findStores } from 'redux/modules/stores';
import { batch } from 'react-redux';

const NewStoreLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-NewStore' */ './NewStore'),
  {
    // @ts-expect-error ts-migrate(2749) FIXME: 'LoadingComponent' refers to a value, but is being... Remove this comment to see the full error message
    fallback: <LoadingComponent />,
  }
);

// @ts-expect-error ts-migrate(2749) FIXME: 'NewStoreLoadable' refers to a value, but is being... Remove this comment to see the full error message
const NewStore = (prop: any) => <NewStoreLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { getState, dispatch } }: any) => {
    const promises: any = [];

    const state = getState();

    if (!state.storesPlatforms.result.all) {
      promises.push(() =>
        dispatch(
          findStoresPlatforms('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (params.id) {
      promises.push(() =>
        dispatch(
          findStores('editStore', {
            query: {
              $paginate: false,
              uid: params.id,
            },
          })
        ).catch(() => null)
      );
    }

    await new Promise((resolve) => {
      batch(async () => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
        const finalPromises = promises.map((func) => func());
        await Promise.all(finalPromises);
        // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      });
    });
  },
})(NewStore);
