import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
import { find as findAddressTypes } from 'redux/modules/addressTypes';

// @ts-expect-error hide this
import provideHooks from 'redial/lib/provideHooks';

const ServiceCountriesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-address' */ './Address'),
  {
    fallback: <LoadingComponent />,
  }
);

const ServiceCountries = (prop: any) => <ServiceCountriesLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { dispatch } }: any) => {
    const promises = [];
    promises.push(
      dispatch(
        findAddressTypes(`all`, {
          query: {
            $paginate: false,
          },
        })
      )
    );
    await Promise.all(promises);
  },
})(ServiceCountries);
