/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { createSvgIcon } from '@mui/material';

const useStyles = makeStyles(
  {
    icon: {
      width: 160,
      height: 220,
    },
  },
  { name: 'PowerIcon' }
);

// eslint-disable-next-line react/prop-types
export default ({ className, ...props }: any) => {
  const classes = useStyles();
  const Icon = createSvgIcon(
    <>
      <defs>
        <linearGradient
          id="f80a5d58-5c0a-458e-bb3a-8e0d3592bfed"
          x1="84.13"
          y1="48.8"
          x2="88.58"
          y2="49.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5333ed" />
          <stop offset={1} stopColor="#2cd4d9" stopOpacity={0} />
        </linearGradient>

        <linearGradient
          id="e9c36294-7fe8-4a10-a4e2-b9392292a346"
          x1="27.21"
          y1="40.95"
          x2="36.75"
          y2="40.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset="0.04" stopColor="#e2e2e4" />
          <stop offset="0.09" stopColor="#bdbcc1" />
          <stop offset="0.16" stopColor="#9c9ba2" />
          <stop offset="0.22" stopColor="#807f87" />
          <stop offset="0.3" stopColor="#696872" />
          <stop offset="0.39" stopColor="#585662" />
          <stop offset="0.5" stopColor="#4c4a56" />
          <stop offset="0.64" stopColor="#454350" />
          <stop offset={1} stopColor="#43414e" />
        </linearGradient>
        <linearGradient
          id="0ec6c475-5734-4359-b039-a021c5b3d8d9"
          x1="110.19"
          y1="88.8"
          x2="119.73"
          y2="88.8"
          xlinkHref="#e9c36294-7fe8-4a10-a4e2-b9392292a346"
        />
      </defs>
      <g style={{ isolation: 'isolate' }}>
        <g id="29f8a410-2cbd-4727-bbc2-7b051085aa30" data-name="figures and interactions">
          <g>
            <ellipse cx="86.66" cy="179.64" rx="51.51" ry="29.75" style={{ fill: '#dfe9f8' }} />

            <g>
              <path
                d="M90.67,43.5a1.83,1.83,0,0,0-1.44-1.79,2.26,2.26,0,1,0-4.1.87,3.92,3.92,0,0,0-.5,7.38,2.72,2.72,0,0,0-.09.67s0,.09,0,.13a3.31,3.31,0,1,0,4.73,1.73,2.71,2.71,0,0,0,0-3.73,3.81,3.81,0,0,0,.6-3.75A1.83,1.83,0,0,0,90.67,43.5Z"
                style={{ fill: '#2cd4d9' }}
              />
              <circle cx="85.2" cy="41.99" r="1.93" style={{ fill: '#120667' }} />
              <ellipse
                cx="66.88"
                cy="60.06"
                rx="3.35"
                ry="5.79"
                transform="translate(-22.62 68.34) rotate(-47.21)"
                style={{ fill: '#ffc398' }}
              />
              <path
                d="M71.65,62.94l-9.46-6C60.65,63.61,59,79,54.36,77.51c-3.77-1.18,4.15-18.22,4.15-18.22l-3.3-1.82s-7.39,11.15-8.34,17.73c-.89,6.13,6.09,15.36,14,8.42S71.65,62.94,71.65,62.94Z"
                style={{ fill: '#ffc398' }}
              />
              <path
                d="M87.22,171.85v-2.7a2.94,2.94,0,0,0-1.77-2.7l-.82-.35a3.6,3.6,0,0,0-3.89.66,69.31,69.31,0,0,0-6,6.48c-1,1.17-1.63,2.1-2.2,2.73a1.92,1.92,0,0,0,.49,3l.69.39a1.93,1.93,0,0,0,1.92,0L86.53,173A1.38,1.38,0,0,0,87.22,171.85Z"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M73,177.64a1.93,1.93,0,0,0,.94,1.29l.69.39a1.93,1.93,0,0,0,1.92,0l10.42-6"
                style={{
                  fill: 'none',
                  stroke: '#fff',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '1.71981745188161px',
                }}
              />
              <path
                d="M86.51,161.4,81,159.33l-.35,7h0c-.22.83.53,2,1.83,2.75s3,.83,3.47,0a1.1,1.1,0,0,0,.13-.56h0Z"
                style={{ fill: '#ffc398' }}
              />
              <ellipse
                cx="82.15"
                cy="168.15"
                rx="1.41"
                ry="2.44"
                transform="translate(-104.55 155.22) rotate(-60)"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M76.4,171.31l2.34-2.65A9.57,9.57,0,0,0,80,166.93l4.23,2.44A4.18,4.18,0,0,1,83.14,171l-3.36,2.76Z"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M79.56,167.58a3.45,3.45,0,0,1,1.79.5c1.17.67,1.8,1.77,1.41,2.44"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <path
                d="M78.27,169.2a3.32,3.32,0,0,1,1.72.49c1.13.65,1.73,1.7,1.36,2.35"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <path
                d="M76.83,170.86a3.09,3.09,0,0,1,1.6.45c1,.61,1.61,1.59,1.26,2.19"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <line
                x1={72}
                y1="177.82"
                x2="74.25"
                y2="179.12"
                style={{
                  fill: 'none',
                  stroke: '#fff',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '1.71981745188161px',
                }}
              />
              <path
                d="M98.41,178.38v-2.7a2.94,2.94,0,0,0-1.77-2.7l-.82-.35a3.6,3.6,0,0,0-3.89.66,69.31,69.31,0,0,0-6,6.48c-1,1.17-1.63,2.1-2.2,2.73a1.92,1.92,0,0,0,.49,3l.69.39a1.93,1.93,0,0,0,1.92,0l10.86-6.27A1.38,1.38,0,0,0,98.41,178.38Z"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M84.17,184.18a1.93,1.93,0,0,0,.94,1.29l.69.39a1.93,1.93,0,0,0,1.92,0l10.42-6"
                style={{
                  fill: 'none',
                  stroke: '#fff',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '1.71981745188161px',
                }}
              />
              <path
                d="M97.7,167.93l-5.48-2.07-.35,7h0c-.22.83.53,2,1.83,2.75s3,.83,3.47,0a1.1,1.1,0,0,0,.13-.56h0Z"
                style={{ fill: '#ffc398' }}
              />
              <path
                d="M99.46,142.87c0-5.37-1.48-23.18-1.7-25.62a229.7,229.7,0,0,0-4.58-23.66l0-.11c-.36-3.58-3.65-7.85-8.69-10.76-6.68-3.86-13.9-3.86-16.13,0a4.92,4.92,0,0,0-.61,2h0s-1.78,6.3.85,19.6a4.1,4.1,0,0,0,.17.9s2.72,10.09,6.88,26.56A148.1,148.1,0,0,1,80.27,159a3.76,3.76,0,0,0,2.07,3.58c1.71,1,3.57,1,4.14,0a1.26,1.26,0,0,0,.15-.48h0V162a1.64,1.64,0,0,0,0-.38c-.14-3.22-1-22.53-1.32-26.91l0-.22s0-.07,0-.11c-.41-5.24-2.85-23-3.15-25.11l1.08.69s.69,2.22,1,3.3h0a4.58,4.58,0,0,0,.33,1.22,205.72,205.72,0,0,1,5.26,25l0,.16a2.68,2.68,0,0,0,.06.49c1.67,12.51,2.35,22.64,1.95,25.79h0A3.66,3.66,0,0,0,94,169.27c1.71,1,3.57,1,4.14,0a1.32,1.32,0,0,0,.16-.71h0S99.5,148.25,99.46,142.87Z"
                style={{ fill: '#0c022f' }}
              />
              <ellipse
                cx="93.34"
                cy="174.68"
                rx="1.41"
                ry="2.44"
                transform="translate(-104.61 168.17) rotate(-60)"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M100.2,69.09l0,0a4.87,4.87,0,0,0-2.06-2.12L98,66.86l0,0L75.71,54a14.63,14.63,0,0,0-6.35-1.81,7.84,7.84,0,0,0-1.09.08,8.38,8.38,0,0,0-3.56,1.14l-.11.07a5.87,5.87,0,0,0-2.55,6.69c1.12,3.88,3.18,11,4.42,15.19a24.54,24.54,0,0,1,1,7h0c-.21,3.87,3.3,8.81,9,12.08,6.89,4,14.33,4,16.63,0a5.05,5.05,0,0,0,.62-1.9h0v0a6.27,6.27,0,0,0,0-1.53v-4l0-.21C93.92,86,104.32,76.11,100.2,69.09Z"
                style={{ fill: '#120667' }}
              />
              <path
                d="M87.59,177.84l2.34-2.65a9.57,9.57,0,0,0,1.29-1.74l4.23,2.44a4.18,4.18,0,0,1-1.13,1.65L91,180.31Z"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M90.75,174.11a3.45,3.45,0,0,1,1.79.5c1.17.67,1.8,1.77,1.41,2.44"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <path
                d="M89.46,175.73a3.32,3.32,0,0,1,1.72.49c1.13.65,1.73,1.7,1.36,2.35"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <path
                d="M88,177.39a3.09,3.09,0,0,1,1.6.45c1,.61,1.61,1.59,1.26,2.19"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <path
                d="M79,168.36a3.32,3.32,0,0,1,1.72.49c1.13.65,1.73,1.7,1.36,2.35"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <path
                d="M77.52,170a3.09,3.09,0,0,1,1.6.45c1,.61,1.61,1.59,1.26,2.19"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <path
                d="M90.15,174.89a3.32,3.32,0,0,1,1.72.49c1.13.65,1.73,1.7,1.36,2.35"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <path
                d="M88.71,176.55a3.09,3.09,0,0,1,1.6.45c1,.61,1.61,1.59,1.26,2.19"
                style={{
                  fill: 'none',
                  stroke: '#120667',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.5px',
                }}
              />
              <ellipse
                cx="97.15"
                cy="70.31"
                rx="3.35"
                ry="5.79"
                transform="translate(-12.32 119.29) rotate(-60)"
                style={{ fill: '#ffc398' }}
              />
              <path
                d="M102.44,72.07,91.88,68.28c0,6.89.73,25.31-4.12,24.93-3.94-.31.84-17.88.84-17.88l-4.3-.91s-4.61,13.42-3.54,20,9.58,8.73,15.73.23S102.44,72.07,102.44,72.07Z"
                style={{ fill: '#ffc398' }}
              />
              <circle
                cx="79.5"
                cy="43.91"
                r="3.38"
                transform="translate(9.09 101.28) rotate(-68.08)"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M84.75,59.73l0-9.4-8.75,0V63.12C76.07,66,83.46,63.45,84.75,59.73Z"
                style={{ fill: '#eba87c' }}
              />
              <path
                d="M76.05,56l0-4.49,8.69.28v2.5c-1.25,2.19-3.13,3.15-7,2.5A3.67,3.67,0,0,1,76.05,56Z"
                style={{ fill: '#ffc398', opacity: '0.5', mixBlendMode: 'multiply' }}
              />
              <path
                d="M87.06,41.51a9.68,9.68,0,0,1,1.57,2.28,7.73,7.73,0,0,1-.39,5,9.51,9.51,0,0,0-.15,3.44c.12,1.64-.25,4.32-1.85,4.67a4.29,4.29,0,0,1-1.47-.31s0-3.76,0-3.85c.23-1.29,1.72-2.61,1.57-2.69a10.61,10.61,0,0,0,1.56-3.32c.5-1.92.35-2.54.09-2.84a1.74,1.74,0,0,0-1.26-.75A2,2,0,0,0,87.06,41.51Z"
                style={{
                  mixBlendMode: 'multiply',
                  fill: 'url(#f80a5d58-5c0a-458e-bb3a-8e0d3592bfed)',
                }}
              />
              <path
                d="M86.82,52.23a1.72,1.72,0,0,0-.76-2.31s-.06,0-.09,0L86.53,48a5.51,5.51,0,0,0-10.59-3.06l-1.28,4.41c-1.14,3.95.79,5.27,3.42,6A6,6,0,0,0,84.69,53,1.69,1.69,0,0,0,86.82,52.23Z"
                style={{ fill: '#ffc398' }}
              />
              <path
                d="M87.56,47.73c0-.14,1-2.34.59-3.48s-1.83-1.13-1.83-1.13S86,40.8,84.1,40.84c0-.42-3.66-1.5-5.41-.64-1.4.69.09,2.74,1.54,3.32a2.77,2.77,0,0,0,1.92,0l0,.06s1.37-.57,2,.23a1.3,1.3,0,0,1-.31,1.9,3.5,3.5,0,0,0-.21.39,2.14,2.14,0,0,0,1.19,2.78l.09,0c-.18.15-1.12,1-.88,1.61l.64.37a1.32,1.32,0,0,1,1.35-1,.64.64,0,0,1,.37.16A6.93,6.93,0,0,0,87.56,47.73Z"
                style={{ fill: '#2cd4d9' }}
              />

              <g>
                <line
                  x1="32.29"
                  y1="37.99"
                  x2="125.11"
                  y2="91.62"
                  style={{
                    fill: 'none',
                    stroke: '#43414e',
                    strokeMiterlimit: 10,
                    strokeWidth: 2,
                  }}
                />

                <g>
                  <polygon
                    points="21.83 38.76 37.84 48 47.74 42.23 47.74 41.49 31.74 32.25 21.83 38.01 21.83 38.76"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="21.83 38.01 37.84 47.25 47.74 41.49 31.74 32.25 21.83 38.01"
                    style={{ fill: '#6e7075' }}
                  />
                  <polygon
                    points="31.7 19.39 31.7 32.24 47.74 41.5 47.74 28.65 31.7 19.39"
                    style={{ fill: '#43414e' }}
                  />

                  <g>
                    <g>
                      <polygon
                        points="31.98 21.72 31.98 20.1 34.42 21.51 34.42 23.18 31.98 21.77 31.98 21.72"
                        style={{ fill: '#120667' }}
                      />
                      <polygon
                        points="34.42 23.18 34.42 24.27 31.98 22.86 31.98 21.77 34.42 23.18"
                        style={{ fill: '#2cd4d9' }}
                      />
                    </g>
                    <polygon
                      points="31.98 22.86 34.42 24.27 34.42 33.32 31.98 31.91 31.98 22.86"
                      style={{ fill: '#120667' }}
                    />
                    <polygon
                      points="34.42 21.51 42.07 25.92 47.4 28.98 47.4 30.61 47.4 30.66 34.42 23.18 34.42 21.51"
                      style={{ fill: '#fff' }}
                    />
                    <polygon
                      points="34.42 23.18 47.4 30.66 47.4 40.79 42.07 37.73 34.42 33.32 34.42 23.18"
                      style={{ fill: '#dfe9f8' }}
                    />
                    <polygon
                      points="33.85 21.89 33.85 22.17 32.49 21.39 32.49 21.11 33.85 21.89"
                      style={{ fill: '#2cd4d9' }}
                    />
                  </g>
                  <polygon
                    points="27.21 40.31 33.1 43.72 36.75 41.6 30.86 38.19 27.21 40.31"
                    style={{ fill: 'url(#e9c36294-7fe8-4a10-a4e2-b9392292a346)' }}
                  />
                  <polygon
                    points="31.15 37.97 37.05 41.38 37.97 40.85 32.07 37.44 31.15 37.97"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="31.57 37.15 30.75 36.68 29.83 37.21 30.65 37.68 31.57 37.15"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="30.06 34.91 28.89 34.24 27.97 34.77 29.13 35.44 30.06 34.91"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="32.47 33.52 31.3 32.85 30.38 33.38 31.54 34.05 32.47 33.52"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="43.91 42.86 42.26 41.92 41.34 42.45 42.99 43.39 43.91 42.86"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="45.08 42.15 42.94 40.94 42.02 41.47 44.16 42.68 45.08 42.15"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="46.28 41.5 44.64 40.56 43.72 41.09 45.36 42.03 46.28 41.5"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="29.41 35.91 28.59 35.44 27.67 35.97 28.49 36.44 29.41 35.91"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="30.5 36.54 29.68 36.06 28.76 36.6 29.57 37.07 30.5 36.54"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="35.5 38.01 34.68 37.54 33.76 38.07 34.58 38.54 35.5 38.01"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="31.15 35.51 30.33 35.03 29.41 35.57 30.23 36.04 31.15 35.51"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="32.24 36.14 31.42 35.66 30.5 36.19 31.32 36.67 32.24 36.14"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="33.34 36.77 32.52 36.3 31.59 36.83 32.41 37.3 33.34 36.77"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="34.42 37.4 33.6 36.92 32.68 37.46 33.5 37.93 34.42 37.4"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="40.91 41.15 40.09 40.67 39.17 41.2 39.99 41.67 40.91 41.15"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="41.99 41.77 41.16 41.29 40.24 41.82 41.06 42.3 41.99 41.77"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="36.56 38.64 35.74 38.17 34.82 38.7 35.64 39.17 36.56 38.64"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="37.65 39.27 36.83 38.8 35.91 39.33 36.73 39.8 37.65 39.27"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="38.75 39.91 37.92 39.43 37 39.96 37.82 40.43 38.75 39.91"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="39.84 40.53 39.01 40.06 38.09 40.59 38.91 41.06 39.84 40.53"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="36.15 37.03 35.33 36.55 34.41 37.08 35.23 37.56 36.15 37.03"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="31.8 34.52 30.98 34.05 30.06 34.58 30.88 35.05 31.8 34.52"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="32.89 35.15 32.06 34.68 31.14 35.21 31.96 35.68 32.89 35.15"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="33.98 35.79 33.16 35.31 32.24 35.84 33.06 36.32 33.98 35.79"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="35.07 36.41 34.25 35.94 33.33 36.47 34.15 36.94 35.07 36.41"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="41.56 40.16 40.74 39.69 39.82 40.22 40.64 40.69 41.56 40.16"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="42.63 40.78 41.81 40.31 40.89 40.84 41.71 41.31 42.63 40.78"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="37.21 37.66 36.39 37.18 35.47 37.71 36.29 38.19 37.21 37.66"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="38.3 38.29 37.48 37.81 36.55 38.34 37.37 38.82 38.3 38.29"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="39.39 38.92 38.57 38.45 37.65 38.98 38.47 39.45 39.39 38.92"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="40.48 39.55 39.66 39.07 38.74 39.6 39.56 40.08 40.48 39.55"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="37.9 36.65 37.08 36.17 36.15 36.7 36.97 37.18 37.9 36.65"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="33.55 34.14 32.73 33.67 31.8 34.2 32.62 34.67 33.55 34.14"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="34.63 34.77 33.81 34.3 32.89 34.83 33.71 35.3 34.63 34.77"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="35.73 35.41 34.91 34.93 33.99 35.46 34.81 35.94 35.73 35.41"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="36.82 36.03 36 35.56 35.08 36.09 35.9 36.56 36.82 36.03"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="43.31 39.78 42.48 39.31 41.56 39.84 42.38 40.31 43.31 39.78"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="44.38 40.4 43.56 39.93 42.64 40.46 43.46 40.93 44.38 40.4"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="38.96 37.28 38.13 36.8 37.21 37.33 38.03 37.81 38.96 37.28"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="40.05 37.91 39.22 37.43 38.3 37.96 39.12 38.44 40.05 37.91"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="41.14 38.54 40.32 38.06 39.4 38.6 40.22 39.07 41.14 38.54"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="42.23 39.17 41.41 38.69 40.49 39.22 41.31 39.7 42.23 39.17"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="39.07 41.47 38.25 41 37.33 41.53 38.15 42 39.07 41.47"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="40.16 42.1 39.34 41.63 38.42 42.16 39.24 42.63 40.16 42.1"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="41.25 42.73 40.43 42.26 39.51 42.79 40.33 43.27 41.25 42.73"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="42.34 43.36 41.52 42.89 40.6 43.42 41.42 43.89 42.34 43.36"
                    style={{ fill: '#43414e' }}
                  />
                </g>
                <g>
                  <polygon
                    points="104.81 86.6 120.82 95.84 130.73 90.07 130.73 89.34 114.72 80.1 104.81 85.85 104.81 86.6"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="104.81 85.85 120.82 95.09 130.73 89.34 114.72 80.1 104.81 85.85"
                    style={{ fill: '#6e7075' }}
                  />
                  <polygon
                    points="114.69 67.23 114.69 80.08 130.73 89.34 130.73 76.49 114.69 67.23"
                    style={{ fill: '#43414e' }}
                  />
                  <g>
                    <g>
                      <polygon
                        points="114.96 69.57 114.96 67.94 117.4 69.35 117.4 71.02 114.96 69.61 114.96 69.57"
                        style={{ fill: '#120667' }}
                      />

                      <polygon
                        points="117.4 71.02 117.4 72.11 114.96 70.7 114.96 69.61 117.4 71.02"
                        style={{ fill: '#2cd4d9' }}
                      />
                    </g>
                    <polygon
                      points="114.96 70.7 117.4 72.11 117.4 81.16 114.96 79.75 114.96 70.7"
                      style={{ fill: '#120667' }}
                    />

                    <polygon
                      points="117.4 69.35 125.05 73.76 130.38 76.83 130.38 78.45 130.38 78.5 117.4 71.02 117.4 69.35"
                      style={{ fill: '#fff' }}
                    />
                    <polygon
                      points="117.4 71.02 130.38 78.5 130.38 88.63 125.05 85.57 117.4 81.16 117.4 71.02"
                      style={{ fill: '#dfe9f8' }}
                    />
                    <polygon
                      points="116.83 69.73 116.83 70.01 115.47 69.23 115.47 68.95 116.83 69.73"
                      style={{ fill: '#2cd4d9' }}
                    />
                  </g>
                  <polygon
                    points="110.19 88.15 116.08 91.56 119.73 89.44 113.84 86.03 110.19 88.15"
                    style={{ fill: 'url(#0ec6c475-5734-4359-b039-a021c5b3d8d9)' }}
                  />

                  <polygon
                    points="114.13 85.81 120.03 89.22 120.95 88.69 115.06 85.28 114.13 85.81"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="114.56 84.99 113.73 84.52 112.81 85.05 113.63 85.52 114.56 84.99"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="113.04 82.75 111.87 82.08 110.95 82.61 112.11 83.28 113.04 82.75"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="115.45 81.36 114.28 80.69 113.36 81.22 114.52 81.89 115.45 81.36"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="126.89 90.7 125.24 89.76 124.32 90.29 125.97 91.23 126.89 90.7"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="128.06 89.99 125.92 88.78 125 89.31 127.14 90.52 128.06 89.99"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="129.27 89.34 127.62 88.4 126.7 88.93 128.34 89.87 129.27 89.34"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="112.39 83.75 111.57 83.28 110.65 83.81 111.47 84.28 112.39 83.75"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="113.48 84.38 112.66 83.91 111.74 84.44 112.56 84.91 113.48 84.38"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="118.48 85.85 117.66 85.38 116.74 85.91 117.56 86.38 118.48 85.85"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="114.13 83.35 113.31 82.88 112.39 83.41 113.21 83.88 114.13 83.35"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="115.22 83.98 114.4 83.5 113.48 84.03 114.3 84.51 115.22 83.98"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="116.32 84.61 115.5 84.14 114.58 84.67 115.4 85.14 116.32 84.61"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="117.41 85.24 116.58 84.77 115.66 85.3 116.48 85.77 117.41 85.24"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="123.89 88.99 123.07 88.51 122.15 89.04 122.97 89.52 123.89 88.99"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="124.97 89.61 124.15 89.13 123.22 89.67 124.05 90.14 124.97 89.61"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="119.54 86.48 118.72 86.01 117.8 86.54 118.62 87.01 119.54 86.48"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="120.63 87.11 119.81 86.64 118.89 87.17 119.71 87.64 120.63 87.11"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="121.73 87.75 120.91 87.27 119.99 87.8 120.81 88.28 121.73 87.75"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="122.82 88.37 122 87.9 121.07 88.43 121.89 88.9 122.82 88.37"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="119.13 84.87 118.31 84.39 117.39 84.92 118.21 85.4 119.13 84.87"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="114.78 82.37 113.96 81.89 113.04 82.42 113.86 82.9 114.78 82.37"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="115.87 82.99 115.05 82.52 114.13 83.05 114.95 83.52 115.87 82.99"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="116.97 83.63 116.14 83.15 115.22 83.68 116.04 84.16 116.97 83.63"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="118.05 84.26 117.23 83.78 116.31 84.31 117.13 84.79 118.05 84.26"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="124.54 88 123.72 87.53 122.8 88.06 123.62 88.53 124.54 88"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="125.61 88.62 124.79 88.15 123.87 88.68 124.69 89.15 125.61 88.62"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="120.19 85.5 119.37 85.03 118.45 85.56 119.27 86.03 120.19 85.5"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="121.28 86.13 120.46 85.65 119.54 86.18 120.36 86.66 121.28 86.13"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="122.38 86.76 121.55 86.29 120.63 86.82 121.45 87.29 122.38 86.76"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="123.46 87.39 122.64 86.92 121.72 87.45 122.54 87.92 123.46 87.39"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="120.88 84.49 120.06 84.01 119.14 84.54 119.96 85.02 120.88 84.49"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="116.53 81.99 115.71 81.51 114.79 82.04 115.61 82.52 116.53 81.99"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="117.62 82.61 116.8 82.14 115.87 82.67 116.69 83.14 117.62 82.61"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="118.71 83.25 117.89 82.77 116.97 83.3 117.79 83.78 118.71 83.25"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="119.8 83.88 118.98 83.4 118.06 83.93 118.88 84.41 119.8 83.88"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="126.29 87.62 125.47 87.15 124.55 87.68 125.37 88.15 126.29 87.62"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="127.36 88.24 126.54 87.77 125.62 88.3 126.44 88.77 127.36 88.24"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="121.94 85.12 121.12 84.64 120.2 85.17 121.02 85.65 121.94 85.12"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="123.03 85.75 122.2 85.27 121.28 85.8 122.1 86.28 123.03 85.75"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="124.12 86.38 123.3 85.91 122.38 86.44 123.2 86.91 124.12 86.38"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="125.21 87.01 124.39 86.53 123.47 87.06 124.29 87.54 125.21 87.01"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="122.05 89.31 121.23 88.84 120.31 89.37 121.13 89.84 122.05 89.31"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="123.14 89.94 122.32 89.47 121.4 90 122.22 90.47 123.14 89.94"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="124.24 90.58 123.41 90.1 122.49 90.63 123.31 91.11 124.24 90.58"
                    style={{ fill: '#43414e' }}
                  />
                  <polygon
                    points="125.32 91.2 124.5 90.73 123.58 91.26 124.4 91.73 125.32 91.2"
                    style={{ fill: '#43414e' }}
                  />
                </g>
              </g>
              <path
                d="M88.38,76.28S90.46,74,90.7,72.87L89.14,72l-.17.09S88,72,88.66,70.14L89.1,69a.71.71,0,0,0-.23-.62c-.28-.2-.73.14-.73.14a.58.58,0,0,0-.21-.69c-.39-.28-.73.25-.73.25a.53.53,0,0,0-.23-.64c-.39-.3-.85.28-.85.28l-.62.82.14-1.3s1.07-.92.71-1.85l-1.69,1.69s-1.52,5.85-.39,8A3,3,0,0,0,88.38,76.28Z"
                style={{ fill: '#ffc398' }}
              />
              <path
                d="M58.51,59.28s2.26-1.67-.18-4.08c-1.29-1.27.17-3.31.17-3.31.61.23,1.2-.43,1.2-.43l-1-.46a.83.83,0,0,0-1,.21l-.94,1.1L57.37,51a.65.65,0,0,0-.3-.86c-.41-.26-.87.16-.87.16a.83.83,0,0,0-.29-1,.71.71,0,0,0-.92,0c.43-.55-.46-1.49-1-.7a8.19,8.19,0,0,0-1.11,2.53,17.56,17.56,0,0,0,2.38,6.39Z"
                style={{ fill: '#ffc398' }}
              />
              <path
                d="M76.33,42.73c.64,0,5.22.62,11.3,4.65,0,0,.16.07.16.34a9.26,9.26,0,0,1-.71,1.6s-.15.25-.33.12a40.71,40.71,0,0,0-5-3A33,33,0,0,0,76,44.68C75.71,44.63,75.73,42.73,76.33,42.73Z"
                style={{ fill: '#dfe9f8' }}
              />
              <path
                d="M75.94,43.13a20,20,0,0,1,6,1.68A23.07,23.07,0,0,1,87.7,48l-.12.28a29.7,29.7,0,0,0-5.76-3.26,26.55,26.55,0,0,0-6-1.65Z"
                style={{ fill: '#120667' }}
              />
              <path
                d="M75.82,43.9a21.89,21.89,0,0,1,5.78,1.66,23.07,23.07,0,0,1,5.75,3.24l-.13.28a29.58,29.58,0,0,0-5.75-3.26,27.49,27.49,0,0,0-5.65-1.61Z"
                style={{ fill: '#120667' }}
              />
              <line
                x1="83.19"
                y1="184.36"
                x2="85.44"
                y2="185.66"
                style={{
                  fill: 'none',
                  stroke: '#fff',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '1.71981745188161px',
                }}
              />
            </g>
          </g>
        </g>
      </g>
    </>,
    'Care'
  );
  return <Icon className={clsx(classes.icon, className)} {...props} viewBox="0 0 160 220" />;
};
