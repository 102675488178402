import type { Application } from '@feathersjs/feathers';

export interface Params {
  service?: string;
  query: {
    [x: string]: {
      $in?: string[] | number[] | null;
      $nin?: string[] | number[] | null;
      $lt?: any;
      $lte?: any;
      $gt?: any;
      $gte?: any;
      $ne?: any;
      $or?: any[];
      $like?: string;
    } & any;
    $limit?: number;
    $skip?: number;
    $sort?: any;
    $paginate?: boolean | number;
    $select?: string[];
    include?: string[];
    origin?: string;
  };
}

const defaultReduxActions = ({ namespace }: { namespace: string }) => {
  const LOAD = `redux-bringer/${namespace}/LOAD`;
  const LOAD_SUCCESS = `redux-bringer/${namespace}/LOAD_SUCCESS`;
  const LOAD_FAIL = `redux-bringer/${namespace}/LOAD_FAIL`;

  const LOAD_GET = `redux-bringer/${namespace}/LOAD_GET`;
  const LOAD_GET_SUCCESS = `redux-bringer/${namespace}/LOAD_GET_SUCCESS`;
  const LOAD_GET_FAIL = `redux-bringer/${namespace}/LOAD_GET_FAIL`;

  const CREATE = `redux-bringer/${namespace}/CREATE`;
  const CREATE_SUCCESS = `redux-bringer/${namespace}/CREATE_SUCCESS`;
  const CREATE_FAIL = `redux-bringer/${namespace}/CREATE_FAIL`;

  const UPDATE = `redux-bringer/${namespace}/UPDATE`;
  const UPDATE_SUCCESS = `redux-bringer/${namespace}/UPDATE_SUCCESS`;
  const UPDATE_FAIL = `redux-bringer/${namespace}/UPDATE_FAIL`;

  const PATCH = `redux-bringer/${namespace}/PATCH`;
  const PATCH_SUCCESS = `redux-bringer/${namespace}/PATCH_SUCCESS`;
  const PATCH_FAIL = `redux-bringer/${namespace}/PATCH_FAIL`;

  const REMOVE = `redux-bringer/${namespace}/REMOVE`;
  const REMOVE_SUCCESS = `redux-bringer/${namespace}/REMOVE_SUCCESS`;
  const REMOVE_FAIL = `redux-bringer/${namespace}/REMOVE_FAIL`;

  const initialState = {
    loaded: false,
    loaders: {},
    loadings: {},
    loadeds: {},

    result: {},
    createResult: {},
    patchResult: {},
    getResult: {},
    removeResult: {},
  };

  const reducer = (state = initialState, action: any = {}) => {
    switch (action.type) {
      case LOAD:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case LOAD_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          error: {},
          result: { ...state.result, [action.key]: action.result },
        };
      case LOAD_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          result: {},
          error: action.error,
        };
      case LOAD_GET:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case LOAD_GET_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          getResult: { ...state.result, [action.key]: action.result },
        };
      case LOAD_GET_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          error: action.error,
        };

      case CREATE:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case CREATE_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          createResult: { ...state.result, [action.key]: action.result },
        };
      case CREATE_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          error: action.error,
        };
      case PATCH:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case PATCH_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          patchResult: { ...state.result, [action.key]: action.result },
        };
      case PATCH_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          error: action.error,
        };

      case REMOVE:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case REMOVE_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          removeResult: { ...state.result, [action.key]: action.result },
        };
      case REMOVE_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          error: action.error,
        };
      default:
        return state;
    }
  };

  const find = (key: string, params: Params) => ({
    key,
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: { app: Application }) => {
      if (namespace === 'api') {
        const { service, ...rest } = params;
        if (!service) {
          throw new Error('For Api redux service param is mandatory');
        }
        return app.service(`${service}`).find(rest);
      }

      const service = app.service(`${namespace}`);
      return service.find(params);
    },
  });

  const get = (key: string, id: number, service?: string) => ({
    key,
    types: [LOAD_GET, LOAD_GET_SUCCESS, LOAD_GET_FAIL],
    promise: ({ app }: { app: Application }) => {
      if (namespace === 'api') {
        if (!service) {
          throw new Error('For Api redux service param is mandatory');
        }
        return app.service(`${service}`).get(id);
      }
      return app.service(`${namespace}`).get(id);
    },
  });

  const create = (key: string, data: any, service: string) => ({
    key,
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    promise: ({ app }: { app: Application }) => {
      if (namespace === 'api') {
        if (!service) {
          throw new Error('For Api redux service param is mandatory');
        }
        return app.service(`${service}`).create(data);
      }
      return app.service(`${namespace}`).create(data);
    },
  });

  const update = (key: string, id: number | null, data: any, service?: string) => ({
    key,
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: ({ app }: { app: Application }) => {
      if (namespace === 'api') {
        if (!service) {
          throw new Error('For Api redux service param is mandatory');
        }
        return app.service(`${service}`).update(id, data);
      }
      return app.service(`${namespace}`).update(id, data);
    },
  });
  const patch = (key: string, id: number | null, data: any, service?: string) => ({
    key,
    types: [PATCH, PATCH_SUCCESS, PATCH_FAIL],
    promise: ({ app }: { app: Application }) => {
      if (namespace === 'api') {
        if (!service) {
          throw new Error('For Api redux service param is mandatory');
        }
        return app.service(`${service}`).patch(id, data);
      }
      return app.service(`${namespace}`).patch(id, data);
    },
  });

  const remove = (key: string, id: number | null, params?: Params) => ({
    key,
    types: [REMOVE, REMOVE_SUCCESS, REMOVE_FAIL],
    promise: ({ app }: { app: Application }) => {
      if (namespace === 'api') {
        const service = params?.service;
        if (!service) {
          throw new Error('For Api redux service param is mandatory');
        }
        return app.service(`${service}`).remove(id, params);
      }
      return app.service(`${namespace}`).remove(id, params);
    },
  });

  return {
    reducer,
    find,
    get,
    create,
    update,
    patch,
    remove,
  };
};

export default defaultReduxActions;
