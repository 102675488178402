import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ReportsLoadable = loadable(
  () => import(/* webpackChunkName: 'retailer-Reports' */ './Reports'),
  {
    fallback: <LoadingComponent />,
  }
);

export default ReportsLoadable;
