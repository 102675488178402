import { createSvgIcon } from '@mui/material';

export default (props: any) => {
  const Icon = createSvgIcon(
    <g style={{ isolation: 'isolate' }}>
      <g>
        <polyline
          points="23.86 11.32 23.86 27.09 8.85 27.09"
          style={{
            fill: 'none',
            stroke: '#2cd4d9',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
            mixBlendMode: 'multiply',
          }}
        />
        <polygon
          points="23.86 11.32 17.46 11.32 17.46 4.91 23.86 11.32"
          style={{
            fill: 'none',
            stroke: '#2cd4d9',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
            mixBlendMode: 'multiply',
          }}
        />
        <polyline
          points="17.46 4.91 8.14 4.91 8.14 27.09"
          style={{
            fill: 'none',
            stroke: '#5333ed',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
          }}
        />
        <polyline
          points="13.43 17.75 16.02 20.13 20.24 15.91"
          style={{
            fill: 'none',
            stroke: '#2cd4d9',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
            mixBlendMode: 'multiply',
          }}
        />
      </g>
    </g>,
    'Papercheck'
  );
  return <Icon {...props} viewBox="0 0 32 32" />;
};
