import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const HarmonizeCodesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-HarmonizeCodes' */ './HarmonizeCodes'),
  {
    fallback: <LoadingComponent />,
  }
);

const RatesServices = (prop: any) => <HarmonizeCodesLoadable {...prop} />;

export default RatesServices;
