import loadable from '@loadable/component';
import { find as findAirwayBills } from 'redux/modules/airwayBills';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
// @ts-expect-error ts-migrate(6133) FIXME: 'LoadingComponent' is declared but its value is ne... Remove this comment to see the full error message
import LoadingComponent from 'components/Loaders/LoadingComponent';

const AirwayBillNewLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-AirwayBillNew' */ './AirwayBillNew'),
  {
    // @ts-expect-error ts-migrate(2749) FIXME: 'LoadingComponent' refers to a value, but is being... Remove this comment to see the full error message
    fallback: <LoadingComponent />,
  }
);

// @ts-expect-error ts-migrate(2749) FIXME: 'AirwayBillNewLoadable' refers to a value, but is ... Remove this comment to see the full error message
const AirwayBillNew = (prop: any) => <AirwayBillNewLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises = [];

    promises.push(
      dispatch(
        findAirwayBills('airwayBillDispatchEdit', {
          query: {
            uid: params.id,
            $paginate: false,
            include: ['DispatchComplete', 'Account'],
          },
        })
      ).catch(console.error)
    );

    await Promise.all(promises);
  },
})(AirwayBillNew);
