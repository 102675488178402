import createCache from '@emotion/cache';
import { prefixer, RULESET } from 'stylis';

function globalSelector(element) {
  // eslint-disable-next-line default-case
  switch (element.type) {
    case RULESET:
      element.props = element.props.map((value) => {
        if (value.match(/(:where|:has)\(/)) {
          return value.replace(/\.[^:]+(:where|:has)/, '$1');
        }
        return value;
      });
  }
}

export default function createEmotionCache() {
  // TODO remove prepend: true once JSS is out
  return createCache({ key: 'css', prepend: true, stylisPlugins: [globalSelector, prefixer] });
}
