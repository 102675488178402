import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PrevIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import SendIcon from '@mui/icons-material/SendOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Children, useEffect, useRef, useState } from 'react';
import type { FormRenderProps } from 'react-final-form';
import { Form } from 'react-final-form';
import { useSettings } from 'redux/modules/settings';
import cache from 'utils/globalCache';
import { checkDeepHasErrors } from 'utils/helpers';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Grid,
  Grow,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';

const ComponentGrow = ({
  children: c,
  hideBodyIfHeaderHasErrors,
  headerHasErrors,
}: {
  children: any;
  hideBodyIfHeaderHasErrors: boolean;
  headerHasErrors: boolean;
}) => {
  if (hideBodyIfHeaderHasErrors) {
    return <Grow in={!headerHasErrors}>{c}</Grow>;
  }

  return c;
};

let _clearTimeout: any = null;

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(2),
    },
    buttons: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginTop: theme.spacing(2),
    },
    stepper: {
      background: 'transparent',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      paddingTop: theme.spacing(3),
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      paddingBottom: theme.spacing(3),
      paddingLeft: 0,
      paddingRight: 0,
    },
    wrapper: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
  { name: 'BringerWizard' }
);

const WrapperTooltipError = ({
  hasValidationErrors,
  errors,
  children,
}: {
  hasValidationErrors: boolean;
  children: React.ReactElement;
}) => {
  if (hasValidationErrors) {
    console.log(errors, 'errors');
    return (
      <Tooltip placement="top-end" title="Form has errors or is incomplete">
        {children}
      </Tooltip>
    );
  }

  return children;
};

interface ChildrenProps extends FormRenderProps {
  countPages: number;
  isLastPage: boolean;
  page: number;
  key: any;
  mode: 'accordion' | 'steps';
}

interface PageProps {
  placeInHead?: boolean;
  label: string;
  description?: string;
  children: (props: ChildrenProps) => React.ReactElement | null | boolean;
  validate: (values: any) => any;
}

type PageComponent<P> = React.ReactElement<P> | null;
interface WizardProps {
  mode?: 'steps' | 'accordion';
  formRef?: any;
  onSubmit: (values: any) => any;
  children: PageComponent<PageProps> | PageComponent<PageProps>[] | boolean[];
  mutators?: any;
  initialValues?: any;
  hideBodyIfHeaderHasErrors?: boolean;
  hideSteps?: boolean;
  showSubmitButton?: boolean;
}

const Wizard = (props: WizardProps): React.ReactElement => {
  const ref = useRef<any>();
  const validationsStatus = useRef<any>({ head: {}, body: {} });
  const initialValuesRef = useRef();
  const valuesRef = useRef();
  const {
    formRef,
    initialValues,
    mode: _mode,
    hideBodyIfHeaderHasErrors,
    showSubmitButton,
  } = props;
  const form = formRef || ref;

  const { set: setSettings, state: settings } = useSettings();

  const classes = useStyles();

  if (initialValuesRef.current) {
    initialValuesRef.current = initialValues;
  }

  const { formViews } = settings;
  let formView = formViews?.default || _mode;

  if (props.hideSteps) {
    formView = _mode;
  }

  const [state, setState] = useState({
    page: 0,
    values: initialValues || {},
    mode: formView,
  });

  const handleChangeMode = () => {
    const prevState = { ...state };
    prevState.mode = prevState.mode === 'accordion' ? 'steps' : 'accordion';

    setSettings({
      formViews: {
        ...formViews,
        default: prevState.mode,
      },
    });

    setState(prevState);
  };

  useEffect(() => {
    initialValuesRef.current = initialValues;
    setState((prev) => {
      return {
        ...prev,
        values: initialValues,
      };
    });
  }, [initialValuesRef.current !== initialValues]);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const next = (values: any) => {
    const { children } = props;
    setState((prev) => ({
      ...prev,
      page: Math.min(prev.page + 1, children.length - 1),
      values,
    }));
  };

  const previous = () => {
    setState((prev) => ({
      ...prev,
      page: Math.max(prev.page - 1, 0),
    }));
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  const validate = async (values: any) => {
    const { children } = props;
    const { page, mode } = state;
    if (mode === 'steps') {
      const activePage: React.ReactElement = (
        Children.toArray(children) as React.ReactElement[]
      ).filter((child: React.ReactElement) => !child.props.placeInHead)[page] as React.ReactElement;
      const childrenArrayDirty = Children.toArray(children) as React.ReactElement[];

      const componentsInHead: PageComponent<PageProps>[] = [];
      childrenArrayDirty.forEach((child) => {
        if (child.props.placeInHead) {
          componentsInHead.push(child);
        }
      });

      if (activePage && activePage.props && activePage.props.validate) {
        return new Promise((resolve) => {
          if (_clearTimeout) _clearTimeout();
          const timerId = setTimeout(async () => {
            const bodyErrors = await activePage.props.validate(values);

            if (!validationsStatus.current.body[page]) {
              validationsStatus.current.body[page] = {};
            }

            validationsStatus.current.body[page].errors = bodyErrors;
            validationsStatus.current.body[page].hasErrors = checkDeepHasErrors(bodyErrors);

            const promisesHead = componentsInHead.map((child) => {
              return child.props.validate(values);
            });
            const headErrors = await Promise.all(promisesHead).then((results) => {
              const valdiations = results.reduce((acc, curr, index) => {
                if (!validationsStatus.current.head[index]) {
                  validationsStatus.current.head[index] = {};
                }
                validationsStatus.current.head[index].errors = curr;
                validationsStatus.current.head[index].hasErrors = checkDeepHasErrors(curr);
                acc = { ...acc, ...curr };
                return acc;
              }, {});
              return valdiations;
            });

            resolve({ ...headErrors, ...bodyErrors });
          }, 500);
          _clearTimeout = () => {
            clearTimeout(timerId);
            resolve({});
          };
        });
      }
    } else {
      const childrenArrayDirty = Children.toArray(children) as React.ReactElement[];

      const componentsInHead: PageComponent<PageProps>[] = [];
      childrenArrayDirty.forEach((child) => {
        if (child.props.placeInHead) {
          componentsInHead.push(child);
        }
      });

      const childrenArray = childrenArrayDirty.filter((child) => !child.props.placeInHead);

      // eslint-disable-next-line no-async-promise-executor
      return new Promise((resolve) => {
        if (_clearTimeout) _clearTimeout();

        const timerId = setTimeout(async () => {
          const promisesHead = componentsInHead.map((child) => {
            return child.props.validate(values);
          });
          const promises = childrenArray.map((child) => {
            return child.props.validate(values);
          });
          const errorsHead = await Promise.all(promisesHead).then((results) => {
            const valdiations = results.reduce((acc, curr, index) => {
              if (!validationsStatus.current.head[index]) {
                validationsStatus.current.head[index] = {};
              }
              validationsStatus.current.head[index].errors = curr;
              validationsStatus.current.head[index].hasErrors = checkDeepHasErrors(curr);
              acc = { ...acc, ...curr };
              return acc;
            }, {});
            return valdiations;
          });
          const errorsBody = await Promise.all(promises).then((results) => {
            const valdiations = results.reduce((acc, curr, index) => {
              if (!validationsStatus.current.body[index]) {
                validationsStatus.current.body[index] = {};
              }
              validationsStatus.current.body[index].errors = curr;
              validationsStatus.current.body[index].hasErrors = checkDeepHasErrors(curr);
              acc = { ...acc, ...curr };
              return acc;
            }, {});
            return valdiations;
          });
          resolve({ ...errorsHead, ...errorsBody });
        }, 500);

        _clearTimeout = () => {
          clearTimeout(timerId);
          resolve({});
        };
      });
    }

    return {};
  };

  const _handleSubmit = (values: any) => {
    const { children, onSubmit } = props;
    const { page, mode } = state;
    if (mode === 'steps') {
      const isLastPage =
        page ===
        (Children.toArray(children) as React.ReactElement[]).filter(
          (child) => !child.props.placeInHead
        ).length -
          1;
      if (isLastPage) {
        return onSubmit(values);
      }
      next(values);
    } else {
      return onSubmit(values);
    }
  };

  // const handleButtonClick = () => {
  //   const { loading } = state;
  //   if (!loading) {
  //     setState((prev) => ({ ...prev, loading: true }));
  //   }
  // };

  const { children, mutators } = props;
  const { page, values: valuesState, mode } = state;
  const activePage = (Children.toArray(children) as React.ReactElement[]).filter(
    (item: React.ReactElement) => !item.props.placeInHead
  )[page] as React.ReactElement;
  const countPages = (Children.toArray(children) as React.ReactElement[]).filter(
    (child) => !child.props.placeInHead
  ).length;
  const isLastPage = page === countPages - 1;

  const success = false;
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <Form
      initialValues={valuesState}
      validate={validate}
      onSubmit={_handleSubmit}
      mutators={mutators}
    >
      {({ handleSubmit, submitting, errors, hasValidationErrors, values, ...rest }) => {
        valuesRef.current = values;
        form.current = rest.form;

        const childrenArrayDirty = Children.toArray(children) as React.ReactElement[];

        const componentsInHead: PageComponent<PageProps>[] = [];
        childrenArrayDirty.forEach((child) => {
          if (child.props.placeInHead) {
            componentsInHead.push(child);
          }
        });

        const components = [];

        const childrenArray = childrenArrayDirty.filter((child) => !child.props.placeInHead);

        const keys = Object.keys(validationsStatus.current.head);
        const headerHasErrors =
          keys.length === 0 ||
          keys.some((key) => {
            return validationsStatus.current.head[key].hasErrors;
          });

        components.push(
          <Grid key="header-form" item xs={12}>
            <Paper className={classes.paper} elevation={2}>
              <Grid container spacing={2}>
                <Grid item xs>
                  {/* {componentsInHead.length === 0 && (
                    <Typography variant="h6">Form Settings</Typography>
                  )} */}
                  {componentsInHead.map((child, index) => {
                    return (
                      child &&
                      child.props.children({
                        key: `wizard-header-${index}`,
                        handleSubmit,
                        values,
                        submitting,
                        countPages,
                        isLastPage,
                        page,
                        mode,
                        ...rest,
                      } as any)
                    );
                  })}
                </Grid>
                <Grid item xs="auto">
                  <Tooltip title="Clear Cached Validations" arrow>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        cache.clear();
                      }}
                      startIcon={<CachedIcon />}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </Tooltip>
                </Grid>
                {!props.hideSteps && (
                  <Grid item xs="auto">
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                      <Tooltip title="Stepper View" arrow>
                        <Button
                          onClick={handleChangeMode}
                          variant={mode === 'accordion' ? 'outlined' : 'contained'}
                        >
                          <MoreHorizIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="List View" arrow>
                        <Button
                          onClick={handleChangeMode}
                          variant={mode === 'accordion' ? 'contained' : 'outlined'}
                        >
                          <ListIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        );

        if (mode === 'accordion') {
          components.push(
            <Grid item xs={12} key="accordion-form">
              {childrenArray.map((child, index) => (
                <ComponentGrow
                  hideBodyIfHeaderHasErrors={Boolean(hideBodyIfHeaderHasErrors)}
                  headerHasErrors={headerHasErrors}
                >
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                    >
                      {!!validationsStatus.current.body[index] &&
                        !!validationsStatus.current.body[index].hasErrors && (
                          <ErrorIcon sx={{ marginRight: 2, color: 'error.main' }} />
                        )}
                      {!!validationsStatus.current.body[index] &&
                        !validationsStatus.current.body[index].hasErrors && (
                          <CheckIcon sx={{ marginRight: 2, color: 'success.main' }} />
                        )}
                      {!validationsStatus.current.body[index] && (
                        <CheckIcon color="disabled" sx={{ marginRight: 2 }} />
                      )}
                      <Typography sx={{ width: '33%', flexShrink: 0 }} variant="h6">
                        {child.props.label}
                      </Typography>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {child.props.description}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {child &&
                        child.props.children({
                          key: `wizard-${index}`,
                          handleSubmit,
                          values,
                          submitting,
                          countPages,
                          isLastPage,
                          page,
                          mode,
                          ...rest,
                        })}
                    </AccordionDetails>
                  </Accordion>
                </ComponentGrow>
              ))}
              {(!hideBodyIfHeaderHasErrors ||
                (!!hideBodyIfHeaderHasErrors && !headerHasErrors)) && (
                <div className={classes.buttons}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      {Boolean(showSubmitButton) && (
                        <WrapperTooltipError
                          hasValidationErrors={hasValidationErrors}
                          errors={errors}
                        >
                          <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            // disabled={hasValidationErrors}
                            loading={submitting}
                            startIcon={<SendIcon />}
                          >
                            Submit
                          </LoadingButton>
                        </WrapperTooltipError>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}
              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              {/* <pre>{JSON.stringify(props, 0, 2)}</pre> */}
            </Grid>
          );
        }
        if (mode === 'steps') {
          components.push(
            <Grid item xs={12} key="steps-form">
              <Stepper className={classes.stepper} activeStep={page} nonLinear>
                {childrenArray.map((child, index) => (
                  <Step completed={page > index} key={child.props.label}>
                    <StepLabel>{child.props.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div key={`${page}-wizard`}>
                {activePage &&
                  activePage.props.children({
                    key: `wizard-${page}`,
                    handleSubmit,
                    values,
                    submitting,
                    countPages,
                    isLastPage,
                    page,
                    ...rest,
                  })}
              </div>
              <div className={classes.buttons}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    {page > 0 && (
                      <Button variant="outlined" color="primary" type="button" onClick={previous}>
                        <PrevIcon />
                        Previous
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {!isLastPage && (
                      <WrapperTooltipError
                        hasValidationErrors={
                          validationsStatus.current.body[page] &&
                          validationsStatus.current.body[page].hasErrors
                        }
                      >
                        <LoadingButton
                          // disabled={hasValidationErrors}
                          loading={submitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Next
                          <NextIcon />
                        </LoadingButton>
                      </WrapperTooltipError>
                    )}
                    {(isLastPage || props?.hideSteps) && (
                      <WrapperTooltipError hasValidationErrors={hasValidationErrors}>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={buttonClassname}
                          // disabled={hasValidationErrors}
                          loading={submitting}
                        >
                          Submit
                        </LoadingButton>
                      </WrapperTooltipError>
                    )}
                  </Grid>
                </Grid>
              </div>

              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              {/* <pre>{JSON.stringify(props, 0, 2)}</pre> */}
            </Grid>
          );
        }

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              {components}
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};

// @ts-expect-error ts-migrate(2352) FIXME: Conversion of type '(props: ChildrenProps) => Reac... Remove this comment to see the full error message
Wizard.Page = ({ children }: PageProps): React.ReactElement => children as React.ReactElement;

Wizard.defaultProps = {
  mode: 'steps',
};

export default Wizard;
