import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ParcelsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-parcels-taxed' */ './ParcelsTaxedPending'),
  {
    fallback: <LoadingComponent />,
  }
);

export default ParcelsLoadable;
