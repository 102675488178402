/* eslint-disable no-constant-condition */
/* eslint-disable no-use-before-define */
import * as React from 'react';
import {
  getGridDefaultColumnTypes,
  GridRowModel,
  GridFilterModel,
  GridSortModel,
  GridRowId,
  GridLogicOperator,
  GridFilterOperator,
  GridColDef,
} from '@mui/x-data-grid-pro';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import snackbarHelper from 'utils/snackbarHelper';
import { useFind } from 'store';
import { useNotifier } from 'redux/modules/notifier';
import { getColumnsFromOptions, getInitialState, UseDemoDataOptions } from './useDemoData';

const simplifiedValueGetter = (field: string, colDef: GridColDef) => (row: GridRowModel) => {
  const params = { id: row.id, row, field, rowNode: {} };
  return colDef?.valueGetter?.(params) || row[field];
};

const getRowComparator = (
  sortModel: GridSortModel | undefined,
  columnsWithDefaultColDef: GridColDef[]
) => {
  if (!sortModel) {
    const comparator = () => 0;
    return comparator;
  }
  const sortOperators = sortModel.map((sortItem) => {
    const columnField = sortItem.field
      .replace('__row_group_by_columns_group_', '')
      .replace('__', '');
    const colDef = columnsWithDefaultColDef.find(({ field }) => field === columnField) as any;
    return {
      ...sortItem,
      valueGetter: simplifiedValueGetter(columnField, colDef),
      sortComparator:
        colDef?.sortComparator ||
        ((a: any, b: any) => {
          return a - b;
        }),
    };
  });

  const comparator = (row1: GridRowModel, row2: GridRowModel) =>
    sortOperators.reduce((acc, { valueGetter, sort, sortComparator }) => {
      if (acc !== 0) {
        return acc;
      }
      const v1 = valueGetter(row1);
      const v2 = valueGetter(row2);
      return sort === 'desc' ? -1 * sortComparator(v1, v2) : sortComparator(v1, v2);
    }, 0);

  return comparator;
};

const getFilteredRows = (
  rows: GridRowModel[],
  filterModel: GridFilterModel | undefined,
  columnsWithDefaultColDef: GridColDef[]
) => {
  if (filterModel === undefined || filterModel.items.length === 0) {
    return rows;
  }

  const valueGetters = filterModel.items.map(({ columnField }) =>
    simplifiedValueGetter(
      columnField,
      columnsWithDefaultColDef.find(({ field }) => field === columnField) as any
    )
  );
  const filterFunctions = filterModel.items.map((filterItem) => {
    const { columnField, operatorValue } = filterItem;
    const colDef = columnsWithDefaultColDef.find(({ field }) => field === columnField) as any;

    const filterOperator: any = colDef.filterOperators.find(
      ({ value }: GridFilterOperator) => operatorValue === value
    );

    let parsedValue = filterItem.value;
    if (colDef.valueParser) {
      const parser = colDef.valueParser;
      parsedValue = Array.isArray(filterItem.value)
        ? filterItem.value?.map((x) => parser(x))
        : parser(filterItem.value);
    }

    return filterOperator?.getApplyFilterFn({ filterItem, value: parsedValue }, colDef);
  });

  if (filterModel.logicOperator === GridLogicOperator.Or) {
    return rows.filter((row: GridRowModel) =>
      filterModel.items.some((_, index) => {
        const value = valueGetters[index](row);
        return filterFunctions[index] === null ? true : filterFunctions[index]({ value });
      })
    );
  }
  return rows.filter((row: GridRowModel) =>
    filterModel.items.every((_, index) => {
      const value = valueGetters[index](row);
      return filterFunctions[index] === null ? true : filterFunctions[index]({ value });
    })
  );
};

/**
 * Simulates server data loading
 */
const loadServerRows = (
  rows: GridRowModel[],
  queryOptions: QueryOptions,
  serverOptions: ServerOptions,
  columnsWithDefaultColDef: GridColDef[],
  serverTotalCount: number
): Promise<FakeServerResponse> => {
  const { minDelay = 100, maxDelay = 300, useCursorPagination } = serverOptions;

  if (maxDelay < minDelay) {
    throw new Error('serverOptions.minDelay is larger than serverOptions.maxDelay ');
  }
  const { cursor, page = 0, pageSize } = queryOptions;

  let nextCursor;
  let firstRowIndex;
  let lastRowIndex;

  // call here to the server
  let filteredRows =
    serverTotalCount > pageSize || true
      ? rows
      : getFilteredRows(rows, queryOptions.filterModel, columnsWithDefaultColDef);

  const rowComparator = getRowComparator(queryOptions.sortModel, columnsWithDefaultColDef);
  filteredRows = [...filteredRows].sort(rowComparator);

  const totalRowCount =
    serverTotalCount > pageSize || true ? serverTotalCount : filteredRows.length;
  if (serverTotalCount > pageSize && false) {
    if (!pageSize) {
      firstRowIndex = 0;
      lastRowIndex = filteredRows.length;
    } else if (useCursorPagination) {
      firstRowIndex = cursor ? filteredRows.findIndex(({ id }) => id === cursor) : 0;
      firstRowIndex = Math.max(firstRowIndex, 0); // if cursor not found return 0
      lastRowIndex = firstRowIndex + pageSize;

      nextCursor = lastRowIndex >= filteredRows.length ? undefined : filteredRows[lastRowIndex].id;
    } else {
      firstRowIndex = page * pageSize;
      lastRowIndex = (page + 1) * pageSize;
    }
  }

  const response: FakeServerResponse = {
    returnedRows:
      serverTotalCount > pageSize ? filteredRows : filteredRows.slice(firstRowIndex, lastRowIndex),
    nextCursor,
    totalRowCount,
  };

  return new Promise<FakeServerResponse>((resolve) => {
    resolve(response);
    // setTimeout(() => {
    //   resolve(response);
    // }, delay); // simulate network latency
  });
};

interface FakeServerResponse {
  returnedRows: GridRowModel[];
  nextCursor?: string;
  totalRowCount: number;
}

interface PageInfo {
  totalRowCount?: number;
  nextCursor?: string;
  pageSize?: number;
}

export interface ServerOptions {
  minDelay: number;
  maxDelay: number;
  useCursorPagination?: boolean;
  service?: string;
}

export interface QueryOptions {
  cursor?: GridRowId;
  refreshTime?: any;
  page?: number;
  skip?: boolean;
  pageSize?: number;
  // TODO: implement the behavior liked to following models
  filterModel?: GridFilterModel;
  sortModel?: GridSortModel;
}

const DEFAULT_DATASET_OPTIONS = {
  dataSet: 'Commodity',
  rowLength: 100,
  maxColumns: 6,
  columns: [],
  sortModel: undefined,
  filterModel: undefined,
  columnsOrder: [],
  // treeData: {
  //   averageChildren: 2,
  //   groupingField: 'id',
  //   maxDepth: 3,
  // },
};

const DEFAULT_SERVER_OPTIONS: ServerOptions = {
  minDelay: 100,
  maxDelay: 300,
  useCursorPagination: false,
};

export const createFakeServer = (
  dataSetOptions?: Partial<UseDemoDataOptions>,
  serverOptions?: Partial<ServerOptions>
) => {
  const dataSetOptionsWithDefault: UseDemoDataOptions = {
    ...DEFAULT_DATASET_OPTIONS,
    ...dataSetOptions,
  };
  const serverOptionsWithDefault = { ...DEFAULT_SERVER_OPTIONS, ...serverOptions };

  const columns = getColumnsFromOptions(dataSetOptionsWithDefault);
  const initialState = getInitialState(
    dataSetOptionsWithDefault,
    columns,
    dataSetOptionsWithDefault.columnVisibilityModel
  );
  initialState.sorting = {
    sortModel: dataSetOptionsWithDefault.sortModel,
  };
  initialState.filter = {
    filterModel: dataSetOptionsWithDefault.filterModel,
  };
  initialState.pinnedColumns = dataSetOptionsWithDefault.pinnedColumns;

  const defaultColDef = getGridDefaultColumnTypes();
  const columnsWithDefaultColDef = columns.map((column) => ({
    ...defaultColDef[column.type || 'string'],
    ...column,
  }));

  const useQuery = (queryOptions: QueryOptions) => {
    // const {
    //   data: { rows },
    //   loading: dataGenerationIsLoading,
    // } = useDemoData(dataSetOptionsWithDefault);
    // console.log(rows, 'rows');
    // pass queryOptions to useDemoData and get all datas here filter by server
    // and then filter again in client by loadServerRows

    const { dispatch } = useNotifier();
    const store = React.useRef<{
      totalServerCount?: number;
      clientPageSize?: number;
      lastServerResult?: { data: any[]; total: number; skip: number; limit: number };
    }>({});
    const queryOptionsRef = React.useRef(queryOptions);
    const [response, setResponse] = React.useState<{
      pageInfo: PageInfo;
      data: GridRowModel[];
    }>({ pageInfo: {}, data: [] });

    queryOptionsRef.current = queryOptions;
    const { page = 0, pageSize = 25 } = queryOptions;
    const {
      isFetching,
      data: rows,
      limit,
      total,
      skip,
      refetch,
    } = useFind(serverOptionsWithDefault.service, {
      realtime: false,
      skip: queryOptions.skip,
      query: {
        $include: serverOptionsWithDefault.include,
        queryOptions,
        $limit: pageSize,
        timezoneOffset: new Date().getTimezoneOffset(),
        $skip: page * pageSize,
        ...serverOptionsWithDefault.query,
      },
    });

    const data = { rows, limit, total, skip };

    // console.log(rows, 'rows');

    // interface Response {
    //   pageInfo: PageInfo;
    //   data: GridRowModel[];
    // }

    const isLoading = isFetching;

    React.useEffect(() => {
      // if (dataGenerationIsLoading) {
      //   // dataset is not ready
      //   return () => {
      //     return null;
      //   };
      // }

      queryOptionsRef.current = queryOptions;
      let active = true;

      React.startTransition(() => {
        setResponse((prev) =>
          Object.keys(prev.pageInfo).length === 0 ? prev : { ...prev, pageInfo: {} }
        );
      });

      if (!isFetching) {
        (async function fetchData() {
          try {
            store.current.totalServerCount = data.total;
            store.current.clientPageSize = pageSize;
            store.current.lastServerResult = data;
            const { returnedRows, nextCursor, totalRowCount } = await loadServerRows(
              data.rows || [],
              queryOptions,
              serverOptionsWithDefault,
              columnsWithDefaultColDef,
              data.total
            );
            if (!active) {
              return;
            }
            const newRep = {
              storageKey: dataSetOptionsWithDefault.storageKey,
              data: returnedRows,
              pageInfo: {
                totalRowCount,
                nextCursor,
                pageSize: returnedRows.length,
              },
            };
            React.startTransition(() => {
              setResponse((prev) => (isDeepEqual(prev, newRep) ? prev : newRep));
            });
          } catch (error) {
            snackbarHelper({
              dispatch,
              error,
              message: 'Error requesting server',
            });
          }
        })();
      }

      return () => {
        active = false;
      };
    }, [
      /* dataGenerationIsLoading, */ queryOptions,
      isFetching /* rows */,
      // dataSetOptionsWithDefault.storageKey,
    ]);

    // We use queryOptions pointer to be sure that isLoading===true as soon as the options change
    const effectShouldStart = queryOptionsRef.current !== queryOptions;
    return {
      isLoading: isLoading || effectShouldStart,
      refetch,
      ...response,
    };
  };

  const useQueryClient = () => {
    return {
      data: dataSetOptions?.rows,
      storageKey: dataSetOptionsWithDefault.storageKey,
      pageInfo: {
        totalRowCount: dataSetOptions?.rows?.length,
      },
      refetch: () => {
        return undefined;
      },
      isLoading: false,
    };
  };
  if (dataSetOptions?.rows) {
    return { columns, initialState, useQuery: useQueryClient };
  }

  return { columns, initialState, useQuery };
};
