/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(
  {
    container: {
      width: '100%',
      backgroundColor: 'transparent',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    containerPaddingDefault: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    containerPaddingHome: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    containerFull: {
      width: '100%',
      backgroundColor: 'transparent',
    },
    normal: {
      maxWidth: 1120,
    },
    publicationSize: {
      maxWidth: 1040,
    },
    extremeSize: {
      maxWidth: 1214,
    },
    small: {
      maxWidth: 740,
    },
    spacing0: {},
    spacing1: {
      padding: 24,
    },
    spacing2: {
      padding: 28,
    },
    spacing3: {
      padding: 32,
    },
    spacing4: {
      padding: 36,
    },
    spacing5: {
      padding: 40,
    },

    spacingMargin0: {},
    spacingMargin1: {
      margin: -4,
    },
    spacingMargin2: {
      margin: -8,
    },
    spacingMargin3: {
      margin: -12,
    },
    spacingMargin4: {
      margin: -16,
    },
    spacingMargin5: {
      margin: -20,
    },

    '@media (max-width: 991px)': {
      containerPaddingDefault: {
        paddingLeft: 20,
        paddingRight: 20,
      },
      containerPaddingHome: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      spacing1: {
        padding: 4,
        paddingLeft: 24,
        paddingRight: 24,
      },
      spacing2: {
        padding: 8,
        paddingLeft: 28,
        paddingRight: 28,
      },
      spacing3: {
        padding: 12,
        paddingLeft: 32,
        paddingRight: 32,
      },
      spacing4: {
        padding: 16,
        paddingLeft: 36,
        paddingRight: 36,
      },
      spacing5: {
        padding: 20,
        paddingLeft: 40,
        paddingRight: 40,
      },
    },
  },
  { name: 'WMContainer' }
);

const Container = ({
  children = null,
  className = '',
  fullWidth = false,
  fullWidthPadding = false,
  size = 'normal',
  // spacing = 0,
  padding = 'Default',
  component: Component = 'div',
  ...otherProps
}: {
  children?: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  fullWidthPadding?: boolean;
  size?: 'normal';
  // spacing = 0,
  padding?: 'Default';
  component?: React.ElementType;
}): React.ReactElement => {
  const classes = useStyles();
  return (
    <Component
      className={clsx(
        {
          [classes.container]: !fullWidth,
          [classes[size]]: !fullWidth && !fullWidthPadding && classes[size],
          [classes.containerFull]: fullWidth || fullWidthPadding,
          [classes[`containerPadding${padding}`]]: !fullWidth || fullWidthPadding,
        },
        className
        // classes[`spacing${spacing}`]
      )}
      {...otherProps}
    >
      {children}
      {/* {spacing === 0 ? children : <div className={classes[`spacingMargin${spacing}`]}>{children}</div>} */}
    </Component>
  );
};

Container.defaultProps = {
  children: null,
  className: '',
  fullWidth: false,
  fullWidthPadding: false,
  size: 'normal',
  // spacing: 0,
  padding: 'Default',
  component: 'div',
};

export default Container;
