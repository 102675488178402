import { GridColDefCustom } from 'components/DataGrid/useDemoData';
import { Typography } from '@mui/material';
import formatDate from 'date-fns/format';
import type { ContactForm } from '../../../../api/database/models';

const columns: GridColDefCustom<ContactForm>[] = [
  {
    field: 'id',
    defaultHide: true,
    type: 'number',
    headerName: 'Claim ID',
  },
  {
    field: 'uid',
    headerName: 'Claim UID',
    width: 180,
  },
  {
    field: 'email',
    headerName: 'E-mail',
    width: 220,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 200,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 200,
    renderCell: (params) => {
      return (
        <Typography variant="body2" noWrap>
          {params.row.createdAt
            ? formatDate(new Date(params.row.createdAt), 'MM/dd/yyyy h:mm aaa')
            : '-'}
        </Typography>
      );
    },
  },
  {
    field: '$country.isoCode$',
    headerName: 'Country',
    renderCell: (params) => {
      return (
        <Typography variant="body2" noWrap>
          {params.row?.country?.isoCode}
        </Typography>
      );
    },
  },
];

export default columns;
