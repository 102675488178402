import { useRef, useEffect } from 'react';

const useIsMounted = () => {
  const isMounted = useRef(false);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => () => boolean' is not assi... Remove this comment to see the full error message
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      return isMounted.current;
    };
  }, []);
  return isMounted;
};

export default useIsMounted;
