/* eslint-disable default-param-last */
/* eslint-disable no-unused-expressions */
/* eslint-disable default-case */
/* eslint-disable no-use-before-define */
import { useMemo, useCallback, useEffect, useRef } from 'react';
import cookie from 'js-cookie';
import type { Application } from '@feathersjs/feathers';
import { atom, useSetRecoilState, selector, useRecoilValue, selectorFamily } from 'recoil';
import { useFigbird } from './core';
import type { User } from '../../api/database/models';

const refRealtime: any = {
  counter: 0,
};

interface AtomStructure {
  status: 'idle' | 'success' | 'not-authenticated' | 'error' | 'authenticated';
  error: any | null;
  auth?: {
    accessToken: string;
    authentication: {
      strategy: 'jwt' | 'local';
      accessToken: string;
      payload: {
        aud: string;
        exp: number;
        iat: number;
        iss: string;
        jti: string;
        sub: string;
      };
    };
    user: User;
  };
  loading: boolean;
}

const initAuth = ({ trigger, setSelf, node }) => {
  if (trigger === 'get' && typeof window !== 'undefined' && window?.__data) {
    if (window?.__data?.[node.key]) {
      // Avoid expensive initialization
      setTimeout(() => {
        setSelf(window.__data[node.key]); // Call synchronously to initialize
      }, 0);
    }
  }
};

export const authAtom = atom({
  key: 'auth',
  default: {
    status: 'idle',
    error: null,
    auth: null,
    loading: false,
  },
  effects_UNSTABLE: [initAuth],
});

const defaultSelector = selector({
  key: `authSelector`,
  get: ({ get }) => {
    return get(authAtom);
  },
});

const map = new Map();

interface ShoulUpdate {
  toJSON: () => any;
  test: (prev: AtomStructure, next: AtomStructure) => boolean;
}
interface AuthParams {
  realtime?: boolean;
  autoload?: boolean;
  shouldUpdate?: ShoulUpdate;
}

const authFamilySelector = selectorFamily({
  key: 'authUseSelector',
  get:
    (shouldUpdate: ShoulUpdate) =>
    ({ get }) => {
      const key = `authUseSelectorMap-${JSON.stringify(shouldUpdate.toJSON())}`;
      const prevState = map.get(key);
      const state = get(authAtom);
      if (shouldUpdate.test(prevState || state, state)) {
        map.set(key, state);
        return state;
      }

      if (!prevState) {
        map.set(key, state);
      }
      return prevState || state;
    },
});

function reducer(state, action) {
  switch (action.type) {
    case 'mutating':
      return { ...state, status: 'loading', loading: true, error: null };
    case 'logout':
      return { ...state, status: 'not-authenticated', loading: false, auth: null };
    case 'success':
      return { ...state, status: 'authenticated', loading: false, auth: action.payload };
    case 'success-register':
      return { ...state, status: 'not-authenticated', loading: false, auth: null };
    case 'error':
      return { ...state, status: 'error', loading: false, error: action.payload };
  }
}
interface AuthResult {
  user?: User;
  accessToken: string;
  authentication: {
    accessToken: string;
    payload: {
      aud: 'https://weshipyou.com' | string;
      exp: number;
      iat: number;
      iss: 'feathers' | string;
      jti: string;
      // uid
      sub: string;
    };
    strategy: 'jwt' | 'local' | 'oauth';
  };
}
export function useAuth({ realtime = true, shouldUpdate, autoload = false }: AuthParams = {}) {
  const { feathers, actions } = useFigbird();
  const setState = useSetRecoilState(authAtom);

  const state = useRecoilValue(shouldUpdate ? authFamilySelector(shouldUpdate) : defaultSelector);

  // const [state, dispatch] = useReducer(reducer, atomState);

  const dispatch = (action) => {
    setState((prev) => {
      return reducer(prev, action);
    });
  };

  const mountedRef = useRef();
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const common = [dispatch, feathers, mountedRef];
  const login = useMethod('login', actions.feathersCreated, ...common);
  const refresh = useMethod('refresh', actions.feathersUpdated, ...common);
  const logout = useMethod('logout', actions.feathersRemoved, ...common);
  const load = useMethod('load', actions.feathersCreated, ...common);
  const register = useMethod('register', actions.feathersRemoved, ...common);

  useEffect(() => {
    if (autoload) {
      if (state.status === 'idle') {
        load();
      }
    }

    if (realtime) {
      refRealtime.counter += 1;
      if (refRealtime.counter === 1) {
        const loginListener = (result) => {
          const isMounted = mountedRef.current;
          isMounted && dispatch({ type: 'success', payload: result });
        };
        refRealtime.loginListener = loginListener;
        const logoutListener = (result) => {
          const isMounted = mountedRef.current;
          feathers.authentication
            .removeAccessToken()
            .then(() => feathers.authentication.reset())
            .then(() => {
              isMounted && dispatch({ type: 'logout', payload: result });
            });
        };
        refRealtime.logoutListener = logoutListener;
        feathers.on('login', loginListener);
        feathers.io.on('login', loginListener);

        feathers.on('logout', logoutListener);
        feathers.io.on('logout', logoutListener);
      }
    }

    return () => {
      refRealtime.counter -= 1;
      if (refRealtime.counter === 0) {
        feathers.off('login', refRealtime.loginListener);
        feathers.io.off('login', refRealtime.loginListener);

        feathers.off('logout', refRealtime.logoutListener);
        feathers.io.off('logout', refRealtime.logoutListener);
      }
    };
  }, [realtime]);

  const mutation = useMemo(
    () => ({
      login,
      refresh,
      logout,
      load,
      register,
      auth: state.auth as AuthResult,
      status: state.status,
      error: state.error,
      loading: state.loading, // deprecated, use status instead
    }),
    [login, refresh, logout, load, register, state, setState]
  );

  return mutation;
}

function useMethod(method, action, dispatch: dispatchNotifier, feathers: Application, mountedRef) {
  return useCallback(
    (...args) => {
      dispatch({ type: 'mutating' });
      if (method === 'load') {
        return feathers.authentication.getAccessToken().then((token) => {
          if (!token) {
            const isMounted = mountedRef.current;
            isMounted && dispatch({ type: 'error', payload: 'NoAuthenticated' });
            return;
          }
          return feathers
            .reAuthenticate()
            .then((res) => {
              const isMounted = mountedRef.current;
              isMounted && dispatch({ type: 'success', payload: res });
            })
            .catch((error) => {
              const isMounted = mountedRef.current;
              isMounted && dispatch({ type: 'error', payload: error });
            });
        });
      }
      if (method === 'login') {
        return feathers
          .authenticate(...args)
          .then((res) => {
            const isMounted = mountedRef.current;
            if (isMounted) {
              dispatch({ type: 'success', payload: res });
            }

            return res;
          })
          .catch((error) => {
            const isMounted = mountedRef.current;
            isMounted && dispatch({ type: 'error', payload: error });
            throw error;
          });
      }
      if (method === 'register') {
        return feathers
          .service('users')
          .create(...args)
          .then((res) => {
            const isMounted = mountedRef.current;
            if (isMounted) {
              dispatch({ type: 'success-register', payload: res });
            }

            return res;
          })
          .catch((error) => {
            const isMounted = mountedRef.current;
            isMounted && dispatch({ type: 'error', payload: error });
            throw error;
          });
      }
      if (method === 'refresh') {
        return feathers.authentication.reAuthenticate(true).then((res) => {
          const isMounted = mountedRef.current;
          isMounted && dispatch({ type: 'success', payload: res });
        });
      }
      if (method === 'logout') {
        return feathers
          .logout()
          .then((item) => {
            const isMounted = mountedRef.current;
            cookie.remove('feathers-jwt');
            feathers.authentication
              .removeAccessToken()
              .then(() => feathers.authentication.reset())
              .then(() => {
                isMounted && dispatch({ type: 'logout', payload: item });
              });
          })
          .catch((err) => {
            const isMounted = mountedRef.current;
            isMounted && dispatch({ type: 'error', payload: err });
            throw err;
          });
      }
    },
    [method, action, dispatch]
  );
}
