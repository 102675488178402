import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { find as findAccounts } from 'redux/modules/accounts';
import { find as findAccountTypes } from 'redux/modules/accountTypes';
import { find as findBillingFactors } from 'redux/modules/billingFactors';
import { find as findBillingTiers } from 'redux/modules/billingTiers';
import { find as findRoles } from 'redux/modules/accountRoles';
import { batch } from 'react-redux';

const NewAccountLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-new-account' */ './NewAccount'),
  {
    fallback: <LoadingComponent />,
    ssr: false,
  }
);

const NewAccount = (prop: any) => <NewAccountLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { getState, dispatch } }: any) => {
    const promises: any = [];

    const state = getState();

    const roles = state.accountRoles.result.allRoles;

    const accountTypes = state.accountTypes.result.allAccountTypes;
    const billingFactors = state.billingFactors.result.allBillingFactors;
    const billingTiers = state.billingTiers.result.allBillingTiers;

    if (!roles) {
      promises.push(() =>
        dispatch(
          findRoles('allRoles', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (!accountTypes) {
      promises.push(() =>
        dispatch(
          findAccountTypes('allAccountTypes', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (!billingFactors) {
      promises.push(() =>
        dispatch(
          findBillingFactors('allBillingFactors', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (!billingTiers) {
      promises.push(() =>
        dispatch(
          findBillingTiers('allBillingTiers', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (params.id) {
      promises.push(() =>
        dispatch(
          findAccounts('editAccount', {
            query: {
              $paginate: false,
              uid: params.id,
              include: ['Address', 'User', 'AccountReturnAddress'],
            },
          })
        ).catch(console.error)
      );
    }
    await new Promise((resolve) => {
      batch(async () => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
        const finalPromises = promises.map((func) => func());
        await Promise.all(finalPromises);
        // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      });
    });
  },
})(NewAccount);
