/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import memoize from 'lodash.memoize';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import reduce from 'lodash.reduce';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import startsWith from 'lodash.startswith';
import classNames from 'classnames';
import './src/utils/prototypes';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { FixedSizeList } from 'react-window';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Flag from 'react-world-flags';
import {
  InputAdornment,
  Popover,
  TextField,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@mui/material';
import CountryData from './src/CountryData';

const getDropdownCountryName = (country: any) => {
  return country.localName || country.name;
};

function renderRow(props: any) {
  const {
    index,
    style,
    data: { countries, handleFlagItemClick, onClick },
  } = props;

  const country = countries[index];
  if (!country) {
    return null;
  }

  const _onClick = (e: any) => {
    handleFlagItemClick(country.country, e);
    onClick(country.country, e);
  };

  return (
    <ListItem onClick={_onClick} button style={style} key={index}>
      <ListItemIcon>
        <Flag height={16} code={country.countryIso} />
      </ListItemIcon>
      <ListItemText primary={country.countryName} secondary={country.countryFormat} />
    </ListItem>
  );
}

function VirtualizedList({ countries, handleFlagItemClick, onClick }: any) {
  return (
    <Box sx={{ width: '100%', height: 400, maxWidth: 360, bgcolor: 'background.paper' }}>
      <FixedSizeList
        itemData={{ countries, handleFlagItemClick, onClick }}
        height={400}
        width={360}
        itemSize={46}
        itemCount={200}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  );
}

const ButtonFlags = ({ countries: _countries, selectedCountry, handleFlagItemClick }: any) => {
  const [anchorEl, setAnchorEl] = useState();
  const [countries, setCountries] = useState(_countries);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setAnchorEl(null);
  };

  const onChange = (event: any) => {
    const { value } = event.target;
    const newCountries = _countries.filter(
      (country: any) =>
        String(country.countryName).toLowerCase().indexOf(String(value).toLowerCase()) !== -1 ||
        String(country.countryFormat).toLowerCase().indexOf(String(value).toLowerCase()) !== -1
    );
    setCountries(newCountries);
  };

  const inputRef = useRef();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClick = () => {
    handleClose();
  };

  return (
    <div>
      <Button
        startIcon={
          <Flag
            height={16}
            code={!!selectedCountry && String(selectedCountry.iso2).toUpperCase()}
            fallBack={<span>Unknown</span>}
          />
        }
        size="small"
        aria-describedby={id}
        onClick={handleClick}
      >
        <KeyboardArrowDownIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <TextField
            autoFocus
            fullWidth
            inputRef={inputRef}
            onChange={onChange}
            label="Search country"
          />
        </Box>
        <List component="nav" aria-label="main countries">
          <VirtualizedList
            onClick={onClick}
            handleFlagItemClick={handleFlagItemClick}
            countries={countries}
          />
        </List>
      </Popover>
    </div>
  );
};

class PhoneInput extends React.Component {
  dropdownContainerRef: any;

  dropdownRef: any;

  numberInputRef: any;

  constructor(props: any) {
    super(props);
    this.numberInputRef = React.createRef();
    const { onlyCountries, preferredCountries, hiddenAreaCodes } = new CountryData(
      props.enableAreaCodes,
      props.enableTerritories,
      props.regions,
      props.onlyCountries,
      props.preferredCountries,
      props.excludeCountries,
      props.preserveOrder,
      props.masks,
      props.priority,
      props.areaCodes,
      props.localization,
      props.prefix,
      props.defaultMask,
      props.alwaysDefaultMask
    );

    const inputNumber = props.value ? props.value.replace(/\D/g, '') : '';

    let countryGuess: any;
    if (props.disableInitialCountryGuess) {
      countryGuess = 0;
    } else if (inputNumber.length > 1) {
      // Country detect by phone
      countryGuess =
        this.guessSelectedCountry(
          inputNumber.substring(0, 6),
          props.country,
          onlyCountries,
          hiddenAreaCodes
        ) || 0;
    } else if (props.country) {
      // Default country
      countryGuess = onlyCountries.find((o: any) => o.iso2 === props.country) || 0;
    } else {
      // Empty params
      countryGuess = 0;
    }

    const dialCode =
      inputNumber.length < 2 && countryGuess && !startsWith(inputNumber, countryGuess.dialCode)
        ? countryGuess.dialCode
        : '';

    const formattedNumber =
      inputNumber === '' && countryGuess === 0
        ? ''
        : this.formatNumber(
            (props.disableCountryCode ? '' : dialCode) + inputNumber,
            countryGuess.name ? countryGuess : undefined
          );

    const highlightCountryIndex = onlyCountries.findIndex((o: any) => o === countryGuess);

    this.state = {
      showDropdown: props.showDropdown,

      formattedNumber,
      onlyCountries,
      preferredCountries,
      hiddenAreaCodes,
      selectedCountry: countryGuess,
      highlightCountryIndex,

      queryString: '',
      freezeSelection: false,
      debouncedQueryStingSearcher: debounce(this.searchCountry, 250),
      searchValue: '',
    };
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableClickOutside' does not exist on ty... Remove this comment to see the full error message
    if (document.addEventListener && this.props.enableClickOutside) {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableClickOutside' does not exist on ty... Remove this comment to see the full error message
    if (document.removeEventListener && this.props.enableClickOutside) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentDidUpdate(prevProps: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'country' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (prevProps.country !== this.props.country) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'country' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.updateCountry(this.props.country);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    } else if (prevProps.value !== this.props.value) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.updateFormattedNumber(this.props.value);
    }
  }

  getProbableCandidate = memoize((queryString: any) => {
    if (!queryString || queryString.length === 0) {
      return null;
    }
    // don't include the preferred countries in search
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
    const probableCountries = this.state.onlyCountries.filter((country: any) => {
      return startsWith(country.name.toLowerCase(), queryString.toLowerCase());
    }, this);
    return probableCountries[0];
  });

  guessSelectedCountry = memoize(
    (inputNumber: any, country: any, onlyCountries: any, hiddenAreaCodes: any) => {
      // if enableAreaCodes == false, try to search in hidden area codes to detect area code correctly
      // then search and insert main country which has this area code
      // https://github.com/bl00mber/react-phone-input-2/issues/201
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableAreaCodes' does not exist on type ... Remove this comment to see the full error message
      if (this.props.enableAreaCodes === false) {
        let mainCode;
        hiddenAreaCodes.some((_country: any) => {
          if (startsWith(inputNumber, _country.dialCode)) {
            onlyCountries.some((o: any) => {
              if (_country.iso2 === o.iso2 && o.mainCode) {
                mainCode = o;
                return true;
              }
              return false;
            });
            return true;
          }
          return false;
        });
        if (mainCode) return mainCode;
      }

      const secondBestGuess = onlyCountries.find((o: any) => o.iso2 === country);
      if (inputNumber.trim() === '') return secondBestGuess;

      const bestGuess = onlyCountries.reduce(
        (selectedCountry: any, _country: any) => {
          if (startsWith(inputNumber, _country.dialCode)) {
            if (_country.dialCode.length > selectedCountry.dialCode.length) {
              return _country;
            }
            if (
              _country.dialCode.length === selectedCountry.dialCode.length &&
              _country.priority < selectedCountry.priority
            ) {
              return _country;
            }
          }
          return selectedCountry;
        },
        { dialCode: '', priority: 10001 },
        this
      );

      if (!bestGuess.name) return secondBestGuess;
      return bestGuess;
    }
  );

  // Hooks for updated props
  updateCountry = (country: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
    const { onlyCountries } = this.state;
    let newSelectedCountry;
    if (country.indexOf(0) >= '0' && country.indexOf(0) <= '9') {
      // digit
      newSelectedCountry = onlyCountries.find((o: any) => o.dialCode === +country);
    } else {
      newSelectedCountry = onlyCountries.find((o: any) => o.iso2 === country);
    }
    if (newSelectedCountry && newSelectedCountry.dialCode) {
      this.setState({
        selectedCountry: newSelectedCountry,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableCountryCode' does not exist on ty... Remove this comment to see the full error message
        formattedNumber: this.props.disableCountryCode
          ? ''
          : this.formatNumber(newSelectedCountry.dialCode, newSelectedCountry),
      });
    }
  };

  updateFormattedNumber(value: any) {
    if (value === null) return this.setState({ selectedCountry: 0, formattedNumber: '' });

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
    const { onlyCountries, selectedCountry, hiddenAreaCodes } = this.state;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'country' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { country, prefix } = this.props;

    if (value === '') return this.setState({ selectedCountry, formattedNumber: '' });

    const inputNumber = value.replace(/\D/g, '');
    let newSelectedCountry;
    let formattedNumber;

    // if new value start with selectedCountry.dialCode, format number, otherwise find newSelectedCountry
    if (selectedCountry && startsWith(value, prefix + selectedCountry.dialCode)) {
      formattedNumber = this.formatNumber(inputNumber, selectedCountry);
      this.setState({ formattedNumber });
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableCountryGuess' does not exist on t... Remove this comment to see the full error message
      if (this.props.disableCountryGuess) {
        newSelectedCountry = selectedCountry;
      } else {
        newSelectedCountry =
          this.guessSelectedCountry(
            inputNumber.substring(0, 6),
            country,
            onlyCountries,
            hiddenAreaCodes
          ) || selectedCountry;
      }
      const dialCode =
        newSelectedCountry && startsWith(inputNumber, prefix + newSelectedCountry.dialCode)
          ? newSelectedCountry.dialCode
          : '';

      formattedNumber = this.formatNumber(
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableCountryCode' does not exist on ty... Remove this comment to see the full error message
        (this.props.disableCountryCode ? '' : dialCode) + inputNumber,
        newSelectedCountry || undefined
      );
      this.setState({ selectedCountry: newSelectedCountry, formattedNumber });
    }
  }

  // View methods
  scrollTo = (country: any, middle: any) => {
    if (!country) return;
    const container = this.dropdownRef;
    if (!container || !document.body) return;

    const containerHeight = container.offsetHeight;
    const containerOffset = container.getBoundingClientRect();
    const containerTop = containerOffset.top + document.body.scrollTop;
    const containerBottom = containerTop + containerHeight;

    const element = country;
    const elementOffset = element.getBoundingClientRect();

    const elementHeight = element.offsetHeight;
    const elementTop = elementOffset.top + document.body.scrollTop;
    const elementBottom = elementTop + elementHeight;

    let newScrollTop = elementTop - containerTop + container.scrollTop;
    const middleOffset = containerHeight / 2 - elementHeight / 2;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableSearch' does not exist on type 'Re... Remove this comment to see the full error message
    if (this.props.enableSearch ? elementTop < containerTop + 32 : elementTop < containerTop) {
      // scroll up
      if (middle) {
        newScrollTop -= middleOffset;
      }
      container.scrollTop = newScrollTop;
    } else if (elementBottom > containerBottom) {
      // scroll down
      if (middle) {
        newScrollTop += middleOffset;
      }
      const heightDifference = containerHeight - elementHeight;
      container.scrollTop = newScrollTop - heightDifference;
    }
  };

  scrollToTop = () => {
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    container.scrollTop = 0;
  };

  formatNumber = (text: any, country: any) => {
    if (!country) return text;

    const { format } = country;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableCountryCode' does not exist on ty... Remove this comment to see the full error message
    const { disableCountryCode, enableAreaCodeStretch, enableLongNumbers, autoFormat } = this.props;

    let pattern;
    if (disableCountryCode) {
      pattern = format.split(' ');
      pattern.shift();
      pattern = pattern.join(' ');
    } else if (enableAreaCodeStretch && country.isAreaCode) {
      pattern = format.split(' ');
      pattern[1] = pattern[1].replace(/\.+/, ''.padEnd(country.areaCodeLength, '.'));
      pattern = pattern.join(' ');
    } else {
      pattern = format;
    }

    if (!text || text.length === 0) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'prefix' does not exist on type 'Readonly... Remove this comment to see the full error message
      return disableCountryCode ? '' : this.props.prefix;
    }

    // for all strings with length less than 3, just return it (1, 2 etc.)
    // also return the same text if the selected country has no fixed format
    if ((text && text.length < 2) || !pattern || !autoFormat) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'prefix' does not exist on type 'Readonly... Remove this comment to see the full error message
      return disableCountryCode ? text : this.props.prefix + text;
    }

    const formattedObject = reduce(
      pattern,
      (acc: any, character: any) => {
        if (acc.remainingText.length === 0) {
          return acc;
        }

        if (character !== '.') {
          return {
            formattedText: acc.formattedText + character,
            remainingText: acc.remainingText,
          };
        }

        const [head, ...tail] = acc.remainingText;

        return {
          formattedText: acc.formattedText + head,
          remainingText: tail,
        };
      },
      {
        formattedText: '',
        remainingText: text.split(''),
      }
    );

    let formattedNumber;
    if (enableLongNumbers) {
      formattedNumber = formattedObject.formattedText + formattedObject.remainingText.join('');
    } else {
      formattedNumber = formattedObject.formattedText;
    }

    // Always close brackets
    if (formattedNumber.includes('(') && !formattedNumber.includes(')')) formattedNumber += ')';
    return formattedNumber;
  };

  // Put the cursor to the end of the input (usually after a focus event)
  cursorToEnd = () => {
    const input = this.numberInputRef.current;
    input.focus();
    let len = input.value.length;
    if (input.value.charAt(len - 1) === ')') len -= 1;
    input.setSelectionRange(len, len);
  };

  getElement = (index: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return this[`flag_no_${index}`];
  };

  // return country data from state
  getCountryData = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedCountry' does not exist on type ... Remove this comment to see the full error message
    if (!this.state.selectedCountry) return {};
    return {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedCountry' does not exist on type ... Remove this comment to see the full error message
      name: this.state.selectedCountry.name || '',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedCountry' does not exist on type ... Remove this comment to see the full error message
      dialCode: this.state.selectedCountry.dialCode || '',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedCountry' does not exist on type ... Remove this comment to see the full error message
      countryCode: this.state.selectedCountry.iso2 || '',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedCountry' does not exist on type ... Remove this comment to see the full error message
      format: this.state.selectedCountry.format || '',
    };
  };

  handleFlagDropdownClick = (e: any) => {
    e.preventDefault();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'showDropdown' does not exist on type 'Re... Remove this comment to see the full error message
    if (!this.state.showDropdown && this.props.disabled) return;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'preferredCountries' does not exist on ty... Remove this comment to see the full error message
    const { preferredCountries, selectedCountry } = this.state;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
    const allCountries = preferredCountries.concat(this.state.onlyCountries);

    const highlightCountryIndex = allCountries.findIndex(
      (o: any) => o.dialCode === selectedCountry.dialCode && o.iso2 === selectedCountry.iso2
    );

    this.setState(
      {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'showDropdown' does not exist on type 'Re... Remove this comment to see the full error message
        showDropdown: !this.state.showDropdown,
        highlightCountryIndex,
      },
      () => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'showDropdown' does not exist on type 'Re... Remove this comment to see the full error message
        if (this.state.showDropdown) {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
          this.scrollTo(this.getElement(this.state.highlightCountryIndex));
        }
      }
    );
  };

  handleInput = (e: any) => {
    const { value } = e.target;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'prefix' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { prefix, onChange } = this.props;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableCountryCode' does not exist on ty... Remove this comment to see the full error message
    let formattedNumber = this.props.disableCountryCode ? '' : prefix;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedCountry' does not exist on type ... Remove this comment to see the full error message
    let newSelectedCountry = this.state.selectedCountry;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'freezeSelection' does not exist on type ... Remove this comment to see the full error message
    let { freezeSelection } = this.state;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'countryCodeEditable' does not exist on t... Remove this comment to see the full error message
    if (!this.props.countryCodeEditable) {
      const mainCode = newSelectedCountry.hasAreaCodes
        ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
          this.state.onlyCountries.find(
            (o: any) => o.iso2 === newSelectedCountry.iso2 && o.mainCode
          ).dialCode
        : newSelectedCountry.dialCode;

      const updatedInput = prefix + mainCode;
      if (value.slice(0, updatedInput.length) !== updatedInput) return;
    }

    if (value === prefix) {
      // we should handle change when we delete the last digit
      if (onChange) onChange('', this.getCountryData(), e, '');
      return this.setState({ formattedNumber: '' });
    }

    // Does exceed default 15 digit phone number limit
    if (value.replace(/\D/g, '').length > 15) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableLongNumbers' does not exist on typ... Remove this comment to see the full error message
      if (this.props.enableLongNumbers === false) return;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableLongNumbers' does not exist on typ... Remove this comment to see the full error message
      if (typeof this.props.enableLongNumbers === 'number') {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableLongNumbers' does not exist on typ... Remove this comment to see the full error message
        if (value.replace(/\D/g, '').length > this.props.enableLongNumbers) return;
      }
    }

    // if the input is the same as before, must be some special key like enter etc.
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'formattedNumber' does not exist on type ... Remove this comment to see the full error message
    if (value === this.state.formattedNumber) return;

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'country' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { country } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
    const { onlyCountries, selectedCountry, hiddenAreaCodes } = this.state;

    if (onChange) e.persist();

    if (value.length > 0) {
      // before entering the number in new format, lets check if the dial code now matches some other country
      const inputNumber = value.replace(/\D/g, '');

      // we don't need to send the whole number to guess the country... only the first 6 characters are enough
      // the guess country function can then use memoization much more effectively since the set of input it
      // gets has drastically reduced
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'freezeSelection' does not exist on type ... Remove this comment to see the full error message
      if (!this.state.freezeSelection || selectedCountry.dialCode.length > inputNumber.length) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableCountryGuess' does not exist on t... Remove this comment to see the full error message
        if (this.props.disableCountryGuess) {
          newSelectedCountry = selectedCountry;
        } else {
          newSelectedCountry =
            this.guessSelectedCountry(
              inputNumber.substring(0, 6),
              country,
              onlyCountries,
              hiddenAreaCodes
            ) || selectedCountry;
        }
        freezeSelection = false;
      }
      formattedNumber = this.formatNumber(inputNumber, newSelectedCountry);
      newSelectedCountry = newSelectedCountry.dialCode ? newSelectedCountry : selectedCountry;
    }

    let caretPosition = e.target.selectionStart;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'formattedNumber' does not exist on type ... Remove this comment to see the full error message
    const oldFormattedText = this.state.formattedNumber;
    const diff = formattedNumber.length - oldFormattedText.length;

    this.setState(
      {
        formattedNumber,
        freezeSelection,
        selectedCountry: newSelectedCountry,
      },
      () => {
        if (diff > 0) {
          caretPosition -= diff;
        }

        const lastChar = formattedNumber.charAt(formattedNumber.length - 1);

        if (lastChar === ')') {
          this.numberInputRef.current.setSelectionRange(
            formattedNumber.length - 1,
            formattedNumber.length - 1
          );
        } else if (caretPosition > 0 && oldFormattedText.length >= formattedNumber.length) {
          this.numberInputRef.current.setSelectionRange(caretPosition, caretPosition);
        }

        if (onChange)
          onChange(
            formattedNumber,
            this.getCountryData(),
            e,
            formattedNumber.replace(/[^0-9]+/g, '')
          );
      }
    );
  };

  handleInputClick = (e: any) => {
    this.setState({ showDropdown: false });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClick' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.onClick) this.props.onClick(e, this.getCountryData());
  };

  // eslint-disable-next-line class-methods-use-this
  handleDoubleClick = (e: any) => {
    const len = e.target.value.length;
    e.target.setSelectionRange(0, len);
  };

  handleFlagItemClick = (country: any, e: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedCountry' does not exist on type ... Remove this comment to see the full error message
    const currentSelectedCountry = this.state.selectedCountry;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
    const newSelectedCountry = this.state.onlyCountries.find((o: any) => o === country);
    if (!newSelectedCountry) return;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'formattedNumber' does not exist on type ... Remove this comment to see the full error message
    const unformattedNumber = this.state.formattedNumber
      .replace(' ', '')
      .replace('(', '')
      .replace(')', '')
      .replace('-', '');
    const newNumber =
      unformattedNumber.length > 1
        ? unformattedNumber.replace(currentSelectedCountry.dialCode, newSelectedCountry.dialCode)
        : newSelectedCountry.dialCode;
    const formattedNumber = this.formatNumber(newNumber.replace(/\D/g, ''), newSelectedCountry);

    this.setState(
      {
        showDropdown: false,
        selectedCountry: newSelectedCountry,
        freezeSelection: true,
        formattedNumber,
      },
      () => {
        this.cursorToEnd();
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
        if (this.props.onChange)
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
          this.props.onChange(
            formattedNumber.replace(/[^0-9]+/g, ''),
            this.getCountryData(),
            e,
            formattedNumber
          );
      }
    );
  };

  handleInputFocus = (e: any) => {
    // if the input is blank, insert dial code of the selected country
    if (this.numberInputRef.current) {
      if (
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'prefix' does not exist on type 'Readonly... Remove this comment to see the full error message
        this.numberInputRef.current.value === this.props.prefix &&
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedCountry' does not exist on type ... Remove this comment to see the full error message
        this.state.selectedCountry &&
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableCountryCode' does not exist on ty... Remove this comment to see the full error message
        !this.props.disableCountryCode
      ) {
        this.setState(
          {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'prefix' does not exist on type 'Readonly... Remove this comment to see the full error message
            formattedNumber: this.props.prefix + this.state.selectedCountry.dialCode,
          },
          () => {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'jumpCursorToEnd' does not exist on type ... Remove this comment to see the full error message
            this.props.jumpCursorToEnd && setTimeout(this.cursorToEnd, 0);
          }
        );
      }
    }

    this.setState({ placeholder: '' });

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFocus' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.props.onFocus && this.props.onFocus(e, this.getCountryData());
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'jumpCursorToEnd' does not exist on type ... Remove this comment to see the full error message
    this.props.jumpCursorToEnd && setTimeout(this.cursorToEnd, 0);
  };

  handleInputBlur = (e: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message
    if (!e.target.value) this.setState({ placeholder: this.props.placeholder });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.props.onBlur && this.props.onBlur(e, this.getCountryData());
  };

  handleInputCopy = (e: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'copyNumbersOnly' does not exist on type ... Remove this comment to see the full error message
    if (!this.props.copyNumbersOnly) return;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const text = window
      .getSelection()
      .toString()
      .replace(/[^0-9]+/g, '');
    e.clipboardData.setData('text/plain', text);
    e.preventDefault();
  };

  getHighlightCountryIndex = (direction: any) => {
    // had to write own function because underscore does not have findIndex. lodash has it
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'highlightCountryIndex' does not exist on... Remove this comment to see the full error message
    const highlightCountryIndex = this.state.highlightCountryIndex + direction;

    if (
      highlightCountryIndex < 0 ||
      highlightCountryIndex >=
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
        this.state.onlyCountries.length + this.state.preferredCountries.length
    ) {
      return highlightCountryIndex - direction;
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableSearch' does not exist on type 'Re... Remove this comment to see the full error message
    if (this.props.enableSearch && highlightCountryIndex > this.getSearchFilteredCountries().length)
      return 0; // select first country
    return highlightCountryIndex;
  };

  searchCountry = () => {
    const probableCandidate =
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'queryString' does not exist on type 'Rea... Remove this comment to see the full error message
      this.getProbableCandidate(this.state.queryString) || this.state.onlyCountries[0];
    const probableCandidateIndex =
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
      this.state.onlyCountries.findIndex((o: any) => o === probableCandidate) +
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'preferredCountries' does not exist on ty... Remove this comment to see the full error message
      this.state.preferredCountries.length;

    this.scrollTo(this.getElement(probableCandidateIndex), true);

    this.setState({ queryString: '', highlightCountryIndex: probableCandidateIndex });
  };

  handleKeydown = (e: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'keys' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { keys } = this.props;
    const {
      target: { className },
    } = e;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'showDropdown' does not exist on type 'Re... Remove this comment to see the full error message
    if (className.includes('selected-flag') && e.which === keys.ENTER && !this.state.showDropdown)
      return this.handleFlagDropdownClick(e);
    if (className.includes('form-control') && (e.which === keys.ENTER || e.which === keys.ESC))
      return e.target.blur();

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'showDropdown' does not exist on type 'Re... Remove this comment to see the full error message
    if (!this.state.showDropdown || this.props.disabled) return;
    if (className.includes('search-box')) {
      if (e.which !== keys.UP && e.which !== keys.DOWN && e.which !== keys.ENTER) {
        if (e.which === keys.ESC && e.target.value === '') {
          // do nothing // if search field is empty, pass event (close dropdown)
        } else {
          return; // don't process other events coming from the search field
        }
      }
    }

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    const moveHighlight = (direction: any) => {
      this.setState(
        {
          highlightCountryIndex: this.getHighlightCountryIndex(direction),
        },
        () => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'highlightCountryIndex' does not exist on... Remove this comment to see the full error message
          this.scrollTo(this.getElement(this.state.highlightCountryIndex), true);
        }
      );
    };

    switch (e.which) {
      case keys.DOWN:
        moveHighlight(1);
        break;
      case keys.UP:
        moveHighlight(-1);
        break;
      case keys.ENTER:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableSearch' does not exist on type 'Re... Remove this comment to see the full error message
        if (this.props.enableSearch) {
          this.handleFlagItemClick(
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'highlightCountryIndex' does not exist on... Remove this comment to see the full error message
            this.getSearchFilteredCountries()[this.state.highlightCountryIndex] ||
              this.getSearchFilteredCountries()[0],
            e
          );
        } else {
          this.handleFlagItemClick(
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'preferredCountries' does not exist on ty... Remove this comment to see the full error message
            [...this.state.preferredCountries, ...this.state.onlyCountries][
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'highlightCountryIndex' does not exist on... Remove this comment to see the full error message
              this.state.highlightCountryIndex
            ],
            e
          );
        }
        break;
      case keys.ESC:
      case keys.TAB:
        this.setState(
          {
            showDropdown: false,
          },
          this.cursorToEnd
        );
        break;
      default:
        if ((e.which >= keys.A && e.which <= keys.Z) || e.which === keys.SPACE) {
          this.setState(
            {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'queryString' does not exist on type 'Rea... Remove this comment to see the full error message
              queryString: this.state.queryString + String.fromCharCode(e.which),
            },
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'debouncedQueryStingSearcher' does not ex... Remove this comment to see the full error message
            this.state.debouncedQueryStingSearcher
          );
        }
    }
  };

  handleInputKeyDown = (e: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'keys' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { keys, onEnterKeyPress, onKeyDown } = this.props;
    if (e.which === keys.ENTER) {
      if (onEnterKeyPress) onEnterKeyPress(e);
    }
    if (onKeyDown) onKeyDown(e);
  };

  handleClickOutside = (e: any) => {
    if (this.dropdownRef && !this.dropdownContainerRef.contains(e.target)) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'showDropdown' does not exist on type 'Re... Remove this comment to see the full error message
      this.state.showDropdown && this.setState({ showDropdown: false });
    }
  };

  handleSearchChange = (e: any) => {
    const {
      currentTarget: { value: searchValue },
    } = e;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'preferredCountries' does not exist on ty... Remove this comment to see the full error message
    const { preferredCountries, selectedCountry } = this.state;
    let highlightCountryIndex = 0;

    if (searchValue === '' && selectedCountry) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onlyCountries' does not exist on type 'R... Remove this comment to see the full error message
      const { onlyCountries } = this.state;
      highlightCountryIndex = preferredCountries
        .concat(onlyCountries)
        .findIndex((o: any) => o === selectedCountry);
      // wait asynchronous search results re-render, then scroll
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      setTimeout(() => this.scrollTo(this.getElement(highlightCountryIndex)), 100);
    }
    this.setState({ searchValue, highlightCountryIndex });
  };

  getSearchFilteredCountries = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'preferredCountries' does not exist on ty... Remove this comment to see the full error message
    const { preferredCountries, onlyCountries, searchValue } = this.state;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableSearch' does not exist on type 'Re... Remove this comment to see the full error message
    const { enableSearch } = this.props;
    const allCountries = preferredCountries.concat(onlyCountries);
    const sanitizedSearchValue = searchValue.trim().toLowerCase();
    if (enableSearch && sanitizedSearchValue) {
      // [...new Set()] to get rid of duplicates
      // firstly search by iso2 code
      if (/^\d+$/.test(sanitizedSearchValue)) {
        // contains digits only
        // values wrapped in ${} to prevent undefined
        return allCountries.filter(({ dialCode }: any) =>
          [`${dialCode}`].some((field) => field.toLowerCase().includes(sanitizedSearchValue))
        );
      }
      const iso2countries = allCountries.filter(({ iso2 }: any) =>
        [`${iso2}`].some((field) => field.toLowerCase().includes(sanitizedSearchValue))
      );
      // || '' - is a fix to prevent search of 'undefined' strings
      // Since all the other values shouldn't be undefined, this fix was accepte
      // but the structure do not looks very good
      const searchedCountries = allCountries.filter(({ name, localName /* , iso2 */ }: any) =>
        [`${name}`, `${localName || ''}`].some((field) =>
          field.toLowerCase().includes(sanitizedSearchValue)
        )
      );
      this.scrollToTop();
      return [...new Set([].concat(iso2countries, searchedCountries))];
    }
    return allCountries;
  };

  getCountryDropdownList = (array = false) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'preferredCountries' does not exist on ty... Remove this comment to see the full error message
    const { preferredCountries, highlightCountryIndex, showDropdown, searchValue } = this.state;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableDropdown' does not exist on type ... Remove this comment to see the full error message
    const { disableDropdown, prefix } = this.props;
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'enableSearch' does not exist on type 'Re... Remove this comment to see the full error message
      enableSearch,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchNotFound' does not exist on type '... Remove this comment to see the full error message
      searchNotFound,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableSearchIcon' does not exist on typ... Remove this comment to see the full error message
      disableSearchIcon,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchClass' does not exist on type 'Rea... Remove this comment to see the full error message
      searchClass,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchStyle' does not exist on type 'Rea... Remove this comment to see the full error message
      searchStyle,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchPlaceholder' does not exist on typ... Remove this comment to see the full error message
      searchPlaceholder,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'autocompleteSearch' does not exist on ty... Remove this comment to see the full error message
      autocompleteSearch,
    } = this.props;

    const searchedCountries = this.getSearchFilteredCountries();

    if (array) {
      return searchedCountries.map((country: any) => {
        return {
          countryName: getDropdownCountryName(country),
          countryFormat: country.format
            ? this.formatNumber(country.dialCode, country)
            : prefix + country.dialCode,
          countryIso: String(country.iso2).toUpperCase(),
          country,
        };
      });
    }

    const countryDropdownList = searchedCountries.map((country: any, index: any) => {
      const highlight = highlightCountryIndex === index;
      const itemClasses = classNames({
        country: true,
        preferred: country.iso2 === 'us' || country.iso2 === 'gb',
        active: country.iso2 === 'us',
        highlight,
      });

      const inputFlagClasses = `flag ${country.iso2}`;

      return (
        <li
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ref={(el) => (this[`flag_no_${index}`] = el)}
          key={`flag_no_${index}`}
          data-flag-key={`flag_no_${index}`}
          className={itemClasses}
          data-dial-code="1"
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
          tabIndex={disableDropdown ? '-1' : '0'}
          data-country-code={country.iso2}
          onClick={(e) => this.handleFlagItemClick(country, e)}
          role="option"
          {...(highlight ? { 'aria-selected': true } : {})}
        >
          <div className={inputFlagClasses} />
          <span className="country-name">{getDropdownCountryName(country)}</span>
          <span className="dial-code">
            {country.format
              ? this.formatNumber(country.dialCode, country)
              : prefix + country.dialCode}
          </span>
        </li>
      );
    });

    const dashedLi = <li key="dashes" className="divider" />;
    // let's insert a dashed line in between preffered countries and the rest
    preferredCountries.length > 0 &&
      (!enableSearch || (enableSearch && !searchValue.trim())) &&
      countryDropdownList.splice(preferredCountries.length, 0, dashedLi);

    const dropDownClasses = classNames({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dropdownClass' does not exist on type 'R... Remove this comment to see the full error message
      [this.props.dropdownClass]: true,
      'country-list': true,
      hide: !showDropdown,
    });

    return (
      <ul
        ref={(el) => {
          !enableSearch && el && el.focus();
          return (this.dropdownRef = el);
        }}
        className={dropDownClasses}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'dropdownStyle' does not exist on type 'R... Remove this comment to see the full error message
        style={this.props.dropdownStyle}
        role="listbox"
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
        tabIndex="0"
      >
        {enableSearch && (
          <li
            className={classNames({
              search: true,
              [searchClass]: searchClass,
            })}
          >
            {!disableSearchIcon && (
              <span
                className={classNames({
                  'search-emoji': true,
                  [`${searchClass}-emoji`]: searchClass,
                })}
                role="img"
                aria-label="Magnifying glass"
              >
                &#128270;
              </span>
            )}
            <input
              className={classNames({
                'search-box': true,
                [`${searchClass}-box`]: searchClass,
              })}
              style={searchStyle}
              type="search"
              placeholder={searchPlaceholder}
              autoFocus
              autoComplete={autocompleteSearch ? 'on' : 'off'}
              value={searchValue}
              onChange={this.handleSearchChange}
            />
          </li>
        )}
        {countryDropdownList.length > 0 ? (
          countryDropdownList
        ) : (
          <li className="no-entries-message">
            <span>{searchNotFound}</span>
          </li>
        )}
      </ul>
    );
  };

  render() {
    const { onlyCountries, selectedCountry, /* showDropdown, */ formattedNumber, hiddenAreaCodes } =
      this.state;
    const {
      // disableDropdown,
      // renderStringAsFlag,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isValid' does not exist on type 'Readonl... Remove this comment to see the full error message
      isValid,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
      error,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultErrorMessage' does not exist on t... Remove this comment to see the full error message
      defaultErrorMessage,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'specialLabel' does not exist on type 'Re... Remove this comment to see the full error message
      specialLabel,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'helperText' does not exist on type 'Read... Remove this comment to see the full error message
      helperText,
    } = this.props;

    let isValidValue;
    let errorMessage;
    if (typeof isValid === 'boolean') {
      isValidValue = isValid;
    } else {
      const isValidProcessed = isValid(
        formattedNumber.replace(/\D/g, ''),
        selectedCountry,
        onlyCountries,
        hiddenAreaCodes
      );
      if (typeof isValidProcessed === 'boolean') {
        isValidValue = isValidProcessed;
        if (isValidValue === false) errorMessage = defaultErrorMessage;
      } else {
        // typeof === 'string'
        isValidValue = false;
        errorMessage = isValidProcessed;
      }
    }

    if (error && isValidValue) {
      isValidValue = false;
      errorMessage = helperText;
    }

    const containerClasses = classNames({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'containerClass' does not exist on type '... Remove this comment to see the full error message
      [this.props.containerClass]: true,
      'react-tel-input': true,
    });
    // const arrowClasses = classNames({ arrow: true, up: showDropdown });
    // const inputClasses = classNames({
    //   [this.props.inputClass]: true,
    //   'form-control': true,
    //   'invalid-number': !isValidValue,
    //   open: showDropdown,
    // });
    // const selectedFlagClasses = classNames({
    //   'selected-flag': true,
    //   open: showDropdown,
    // });
    // const flagViewClasses = classNames({
    //   [this.props.buttonClass]: true,
    //   'flag-dropdown': true,
    //   'invalid-number': !isValidValue,
    //   open: showDropdown,
    // });
    // const inputFlagClasses = `flag ${selectedCountry && selectedCountry.iso2}`;
    const MuiComponent = this.props.component;

    return (
      <div
        className={containerClasses}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
        style={this.props.style || this.props.containerStyle}
        onKeyDown={this.handleKeydown}
      >
        <MuiComponent
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputStyle' does not exist on type 'Read... Remove this comment to see the full error message
          style={this.props.inputStyle}
          onChange={this.handleInput}
          onClick={this.handleInputClick}
          onDoubleClick={this.handleDoubleClick}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onCopy={this.handleInputCopy}
          value={formattedNumber}
          inputRef={this.numberInputRef}
          onKeyDown={this.handleInputKeyDown}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message
          placeholder={this.props.placeholder}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
          disabled={this.props.disabled}
          fullWidth
          error={!isValidValue}
          helperText={errorMessage}
          type="tel"
          label={specialLabel}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputProps' does not exist on type 'Read... Remove this comment to see the full error message
          {...this.props.inputProps}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ButtonFlags
                  handleFlagItemClick={this.handleFlagItemClick}
                  selectedCountry={selectedCountry}
                  countries={this.getCountryDropdownList(true)}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PhoneInput.propTypes = {
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,

  onlyCountries: PropTypes.arrayOf(PropTypes.string),
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  excludeCountries: PropTypes.arrayOf(PropTypes.string),

  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  searchNotFound: PropTypes.string,
  disabled: PropTypes.bool,

  containerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  dropdownStyle: PropTypes.object,
  searchStyle: PropTypes.object,

  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  buttonClass: PropTypes.string,
  dropdownClass: PropTypes.string,
  searchClass: PropTypes.string,

  autoFormat: PropTypes.bool,

  enableAreaCodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  enableTerritories: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),

  disableCountryCode: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  enableLongNumbers: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  countryCodeEditable: PropTypes.bool,
  enableSearch: PropTypes.bool,
  disableSearchIcon: PropTypes.bool,
  disableInitialCountryGuess: PropTypes.bool,
  disableCountryGuess: PropTypes.bool,

  regions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),

  inputProps: PropTypes.object,
  localization: PropTypes.object,
  masks: PropTypes.object,
  areaCodes: PropTypes.object,

  preserveOrder: PropTypes.arrayOf(PropTypes.string),

  defaultMask: PropTypes.string,
  alwaysDefaultMask: PropTypes.bool,
  prefix: PropTypes.string,
  copyNumbersOnly: PropTypes.bool,
  renderStringAsFlag: PropTypes.string,
  autocompleteSearch: PropTypes.bool,
  jumpCursorToEnd: PropTypes.bool,
  priority: PropTypes.object,
  enableAreaCodeStretch: PropTypes.bool,
  enableClickOutside: PropTypes.bool,
  showDropdown: PropTypes.bool,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  isValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  defaultErrorMessage: PropTypes.string,
  specialLabel: PropTypes.string,
};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PhoneInput.defaultProps = {
  country: '',
  value: '',

  onlyCountries: [],
  preferredCountries: [],
  excludeCountries: [],

  placeholder: '1 (702) 123-4567',
  searchPlaceholder: 'search',
  searchNotFound: 'No entries to show',
  flagsImagePath: './src/flags.png',
  disabled: false,

  containerStyle: {},
  inputStyle: {},
  buttonStyle: {},
  dropdownStyle: {},
  searchStyle: {},

  containerClass: '',
  inputClass: '',
  buttonClass: '',
  dropdownClass: '',
  searchClass: '',

  autoFormat: true,
  enableAreaCodes: false,
  enableTerritories: false,
  disableCountryCode: false,
  disableDropdown: false,
  enableLongNumbers: false,
  countryCodeEditable: true,
  enableSearch: false,
  disableSearchIcon: false,
  disableInitialCountryGuess: false,
  disableCountryGuess: false,

  regions: '',

  inputProps: {},
  localization: {},

  masks: null,
  priority: null,
  areaCodes: null,

  preserveOrder: [],

  defaultMask: '... ... ... ... ..', // prefix+dialCode+' '+defaultMask
  alwaysDefaultMask: false,
  prefix: '+',
  copyNumbersOnly: true,
  renderStringAsFlag: '',
  autocompleteSearch: false,
  jumpCursorToEnd: true,
  enableAreaCodeStretch: false,
  enableClickOutside: true,
  showDropdown: false,

  isValid: true, // (value, selectedCountry, onlyCountries, hiddenAreaCodes) => true | false | 'Message'
  defaultErrorMessage: '',
  specialLabel: 'Phone',

  onEnterKeyPress: null, // null or function

  keys: {
    UP: 38,
    DOWN: 40,
    RIGHT: 39,
    LEFT: 37,
    ENTER: 13,
    ESC: 27,
    PLUS: 43,
    A: 65,
    Z: 90,
    SPACE: 32,
  },
};

export default PhoneInput;
