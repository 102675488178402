import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error hide this
import provideHooks from 'redial/lib/provideHooks';
import { find as findServiceRoutesLegs } from 'redux/modules/serviceRouteLegs';

const ComposeCarrierCostSimple = loadable(
  () =>
    import(
      /* webpackChunkName: 'admin-ComposeCarrierCostsSimpleCreate' */ './ComposeCarrierCostsSimpleCreate'
    ),
  {
    fallback: <LoadingComponent />,
  }
);

export default provideHooks({
  fetch: async ({ store: { dispatch } }: any) => {
    const promises = [];
    promises.push(
      dispatch(
        findServiceRoutesLegs('all', {
          query: {
            $paginate: false,
            include: ['ServiceRouteServiceRouteLeg'],
          },
        })
      ).catch(console.error)
    );
    await Promise.all(promises);
  },
})(ComposeCarrierCostSimple);
