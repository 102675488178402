import { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import { ReactNode } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import {
  TextField,
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeReason,
  UseAutocompleteProps as MuiUseAutocompleteProps,
  TextFieldProps as MuiTextFieldProps,
  AutocompleteChangeDetails,
  AutocompleteValue as Value,
} from '@mui/material';
import { ShowErrorFunc, showErrorOnChange } from './Util';

export type AutocompleteData = {
  [key: string]: any | null;
};

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> &
      MuiUseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  name: string;
  label: ReactNode;
  helperText?: string;
  required?: boolean;
  getOptionValue?: (option: T) => any;
  options: T[];
  fieldProps?: Partial<FieldProps<any, any>>;
  textFieldProps?: Partial<MuiTextFieldProps>;
  showError?: ShowErrorFunc;
}

interface AutocompleteWrapperProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  label: ReactNode;
  required?: boolean;
  textFieldProps?: Partial<MuiTextFieldProps>;
  getOptionValue?: (option: T) => any;
}

function AutocompleteWrapper<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteWrapperProps<T, Multiple, DisableClearable, FreeSolo> &
    FieldRenderProps<MuiTextFieldProps, HTMLElement>
): JSX.Element {
  const {
    input: { name, onChange, value },
    meta,
    options,
    label,
    required,
    multiple,
    textFieldProps,
    getOptionValue,
    showError = showErrorOnChange,
    placeholder,
    onChange: onChangeCallback,
    ...rest
  } = props;

  function getValue(values: any) {
    if (!getOptionValue) {
      return values;
    }

    // ternary hell...
    // eslint-disable-next-line no-nested-ternary
    return multiple
      ? values
        ? values.map(getOptionValue)
        : null
      : values
      ? getOptionValue(values)
      : null;
  }

  const { helperText, ...lessrest } = rest;
  const { variant, ...restTextFieldProps } = textFieldProps || {};

  // yuck...
  let defaultValue: Value<T, Multiple, DisableClearable, FreeSolo> | undefined;

  if (!getOptionValue) {
    defaultValue = value as Value<T, Multiple, DisableClearable, FreeSolo> | undefined;
  } else if (value) {
    options.forEach((option) => {
      const optionValue = getOptionValue(option);
      if (multiple) {
        if (!defaultValue) {
          defaultValue = [] as any;
        }
        (value as any).forEach((v: any) => {
          if (v === optionValue) {
            (defaultValue as any).push(option);
          }
        });
      } else if (value === optionValue) {
        defaultValue = option as any;
      }
    });
  }

  const onChangeFunc = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.SyntheticEvent,
    newValue: Value<T, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    const gotValue = getValue(newValue);
    onChange(gotValue);

    if (onChangeCallback) {
      onChangeCallback(event, newValue, reason, details);
    }
  };

  const { error, submitError } = meta;
  const isError = showError({ meta });

  return (
    <MuiAutocomplete
      multiple={multiple}
      onChange={onChangeFunc}
      options={options}
      value={defaultValue}
      renderInput={(params) => {
        const { inputProps, ...restTextFieldPropsSafe } = restTextFieldProps;
        params.inputProps = { ...params.inputProps, ...inputProps };
        // fallback when is empty (weird beharviour)
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'browser' does not exist on type 'Process... Remove this comment to see the full error message
        if (process.browser && !params.inputProps.value && defaultValue) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'HTMLAttri... Remove this comment to see the full error message
          params.inputProps.value = defaultValue.label;
        }
        return (
          <TextField
            label={label}
            required={required}
            helperText={isError ? error || submitError : helperText}
            error={isError}
            name={name}
            placeholder={placeholder}
            variant={variant}
            {...params}
            {...restTextFieldPropsSafe}
            fullWidth
          />
        );
      }}
      {...lessrest}
    />
  );
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      render={(fieldRenderProps) => <AutocompleteWrapper {...fieldRenderProps} {...rest} />}
      {...fieldProps}
    />
  );
}
