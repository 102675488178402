import { createSvgIcon } from '@mui/material';

export default (props: any) => {
  const Icon = createSvgIcon(
    <g style={{ isolation: 'isolate' }}>
      <g id="4d454bb2-61fb-41fe-9060-c45416029f17" data-name="figures and interactions">
        <polyline
          points="5 10.5 12.08 10.5 17.96 16.38 26.33 16.38"
          style={{
            fill: 'none',
            stroke: '#5333ed',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
          }}
        />
        <polyline
          points="5 22.25 12.08 22.25 17.96 16.38"
          style={{
            fill: 'none',
            stroke: '#2cd4d9',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
            mixBlendMode: 'multiply',
          }}
        />
      </g>
    </g>,
    'Migrations'
  );
  return <Icon {...props} viewBox="0 0 32 32" />;
};
