import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const CN33ReceptacleListLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-CN33ReceptacleList' */ './CN33ReceptacleList'),
  {
    fallback: <LoadingComponent />,
  }
);

export default CN33ReceptacleListLoadable;
