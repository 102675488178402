import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const HarmonizeCodesRestrictionsLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'admin-HarmonizeCodesRestrictions' */ './HarmonizeCodesRestrictions'
    ),
  {
    fallback: <LoadingComponent />,
  }
);

const RatesServices = (prop: any) => <HarmonizeCodesRestrictionsLoadable {...prop} />;

export default RatesServices;
