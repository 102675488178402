import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import {
  gridPaginationModelSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import { useRef } from 'react';

export default function GoToPage() {
  const inputRef = useRef<HTMLInputElement>();
  const apiRef = useGridApiContext();
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageCount =
    paginationModel.pageSize > 0 && rowCount > 0
      ? Math.ceil(rowCount / paginationModel.pageSize)
      : 0;

  const onSubmit = (event: SubmitEvent) => {
    const number = Number(inputRef.current?.value);
    if (number && number > 0 && number < pageCount) {
      apiRef.current.setPage(number - 1);
    }
    event.preventDefault();
  };

  const goToPage = () => {
    const number = Number(inputRef.current?.value);
    if (number && number > 0 && number <= pageCount) {
      apiRef.current.setPage(number - 1);
    }
  };

  // only show this if we have more than 20 pages
  if (pageCount < 20) {
    return null;
  }
  return (
    <Paper
      onSubmit={onSubmit}
      component="form"
      sx={(theme) => ({
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 160,
        ml: 'auto',
        mr: 2,
        [theme.breakpoints.down('sm')]: {
          ml: 0,
          mr: 0,
        },
      })}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Go To Page"
        inputProps={{ 'aria-label': 'go to page' }}
        inputRef={inputRef}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton
        onClick={goToPage}
        color="primary"
        sx={{ p: '10px', borderWidth: 0 }}
        aria-label="directions"
      >
        <DirectionsIcon />
      </IconButton>
    </Paper>
  );
}
