import isBot from 'isbot';
import IsMobile from 'utils/isMobile';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import socketio from '@feathersjs/socketio-client';
// import authentication from '@feathersjs/authentication-client';
import { hooks, defaults } from '@feathersjs/authentication-client';
import { io } from 'socket.io-client';
import axios from 'axios';
import type { Request } from 'express';
import type { Application, HookContext } from '@feathersjs/feathers';
// import { batchClient } from 'feathers-batch';

// import { stripSlashes } from '@feathersjs/commons';
import config from './config';

// const authenticationHook = () => (context: HookContext) => {
//   const {
//     app,
//     params,
//     path,
//     method,
//     app: { authentication: service },
//   } = context;
//   if (stripSlashes(service.options.path) === path && method === 'create') {
//     return context;
//   }

//   return Promise.resolve(app.get('authentication'))
//     .then((authResult) => {
//       if (authResult) {
//         context.params = { ...authResult, ...params };
//       }
//       return context;
//     })
//     .catch(() => null);
// };

const myauthentication = (_options = {}) => {
  const options = { ...defaults, ..._options };
  const { Authentication } = options;
  return (app: any) => {
    const authentication = new Authentication(app, options);
    app.authentication = authentication;
    app.authenticate = authentication.authenticate.bind(authentication);
    app.reAuthenticate = authentication.reAuthenticate.bind(authentication);
    app.logout = authentication.logout.bind(authentication);
    app.hooks([
      hooks.authentication(),
      (context: HookContext, next: any) => {
        // fix for rest auth problems accesstoken always undefined
        if (
          context.app.rest &&
          context?.app?.settings?.accessToken &&
          !context?.params?.accessToken
        ) {
          context.params.accessToken = context.app.settings.accessToken.replace('Bearer ', '');
        }

        return next();
      },
      hooks.populateHeader(),
    ]);
  };
};

// const storage = __SERVER__ ? null : require('localforage');

export const host = (clientUrl: string): string =>
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__SERVER__'.
  __SERVER__ ? `http://${config.apiHost}:${config.apiPort}` : clientUrl;

const configureApp = (transport: any) => {
  // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
  const client = feathers().configure(transport).configure(myauthentication());
  // .configure(authentication());

  // if (typeof window !== 'undefined') {
  //   client.configure(
  //     batchClient({
  //       batchService: 'batch',
  //     })
  //   );
  // }
  return client;
};

const clientPort = typeof window !== 'undefined' && window.location.port;
export const socket = io('', {
  path: host(__DEVELOPMENT__ && clientPort !== process.env.PORT ? '/websocket' : '/ws'),
  autoConnect: false,
  transports: ['websocket'],
  upgrade: false,
  timeout: __DEVELOPMENT__ ? 1000 : 20000,
});
export const createApp = (req: Request | string): Application => {
  if (req === 'rest') {
    return configureApp(rest(host('/api')).axios(axios));
  }
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__SERVER__'.
  if (__SERVER__ && req) {
    const realHost =
      (req as any).header('x-forwarded-host') || (req as any).header('host') || (req as any).host;
    const ip =
      (req as any).header('x-forwarded-for') || (req as any).header('x-real-ip') || (req as any).ip;
    let accessToken =
      (req as any).header('authorization') ||
      ((req as any).cookies && (req as any).cookies['feathers-jwt']) ||
      '';

    if (String(accessToken).slice(0, 7) === 'Bearer ') {
      accessToken = accessToken.slice(7);
    }
    const app = configureApp(
      rest(host('/api')).axios(
        axios.create({
          headers: {
            Cookie: (req as any).get('cookie') || '',
            Authorization: accessToken,
            'x-real-ip': ip,
            'x-forwarded-for': ip,
            'x-forwarded-host': realHost,
            'user-agent': (req as any).get('user-agent') || '',
          },
        })
      )
    );

    if (accessToken) {
      app.authentication.setAccessToken(accessToken);
      app.set('accessToken', accessToken);
      app.authenticate().catch(() => null);
    } else {
      app.authentication.removeAccessToken(accessToken);
      app.set('accessToken', null);
    }

    app.req = req;

    const ua = (req as any).get('user-agent');
    app.isSpider = isBot(ua);
    const detector = new IsMobile(ua as any);
    app.isMobile = detector.any; // eslint-disable-line
    app.deviceInformation = {
      isMobile: detector.any,
      amazon: detector.amazon,
      android: detector.android,
      apple: detector.apple,
      other: detector.other,
      phone: detector.phone,
      sevenInch: detector.sevenInch,
      tablet: detector.tablet,
      windows: detector.windows,
    };

    return app;
  }

  const appClient = configureApp(
    socketio(socket, {
      timeout: __DEVELOPMENT__ ? 1000 : 20000,
    })
  );

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
  if (__CLIENT__) {
    const userAgent = navigator && navigator.userAgent;
    appClient.isSpider = isBot(userAgent);
    const detector = new IsMobile(userAgent);
    appClient.isMobile = detector.any;
    appClient.deviceInformation = {
      isMobile: detector.any,
      amazon: detector.amazon,
      android: detector.android,
      apple: detector.apple,
      other: detector.other,
      phone: detector.phone,
      sevenInch: detector.sevenInch,
      tablet: detector.tablet,
      windows: detector.windows,
    };
    (window as any).isMobile = appClient.isMobile;
  }

  return appClient;
};
