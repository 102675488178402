// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'css-... Remove this comment to see the full error message
import * as vendor from 'css-vendor';
import { toCssValue } from 'jss';

/**
 * Add vendor prefix to a property name when needed.
 *
 * @api public
 */
export default function jssVendorPrefixer() {
  function onProcessRule(rule: any) {
    if (rule.type === 'keyframes') {
      const atRule = rule;
      atRule.at = vendor.supportedKeyframes(atRule.at);
    }
  }

  function onProcessStyle(style: any, rule: any) {
    if (rule.type !== 'style') return style;

    // eslint-disable-next-line
    for (const prop in style) {
      if (prop === 'fallbacks') {
        // eslint-disable-next-line
        continue;
      }
      const value = style[prop];

      let changeProp = false;
      const supportedProp = vendor.supportedProperty(prop);
      if (supportedProp && supportedProp !== prop) changeProp = true;

      let changeValue = false;
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      const supportedValue = vendor.supportedValue(supportedProp, toCssValue(value));
      if (supportedValue && supportedValue !== value) changeValue = true;

      if (changeProp || changeValue) {
        if (changeProp) delete style[prop];
        style[supportedProp || prop] = supportedValue || value;
      }
    }

    return style;
  }

  function onChangeValue(value: any, prop: any) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    return vendor.supportedValue(prop, toCssValue(value)) || value;
  }

  return { onProcessRule, onProcessStyle, onChangeValue };
}
