/* eslint-disable max-len */
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
  Remove as RemoveIcon,
  Save as SaveIcon,
  Search as SearchIcon,
  CopyAll as CopyICon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Fade,
  IconButton,
  InputAdornment,
  LinearProgress,
  Popover,
  styled,
  Typography,
} from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import clone from 'clone';
import { randomId } from 'components/DataGrid/services';
import { createFakeServer } from 'components/DataGrid/useQuery';
import Tooltip from 'components/TooltipClick/TooltipClick';
import deepEqual from 'deep-equal';
import { addedDiff, deletedDiff, updatedDiff } from 'deep-object-diff';
import deepmerge from 'deepmerge';
import fastDeepEqual from 'fast-deep-equal';
import { __DEVELOPMENT__ } from 'global-env';
import camelCase from 'lodash.camelcase';
import debounce from 'lodash.debounce';
import startCase from 'lodash.startcase';
import { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDatagrid } from 'redux/modules/datagrid';
import { useNotifier } from 'redux/modules/notifier';
import { useMutation } from 'store';
import { getInitialValues as getInitialValuesDefault } from 'utils/getInitialValues';
import snackbarHelper from 'utils/snackbarHelper';
import ClipboardJS from 'clipboard';
import { GridToolbarExport } from './GridToolbarExport';
import Pagination from './Pagination';
import { TitleMenu } from './TitleMenu';
import { getInitialState } from './useDemoData';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  position: 'relative',
  zIndex: 999,
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg width="120" height="70" viewBox="0 0 184 152" aria-hidden focusable="false">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

function GridToolbar({
  getInitialValues = getInitialValuesDefault,
  columns,
  processRowUpdate,
  saveDensity,
  service,
  showQuickFilter,
  showAdd,
  refetch,
  showRefresh,
  storageKey,
  excelOptions,
  skipStateChanges,
  currentViewId,
  showFilters,
}: {
  getInitialValues?: (c: GridColDef[]) => any;
  columns: GridColDef[];
  processRowUpdate: any;
  showFilters?: boolean;
}) {
  const apiRefCurrent = useGridApiContext();
  const apiRef = apiRefCurrent.current;

  const { current: store } = useMemo(() => {
    return {
      current: {
        density: apiRef.state.density,
        columnsOrder: apiRef.state.columns.orderedFields,
        pinnedColumns: apiRef.state.pinnedColumns,
        rowGrouping: apiRef.state.rowGrouping,
        aggregation: apiRef.state.aggregation,
        cellSelection: apiRef.state.cellSelection,
        // columnGrouping: apiRef.state.columnGrouping,
        // rowSelection: apiRef.state.rowSelection,
        storageKey,
      },
    };
  }, [apiRefCurrent, storageKey]);

  const row = apiRef.getRow(apiRef.state.focus.cell?.id);
  const handleClick = () => {
    const id = randomId();
    const newRow = { id, isNew: true };
    const initaiValues = getInitialValues(columns);
    apiRef.updateRows([{ ...newRow, ...initaiValues }]);

    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.scrollToIndexes({
        rowIndex: apiRef.getRowsCount() - 1,
      });

      // apiRef.setCellFocus(id, 'name');
    });
  };
  const handleRemove = () => {
    apiRef.updateRows([
      {
        id: row.id,
        _action: 'delete',
      },
    ]);
  };

  const [cellSelection, setCellSelection] = useState<any>({});

  const numberOfSelectedCells = useMemo(() => {
    return apiRef.unstable_getSelectedCellsAsArray().length;
  }, [apiRef, cellSelection]);

  const handleCopySelectedCells = () => {
    const columnsSelected = apiRef.unstable_getSelectedCellsAsArray();
    const values = columnsSelected.map((selectedCell) => {
      const value = apiRef.getCellValue(selectedCell.id, selectedCell.field);
      return value;
    });
    const uniqueValues = [...new Set(values)];
    ClipboardJS.copy(uniqueValues.join(' '));
  };

  useEffect(() => {
    const listener = debounce(
      (state) => {
        if (currentViewId) {
          return;
        }

        if (store.storageKey === storageKey && !skipStateChanges) {
          if (store.cellSelection !== state.cellSelection) {
            setCellSelection(state.cellSelection);
            store.cellSelection = state.cellSelection;
          }
          if (store.density !== state.density) {
            store.density = state.density;
            saveDensity({ density: state.density.value });
          }
          if (fastDeepEqual(store.columnsOrder, state.columns.orderedFields) !== true) {
            store.columnsOrder = state.columns.orderedFields;
            saveDensity({ columnsOrder: state.columns.orderedFields });
          }
          if (store.pinnedColumns !== state.pinnedColumns) {
            if (state.pinnedColumns.left?.length || state.pinnedColumns.right?.length) {
              if (
                state.pinnedColumns.left?.length === 1 &&
                !state.pinnedColumns.right?.length &&
                state.pinnedColumns.left?.find(
                  (field) => field === GRID_CHECKBOX_SELECTION_COL_DEF.field
                )
              ) {
                state.pinnedColumns.left.shift();
              } else if (
                !state.pinnedColumns.left?.find(
                  (field) => field === GRID_CHECKBOX_SELECTION_COL_DEF.field
                )
              ) {
                state.pinnedColumns.left.unshift(GRID_CHECKBOX_SELECTION_COL_DEF.field);
              }
            }
            store.pinnedColumns = state.pinnedColumns;
            saveDensity({ pinnedColumns: state.pinnedColumns });
          }
          if (store.rowGrouping !== state.rowGrouping) {
            saveDensity({ rowGrouping: state.rowGrouping });
          }
          if (store.aggregation !== state.aggregation) {
            saveDensity({ aggregation: state.aggregation });
          }
          // if (store.columnGrouping !== state.columnGrouping) {
          //   saveDensity({ columnGrouping: state.columnGrouping });
          // }
          // if (store.rowSelection !== state.rowSelection) {
          //   saveDensity({ rowSelection: state.rowSelection });
          // }
        }
      },
      500,
      { maxWait: 1500 }
    );
    return apiRef.subscribeEvent('stateChange', listener);
  }, [apiRefCurrent, storageKey, skipStateChanges, currentViewId]);

  const handleSave = () => {
    processRowUpdate(row, row, true);
  };

  const showCellSelectionCopy = numberOfSelectedCells > 0;
  return (
    <GridToolbarContainer>
      {showQuickFilter ? (
        <GridToolbarQuickFilter
          size="small"
          variant="outlined"
          placeholder="Quick search..."
          sx={[
            {
              mr: 1,
              pb: 0,
              bgcolor: 'background.paper',
              borderRadius: 1,
              fontSize: 13,
              boxShadow: (theme) => theme.shadows[2],
              '& .MuiInputBase-input': {
                fontSize: 13,
              },
              '& .MuiButton-root': {
                mr: 0,
              },
              '& .MuiInputBase-root': {
                pl: 0,
                pr: 0,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'grey.400',
              },
            },
            (theme) => {
              console.log(theme, 'theme');
              return {
                [theme.getColorSchemeSelector('dark')]: {
                  bgcolor: (theme.vars || theme).palette.primaryDark[700],
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primaryDark.300',
                  },
                },
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                },
              };
            },
          ]}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Button
                  sx={{
                    pl: 1,
                    pr: 1,
                    minWidth: 'auto',
                  }}
                >
                  <SearchIcon />
                </Button>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <GridToolbarFilterButton sx={{ mr: 0 }} />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        showFilters && <GridToolbarFilterButton />
      )}

      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport excelOptions={excelOptions} />
      {showAdd && (
        <>
          <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add {startCase(camelCase(service || 'record'))}
          </Button>
          <Fade unmountOnExit in={!!row?.isNew}>
            <Button color="success" startIcon={<SaveIcon />} onClick={handleSave}>
              Save
            </Button>
          </Fade>
          <Fade unmountOnExit in={!!row?.isNew}>
            <Button color="error" startIcon={<RemoveIcon />} onClick={handleRemove}>
              Cancel
            </Button>
          </Fade>
        </>
      )}
      {showCellSelectionCopy && (
        <Fade unmountOnExit in>
          <Button color="primary" startIcon={<CopyICon />} onClick={handleCopySelectedCells}>
            {numberOfSelectedCells > 1 ? 'Copy Selected Cells' : 'Copy Cell'}
          </Button>
        </Fade>
      )}

      {showRefresh && (
        <>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip disableInteractive placement="left" title="Refresh">
            <IconButton color="primary" onClick={refetch}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </GridToolbarContainer>
  );
}

interface DataGridProps extends Omit<DataGridPremiumProps, 'rows'> {
  columns: GridColDef[];
  rows?: any[];
  skipQuery?: boolean;
  disableCellSelection?: boolean;
  service: string;
  title?: string;
  refreshTime?: any;
  showRefresh?: boolean;
  showQuickFilter?: boolean;
  showAdd?: boolean;
  showToolbar?: boolean;
  showFilters?: boolean;
  include?: string[];
  query?: any;
  defaultSort?: { field: string; sort: 'desc' | 'asc' }[];
  defaultPageSize?: number;
  storageKey?: string;
  getInitialValues?: () => any;
}

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  const updates: any = updatedDiff(oldRow, newRow);
  const newValues: any = addedDiff(oldRow, newRow);
  const deletedValues: any = deletedDiff(oldRow, newRow);
  // console.log(deletedValues, 'deletedValues');
  // console.log(updates, 'updates');
  // console.log(newValues, 'newValues');
  const changes: any = deepmerge(updates, newValues);
  // console.log(changes, 'changes');
  const updatedArrayKeys = Object.keys(updates).filter((key) => {
    if (Array.isArray(oldRow[key])) {
      return true;
    }
    return Array.isArray(updates[key]);
  });
  const newArrayKeys = Object.keys(newValues).filter((key) => {
    if (Array.isArray(oldRow[key])) {
      return true;
    }
    return Array.isArray(newValues[key]);
  });
  const deletedArrayKeys = Object.keys(deletedValues).filter((key) => {
    if (Array.isArray(oldRow[key])) {
      return true;
    }
    return Array.isArray(newValues[key]);
  });
  const arrayKeysToUpdate = [
    ...new Set([...updatedArrayKeys, ...newArrayKeys, ...deletedArrayKeys]),
  ];
  arrayKeysToUpdate.forEach((key) => {
    changes[key] = newRow[key];
  });
  if (!changes) {
    return null;
  }
  return Object.keys(changes).length ? changes : null;
}

const DataGridInternal = (props: DataGridProps) => {
  const {
    columns: _columns,
    service,
    skipQuery,
    skipStateChanges: _skipStateChanges,
    title,
    getInitialValues,
    showQuickFilter = false,
    showAdd = true,
    showRefresh = true,
    showToolbar = true,
    showFilters = true,
    refreshTime = false,
    apiRef: apiRefProp,
    storageKey: stk,
    query,
    rows,
    componentsProps,
    defaultPageSize,
    disableCellSelection,
    loading,
    defaultSort,
    ...rest
  } = props;

  const storageKey = stk || service;
  const { current: pendingDatasSaving } = useRef<{ [x: string]: any }>({});
  const apiRef = useGridApiRef();
  const intl = useIntl();

  if (apiRefProp) {
    apiRefProp.current = apiRef.current;
  }

  const defaultSorting = defaultSort || [{ field: 'id', sort: 'desc' }];
  const { getState, setDataGrid, storageSynced } = useDatagrid({
    shouldUpdate: {
      toJSON() {
        return `avoidRenderWhenStateUpdates`;
      },
      test(prev) {
        return !prev;
      },
    },
  });

  const [state, setState] = useState({ synced: false });

  // console.log('render ----------------');

  const skipStateChanges = _skipStateChanges;

  const gridSettings = state[storageKey] || {
    sortModel: defaultSorting,
  };

  // console.log(gridSettings, 'gridSettings');

  const [currentStorageKey, setCurrentStorageKey] = useState<string>();
  const [currentViewId, setCurrentViewId] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(gridSettings.pageSize || defaultPageSize || 25);
  const [page, setPage] = useState<number>(gridSettings.page || 0);
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>();
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>();

  // console.log(currentStorageKey, 'currentStorageKey');
  // console.log(JSON.parse(JSON.stringify(gridSettings)), 'gridSettings');

  const createFaakeServerMemoized = useMemo(
    () =>
      createFakeServer(
        {
          columns: _columns,
          rows,
          sortModel: gridSettings.sortModel,
          filterModel: gridSettings.filterModel,
          columnVisibilityModel: gridSettings.columnVisibilityModel,
          columnsOrder: gridSettings.columnsOrder,
          pinnedColumns: gridSettings.pinnedColumns,
          columnsSize: gridSettings.columnsSize,
          storageKey: currentStorageKey,
        },
        { service, include: props.include, query }
      ),
    [service, query, currentStorageKey]
  );
  const { columns, useQuery, initialState } = createFaakeServerMemoized;

  if (apiRefProp?.current?.initialState) {
    apiRefProp.current.initialState = initialState;
  }

  // console.log(initialState, 'initialState');

  const onFilterChange = useCallback(
    debounce(
      (_filterModel: GridFilterModel) => {
        if (!deepEqual(filterModel, _filterModel)) {
          // Here you save the data you need from the filter model
          startTransition(() => {
            setFilterModel(_filterModel);
          });
        }
      },
      100,
      { maxWait: 1000 }
    ),
    [currentStorageKey]
  );
  const onColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) => {
      // Here you save the data you need from the filter model
      if (!currentViewId) {
        setDataGrid(currentStorageKey, {
          columnVisibilityModel: model,
        });
      }
    },
    [currentStorageKey, currentViewId]
  );

  const saveDensity = useCallback(
    (data: any) => {
      if (!currentViewId) {
        setDataGrid(currentStorageKey, data);
      }
    },
    [currentStorageKey, currentViewId]
  );
  const handleSortModelChange = useCallback(
    (_sortModel: GridSortModel) => {
      // Here you save the data you need from the sort model
      startTransition(() => {
        setSortModel(_sortModel);
      });
    },
    [currentStorageKey]
  );

  const queryOptions = useMemo(() => {
    const sortModelFallbackToDefault = !sortModel?.length ? defaultSorting : sortModel;
    return {
      sortModel: sortModelFallbackToDefault,
      filterModel,
      pageSize,
      page,
      storageKey: currentStorageKey,
      skip:
        skipQuery ||
        skipStateChanges ||
        currentStorageKey !== storageKey ||
        currentViewId !== props.currentViewId,
    };
  }, [
    sortModel,
    filterModel,
    pageSize,
    page,
    currentStorageKey,
    skipQuery,
    storageKey,
    skipStateChanges,
    currentViewId,
  ]);

  useEffect(() => {
    if (storageSynced) {
      Promise.resolve(
        props.currentViewState ? { restoreState: props.currentViewState } : getState()
      ).then((_currentState) => {
        if (_currentState) {
          let currentState = clone(_currentState);
          let currentGridSettings = {};
          if (currentState.restoreState) {
            currentGridSettings = {
              ...currentState.restoreState.sorting,
              ...currentState.restoreState.filter,
              ...currentState.restoreState.pagination,
            };
            currentState = { [storageKey]: clone(currentGridSettings) };
          } else {
            currentGridSettings = currentState[storageKey] || {
              sortModel: defaultSorting,
            };
          }

          startTransition(() => {
            const _initialState = getInitialState({}, _columns, {});
            setSortModel(currentGridSettings.sortModel);
            setFilterModel(currentGridSettings.filterModel);
            setPageSize(Number(currentGridSettings.pageSize) || defaultPageSize || 25);
            setPage(Number(currentGridSettings.page) || 0);
            setCurrentStorageKey(storageKey);
            setCurrentViewId(props.currentViewId);
            setState(currentState);
            const newState = _currentState.restoreState
              ? _currentState.restoreState
              : ({
                  filter: {
                    filterModel: currentGridSettings.filterModel,
                  },
                  sorting: {
                    sortModel: currentGridSettings.sortModel,
                  },
                  pagination: {
                    paginationModel: {
                      page: Number(currentGridSettings.page) || 0,
                      pageSize: Number(currentGridSettings.pageSize) || defaultPageSize || 25,
                    },
                  },
                  pinnedColumns: currentGridSettings.pinnedColumns,
                  rowGrouping: currentGridSettings.rowGrouping || { model: [] },
                  aggregation: currentGridSettings.aggregation,
                  columnGrouping: currentGridSettings.columnGrouping,
                  // rowSelection: currentGridSettings.rowSelection,
                  columns: {
                    columnVisibilityModel:
                      currentGridSettings.columnVisibilityModel ||
                      _initialState.columns.columnVisibilityModel,
                    dimensions: Object.keys(currentGridSettings.columnsSize || {}).reduce(
                      (acc, key) => {
                        acc[key] = {
                          width: currentGridSettings.columnsSize[key],
                        };
                        return acc;
                      },
                      {}
                    ),
                  },
                } as GridInitialStatePremium);

            // do a clean up
            newState.columns.columnVisibilityModel = Object.keys(
              newState.columns.columnVisibilityModel
            ).reduce((acc, curr) => {
              const columnExist = _columns.some((column) => column.field === curr);
              if (columnExist) {
                const columnVisibility = newState.columns.columnVisibilityModel;
                acc[curr] = columnVisibility[curr];
              }
              return acc;
            }, {} as any);
            apiRef.current.restoreState(newState);
          });
        }
      });
    }
  }, [storageKey, storageSynced, props.currentViewId]);

  // this persist all changes in the storage
  useEffect(() => {
    if (
      queryOptions.storageKey === currentStorageKey &&
      currentStorageKey === storageKey &&
      !skipStateChanges
    ) {
      if (!currentViewId) {
        setDataGrid(currentStorageKey, queryOptions);
      }
    }
  }, [queryOptions, currentStorageKey, skipStateChanges]);

  const {
    isLoading: _isLoading,
    data: _data,
    pageInfo: _pageInfo,
    refetch,
    storageKey: resultStorageKey,
  } = useQuery(queryOptions);
  const { create, patch } = useMutation(service);
  const { dispatch } = useNotifier();

  const data = resultStorageKey === storageKey ? _data : [];
  const pageInfo = resultStorageKey === storageKey ? _pageInfo : {};
  const isLoading = resultStorageKey === storageKey ? _isLoading : true;

  apiRef.current.queryOptions = queryOptions;
  apiRef.current.service = service;
  apiRef.current.query = query;
  apiRef.current.include = props.include;
  // console.log(data, 'data');

  useEffect(() => {
    if (refreshTime && !isLoading && data) {
      refetch();
    }
  }, [refreshTime]);

  // moutn and unmount
  useEffect(() => {
    const onPaste = (event: ClipboardEvent) => {
      const parentFormElement: HTMLDivElement = event.target.closest('.MuiDataGrid-filterForm');
      if (parentFormElement) {
        const operatorFormContainer: HTMLDivElement | null = parentFormElement.querySelector(
          '.MuiDataGrid-filterFormOperatorInput'
        );
        if (operatorFormContainer) {
          const input: HTMLSelectElement | null =
            operatorFormContainer.querySelector('.MuiInput-input');
          if (input && input.value === 'isAnyOf') {
            const text = (event.clipboardData || window.clipboardData).getData('text');

            const columnFormContainer: HTMLDivElement | null = parentFormElement.querySelector(
              '.MuiDataGrid-filterFormColumnInput'
            );
            const columnInput: HTMLSelectElement | null | undefined =
              columnFormContainer?.querySelector('.MuiInput-input');

            if (columnInput) {
              const column = columnInput.value;
              const filterId = Number(parentFormElement.getAttribute('data-id'));
              const filterItem = apiRef.current.state.filter.filterModel.items.find(
                (item) => item.id === filterId
              );
              // if filter item is not present it should be becosue is other datagrid instance
              if (filterItem) {
                const getApplyIsAnyFilterFn =
                  apiRef.current.getColumn(column).getApplyIsAnyFilterFn ||
                  ((string: string) => string.split(/\r|\n/));
                const lines: string[] = getApplyIsAnyFilterFn(text).filter((n: string) => n);
                if (lines.length > 1) {
                  const newValuesUniques = [...new Set((filterItem?.value || []).concat(lines))];
                  event.preventDefault();
                  apiRef.current.upsertFilterItem({
                    field: column,
                    id: filterId,
                    value: newValuesUniques,
                    operator: 'isAnyOf',
                  });
                }
              }
            }
          }
        }
      }
    };

    window.addEventListener('paste', onPaste);
    return () => {
      window.removeEventListener('paste', onPaste);
    };
  }, []);

  apiRef.current.refetch = refetch;
  const dataRef = useRef<any>({});

  if (!isLoading) {
    dataRef.current[storageKey] = data;
  }

  const dataByStorageKey = dataRef.current[storageKey] || [];

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(pageInfo?.totalRowCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined ? pageInfo?.totalRowCount : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, setRowCountState]);

  const onPaginationModelChange = useCallback((_page: { page: number; pageSize: number }) => {
    setPage(_page.page || 0);
    setPageSize(_page.pageSize || 25);
  }, []);

  const onColumnResize = useCallback(
    debounce(
      (params: GridColumnResizeParams) => {
        if (!currentViewId) {
          setDataGrid(currentStorageKey, {
            columnsSize: {
              [params.colDef.field]: params.width,
            },
          });
        }
      },
      500,
      { maxWait: 1500 }
    ),
    [currentStorageKey, currentViewId]
  );

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel, persistCreate = false) => {
      const computedChanges = computeMutation(newRow, oldRow);
      // console.log(oldRow, 'oldRow');
      // console.log(newRow, 'newRow');
      // console.log(computedChanges, 'computedChanges');
      if (!computedChanges && !pendingDatasSaving[oldRow.id]) {
        return newRow;
      }

      // if (computedChanges) {
      //   Object.keys(computedChanges).forEach((key) => {
      //     if (Array.isArray(newRow[key]) || Array.isArray(oldRow[key])) {
      //       computedChanges[key] = Object.values(computedChanges[key]);
      //     }
      //   });
      // }

      // console.log('before');
      // console.log(pendingDatasSaving[oldRow.id], 'pendingDatasSaving[oldRow.id]');
      // console.log(computedChanges, 'computedChanges');
      const mutation = deepmerge(pendingDatasSaving[oldRow.id] || {}, computedChanges || {});
      console.log('after');
      console.log(mutation, 'mutation');
      pendingDatasSaving[oldRow.id] = mutation;
      if (mutation || newRow.isNew) {
        try {
          if (newRow.isNew) {
            if (!persistCreate) {
              return newRow;
            }
            try {
              const result = await create(newRow);
              delete pendingDatasSaving[oldRow.id];

              snackbarHelper({
                dispatch,
                message: intl.formatMessage({ id: 'saved', defaultMessage: 'Saved' }),
                persist: false,
                autoHideDuration: 2000,
              });
              apiRef.current?.updateRows([{ id: newRow.id, isNew: false, _action: 'delete' }]);
              const newRowResult = deepmerge(newRow, result);
              apiRef.current?.updateRows([{ ...newRowResult, isNew: false }]);
              return newRowResult;
            } catch (error) {
              snackbarHelper({
                dispatch,
                message: 'Error saving',
                severity: 'warning',
                error,
              });
              return newRow;
            }
          }

          if (__DEVELOPMENT__) {
            console.log(mutation, 'mutation');
          }

          const result = await patch(oldRow.uid, mutation);
          delete pendingDatasSaving[oldRow.id];
          snackbarHelper({
            dispatch,
            message: 'Saved',
            persist: false,
            autoHideDuration: 2000,
          });
          return deepmerge(newRow, result);
        } catch (error) {
          snackbarHelper({
            dispatch,
            message: 'Error saving',
            severity: 'warning',
            error,
          });
          return newRow;
        }
      }
      return oldRow; // Nothing was changed
    },
    [service, currentStorageKey]
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    snackbarHelper({
      dispatch,
      message: 'Error saving',
      severity: 'warning',
      error,
    });
  }, []);

  /** text ellipses detector */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState('');

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    const cellContent =
      event.currentTarget.querySelector('.MuiDataGrid-cellContent') || event.currentTarget;
    if (cellContent && cellContent.clientWidth < cellContent.scrollWidth) {
      setValue(cellContent.textContent || '');
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const initialSelectionModel =
    props.getInitialSelectionModel && props.getInitialSelectionModel(data);

  const newColumns = useMemo(() => {
    if (
      !props.currentViewState ||
      !props.currentViewState.columns ||
      !props.currentViewState.columns.dimensions
    ) {
      return columns;
    }
    return columns.map((column) => {
      const currentColumnDims = props.currentViewState.columns.dimensions[column.field];
      const newWidth = currentColumnDims?.width || column.width;
      // fix undefined values
      if (!newWidth) {
        return column;
      }
      return {
        ...column,
        width: newWidth,
      };
    });
  }, [props.currentViewId, service, currentStorageKey, columns]);

  const disableAllEventsListeners = skipStateChanges || storageKey !== currentStorageKey;
  return (
    <Box
      sx={[
        {
          overflow: 'visible',
          height: data.length ? '85vh' : '250px',
          minHeight: data.length ? '500px' : '250px',
          backgroundColor: 'transparent',
          // display: 'flex',
          pb: 6,
          '& .MuiDataGrid-toolbarContainer': {
            mb: 2,
            overflow: 'visible',
          },
          '& .MuiLinearProgress-root': {
            borderRadius: 1,
          },
          '& .MuiDataGrid-columnHeaders': {
            bgcolor: '#fff',
            borderRadius: 1,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'grey.200',
            boxShadow: (theme) => theme.shadows[1],
            '& + div': {
              borderRadius: 2,
              transform: (theme) => `translateY(${theme.spacing(3)})`,
            },
          },
          '& .MuiDataGrid-main': {
            overflow: 'visible',
            minHeight: data.length > 0 ? 'auto' : '200px',
          },
          '& .MuiDataGrid-virtualScroller': {
            bgcolor: '#fff',
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'grey.200',
            boxShadow: (theme) => theme.shadows[1],
            // transform: (theme) => `translateY(${theme.spacing(3)})`,
          },
          '& .MuiDataGrid-cell--editing': {
            '& .MuiInputBase-root': {
              height: '100%',
            },
          },
          '& .MuiDataGrid-root': {
            backgroundColor: 'transparent',
            // bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'primaryDark.900' : '#fff'),
            '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 14, fontWeight: 'bold' },
            '& .MuiButton-root': { marginLeft: 0, marginRight: 1 },
            '& .MuiInputAdornment-root .MuiButton-root': { marginLeft: 0, marginRight: 0 },
            '& .MuiDataGrid-renderingZone': {
              '& .MuiDataGrid-cell': {
                bgcolor: 'grey.50',
              },
            },
            '& .MuiDataGrid-footerContainer': {
              minHeight: 48,
              // borderTop: '1px solid',
              // borderColor: (theme) =>
              //   theme.palette.mode === 'dark' ? 'primaryDark.600' : 'grey.200',
              transform: (theme) => `translateY(${theme.spacing(6)})`,
              borderTop: 0,
              '& .MuiTablePagination-select': {
                bgcolor: '#fff',
                borderRadius: 1,
                boxShadow: (theme) => theme.shadows[1],
                // '& :hover': {

                // }
              },
            },
            '& .MuiTablePagination-root': {
              fontSize: '0.75rem',
              '& p': {
                fontSize: '0.75rem',
              },
              '& .MuiToolbar-root': {
                minHeight: 48,
              },
            },
            '& .Mui-error': {
              bgcolor: `rgb(126,10,15, 0.1)`,
              color: '#750f0f',
            },
          },
        },
        (theme) => {
          return {
            [theme.getColorSchemeSelector('dark')]: {
              '& .MuiDataGrid-columnHeaders': {
                bgcolor: 'primaryDark.700',
                borderColor: 'primaryDark.400',
              },
              '& .MuiDataGrid-virtualScroller': {
                bgcolor: 'primaryDark.700',
                borderRadius: 2,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'primaryDark.400',
              },
              '& .MuiDataGrid-root': {
                '& .MuiDataGrid-renderingZone': {
                  '& .MuiDataGrid-cell': {
                    bgcolor: 'primaryDark.800',
                  },
                },
                '& .MuiDataGrid-footerContainer': {
                  '& .MuiTablePagination-select': {
                    bgcolor: 'primaryDark.700',
                  },
                },
                '& .Mui-error': {
                  bgcolor: `rgb(126,10,15, 0)`,
                  color: '#ff4343',
                },
              },
            },
          };
        },
      ]}
    >
      <DataGridPremium
        columns={newColumns}
        apiRef={apiRef}
        unstable_cellSelection={!disableCellSelection}
        // key={storageKey}
        // getEstimatedRowHeight={() => 200}
        // getRowHeight={() => 'auto'}
        experimentalFeatures={{
          columnGrouping: true,
          lazyLoading: true,
          rowPinning: true,
          warnIfFocusStateIsNotSynced: true,
        }}
        rowGroupingColumnMode="multiple"
        initialState={initialState}
        rows={dataByStorageKey}
        localeText={{
          toolbarExport: intl.formatMessage({ id: 'export', defaultMessage: 'Export' }),
          toolbarExportLabel: intl.formatMessage({
            id: 'exportLabel',
            defaultMessage: 'Export',
          }),
          toolbarExportCSV: intl.formatMessage({
            id: 'exportCsv',
            defaultMessage: 'Download as CSV',
          }),
          toolbarExportPrint: intl.formatMessage({
            id: 'exportPrint',
            defaultMessage: 'Print',
          }),
          toolbarExportExcel: intl.formatMessage({
            id: 'exportExcel',
            defaultMessage: 'Download as Excel',
          }),
          toolbarFilters: intl.formatMessage({ id: 'filters', defaultMessage: 'Filters' }),
          toolbarColumns: intl.formatMessage({ id: 'columns', defaultMessage: 'Columns' }),
          toolbarColumnsLabel: intl.formatMessage({
            id: 'columnsLabel',
            defaultMessage: 'Columns Label Pendind Translate',
          }),
          toolbarDensity: intl.formatMessage({ id: 'density', defaultMessage: 'Density' }),
          toolbarDensityLabel: intl.formatMessage({ id: 'size', defaultMessage: 'Size' }),
          toolbarDensityCompact: intl.formatMessage({ id: 'small', defaultMessage: 'Small' }),
          toolbarDensityStandard: intl.formatMessage({
            id: 'medium',
            defaultMessage: 'Medium',
          }),
          toolbarDensityComfortable: intl.formatMessage({
            id: 'large',
            defaultMessage: 'Large',
          }),
        }}
        loading={loading || isLoading}
        rowCount={rowCountState}
        checkboxSelection
        disableRowSelectionOnClick
        onColumnResize={disableAllEventsListeners ? undefined : onColumnResize}
        initialSelectionModel={initialSelectionModel}
        density={
          typeof gridSettings.density === 'string' ? gridSettings.density || 'standard' : 'standard'
        }
        onPaginationModelChange={disableAllEventsListeners ? undefined : onPaginationModelChange}
        pageSizeOptions={[5, 25, 50, 100, 500, 1000, 5000]}
        paginationMode={rows ? 'client' : 'server'}
        onFilterModelChange={disableAllEventsListeners ? undefined : onFilterChange}
        filterMode={rows ? 'client' : 'server'}
        onSortModelChange={handleSortModelChange}
        // error with grouping
        sortingMode={rows ? 'client' : 'server'}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        onColumnVisibilityModelChange={
          disableAllEventsListeners ? undefined : onColumnVisibilityModelChange
        }
        components={
          showToolbar
            ? {
                Toolbar: GridToolbar,
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
                Pagination,
              }
            : {
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
                Pagination,
              }
        }
        componentsProps={{
          toolbar: {
            getInitialValues,
            columns,
            processRowUpdate,
            saveDensity,
            service,
            showQuickFilter,
            showAdd,
            showRefresh,
            showFilters,
            storageKey: currentStorageKey,
            refetch,
            skipStateChanges: disableAllEventsListeners,
            currentViewId,
            ...componentsProps?.toolbar,
          },
          cell: {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          },
        }}
        autoPageSize={false}
        pagination={dataByStorageKey.length > 0}
        {...rest}
      />

      {!dataByStorageKey.length && (
        <Box sx={{ textAlign: 'center', marginTop: 27, pb: 10 }}>No rows</Box>
      )}

      <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableScrollLock
        hideBackdrop
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableAutoFocus
      >
        <Typography sx={{ p: 1 }}>{`${value}`}</Typography>
      </Popover>
    </Box>
  );
};

const DataGrid = (props: DataGridProps) => {
  let apiRef = useGridApiRef();
  if (props.apiRef) {
    apiRef = props.apiRef;
  }
  const storageKey = props.storageKey || props.service;
  return props.title ? (
    <TitleMenu
      initialState={apiRef.current.initialState}
      title={props.title}
      apiRef={apiRef}
      storageKey={storageKey}
    >
      <DataGridInternal {...props} apiRef={apiRef} />
    </TitleMenu>
  ) : (
    <DataGridInternal {...props} apiRef={apiRef} />
  );
};

export default DataGrid;
