/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */

import { ServerStyleSheets } from '@mui/styles';
import createEmotionCache from 'createEmotionCache';

import { createContext } from 'react';

import { create } from 'jss';
import jssPluginRuleValueFunction from 'jss-plugin-rule-value-function';
import jssPluginGlobal from 'jss-plugin-global';
import jssPluginNested from 'jss-plugin-nested';
import jssPluginCamelCase from 'jss-plugin-camel-case';
import jssPluginDefaultUnit from 'jss-plugin-default-unit';
import jssPluginVendorPrefixer from 'utils/styles/vendor-prefixer';
import jssPluginPropsSort from 'jss-plugin-props-sort';
import createGenerateClassName from 'utils/styles/createGenerateClassName';

const jssCache = new Map();

export interface PageContextType {
  sheetsCache: Map<any, any>;
}

function jssPreset() {
  return {
    plugins: [
      jssPluginRuleValueFunction(),
      jssPluginGlobal(),
      jssPluginNested(),
      jssPluginCamelCase(),
      jssPluginDefaultUnit(),
      // Disable the vendor prefixer server-side, it does nothing.
      // This way, we can get a performance boost.
      // In the documentation, we are using `autoprefixer` to solve this problem.
      typeof window === 'undefined' ? null : jssPluginVendorPrefixer(),
      jssPluginPropsSort(),
    ],
  };
}

const jss = create({ plugins: [...jssPreset().plugins] });

export const PageContext = createContext<PageContextType | undefined>(undefined);

const generateClassName = createGenerateClassName({
  productionPrefix: 'j', // Reduce the bandwidth usage.
});

function createPageContext() {
  const cache = createEmotionCache();

  return {
    sheetsCache: cache,
    jssCache,
    jss,
    sheetsRegistry: new ServerStyleSheets({ jss, serverGenerateClassName: generateClassName }),
    generateClassName,
    // This is needed in order to deduplicate the injection of CSS in the page.
    // sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
  };
}

export default function getPageContext(): PageContextType {
  // Make sure to create a new store for every server-side request so that data
  // isn't shared between connections (which would be bad)
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'browser' does not exist on type 'Process... Remove this comment to see the full error message
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side
  // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  if (!global.__MUI_PAGE_CONTEXT__) {
    // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
    global.__MUI_PAGE_CONTEXT__ = createPageContext();
  }

  // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  return global.__MUI_PAGE_CONTEXT__;
}
