import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const GoodCategoriesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-GoodCategories' */ './GoodCategories'),
  {
    fallback: <LoadingComponent />,
  }
);

const GoodCategories = () => <GoodCategoriesLoadable />;

export default GoodCategories;
