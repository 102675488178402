/* eslint-disable max-len */
import loadable from '@loadable/component';
import { find as findMoneyRanges } from 'redux/modules/moneyRanges';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ServicesAreasInsuranceCostsLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'admin-ServicesAreasInsuranceCosts' */ './ServicesAreasInsuranceCosts'
    ),
  {
    fallback: <LoadingComponent />,
  }
);

const ServicesAreasInsuranceCosts = (prop: any) => (
  <ServicesAreasInsuranceCostsLoadable {...prop} />
);

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();

    const moneyRanges = state.moneyRanges.result && state.moneyRanges.result.all;

    if (!moneyRanges) {
      promises.push(
        dispatch(
          findMoneyRanges('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    await Promise.all(promises);
  },
})(ServicesAreasInsuranceCosts);
