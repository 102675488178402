import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ColombiaManifestLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-ColombiaManifestLoadable' */ './ColombiaManifest'),
  {
    fallback: <LoadingComponent />,
  }
);

export default ColombiaManifestLoadable;
