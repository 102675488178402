import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { find as findReceptacleTypes } from '../../../redux/modules/receptacleTypes';

const NewReceptacleTypeLoadable = loadable(() => import('./NewReceptacleType'), {
  fallback: <LoadingComponent />,
});

const NewReceptacleType = (prop: any) => <NewReceptacleTypeLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises = [];

    if (params.id) {
      promises.push(
        dispatch(
          findReceptacleTypes('editReceptacleType', {
            query: {
              $paginate: false,
              uid: params.id,
            },
          })
        ).catch(console.error)
      );
    }
    await Promise.all(promises);
  },
})(NewReceptacleType);
