import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const WhiteLabelLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-white-label' */ './WhiteLabel'),
  {
    fallback: <LoadingComponent />,
  }
);

const Accounts = (prop: any) => <WhiteLabelLoadable {...prop} />;

export default Accounts;
