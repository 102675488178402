import auth from './modules/auth';
import users from './modules/users';
import settings from './modules/settings';
import notifs from './modules/notifs';
import online from './modules/online';
import retailer from './modules/retailer';
import siteConfigs from './modules/siteConfigs';
import parcels from './modules/parcels';
import undeliverableParcels from './modules/undeliverableParcels';
import accounts from './modules/accounts';
import services from './modules/services';
import internalServices from './modules/internal-services';
import countries from './modules/countries';
import shipping from './modules/shipping';
import externalServices from './modules/externalServices';
import externalServiceProviders from './modules/externalServiceProviders';
import countryAddressTypes from './modules/countryAddressTypes';
import states from './modules/states';
import accountUsers from './modules/accountUsers';
import harmonizedCodes from './modules/harmonizedCodes';
import unitizers from './modules/unitizers';
import dispatches from './modules/dispatches';
import airwayBillNumbers from './modules/airwayBillNumbers';
import airwayBills from './modules/airwayBills';
import postalCodes from './modules/postalCodes';
import tariffs from './modules/tariffs';
import tariffWeightRates from './modules/tariffWeightRates';
import serviceAreaWeightCarrierCosts from './modules/serviceAreaWeightCarrierCosts';
import serviceAreaWeightLastMileCosts from './modules/serviceAreaWeightLastMileCosts';
import composeRates from './modules/composeRates';
import weights from './modules/weights';
import moneyRanges from './modules/moneyRanges';
import externalServiceAreas from './modules/externalServiceAreas';
import serviceAreaTariffs from './modules/serviceAreaTariffs';
import externalServiceRules from './modules/externalServiceRules';
import externalServiceRuleTypes from './modules/externalServiceRuleTypes';
import externalServiceProviderDestinations from './modules/externalServiceProviderDestinations';
import externalServiceProviderCountries from './modules/externalServiceProviderCountries';
import airports from './modules/airports';
import airportOffices from './modules/airportOffices';
import airlines from './modules/airlines';
import parcelImports from './modules/parcelImports';
import parcelTrackingItems from './modules/parcelTrackingItems';
import userRoles from './modules/userRoles';
import billingTiers from './modules/billingTiers';
import billingFactors from './modules/billingFactors';
import accountTypes from './modules/accountTypes';
import accountRoles from './modules/accountRoles';
import accountCustomLogos from './modules/accountCustomLogos';
import userCustomLogos from './modules/userCustomLogos';
import tracking from './modules/tracking';
import statistics from './modules/statistics';
import pickups from './modules/pickups';
import serviceConsignees from './modules/serviceConsignees';
import serviceOptions from './modules/serviceOptions';
import serviceRoutes from './modules/serviceRoutes';
import serviceRouteLegs from './modules/serviceRouteLegs';
import airportRoutes from './modules/airportRoutes';
import loginLogs from './modules/loginLogs';
import docs from './modules/docs';
import validator from './modules/validator';
import autocomplete from './modules/autocomplete';
import trackingCodes from './modules/trackingCodes';
import trackingCodesCustomers from './modules/trackingCodesCustomers';
import trackingCodeLocales from './modules/trackingCodeLocales';
import trackingCodeVendors from './modules/trackingCodeVendors';
import trackingCodeVendorLocales from './modules/trackingCodeVendorLocales';
import ratesCalculator from './modules/ratesCalculator';
import meliService from './modules/meliService';
import contactForms from './modules/contactForms';
import contactForm from './modules/contactForm';
import storesDriver from './modules/storesDriver';
import stores from './modules/stores';
import storesPlatforms from './modules/storesPlatforms';
import storeOrders from './modules/storeOrders';
import currencyMarkets from './modules/currencyMarkets';
import goodCategories from './modules/goodsCategories';
import harmonizedCodesInterchange from './modules/harmonizedCodesInterchanges';
import accountSkuCategories from './modules/accountSkuCategories';
import keyValue from './modules/key-value';
import parcelRecipients from './modules/parcelRecipients';
import accountServiceRestrictions from './modules/accountServiceRestrictions';
import facilities from './modules/facilities';
import address from './modules/address';
import addressTypes from './modules/addressTypes';
import bpsKpi from './modules/bpsKpi';
import receptacleTypes from './modules/receptacleTypes';
import parcelReceptacleUsers from './modules/parcelReceptacleUsers';
import parcelsEditService from './modules/parcelsEditService';
import cleanedLocation from './modules/cleanedLocation';
import parcelImportsDLPacket from './modules/parcelImportsDLPacket';
import scanPickups from './modules/scanPickups';
import amazonApi from './modules/amazonApi';
import labels from './modules/labels';

export default function createReducers(asyncReducers?: any) {
  return {
    keyValue,
    online,
    auth,
    users,
    settings,
    notifs,
    retailer,
    siteConfigs,
    parcels,
    undeliverableParcels,
    accounts,
    services,
    internalServices,
    countries,
    shipping,
    externalServices,
    externalServiceProviders,
    countryAddressTypes,
    states,
    accountUsers,
    harmonizedCodes,
    unitizers,
    dispatches,
    airwayBillNumbers,
    airwayBills,
    postalCodes,
    tariffs,
    tariffWeightRates,
    serviceAreaWeightLastMileCosts,
    serviceAreaWeightCarrierCosts,
    composeRates,
    weights,
    moneyRanges,
    serviceAreaTariffs,
    externalServiceAreas,
    externalServiceRules,
    externalServiceRuleTypes,
    externalServiceProviderDestinations,
    externalServiceProviderCountries,
    airports,
    airlines,
    airportOffices,
    parcelImports,
    parcelTrackingItems,
    userRoles,
    billingTiers,
    billingFactors,
    accountTypes,
    accountRoles,
    accountCustomLogos,
    userCustomLogos,
    tracking,
    statistics,
    pickups,
    serviceConsignees,
    serviceOptions,
    serviceRoutes,
    serviceRouteLegs,
    airportRoutes,
    loginLogs,
    docs,
    validator,
    autocomplete,
    trackingCodes,
    trackingCodesCustomers,
    trackingCodeLocales,
    trackingCodeVendors,
    trackingCodeVendorLocales,
    ratesCalculator,
    meliService,
    contactForms,
    contactForm,
    storesDriver,
    stores,
    storesPlatforms,
    storeOrders,
    currencyMarkets,
    goodCategories,
    accountSkuCategories,
    parcelRecipients,
    accountServiceRestrictions,
    facilities,
    address,
    addressTypes,
    harmonizedCodesInterchange,
    bpsKpi,
    receptacleTypes,
    parcelReceptacleUsers,
    parcelsEditService,
    cleanedLocation,
    labels,
    amazonApi,
    parcelImportsDLPacket,
    scanPickups,
    ...asyncReducers,
  };
}
