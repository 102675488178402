import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Card, CardContent, CardHeader, Skeleton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
    margin: theme.spacing(2),
    marginTop: 100,
  },
  containerSimple: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
    padding: theme.spacing(2),
  },
  card: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
    margin: theme.spacing(2),
  },
  media: {
    height: 250,
  },
}));

const LoadingComponent = ({
  useMargins = true,
  simple = false,
}: {
  useMargins?: boolean;
  simple?: boolean;
}): React.ReactElement => {
  const classes = useStyles();

  if (simple) {
    return (
      <div className={clsx(useMargins && classes.containerSimple)}>
        <Skeleton variant="rectangular" width={210} height={118} />
        <Skeleton />
        <Skeleton width="60%" />
      </div>
    );
  }

  return (
    <div className={clsx(useMargins && classes.container)}>
      <Card className={classes.card}>
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
          title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
        <Skeleton animation="wave" variant="rectangular" className={classes.media} />

        <CardContent>
          <>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" />
          </>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoadingComponent;
