/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import { makeStyles } from '@mui/styles';
import type { Theme } from '@mui/material/styles';
import { socket } from 'app';
import HeaderAdmin from 'components/Admin/Header';
import LangContext from 'components/Context/Lang';
import Footer from 'components/Footer/Footer';
import HeaderApp from 'components/HeaderApp/HeaderApp';
import HeaderApp2 from 'components/HeaderApp2/HeaderApp';
import Maintenance from 'components/Maintenance/Maintenance';
import Notifier from 'components/Notifier/Notifier';
import HeaderRetailer from 'components/Retailer/Loadable';
import config from 'config';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { logout } from 'redux/modules/auth';
import { useNotifier } from 'redux/modules/notifier';
import { load as isOnlineAction } from 'redux/modules/online';
import AppSettingsDrawer from 'components/AppSettingsDrawer/AppSettingsDrawer';
import { SnackbarProvider } from 'components/notistack';
import useApp from 'hooks/useApp';
import { Button } from '@mui/material';

const useStyle = makeStyles(
  (theme: Theme) => ({
    '@keyframes nprogress-spinner': {
      '0%': {
        transform: 'rotate(0deg)',
      },

      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    '@global': {
      '.react-hot-loader-error-overlay': {
        position: 'absolute',
        zIndex: 9999999,
      },
      // 'input[readonly]': {
      //   color: '#dcdcdc'
      // },
      html: {
        WebkitFontSmoothing: 'antialiased',
        // Antialiasing.
        MozOsxFontSmoothing: 'grayscale',
        // Antialiasing.
        // Change from `box-sizing: content-box` so that `width`
        // is not affected by `padding` or `border`.
        boxSizing: 'border-box',
        fontFamily: 'sans-serif',
        '-ms-text-size-adjust': '100%',
        '-webkit-text-size-adjust': '100%',
      },
      '@media screen and (max-device-width:1000px)': {
        html: {
          '-webkit-text-size-adjust': 'none',
        },
      },
      '*, *::before, *::after': {
        boxSizing: 'inherit',
      },
      body: {
        background: theme.palette.mode === 'light' ? '#FCFDFF' : theme.palette.grey[900],
        margin: 0,
        letterSpacing: 0,
        fontWeight: 400,
        fontStyle: 'normal',
        textRendering: 'optimizeLegibility',
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        '-moz-font-feature-settings': '"liga" on',
        color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.84)' : '#fff',
        fontSize: 16,
        lineHeight: 1.4,
        '@media print': {
          // Save printer ink.
        },
      },
      pre: {
        margin: 0,
      },
      '#content': {
        overflow: 'visible',
      },
      '.no-js': {
        '& .img-no-webp': {
          display: 'none',
          backgroundImage: 'none!important',
        },
        '& .img-webp': {
          display: 'none',
          backgroundImage: 'none!important',
        },
      },
      'html.js:not(.webp):not(.no-webp)': {
        '& .img-no-webp': {
          display: 'none',
          backgroundImage: 'none!important',
        },
        '& .img-webp': {
          display: 'none',
          backgroundImage: 'none!important',
        },
      },
      'html.js.webp': {
        '& .img-no-webp': {
          display: 'none',
          backgroundImage: 'none!important',
        },
        '& .img-webp': {
          display: 'inherit',
        },
      },
      'html.js.no-webp': {
        '& .img-no-webp': {
          display: 'inherit',
        },
        '& .img-webp': {
          display: 'none',
          backgroundImage: 'none!important',
        },
      },
      '.links': {
        color: theme.palette.mode === 'light' ? '#1070e0' : '#69c',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.mode === 'light' ? 'rgba(16,112,224,0.85)' : '#98caff',
        },
        '&:focus': {
          color: theme.palette.mode === 'light' ? 'rgba(16,112,224,0.85)' : '#98caff',
        },
      },
      'h1,h2,h3,h4,h5,h6': {
        letterSpacing: 0,
        fontWeight: 700,
        fontStyle: 'normal',
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
      '.headerWrapper': {
        position: 'relative',
        zIndex: 10,
      },
      '.headerBackground': {
        transition: 'height 300ms ease-in 2s',
        transitionProperty: 'height',
        transitionDuration: '300ms',
        transitionTimingFunction: 'ease-in',
        transitionDelay: '100ms',

        height: 300,
        background:
          theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black,
        backgroundImage:
          theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black,
        backgroundRepeat: 'repeat-x',
        filter:
          // eslint-disable-next-line
          "progid: DXImageTransform.Microsoft.gradient(startColorstr= '#FF2364A7', endColorstr= '#FF15497B', GradientType=1)",
      },
      '.appBarAnimation': {
        transition: 'background 300ms ease-in 2s',
        transitionProperty: 'background',
        transitionDuration: '300ms',
        transitionTimingFunction: 'ease-in',
        transitionDelay: '100ms',
      },
      '.is404Body': {
        background: '#2364A7',
        backgroundImage: 'linear-gradient(to right, #2364A7 0%, #15497B 100%)',
        backgroundRepeat: 'repeat-x',
        filter:
          // eslint-disable-next-line
          "progid: DXImageTransform.Microsoft.gradient(startColorstr= '#FF2364A7', endColorstr= '#FF15497B', GradientType=1)",
      },
      '.appBar': {
        background: 'transparent',
        color: '#fff',
      },
      '.appContent': {
        position: 'relative',
      },
      '.notifs': {
        // margin: '15px 0'
      },
      '.topBar': {
        position: 'relative',
        width: '100%',
        zIndex: 22,
        fontSize: 12,
        textAlign: 'right',
      },
      '.flagSelect': {
        marginRight: 10,
      },
      '.titleLive': {
        color: '#fff',
        fontSize: 26,

        '& strong': {
          color: '#000',
          background: '#fff',
        },
      },
      '.clearfix:before, .clearfix:after': {
        content: '"."',
        display: 'block',
        height: 0,
        overflow: 'hidden',
      },

      '.clearfix:after': {
        clear: 'both',
      },

      '.clearfix': {
        zoom: 1,
      },

      // '.error.error': {
      //   backgroundColor: 'transparent'
      // },

      '.fancy': {
        lineHeight: 0.1,
        textAlign: 'center',

        '& span': {
          display: 'inline-block',
          position: 'relative',
        },
        '& span:before, span:after': {
          content: '""',
          position: 'absolute',
          height: 5,
          borderBottom: '1px solid #eeeeee',
          top: 0,
          width: 600,
        },

        '& span:before': {
          right: '100%',
          marginRight: 15,
        },

        '& span:after': {
          left: '100%',
          marginLeft: 15,
        },
      },
      '#nprogress': {
        pointerEvents: 'none',
        '& .bar': {
          background: '#34e79a',
          position: 'fixed',
          zIndex: 10317777,
          top: 0,
          left: 0,
          width: '100%',
          height: 2,
        },
        '& .peg': {
          display: 'block',
          position: 'absolute',
          right: 0,
          width: 100,
          height: '100%',
          boxShadow: '0 0 10px #34e79a, 0 0 5px #34e79a',
          opacity: 1.0,
          transform: 'rotate(3deg) translate(0px, -4px)',
        },
        '& .spinner': {
          display: 'block',
          position: 'fixed',
          zIndex: 1031,
          top: 15,
          right: 15,
        },
        '& .spinner-icon': {
          width: 18,
          height: 18,
          boxSizing: 'border-box',
          border: 'solid 2px transparent',
          borderTopColor: '#34e79a',
          borderLeftColor: '#34e79a',
          borderRadius: '50%',
          animation: 'nprogress-spinner 400ms linear infinite',
          animationName: '$nprogress-spinner',
        },
      },

      '.nprogress-custom-parent': {
        overflow: 'hidden',
        position: 'relative',
        '& #nprogress': {
          '& .spinner, .bar': {
            position: 'absolute',
          },
        },
      },
      '@media (max-width: 600px)': {
        '.textCenterXs': {
          textAlign: 'center',
        },
        '.titleLive': {
          fontSize: 19,
        },
      },
      '@global .animate-icon': {
        width: 38,
        height: 38,
      },
    },
    headerLogo: {
      width: '100%',
      height: '100%',
    },
    titleHeaderApp: {
      fontWeight: 'bold',
    },
    containerPublicationLogo: {
      height: 65,
      marginRight: 18,
      display: 'flex',
      alignItems: 'center',
    },
    publicationLogoA: {
      lineHeight: 1,
    },
    publicationLogoIMG: {
      marginTop: 5,
    },
    avatarPublicPublciation: {
      width: 32,
      height: 32,
    },
    wrapper: {
      // display: 'flex',
      width: '100%',
    },
    wrapperContentBlock: {
      opacity: 0.1,
      pointerEvents: 'none',
      display: 'flex',
    },
    main: {
      flexGrow: 1,
      overflow: 'hidden',
    },
    mainInAdmin: {
      flexGrow: 1,
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        marginLeft: 240,
      },
    },
    closedDrawer: {
      [theme.breakpoints.up('sm')]: {
        flexGrow: 1,
        overflow: 'hidden',
        marginLeft: 90,
      },
    },
  }),
  { name: 'AppWrapper' }
);

let intervalIsOnline: any = null;
ReactGA.initialize('UA-70180309-4');
const trackPage = (page: any) => {
  ReactGA.set({
    page,
  });
  ReactGA.pageview(page);
};
const AppWrapper = (props: any) => {
  const { changeTheme, lang: originalLang, handleChangeLocale, location } = props;

  const [prevLocation, setPrevLocation] = useState(location);
  const classes = useStyle();
  const dispatch = useDispatch();
  const app = useApp();

  const [state, setState] = useState({ wsStatus: true });
  const { isMobile } = app;
  const configs = useSelector((_state) => _get(_state, 'retailer.result.configs.data'));
  const drawerServiceOpen = useSelector((_state: any) => _state.settings.drawerServiceOpen);

  let configsByKey = {};

  if (Array.isArray(configs)) {
    configsByKey = configs.reduce((acc, curr) => {
      acc[curr._key] = curr.value;
      return acc;
    }, {});
  }

  const userRoles = useSelector((_state) => _get(_state, 'auth.user.roles'));

  const headerAdminRoles = ['admin', 'warehouse', 'accounting', 'accounting_chief', 'analyst'];

  const isDevUser = userRoles && userRoles.some((role: any) => role.role.role === 'dev');
  const seeAdminHeader =
    userRoles && userRoles.some((role: any) => headerAdminRoles.includes(role.role.role));

  const { user, isOnline, isOnlineLoading, servicePanelMode } = useSelector((s) => ({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'online' does not exist on type 'DefaultR... Remove this comment to see the full error message
    isOnline: s.online.isOnline,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'online' does not exist on type 'DefaultR... Remove this comment to see the full error message
    isOnlineLoading: s.online.loading,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'settings' does not exist on type 'Defaul... Remove this comment to see the full error message
    uiTheme: s.settings.uiTheme,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'settings' does not exist on type 'Defaul... Remove this comment to see the full error message
    servicePanelMode: s.settings.servicePanelMode,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'settings' does not exist on type 'Defaul... Remove this comment to see the full error message
    lang: s.settings.lang,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'auth' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    user: s.auth.user,
  }));

  const { closeSnackbar, enqueueSnackbar } = useNotifier({
    shouldUpdate: {
      toJSON: () => 'AuthWrapperDrawerOpen',
      test: (prev, next) => {
        return (
          prev.closeSnackbar !== next.closeSnackbar || prev.enqueueSnackbar !== next.enqueueSnackbar
        );
      },
    },
  });

  useEffect(() => {
    // banner for labor day leave to have any example for other days
    // dispatch(
    //   enqueueSnackbar({
    //     message:
    //       'In observance of Labor Day, our offices will be closed on Monday, September 5th, 2022. Thank you.',
    //     options: {
    //       variant: 'warning',
    //       anchorOrigin: {
    //         vertical: 'top',
    //         horizontal: 'center',
    //       },
    //     },
    //   })
    // );

    const checkIfIsOnline = () => {
      dispatch(isOnlineAction());
    };
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__DEVELOPMENT__'.
    if (!__DEVELOPMENT__) {
      socket.on('disconnect', (reason: any) => {
        console.log('Reason socket got disconnected:', reason);
        setState({ wsStatus: false });
        closeSnackbar('reconnectedws');
        closeSnackbar('reconnectingws');
        closeSnackbar('disconectedws');

        enqueueSnackbar({
          message: 'Connection with WebSocket lost',
          options: {
            key: 'disconectedws',
            persist: true,
            severity: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        });
      });

      socket.io.on('reconnect_attempt', (attempt: any) => {
        closeSnackbar('reconnectedws');
        closeSnackbar('reconnectingws');
        closeSnackbar('disconectedws');
        enqueueSnackbar({
          message: `Reconnecting... attempt ${attempt}`,
          options: {
            key: 'reconnectingws',
            persist: true,
            severity: 'info',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        });
      });

      socket.io.on('reconnect', () => {
        setState({ wsStatus: true });
        closeSnackbar('reconnectingws');
        closeSnackbar('disconectedws');

        enqueueSnackbar({
          message: 'WebSocket Established...',
          options: {
            key: 'reconnectedws',
            persist: true,
            severity: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            action: (key: any) => <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>,
          },
        });
      });
    }

    window.addEventListener('online', checkIfIsOnline);
    window.addEventListener('offline', checkIfIsOnline);

    intervalIsOnline = setInterval(() => {
      dispatch(isOnlineAction());
    }, 600000);

    ReactGA.pageview(location.pathname + location.search);
    // Remove the server-side injected CSS.

    const jssStylesBlankHtml = document.querySelector('#pg-loading-screen');
    if (jssStylesBlankHtml && jssStylesBlankHtml.parentNode) {
      jssStylesBlankHtml.parentNode.removeChild(jssStylesBlankHtml);
    }

    const jssStylesBlank = document.querySelector('#server-side-styles-blank-page');
    if (jssStylesBlank && jssStylesBlank.parentNode) {
      jssStylesBlank.parentNode.removeChild(jssStylesBlank);
    }

    const jssStyles = document.querySelector('#jss-side-styles');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    const links = document.querySelectorAll('link[media="none"]');
    if (links) {
      for (let i = 0; i < links.length; i += 1) {
        const link = links[i];
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'media' does not exist on type 'Element'.
        link.media = 'screen, projection';
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'rel' does not exist on type 'Element'.
        link.rel = 'stylesheet';
      }
    }

    return () => {
      window.removeEventListener('online', checkIfIsOnline);
      window.removeEventListener('offline', checkIfIsOnline);

      if (intervalIsOnline) {
        clearInterval(intervalIsOnline);
        intervalIsOnline = false;
      }
    };
  }, []);

  // didComponentUpdate
  useEffect(() => {
    if (!intervalIsOnline) {
      clearInterval(intervalIsOnline);
      intervalIsOnline = setInterval(() => {
        dispatch(isOnlineAction());
      }, 30000);
    }

    const nextPage = location.pathname + location.search;
    const currentPage = prevLocation.pathname + prevLocation.search;

    if (currentPage !== nextPage) {
      trackPage(nextPage);
      setPrevLocation(location);
    }

    if (location.pathname.indexOf('/page') === -1) {
      if (location !== prevLocation) {
        // remove lang on the url
        let nextPath = location.pathname;
        let prevPath = prevLocation.pathname;
        ['en', 'es', 'pt'].forEach((lang) => {
          nextPath = nextPath.replace(`/${lang}/`, '/');
          prevPath = prevPath.replace(`/${lang}/`, '/');
        });

        if (nextPath !== prevPath) {
          window.scrollTo(0, 0);
        }
      }
    }
  });

  const handleLogout = (event: any) => {
    event.preventDefault();
    dispatch(logout());
  };

  const [settingsOpen, setSettingsOpen] = useState(false);
  const handleSettingsDrawerOpen = () => {
    setSettingsOpen(true);
  };
  const handleSettingsDrawerClose = useCallback(() => {
    setSettingsOpen(false);
  }, []);

  const pathnameParts = location.pathname.split('/');
  const isAdminPage = pathnameParts.length > 1 && pathnameParts[1] === 'admin';
  const isHome2 = pathnameParts.length > 1 && pathnameParts[1] === 'home2';
  const isRetailerPage = pathnameParts.length > 1 && pathnameParts[1] === 'service-area';

  let headerLabel = 'default';

  if (isAdminPage && servicePanelMode !== 'wholesaler' && seeAdminHeader) {
    headerLabel = 'admin';
  } else if (isAdminPage) {
    headerLabel = 'admin';
  }

  if (isHome2) {
    headerLabel = 'default2';
  }

  const headerProps = {
    isOnline,
    isOnlineAction,
    isOnlineLoading,
    position: 'fixed',
    location,
    user,
    logout: handleLogout,
    changeTheme,
    handleChangeLocale,
    handleSettingsDrawerOpen,
  };

  const Headers = {
    default: HeaderApp,
    default2: HeaderApp2,
    admin: HeaderAdmin,
    retailer: HeaderRetailer,
  };

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const HeaderComp = Headers[headerLabel];
  const HeaderComponent = <HeaderComp {...headerProps} />;

  const lang = originalLang;

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'maintenance' does not exist on type '{}'... Remove this comment to see the full error message
  const isInMaintenance = configsByKey.maintenance === 'true';
  const showMaintenancePage = !isDevUser && isInMaintenance && (isAdminPage || isRetailerPage);

  const content = !showMaintenancePage && <Outlet />;

  const mainClassName = !isAdminPage
    ? classes.main
    : isMobile || drawerServiceOpen
    ? classes.mainInAdmin
    : classes.closedDrawer;

  return (
    <LangContext.Provider value={lang}>
      <div className={classes.wrapper}>
        <Helmet {...config.app.head} />
        <div className={!state.wsStatus ? classes.wrapperContentBlock : classes.wrapper}>
          {HeaderComponent}
          <main className={mainClassName}>
            {!showMaintenancePage && content}
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ message: any; }' is not assignable to type... Remove this comment to see the full error message */}
            {showMaintenancePage && <Maintenance message={configsByKey.maintenance_message} />}
            {!isAdminPage && !isRetailerPage && !isHome2 && <Footer />}
          </main>
          <AppSettingsDrawer
            onClose={handleSettingsDrawerClose}
            changeTheme={changeTheme}
            open={settingsOpen}
          />
        </div>
      </div>
      <SnackbarProvider maxSnack={3}>
        <Notifier />
      </SnackbarProvider>
    </LangContext.Provider>
  );
};

AppWrapper.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  changeTheme: PropTypes.func.isRequired,
  handleChangeLocale: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default AppWrapper;
