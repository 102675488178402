/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FORM_ERROR } from 'final-form';
import { setCookie, setUser, setToken } from 'utils/auth/helpers';
import cookie from 'js-cookie';
import type { Application } from '@feathersjs/feathers';
import { objectErrorToString } from 'utils/helpers';
import { ResponseError, Action } from './defaults';

const LOAD = 'redux-bringer/auth/LOAD';
const LOAD_SUCCESS = 'redux-bringer/auth/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-bringer/auth/LOAD_FAIL';
const LOGIN = 'redux-bringer/auth/LOGIN';
const LOGIN_SUCCESS = 'redux-bringer/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'redux-bringer/auth/LOGIN_FAIL';
const REGISTER = 'redux-bringer/auth/REGISTER';
const REGISTER_SUCCESS = 'redux-bringer/auth/REGISTER_SUCCESS';
const REGISTER_FAIL = 'redux-bringer/auth/REGISTER_FAIL';
const LOGOUT = 'redux-bringer/auth/LOGOUT';
const LOGOUT_SUCCESS = 'redux-bringer/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'redux-bringer/auth/LOGOUT_FAIL';

export interface ResponseResult {
  accessToken: string;
  user: any;
}

export interface initialState {
  accessToken?: string;
  error?: ResponseError | boolean;
  loginError?: ResponseError | boolean;
  registerError?: ResponseError | boolean;
  logoutError?: ResponseError | boolean;
  registeringIn?: boolean;
  loggingOut?: boolean;
  loggingIn?: boolean;
  loading?: boolean;
  loaded: boolean;
  user: boolean | any;
}

const initialState = <initialState>{
  loaded: false,
  user: null,
};

export default function reducer<Multiple extends any | string | number | string[] | undefined>(
  state = initialState,
  action: Action<Multiple & ResponseResult> = {} as any
): initialState {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        accessToken: action.result.accessToken,
        user: action.result.user,
        error: false,
      };
    case LOAD_FAIL: {
      let failParams = {};
      if (
        action.error &&
        (action.error.message === 'invalid signature' || action.error.name === 'NotAuthenticated')
      ) {
        failParams = {
          loaded: true,
          user: false,
          accessToken: false,
        };
      }
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        ...failParams,
      };
    }
    case LOGIN:
      return {
        ...state,
        loggingIn: true,
        error: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loaded: true,
        accessToken: action.result.accessToken,
        user: action.result.user,
        error: false,
        loginError: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        loginError: action.error,
      };
    case REGISTER:
      return {
        ...state,
        registeringIn: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registeringIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        registeringIn: false,
        registerError: action.error,
      };
    case LOGOUT:
      return {
        ...state,
        loggingOut: true,
        logoutError: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        accessToken: undefined,
        user: null,
        logoutError: false,
        error: false,
        loginError: false,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error,
      };
    default:
      return state;
  }
}

const catchValidation = (error: any) => {
  if (error.message) {
    if (String(error.message).toLowerCase() === 'validation failed' && error.data) {
      const errorMsg = objectErrorToString(error.data);
      const err = {
        [FORM_ERROR]: errorMsg,
      };
      return Promise.reject(err);
    }
    const err = {
      [FORM_ERROR]: error.message,
    };
    return Promise.reject(err);
  }
  return Promise.reject(error);
};

/*
 * Actions
 * * * * */

export function isLoaded(globalState: any): boolean {
  return globalState.auth && globalState.auth.loaded;
}

export function load(force = false) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: async ({ app, restApp, client }: { app: Application; restApp: any; client: any }) => {
      const response = force
        ? await app.authentication.reAuthenticate(true)
        : await app.authenticate();
      await setCookie()(response);
      setToken({ client, app, restApp })(response);
      setUser({ app, restApp })(response);
      return response;
    },
  };
}

export function register(data: any) {
  return {
    types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
    promise: ({ app }: { app: Application; restApp: any; client: any }) =>
      app.service('users').create(data).catch(catchValidation),
  };
}

export function login(strategy: string, data: any) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: async ({ client, restApp, app }: { app: Application; restApp: any; client: any }) => {
      try {
        const response = await app.authenticate({
          ...data,
          strategy,
        });
        await setCookie()(response);
        setToken({ client, app, restApp })(response);
        setUser({ app, restApp })(response);
        return response;
      } catch (error) {
        if (strategy === 'local') {
          return catchValidation(error);
        }
        throw error;
      }
    },
  };
}

export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: async ({ client, app, restApp }: { app: Application; restApp: any; client: any }) => {
      try {
        await app.logout();
        setToken({ client, app, restApp })({ accessToken: null });
        cookie.remove('feathers-jwt');
      } catch (e) {
        if (e.error === 'NotAuthenticated') {
          setToken({ client, app, restApp })({ accessToken: null });
          cookie.remove('feathers-jwt');
        }
      }
    },
  };
}
