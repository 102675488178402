import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const PickupsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-pikcups' */ './Pickups'),
  {
    fallback: <LoadingComponent />,
  }
);

export default PickupsLoadable;
