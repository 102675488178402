/* eslint-disable no-use-before-define */
import type { Application } from '@feathersjs/feathers/lib';
import { createContext, useContext, useMemo, useCallback } from 'react';
// import loadable from '@loadable/component';
import { RecoilLogger, createLogger } from 'recoil-devtools-logger';
import { settingsAtom } from 'redux/modules/settings';
import { __DEVELOPMENT__ } from 'global-env';
import { rootAtom, useCacheInstance } from './cache';
// import { authAtom } from './useAuth';

// const app = typeof document !== 'undefined' && document.getElementById('content');

// const RecoilizeDebugger = loadable(() => import('recoilize'), {
//   ssr: false,
// });

const logger = createLogger({
  collapsed: true,
});

export const FigbirdContext: { feathers: Application } = createContext({});

const defaultIdField = (item: any) =>
  item && (item.uid || item.id || item._id || item?.properties?.id);
const defaultUpdatedAtField = (item: any) => item && (item.updatedAt || item.updated_at);

/**
 * This is the Figbird Provider that at the minimum needs to be passed in a feathers client.
 * Once the app is wrapped in this Provider, all of the figbird hooks will start working!
 */
export const Provider = ({ feathers, children, store, ...props }) => {
  if (!feathers || !feathers.service) {
    throw new Error('Please pass in a feathers client');
  }

  const idField = useIdField(props.idField);
  const updatedAtField = useUpdatedAtField(props.updatedAtField);
  const config = useMemo(() => ({ idField, updatedAtField }), [idField, updatedAtField]);

  const { AtomProvider, actions } = useCacheInstance(config);

  // figbird is a catch all context value we use to pass down
  // the feathers api client, the atom instance and the useSelector hook
  // now we have all the pieces in context, the api to fetch data, the atom
  // to store the cache and the selector to efficiently bind to the cache store
  const figbird = useMemo(() => {
    return { feathers, actions, config };
  }, [feathers, config]);

  function initializeState({ set }) {
    set(rootAtom, { feathers: store.feathers });

    // if (store.auth) {
    //   set(authAtom, store.auth);
    // }
    if (store.settings) {
      set(settingsAtom, store.settings);
    }
  }

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <AtomProvider initializeState={initializeState}>
      {/* <RecoilizeDebugger app={app} /> */}
      {__DEVELOPMENT__ && <RecoilLogger logger={logger} />}
      <FigbirdContext.Provider value={figbird}>{children}</FigbirdContext.Provider>
    </AtomProvider>
  );
};

/** Get the entire figbird context */
export function useFigbird(): { feathers: Application } {
  return useContext(FigbirdContext);
}

/** Get just the feathers client */
export function useFeathers() {
  const { feathers } = useContext(FigbirdContext);
  return feathers;
}

function useIdField(idField = defaultIdField) {
  return useCallback(
    (item) => {
      const id = typeof idField === 'string' ? item[idField] : idField(item);
      if (!id) console.warn('An item has been received without any ID', item);
      return id;
    },
    [idField]
  );
}

function useUpdatedAtField(updatedAtField = defaultUpdatedAtField) {
  return useCallback(
    (item) => {
      return typeof updatedAtField === 'string' ? item[updatedAtField] : updatedAtField(item);
    },
    [updatedAtField]
  );
}
