import type { TooltipProps } from '@mui/material';
import { useTheme, ClickAwayListener, Box, styled, Tooltip, useMediaQuery } from '@mui/material';
import { useState } from 'react';

const BoxStyled = styled(Box)(() => {
  return {
    maxHeight: '60vh',
    overflow: 'auto',
  };
});

const TooltipClick = (
  props: TooltipProps & { rootBoundary?: string; boundary?: string; preventPropagation?: boolean }
): React.ReactElement => {
  const { children, PopperProps, rootBoundary, boundary, preventPropagation, title, sx, ...rest } =
    props;
  const [open, setOpen] = useState(false);
  const globalTheme = useTheme();
  const isMdUp = useMediaQuery(globalTheme.breakpoints.up('md'));

  const handleTooltipClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  const handleTooltipOpen = (event: Event) => {
    if (preventPropagation === undefined || preventPropagation) {
      event.stopPropagation();
    }
    setOpen(true);
  };

  if (!title) {
    return children;
  }
  return (
    <ClickAwayListener
      mouseEvent="onMouseUp"
      touchEvent="onTouchEnd"
      onClickAway={handleTooltipClose}
    >
      <Tooltip
        sx={{ ...sx, maxHeight: '50vh' }}
        title={<BoxStyled>{title}</BoxStyled>}
        PopperProps={{
          // disablePortal: true,
          ...PopperProps,
          modifiers: [
            {
              name: 'flip',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary,
                boundary,
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary,
                boundary,
                padding: 8,
              },
            },
          ]
            .concat(PopperProps?.modifiers)
            .filter(Boolean),
        }}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        open={open}
        onClick={handleTooltipOpen}
        {...rest}
        disableFocusListener={!isMdUp}
        disableHoverListener={!isMdUp}
        disableTouchListener={!isMdUp}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TooltipClick;
