import loadable from '@loadable/component';
import { get } from 'redux/modules/retailer';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
// @ts-expect-error ts-migrate(6133) FIXME: 'LoadingComponent' is declared but its value is ne... Remove this comment to see the full error message
import LoadingComponent from 'components/Loaders/LoadingComponent';

const InvoiceLoadable = loadable(
  () => import(/* webpackChunkName: 'retailer-invoice' */ './Invoice'),
  {
    // @ts-expect-error ts-migrate(2749) FIXME: 'LoadingComponent' refers to a value, but is being... Remove this comment to see the full error message
    fallback: <LoadingComponent />,
  }
);

// @ts-expect-error ts-migrate(2749) FIXME: 'InvoiceLoadable' refers to a value, but is being ... Remove this comment to see the full error message
const Invoice = (prop: any) => <InvoiceLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises = [];

    promises.push(
      dispatch(
        get('invoice', {
          query: {
            service: 'accounting',
            path: 'admin/graph/find/invoices.json ',
            params: ['Invoices.id'],
            values: [params.id],
            models: ['Billables', 'Billables.BillableItems', 'Payments'],
          },
        })
      )
    );

    await Promise.all(promises);
  },
})(Invoice);
