/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import LocalizationProvider from '@mui/lab/LocalizationProvider';

export default function pickerProviderWrapper(
  AdapterDateFns: any,
  component: any,
  locale: any
): React.ReactElement {
  return AdapterDateFns ? (
    <LocalizationProvider locale={locale} dateAdapter={AdapterDateFns}>
      {component}
    </LocalizationProvider>
  ) : (
    component
  );
}
