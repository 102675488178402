/* eslint-disable max-len */
import loadable from '@loadable/component';

import { find as findTrackingCodes } from 'redux/modules/trackingCodes';

import { find as findExternalServiceProviders } from 'redux/modules/externalServiceProviders';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';
import { batch } from 'react-redux';

const TrackingCodesVendorsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-tracking-codes-vendors' */ './TrackingCodesVendors'),

  {
    fallback: <LoadingComponent />,
  }
);

const TrackingCodesVendors = (prop: any) => <TrackingCodesVendorsLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises: any[] = [];

    const state = getState();

    const trackingCodes = state.trackingCodes.result.all;

    const externalServiceProviders = state.externalServiceProviders.result.all;

    if (!trackingCodes) {
      promises.push(() =>
        dispatch(
          findTrackingCodes('all', {
            query: {
              $paginate: false,

              $limit: 1000,
            },
          })
        ).catch(console.error)
      );
    }

    if (!externalServiceProviders) {
      promises.push(() =>
        dispatch(
          findExternalServiceProviders('all', {
            query: {
              $paginate: false,

              $limit: 1000,
            },
          })
        ).catch(console.error)
      );
    }

    // just one reflow call

    await new Promise((resolve) => {
      batch(async () => {
        const finalPromises = promises.map((func) => func());

        await Promise.all(finalPromises);

        resolve(null);
      });
    });
  },
})(TrackingCodesVendors);
