import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ComposeLastMileCostsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-compose-lastmile-costs' */ './ComposeLastMileCosts'),
  {
    fallback: <LoadingComponent />,
  }
);

const ComposeLastMileCosts = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <ComposeLastMileCostsLoadable {...prop} />
);

export default ComposeLastMileCosts;
