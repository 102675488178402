/* eslint-disable prefer-const */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import type { Service } from '@feathersjs/feathers';
import { useState } from 'react';
import { useFigbird } from './core';
import { hashObject, inflight } from './helpers';
import logger from './logger';
import { UseQueryHookOptions, UseQueryOptions } from './useQuery';

const get = inflight(
  (service, id, params, options) => `${service.path}/${options.queryId}`,
  getter
);
const find = inflight(
  (service: string, params: any, options: any) => `${service.path}/${options.queryId}`,
  finder
);

/**
 * A generic abstraction of both get and find
 */
export const useFetcher = <D>(
  serviceName: string,
  options: UseQueryOptions,
  queryHookOptions: UseQueryHookOptions
): {
  status: 'loading' | 'error' | 'success';
  isFetching: boolean;
  progress: { etaInMs: number; percent: number };
  error: Error | string;
  fetcher: () => Promise<D>;
} => {
  const { method, id } = queryHookOptions;

  const { allPages, parallel, ...params } = options;

  const { feathers } = useFigbird();

  const queryId = `${method.slice(0, 1)}:${hashObject({
    serviceName,
    method,
    id,
    params,
  })}`;

  const [status, setStatus] = useState('loading');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({ etaInMs: 0, percent: 0 });

  const fetcher = (): Promise<D> => {
    const service = feathers.service(serviceName);
    setStatus('loading');
    setLoading(true);
    setProgress({ etaInMs: 0, percent: 0 });
    const result =
      method === 'get'
        ? get(service, id, params, { queryId })
        : find(service, params, { queryId, allPages, parallel, setProgress });
    return result
      .then((data) => {
        setLoading(false);
        setStatus('success');
        return data;
      })
      .catch(() => {
        setLoading(false);
        setStatus('error');
      });
  };

  return { fetcher, isFetching: loading, status, progress };
};

function getter(service, id, params) {
  logger({ type: 'Fetching get', serviceName: [id, service.find.Context.prototype.path], params });
  return service
    .get(id, params)
    .then((result) => {
      logger({
        type: 'Fetching get',
        serviceName: [id, service.find.Context.prototype.path, 'result'],
        params: result,
      });
      return result;
    })
    .catch((error) => {
      logger({
        type: 'Fetching get',
        serviceName: [
          service.find.Context.prototype.path,
          'error',
          `${error.toString().slice(0, 30)}...`,
        ],
        params: { error, msg: error.toString() },
      });
      throw error;
    });
}

function finder(service: Service, params, { allPages, parallel, setProgress }) {
  if (!allPages) {
    logger({ type: 'Fetching find', serviceName: service.find.Context.prototype.path, params });
    return service
      .find(params)
      .then((r) => {
        logger({
          type: 'Fetching find',
          serviceName: [service.find.Context.prototype.path, 'result'],
          params: r,
        });
        setProgress({ percent: 100, etaInMs: 0 });
        return r;
      })
      .catch((error) => {
        logger({
          type: 'Fetching find',
          serviceName: [
            service.find.Context.prototype.path,
            'error',
            `${error.toString().slice(0, 30)}...`,
          ],
          params: { error, msg: error.toString() },
        });
        throw error;
      });
  }

  return new Promise((resolve, reject) => {
    let skip = 0;
    const result = { data: [], skip: 0 };

    fetchNext();

    function doFind(_skip) {
      const allParams = {
        ...params,
        query: {
          ...(params.query || {}),
          $skip: _skip,
        },
      };

      logger({ type: 'Fetching find', serviceName: service.find.Context.prototype.path, params });

      return service.find(allParams);
    }

    function resolveOrFetchNext(res) {
      if (res.data.length === 0 || result.data.length >= result.total) {
        resolve(result);
      } else {
        skip = result.data.length;
        fetchNext();
      }
    }

    function fetchNextParallel() {
      const requiredFetches = Math.ceil((result.total - result.data.length) / result.limit);

      if (requiredFetches > 0) {
        Promise.all(
          new Array(requiredFetches).fill().map((_, idx) => doFind(skip + idx * result.limit))
        )
          .then((results) => {
            const [lastResult] = results.slice(-1);
            result.limit = lastResult.limit;
            result.total = lastResult.total;
            result.data = result.data.concat(results.flatMap((r) => r.data));
            setProgress({ percent: 100, etaInMs: 0 });
            resolveOrFetchNext(lastResult);
          })
          .catch(reject);
      } else {
        resolve(result);
      }
    }

    function fetchNext() {
      if (
        typeof result.total !== 'undefined' &&
        typeof result.limit !== 'undefined' &&
        parallel === true
      ) {
        fetchNextParallel();
      } else {
        const timeToDownloadStart = new Date().getTime();
        doFind(skip)
          .then((res) => {
            const timeToDownloadEnd = new Date().getTime();
            const timeInMs = timeToDownloadEnd - timeToDownloadStart;
            result.limit = res.limit;
            result.total = res.total;

            result.data = result.data.concat(res.data);
            const percent = Math.ceil((result.data.length / res.total) * 100);

            const requiredFetches = Math.ceil((result.total - result.data.length) / result.limit);
            const etaInMs = requiredFetches * timeInMs;

            setProgress({ percent, etaInMs });
            logger({
              type: 'Fetching find',
              serviceName: [service.find.Context.prototype.path, 'result'],
              params: res,
            });

            resolveOrFetchNext(res);
          })
          .catch(reject);
      }
    }
  }).catch((error) => {
    logger({
      type: 'Fetching find',
      serviceName: [
        service.find.Context.prototype.path,
        'error',
        `${error.toString().slice(0, 30)}...`,
      ],
      params: { error, msg: error.toString() },
    });
    throw error;
  });
}
