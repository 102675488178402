import getPageContext, { updatePageContext } from 'components/Context/createThemeContext';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import useLocale from 'hooks/useLocale';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { find as findAccounts } from 'redux/modules/accounts';
import { find as findCountries } from 'redux/modules/countries';
import { find as findInternalService } from 'redux/modules/internal-services';
import { find as findService } from 'redux/modules/services';
// import { changeTheme, set as setSettings } from 'redux/modules/settings';
import { useMediaQuery } from '@mui/material';
import { LicenseInfo as LicenseInfoPremium } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import ClipboardJS from 'clipboard';
import pages from 'components/Admin/pages';
import BrandingCssVarsProvider from 'components/Context/BrandingCssVarsProvider';
import { ThemeProvider } from 'components/Context/ThemeContext';
import PageContext from 'components/PageContext/PageContext';
import usePageContext from 'hooks/usePageContext';
import find from 'lodash/find';
import { useSettings } from 'redux/modules/settings';
import { find as findSiteConfig } from 'redux/modules/siteConfigs';
import { useSsrComplectedState } from 'store/useSsrComplectedState';
import DocsStyledEngineProvider from 'utils/StyledEngineProvider';
import createEmotionCache from 'createEmotionCache';
import AppWrapper from './AppWrapper';

const clientSideEmotionCache = createEmotionCache();

LicenseInfo.setLicenseKey(
  '0f94d8b65161817ca5d7f7af8ac2f042T1JERVI6TVVJLVN0b3J5Ym9vayxFWFBJUlk9MTY1NDg1ODc1MzU1MCxLRVlWRVJTSU9OPTE='
);
LicenseInfoPremium.setLicenseKey(
  '61628ce74db2c1b62783a6d438593bc5Tz1NVUktRG9jLEU9MTY4MzQ0NzgyMTI4NCxTPXByZW1pdW0sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

function findActivePage(currentPages: any[], pathname: string) {
  const activePage = find(currentPages, (page) => {
    if (page.children) {
      if (!page.pathname || pathname.indexOf(`${page.pathname}/`) === 0) {
        // Check if one of the children matches (for /components)
        return findActivePage(page.children, pathname);
      }
    }

    // Should be an exact match if no children
    return pathname === page.pathname;
  });

  if (!activePage) {
    return null;
  }

  // We need to drill down
  if (activePage.pathname !== pathname) {
    return findActivePage(activePage.children, pathname);
  }

  return activePage;
}

const importSafe = (locale: any) => {
  switch (locale) {
    case 'es':
      return import('../../locales/es/common');
    case 'pt':
      return import('../../locales/pt/common');
    default:
      return import('../../locales/en/common');
  }

  // this does not work anymore becouse dynamic vars or somewhere exist an bug
  // const file = `./../../locales/${locale}/common`;
  // return import(file);
};

const cache = createIntlCache();

const createDefaultIntl = () =>
  createIntl({ locale: 'en', defaultLocale: 'en' }, createIntlCache());

const _createIntl = async (locale: any) =>
  locale === 'en'
    ? createDefaultIntl()
    : createIntl({
        locale,
        // @ts-expect-error ts-migrate(2339) FIXME: Property '__esModule' does not exist on type 'type... Remove this comment to see the full error message
        messages: await importSafe(locale).then((r) => (r.__esModule ? r.default : r)),
      });

const App = (props: any) => {
  const setSsrCompleted = useSsrComplectedState();
  useEffect(setSsrCompleted, [setSsrCompleted]);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'localeContext' does not exist on type 'u... Remove this comment to see the full error message
  const { localeContext } = useLocale();
  const pageContext = usePageContext();

  const emotionCache = pageContext?.sheetsCache || clientSideEmotionCache;

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const navigate = useNavigate();
  const location = useLocation();
  const intl = createIntl(
    {
      locale: localeContext.lang,
      messages: localeContext.messages,
    },
    cache
  );

  const { changeTheme } = useSettings();

  const { user, uiTheme } = useSelector((state) => ({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'auth' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    user: state.auth.user,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'settings' does not exist on type 'Defaul... Remove this comment to see the full error message
    uiTheme: state.settings.uiTheme,
  }));

  const paletteMode =
    // eslint-disable-next-line no-nested-ternary
    uiTheme.paletteMode === 'system' ? (prefersDarkMode ? 'dark' : 'light') : uiTheme.paletteMode;

  const [state, setState] = useState({
    prevProps: props,
    user,
    pageContext: pageContext || getPageContext({ ...uiTheme, paletteMode }),
    intl,
    location,
  });

  const nextPage = location.pathname + location.search;
  const currentPage = state.location.pathname + state.location.search;

  useEffect(() => {
    if (!state.user && user) {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (query.redirect === '/') {
        delete query.redirect;
      }
      if (query.redirect) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        navigate(query.redirect || '/login-success');
      } else if (user.roles.some((r: any) => r.role.role === 'admin')) {
        navigate('/admin/dashboard');
      } else if (
        user.roles.some((r: any) => r.role.role === 'retailer' || r.role.role === 'wholesaler')
      ) {
        navigate('/admin/dashboard');
      } else {
        navigate('/login-success');
      }

      setState((prev) => ({ ...prev, user }));
    } else if (state.user && !user) {
      // logout
      setState((prev) => ({ ...prev, user: null }));
    } else if (user) {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (query.redirect && query.redirect !== '/' && nextPage !== currentPage) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        navigate(query.redirect);
      }
    }
  }, [user]);

  const _changeTheme = (theme: any = {}) => {
    if (typeof uiTheme.paletteMode === 'object') {
      uiTheme.paletteMode = 'system';
    }
    const _paletteMode =
      // eslint-disable-next-line no-nested-ternary
      (theme?.paletteMode || uiTheme.paletteMode) === 'system'
        ? prefersDarkMode && uiTheme.paletteMode
          ? 'dark'
          : 'light'
        : theme?.paletteMode || (uiTheme.paletteMode === 'dark' ? 'light' : 'dark');

    const data = {
      ...uiTheme,
      // eslint-disable-next-line no-nested-ternary
    };
    if (theme?.paletteMode) {
      data.paletteMode = theme.paletteMode;
    }
    if (theme?.direction) {
      data.direction = theme.direction;
    }
    changeTheme(data);
    setState((prev) => ({
      ...prev,
      pageContext: updatePageContext({ ...data, paletteMode: _paletteMode }),
    }));
  };

  useEffect(() => {
    if (uiTheme.paletteMode === 'system') {
      _changeTheme();
    }
  }, [uiTheme.paletteMode, paletteMode]);

  useEffect(() => {
    const clipboard = new ClipboardJS('.copy', {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(trigger: Element) => string | null' is not ... Remove this comment to see the full error message
      text(trigger) {
        const text = trigger.getAttribute('aria-label');
        if (text) {
          return text;
        }
        return trigger.textContent;
      },
    });
    return () => {
      clipboard.destroy();
    };
  }, []);

  const handleChangeLocale = async (locale: any) => {
    const _intl = await _createIntl(locale);
    setState((prev) => ({ ...prev, intl: _intl }));
  };

  const activePage = findActivePage(pages, location.pathname);

  const pageContextValue = useMemo(() => {
    return { activePage, pages };
  }, [pages, activePage]);

  return (
    <RawIntlProvider value={state.intl}>
      <PageContext.Provider value={pageContextValue}>
        <BrandingCssVarsProvider>
          <ThemeProvider pageContext={pageContext}>
            <DocsStyledEngineProvider cacheLtr={emotionCache}>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; location: Location<Stat... Remove this comment to see the full error message */}
              <ErrorBoundary location={location} navigate={navigate}>
                <AppWrapper
                  {...props}
                  localeContext={localeContext}
                  location={location}
                  handleChangeLocale={handleChangeLocale}
                  lang={intl.locale}
                />
              </ErrorBoundary>
            </DocsStyledEngineProvider>
          </ThemeProvider>
        </BrandingCssVarsProvider>
      </PageContext.Provider>
    </RawIntlProvider>
  );
};

export default provideHooks({
  inject: async ({ app, store: { dispatch, getState } }: any) => {
    const promises = [];

    /**
     * @typedef {impor('../../../api/database/models').User} User
     */

    /**
     * @typedef PropertiesState
     * @type {object}
     * @property {object} auth - auth key.
     * @property {User} auth.user - user key.
     */

    /** @type {PropertiesState} */
    const state = getState();

    const { accountByDomain } = state.accounts.result;

    if (!accountByDomain && app?.req?.headers?.host) {
      const urlObject = new URL(`http://${app?.req?.headers?.host}`);
      const ownHosts: any = {
        localhost: true,
        'bringerparcel.com': true,
        'bringerparcel.dev': true,
      };

      if (!ownHosts[urlObject.hostname]) {
        promises.push(
          dispatch(
            findAccounts('accountByDomain', {
              query: {
                $paginate: false,
                domain: urlObject.hostname,
              },
            })
          ).catch(console.error)
        );
      }
    }

    if (state.auth && state.auth.user) {
      let { account } = state.settings;

      if (account && !account.id && account !== 'all') {
        account = null;
      }

      // const {
      //   /** @type {User} */
      //   user,
      // } = state.auth;

      // const isAdmin = (user.roles || []).some((role: any) => role.role.role === 'admin');
      // const isWarehouse = (user.roles || []).some((role: any) => role.role.role === 'warehouse');
      // // const isRetailer = user.roles.some(role => role.role.role === 'retailer');
      // const userAccounts = (user && user.accounts) || [];

      const { configs } = state.siteConfigs.result;

      if (!configs) {
        promises.push(
          dispatch(
            findSiteConfig('configs', {
              query: {
                $paginate: false,
              },
            })
          ).catch(console.error)
        );
      }

      const accounts = state.accounts.result.allAccounts;
      if (!accounts) {
        promises.push(
          dispatch(
            findAccounts('allAccounts', {
              query: {
                $paginate: false,
                include: ['AccountRole', 'Address', 'AccountType'],
              },
            })
          ).catch(console.error)
        );
      }

      const countries = state.countries.result.allCountries;
      if (!countries) {
        promises.push(
          dispatch(
            findCountries('allCountries', {
              query: {
                $paginate: false,
              },
            })
          ).catch(console.error)
        );
      }

      const services = state.services.result.allServices;
      if (!services) {
        promises.push(
          dispatch(
            findService('allServices', {
              query: {
                $paginate: false,
                $sort: { id: -1 },
              },
            })
          ).catch(console.error)
        );
      }
      const internalServices = state.internalServices.result.all;
      if (!internalServices) {
        promises.push(
          dispatch(
            findInternalService('all', {
              query: {
                $paginate: false,
                $sort: { id: -1 },
              },
            })
          ).catch(console.error)
        );
      }
    } else {
      const countries = state.countries.result.allCountries;
      if (!countries) {
        promises.push(
          dispatch(
            findCountries('allCountries', {
              query: {
                $paginate: false,
              },
            })
          ).catch(console.error)
        );
      }
    }

    await Promise.all(promises);
  },
})(App);
