/* eslint-disable max-len */
import loadable from '@loadable/component';
import { get } from 'redux/modules/retailer';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ServiceDepositsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-ServiceDeposits' */ './ServiceDeposits'),
  {
    fallback: <LoadingComponent />,
  }
);

const ServiceDeposits = (prop: any) => <ServiceDepositsLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();
    const countries = state.retailer.result.countries && state.retailer.result.countries.data;

    if (!countries) {
      promises.push(
        dispatch(
          get('countries', {
            query: {
              path: 'graph/find/countries.json',
              params: '',
              values: '',
            },
          })
        ).catch(() => null)
      );
    }
    await Promise.all(promises);
  },
})(ServiceDeposits);
