import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const DispatchShippedLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-DispatchShipped' */ './DispatchShipped'),
  {
    fallback: <LoadingComponent />,
  }
);

export default DispatchShippedLoadable;
