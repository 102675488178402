import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const LoadDispatchLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-LoadDispatch' */ './LoadDispatch'),
  {
    fallback: <LoadingComponent />,
  }
);

export default LoadDispatchLoadable;
