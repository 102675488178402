/* eslint-disable max-len */
import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';
import { batch } from 'react-redux';
import { find as findStoresPlatforms } from 'redux/modules/storesPlatforms';

const StoresLoadable = loadable(() => import(/* webpackChunkName: 'admin-Stores' */ './Stores'), {
  fallback: <LoadingComponent />,
});

const Stores = (prop: any) => <StoresLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises: any = [];

    const state = getState();

    if (!state.storesPlatforms.result.all) {
      promises.push(() =>
        dispatch(
          findStoresPlatforms('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    await new Promise((resolve) => {
      batch(async () => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
        const finalPromises = promises.map((func) => func());
        await Promise.all(finalPromises);
        resolve(undefined);
      });
    });
  },
})(Stores);
