/* eslint-disable max-len */
import loadable from '@loadable/component';
import { get } from 'redux/modules/retailer';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const RatesServicesProvidersCountriesLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'admin-RatesServicesProvidersCountries' */
      './RatesServicesProvidersCountries'
    ),
  {
    fallback: <LoadingComponent />,
  }
);

const RatesServicesProvidersCountries = (prop: any) => (
  <RatesServicesProvidersCountriesLoadable {...prop} />
);

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();

    const serviceProviders =
      state.retailer.result.serviceProviders && state.retailer.result.serviceProviders.data;

    if (!serviceProviders) {
      promises.push(
        dispatch(
          get('serviceProviders', {
            query: {
              path: 'admin/graph/find/service_providers.json',
              service: 'rates',
              params: ['deleted !='],
              values: [1],
            },
          })
        ).catch(() => null)
      );
    }

    const countries =
      state.retailer.result.ratesCountries && state.retailer.result.ratesCountries.data;

    if (!countries) {
      promises.push(
        dispatch(
          get('ratesCountries', {
            query: {
              path: 'admin/graph/find/countries.json',
              service: 'rates',
              params: ['deleted !='],
              values: [1],
            },
          })
        ).catch(() => null)
      );
    }

    await Promise.all(promises);
  },
})(RatesServicesProvidersCountries);
