import loadable from '@loadable/component';
import { find as findAirports } from 'redux/modules/airports';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
// @ts-expect-error ts-migrate(6133) FIXME: 'LoadingComponent' is declared but its value is ne... Remove this comment to see the full error message
import LoadingComponent from 'components/Loaders/LoadingComponent';

const AirportsOfficesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-AirportsOffices' */ './AirportsOffices'),
  {
    // @ts-expect-error ts-migrate(2749) FIXME: 'LoadingComponent' refers to a value, but is being... Remove this comment to see the full error message
    fallback: <LoadingComponent />,
  }
);

// @ts-expect-error ts-migrate(2749) FIXME: 'AirportsOfficesLoadable' refers to a value, but i... Remove this comment to see the full error message
const AirportsOffices = (prop: any) => <AirportsOfficesLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();
    const airports = state.airports.result.allAirports;

    if (!airports) {
      promises.push(
        dispatch(
          findAirports('allAirports', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }
    await Promise.all(promises);
  },
})(AirportsOffices);
