import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ComposePricingLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-compose-pricing' */ './ComposePricing'),
  {
    fallback: <LoadingComponent />,
  }
);

const ComposePricing = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <ComposePricingLoadable {...prop} />
);

export default ComposePricing;
