import loadable from '@loadable/component';
import { find as findDispatches } from 'redux/modules/dispatches';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const LabelDispatchLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-LabelDispatch' */ './LabelDispatch'),
  {
    fallback: <LoadingComponent />,
  }
);

const LabelDispatch = (prop: any) => <LabelDispatchLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises = [];

    promises.push(
      dispatch(
        findDispatches(`dispatchId-${params.id}`, {
          query: {
            $paginate: false,
            uid: params.id,
            include: [
              'DispatchComplete',
              'AccountExtra',
              'Unitizer',
              'UnitizerItems',
              'Label',
              'AirwayBill',
            ],
          },
        })
      ).catch(console.error)
    );

    await Promise.all(promises);
  },
})(LabelDispatch);
