import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormGroupProps,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
} from '@mui/material';
import { ReactNode } from 'react';

import { Field, FieldProps } from 'react-final-form';
import { ErrorMessage, showErrorOnChange, useFieldForErrors, ShowErrorFunc } from './Util';

export interface CheckboxData {
  name?: string;
  label: ReactNode;
  value: unknown;
  disabled?: boolean;
  indeterminate?: boolean;
}

export interface CheckboxesProps extends Partial<Omit<MuiCheckboxProps, ''>> {
  name: string;
  data: CheckboxData | CheckboxData[];
  label?: ReactNode;
  required?: boolean;
  helperText?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  formGroupProps?: Partial<FormGroupProps>;
  formLabelProps?: Partial<FormLabelProps>;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  showError?: ShowErrorFunc;
}

export function Checkboxes(props: CheckboxesProps): React.ReactElement {
  const {
    required,
    label,
    data,
    name,
    helperText,
    fieldProps,
    formControlProps,
    formGroupProps,
    formLabelProps,
    formControlLabelProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    onChange: onChangeCallback,
    ...restCheckboxes
  } = props;

  const itemsData = Array.isArray(data) ? data : [data];
  const single = !Array.isArray(data);
  const field = useFieldForErrors(name);
  const isError = showError(field);

  return (
    <FormControl required={required} error={isError} {...formControlProps}>
      {label ? <FormLabel {...formLabelProps}>{label}</FormLabel> : null}
      <FormGroup {...formGroupProps}>
        {itemsData.map((item: CheckboxData, idx: number) => (
          <FormControlLabel
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            name={name}
            label={item.label}
            value={single ? undefined : item.value}
            disabled={item.disabled}
            control={
              <Field
                type="checkbox"
                name={item.name || name}
                render={({
                  input: { name: nameField, value, onChange, checked, ...restInput },
                }) => {
                  const onChangeFunc = (event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event);

                    if (onChangeCallback) {
                      onChangeCallback(event, event.target.checked);
                    }
                  };
                  return (
                    <MuiCheckbox
                      name={nameField}
                      value={value}
                      onChange={onChangeFunc}
                      checked={checked}
                      disabled={item.disabled}
                      inputProps={{ required, ...restInput }}
                      indeterminate={item.indeterminate}
                      {...restCheckboxes}
                    />
                  );
                }}
                {...fieldProps}
              />
            }
            {...formControlLabelProps}
          />
        ))}
      </FormGroup>
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText}
      />
    </FormControl>
  );
}
