import loadable from '@loadable/component';
import { find as findServiceProviders } from 'redux/modules/externalServiceProviders';
// @ts-expect-error hide this
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const RatesServicesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-RatesServices' */ './RatesServices'),
  {
    fallback: <LoadingComponent />,
  }
);

const RatesServices = (prop: any) => <RatesServicesLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();

    const serviceProviders = state.externalServiceProviders.result.allServiceProviders;

    if (!serviceProviders) {
      promises.push(
        dispatch(
          findServiceProviders('allServiceProviders', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    await Promise.all(promises);
  },
})(RatesServices);
