import PropTypes from 'prop-types';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer, RULESET } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { useTheme } from '@mui/material/styles';

function globalSelector(element) {
  // eslint-disable-next-line default-case
  switch (element.type) {
    case RULESET:
      element.props = element.props.map((value) => {
        if (value.match(/(:where|:has)\(/)) {
          return value.replace(/\.[^:]+(:where|:has)/, '$1');
        }
        return value;
      });
  }
}
// Cache for the rtl version of the styles
const cacheRtl = createCache({
  key: 'rtl',
  prepend: true,
  stylisPlugins: [globalSelector, prefixer, rtlPlugin],
});

export default function StyledEngineProvider(props) {
  const { children, cacheLtr } = props;
  const theme = useTheme();

  // const rtl = theme.direction === 'rtl';
  const emotionCache = theme.direction === 'rtl' ? cacheRtl : cacheLtr;

  return <CacheProvider value={emotionCache}>{children}</CacheProvider>;
}

StyledEngineProvider.propTypes = {
  cacheLtr: PropTypes.object.isRequired,
  children: PropTypes.node,
};
