import * as React from 'react';
import type { MuiPage } from 'components/Admin/pages';

const PageContext = React.createContext<{
  activePage: MuiPage | null;
  pages: MuiPage[];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
}>(undefined!);

if (process.env.NODE_ENV !== 'production') {
  PageContext.displayName = 'PageContext';
}

export default PageContext;
