import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const CleanedLocationsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-CleanedLocations' */ './CleanedLocations'),
  {
    fallback: <LoadingComponent />,
  }
);

export default CleanedLocationsLoadable;
