import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from '../../../components/Loaders/LoadingComponent';

import { find as findReceptacleTypes } from '../../../redux/modules/receptacleTypes';
import { find as findServices } from '../../../redux/modules/services';
import { find as findCountries } from '../../../redux/modules/countries';
import { find as findReceptacles } from '../../../redux/modules/unitizers';
import { find as findPRU } from '../../../redux/modules/parcelReceptacleUsers';

const EditReceptacleLoadable = loadable(() => import('./EditReceptacle'), {
  fallback: <LoadingComponent />,
});

const NewReceptacle = (prop: any) => <EditReceptacleLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch, getState } }: any) => {
    const promises = [];

    const state = getState();

    if (!state.countries.result.allCountries) {
      promises.push(
        dispatch(
          findCountries('allCountries', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    if (!state.services.result.allActiveServices) {
      promises.push(
        dispatch(
          findServices('allActiveServices', {
            query: {
              $paginate: false,
              active: 1,
            },
          })
        ).catch(() => null)
      );
    }

    if (!state.receptacleTypes.result.allReceptacleTypes) {
      promises.push(
        dispatch(
          findReceptacleTypes('allReceptacleTypes', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    promises.push(
      dispatch(
        findPRU('pruForReceptacle', {
          query: {
            $paginate: false,
            include: ['User', 'Parcel', 'Unitizer'],
            'unitizer.uid': params.id,
          },
        })
      )
    );

    if (params.id) {
      promises.push(
        dispatch(
          findReceptacles('editReceptacle', {
            query: {
              $paginate: false,
              uid: params.id,
            },
          })
        ).catch(console.error)
      );
    }

    await Promise.all(promises);
  },
})(NewReceptacle);
