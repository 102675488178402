import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ListPricingLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-list-pricing' */ './ListPricing'),
  {
    fallback: <LoadingComponent />,
  }
);

const ListPricing = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <ListPricingLoadable {...prop} />
);

export default ListPricing;
