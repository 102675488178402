/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import type { Direction } from '@mui/material/styles';
import {
  PaletteMode,
  // createGenerateClassName,
  createTheme as createMuiTheme,
  Theme,
  ThemeOptions,
  alpha,
  tooltipClasses,
} from '@mui/material';
import { create } from 'jss';
import type { Jss } from 'jss';
import { deepmerge } from '@mui/utils';

import { ServerStyleSheets } from '@mui/styles';

import createGenerateClassName from 'utils/styles/createGenerateClassName';

import jssPluginRuleValueFunction from 'jss-plugin-rule-value-function';
import jssPluginGlobal from 'jss-plugin-global';
import jssPluginNested from 'jss-plugin-nested';
import jssPluginCamelCase from 'jss-plugin-camel-case';
import jssPluginDefaultUnit from 'jss-plugin-default-unit';
import jssPluginVendorPrefixer from 'utils/styles/vendor-prefixer';
import jssPluginPropsSort from 'jss-plugin-props-sort';
import { ArrowDropDownRounded } from '@mui/icons-material';

const shadowKeyUmbraOpacity = 0.2;
const shadowKeyPenumbraOpacity = 0.14;
const shadowAmbientShadowOpacity = 0.12;
function createShadow(...px) {
  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
  ].join(',');
}

// Values from https://github.com/material-components/material-components-web/blob/be8747f94574669cb5e7add1a7c54fa41a89cec7/packages/mdc-elevation/_variables.scss
const shadows = [
  'none',
  createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
  createShadow(0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0),
  createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
  createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
  createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
  createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
  createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
  createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
  createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
  createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
  createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
  createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
  createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
  createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
  createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
  createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
  createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
  createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
  createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
  createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
  createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
  createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
  createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
  createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
];
export { shadows };

interface PageContext {
  theme: Theme;
  jss: Jss;
  sheetsCache: Map<any, any>;
}

interface UiTheme {
  direction: Direction;
  paletteMode: PaletteMode;
}

function jssPreset() {
  return {
    plugins: [
      jssPluginRuleValueFunction(),
      jssPluginGlobal(),
      jssPluginNested(),
      jssPluginCamelCase(),
      jssPluginDefaultUnit(),
      // Disable the vendor prefixer server-side, it does nothing.
      // This way, we can get a performance boost.
      // In the documentation, we are using `autoprefixer` to solve this problem.
      typeof window === 'undefined' ? null : jssPluginVendorPrefixer(),
      jssPluginPropsSort(),
    ],
  };
}

declare module '@mui/material/styles/createPalette' {
  interface ColorRange {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  type PaletteColor = ColorRange;

  interface Palette {
    primaryDark: ColorRange;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontWeightExtraBold?: number;
    fontFamilyCode?: string;
  }

  interface Typography {
    fontWeightExtraBold: number;
    fontFamilyCode: string;
  }
}

// TODO: enable this once types conflict is fixed
// declare module '@mui/material/Button' {
//   interface ButtonPropsVariantOverrides {
//     code: true;
//   }
// }

const defaultTheme = createMuiTheme();

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#A5D8FF',
  300: '#66B2FF',
  400: '#3399FF',
  main: '#007FFF', // contrast 3.83:1
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};
export const blueDark = {
  50: '#E2EDF8',
  100: '#CEE0F3',
  200: '#91B9E3',
  300: '#5090D3',
  main: '#5090D3',
  400: '#265D97',
  500: '#1E4976',
  600: '#173A5E',
  700: '#132F4C', // contrast 13.64:1
  800: '#001E3C',
  900: '#0A1929',
};
const grey = {
  50: '#F3F6F9',
  100: '#EAEEF3',
  200: '#E5E8EC',
  300: '#D7DCE1',
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#7F8E9D',
  700: '#46505A', // contrast 8.21:1
  800: '#2F3A45', // contrast 11.58:1
  900: '#20262D',
};

const systemFont = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

export const getDesignTokens = (mode: 'light' | 'dark') =>
  ({
    shadows: {
      ...shadows,
      // 0 2px 15px rgba(40,78,102,.1)
      1: '0 2px 15px rgba(40,78,102,.1)',
      2: '0 2px 4px rgba(40,78,102,.2)',
      4: '0 10px 20px rgba(40, 78, 102, 0.15)',
    },
    palette: {
      primary: {
        ...blue,
        ...(mode === 'dark' && {
          main: blue[400],
        }),
      },
      // divider: mode === 'dark' ? alpha(blue[100], 0.08) : grey[100],
      primaryDark: blueDark,
      mode,
      ...(mode === 'dark' && {
        background: {
          default: blueDark[800],
          paper: blueDark[900],
        },
      }),
      common: {
        black: '#1D1D1D',
      },
      ...(mode === 'light' && {
        text: {
          primary: grey[900],
          secondary: grey[700],
        },
      }),
      ...(mode === 'dark' && {
        text: {
          primary: '#fff',
          secondary: grey[500],
        },
      }),
      grey,
      error: {
        50: '#FFF0F1',
        100: '#FFDBDE',
        200: '#FFBDC2',
        300: '#FF99A2',
        400: '#FF7A86',
        500: '#FF505F',
        main: '#EB0014', // contrast 4.63:1
        600: '#EB0014',
        700: '#C70011',
        800: '#94000D',
        900: '#570007',
      },
      success: {
        50: '#E9FBF0',
        100: '#C6F6D9',
        200: '#9AEFBC',
        300: '#6AE79C',
        400: '#3EE07F',
        500: '#21CC66',
        600: '#1DB45A',
        ...(mode === 'dark' && {
          main: '#1DB45A', // contrast 6.17:1 (blueDark.800)
        }),
        ...(mode === 'light' && {
          main: '#1AA251', // contrast 3.31:1
        }),
        700: '#1AA251',
        800: '#178D46',
        900: '#0F5C2E',
      },
      warning: {
        50: '#FFF9EB',
        100: '#FFF4DB',
        200: '#FFEDC2',
        300: '#FFE4A3',
        400: '#FFD980',
        500: '#FCC419',
        600: '#FAB005',
        main: '#F1A204', // does not pass constrast ratio
        700: '#F1A204',
        800: '#DB9A00',
        900: '#8F6400',
      },
      ...(mode !== 'dark' && {
        background: {
          default: '#F3F6F9',
        },
      }),
    },
    shape: {
      borderRadius: 10,
    },
    spacing: 10,
    typography: {
      fontFamily: ['"IBM Plex Sans"', ...systemFont].join(','),
      fontFamilyCode: [
        'Consolas',
        'Menlo',
        'Monaco',
        'Andale Mono',
        'Ubuntu Mono',
        'monospace',
      ].join(','),
      fontFamilyTagline: ['"PlusJakartaSans-ExtraBold"', ...systemFont].join(','),
      fontFamilySystem: systemFont.join(','),
      fontWeightSemiBold: 600,
      fontWeightExtraBold: 800,
      h1: {
        fontFamily: ['"PlusJakartaSans-ExtraBold"', ...systemFont].join(','),
        fontSize: 'clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)',
        fontWeight: 800,
        lineHeight: 78 / 70,
        ...(mode === 'light' && {
          color: blueDark[900],
        }),
      },
      h2: {
        fontFamily: ['"PlusJakartaSans-ExtraBold"', ...systemFont].join(','),
        fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
        fontWeight: 800,
        lineHeight: 44 / 36,
        color: mode === 'dark' ? grey[100] : blueDark[700],
      },
      h3: {
        fontFamily: ['"PlusJakartaSans-Bold"', ...systemFont].join(','),
        fontSize: defaultTheme.typography.pxToRem(36),
        lineHeight: 44 / 36,
        letterSpacing: 0.2,
      },
      h4: {
        fontFamily: ['"PlusJakartaSans-Bold"', ...systemFont].join(','),
        fontSize: defaultTheme.typography.pxToRem(28),
        lineHeight: 42 / 28,
        letterSpacing: 0.2,
      },
      h5: {
        fontFamily: ['"PlusJakartaSans-Bold"', ...systemFont].join(','),
        fontSize: defaultTheme.typography.pxToRem(24),
        lineHeight: 36 / 24,
        letterSpacing: 0.1,
        color: mode === 'dark' ? blue[300] : blue.main,
      },
      h6: {
        fontSize: defaultTheme.typography.pxToRem(20),
        lineHeight: 30 / 20,
      },
      button: {
        textTransform: 'initial',
        fontWeight: 700,
        letterSpacing: 0,
      },
      subtitle1: {
        fontSize: defaultTheme.typography.pxToRem(18),
        lineHeight: 24 / 18,
        letterSpacing: 0,
        fontWeight: 500,
      },
      body1: {
        fontSize: defaultTheme.typography.pxToRem(16), // 16px
        lineHeight: 24 / 16,
        letterSpacing: 0,
      },
      body2: {
        fontSize: defaultTheme.typography.pxToRem(13), // 14px
        lineHeight: 21 / 14,
        letterSpacing: 0,
      },
      caption: {
        display: 'inline-block',
        fontSize: defaultTheme.typography.pxToRem(12), // 12px
        lineHeight: 18 / 12,
        letterSpacing: 0,
        // fontWeight: 700,
      },
    },
  } as ThemeOptions);

export function getThemedComponents(theme: Theme) {
  const palettePrimaryMainContrastText = theme.palette.getContrastText(theme.palette.primary.main);
  return {
    components: {
      MuiButtonBase: {
        defaultProps: {
          // disableTouchRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          sizeLarge: {
            padding: '0.875rem 1rem',
            ...theme.typography.body1,
            lineHeight: 21 / 16,
            fontWeight: 700,
          },
          containedPrimary: {
            backgroundColor: theme.palette.primary[500],
            color: '#fff',
          },
        },
        variants: [
          {
            props: { variant: 'code' },
            style: {
              paddingRight: theme.spacing(6.5),
              color:
                theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[800],
              border: '1px solid',
              borderColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primaryDark[400]
                  : theme.palette.grey[300],
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primaryDark[700]
                  : theme.palette.grey[50],
              fontFamily: theme.typography.fontFamilyCode,
              fontWeight: 400,
              fontSize: defaultTheme.typography.pxToRem(13), // 14px
              lineHeight: 21 / 14,
              letterSpacing: 0,
              WebkitFontSmoothing: 'subpixel-antialiased',
              '&:hover, &.Mui-focusVisible': {
                borderColor: theme.palette.primary.main,
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primaryDark[600]
                    : theme.palette.primary[50],
                '& .MuiButton-endIcon': {
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.primary[300]
                      : theme.palette.primary.main,
                },
              },
              '& .MuiButton-startIcon': {
                color: theme.palette.grey[400],
              },
              '& .MuiButton-endIcon': {
                display: 'inline-block',
                position: 'absolute',
                right: 0,
                marginRight: 10,
                color:
                  theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[700],
              },
            },
          },
          {
            props: { variant: 'link' },
            style: {
              fontSize: theme.typography.pxToRem(14),
              fontWeight: 700,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary[300]
                  : theme.palette.primary[600],
              mb: 1,
              '& svg': {
                ml: -0.5,
              },
            },
          },
        ],
      },
      MuiBackdrop: {
        styleOverrides: {
          invisible: {
            backgroundColor: 'transparent',
            backdropFilter: 'none',
          },
          root: {
            backgroundColor: 'rgba(111, 126, 140, 0.2)',
            backdropFilter: 'blur(2px)',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            mt: 0.5,
            minWidth: 160,
            elevation: 0,
            color: theme.palette.text.secondary,
            backgroundImage: 'none',
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[900]
                : theme.palette.background.paper,
            border: `1px solid ${
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[700]
                : theme.palette.grey[200]
            }`,
            '& .MuiMenuItem-root': {
              fontSize: theme.typography.pxToRem(14),
              fontWeight: 500,
              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? alpha(theme.palette.primaryDark[700], 0.4)
                    : theme.palette.grey[50],
              },
              '&:focus': {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? alpha(theme.palette.primaryDark[700], 0.4)
                    : theme.palette.grey[50],
              },
              '&.Mui-selected': {
                fontWeight: 500,
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary[300]
                    : theme.palette.primary[600],
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primaryDark[700]
                    : alpha(theme.palette.primary[100], 0.6),
              },
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: `0px 4px 20px ${
              theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(170, 180, 190, 0.3)'
            }`,
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            [theme.breakpoints.up('md')]: {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor:
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.primary[100], 0.08)
                : theme.palette.grey[100],
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'none',
        },
        styleOverrides: {
          root: {
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.primary[300]
                : theme.palette.primary[600],
            fontWeight: 700,
            display: 'inline-flex',
            alignItems: 'center',
            '&:hover': {
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary[200]
                  : theme.palette.primary[700],
            },
            '&.MuiTypography-body1 > svg': {
              marginTop: 2,
            },
            '& svg:last-child': {
              marginLeft: 2,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ ownerState: { color, variant } }) => ({
            fontWeight: 500,
            ...(variant === 'outlined' &&
              color === 'default' && {
                color:
                  theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[900],
                backgroundColor: 'transparent',
                borderColor:
                  theme.palette.mode === 'dark'
                    ? alpha(theme.palette.grey[100], 0.1)
                    : theme.palette.grey[200],
              }),
            ...(variant === 'filled' &&
              color === 'default' && {
                border: '1px solid transparent',
                color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[800],
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primaryDark[500]
                    : theme.palette.primary[100],
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.primaryDark[600]
                      : theme.palette.primary[200],
                },
              }),
            // for labelling product in the search
            // @ts-expect-error internal repo module augmentation issue
            ...(variant === 'light' && {
              ...(color === 'default' && {
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary[200]
                    : theme.palette.primary[700],
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? alpha(theme.palette.primaryDark[700], 0.5)
                    : alpha(theme.palette.primary[100], 0.3),
              }),
              ...(color === 'warning' && {
                color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.warning[900],
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.warning[900]
                    : theme.palette.warning[100],
              }),
              ...(color === 'success' && {
                color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.success[900],
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.success[900]
                    : theme.palette.success[100],
              }),
            }),
          }),
          deleteIcon: {
            color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[700],
            '&:hover': {
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.grey[100]
                  : theme.palette.primary[900],
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          IconComponent: ArrowDropDownRounded,
        },
        styleOverrides: {
          iconFilled: {
            top: 'calc(50% - .25em)',
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableTouchRipple: true,
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            padding: '8px',
            textTransform: 'none',
            fontWeight: 500,
            fontSize: theme.typography.pxToRem(14),
            color:
              theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[700],
            borderRadius: 0,
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? alpha(theme.palette.primaryDark[700], 0.4)
                  : theme.palette.grey[50],
            },
            '&.Mui-selected': {
              color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[500],
              borderRadius: 10,
              border: '1px solid',
              borderColor:
                theme.palette.mode === 'dark'
                  ? `${theme.palette.primary[700]} !important`
                  : `${theme.palette.primary[500]} !important`,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primaryDark[700]
                  : theme.palette.primary[50],
              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primaryDark[600]
                    : theme.palette.primary[100],
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // backgroundImage: 'none',
            backgroundColor:
              theme.palette.mode === 'dark' ? theme.palette.primaryDark[900] : '#fff',
            '&[href]': {
              textDecorationLine: 'none',
            },
          },
          outlined: {
            display: 'block',
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[500]
                : theme.palette.grey[200],
            ...(theme.palette.mode === 'dark' && {
              backgroundColor: theme.palette.primaryDark[700],
            }),
            'a&, button&': {
              '&:hover': {
                boxShadow: `0px 4px 20px ${
                  theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(170, 180, 190, 0.3)'
                }`,
              },
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1, 2),
            borderColor: theme.palette.divider,
          },
          head: {
            color: theme.palette.text.primary,
            fontWeight: 700,
          },
          body: {
            color: theme.palette.text.secondary,
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            backgroundColor:
              theme.palette.mode === 'dark' ? theme.palette.primaryDark[900] : '#fff',
          },
        },
      },
      MuiIconButton: {
        variants: [
          {
            props: { color: 'primary' },
            style: {
              height: 34,
              width: 34,
              border: `1px solid ${
                theme.palette.mode === 'dark'
                  ? theme.palette.primaryDark[700]
                  : theme.palette.grey[200]
              }`,
              borderRadius: theme.shape.borderRadius,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary[300]
                  : theme.palette.primary[500],
              '&:hover': {
                borderColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primaryDark[600]
                    : theme.palette.grey[300],
                background:
                  theme.palette.mode === 'dark'
                    ? alpha(theme.palette.primaryDark[700], 0.4)
                    : theme.palette.grey[50],
              },
            },
          },
        ],
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 500,
            color:
              theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[700],
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[500]
                : theme.palette.grey[200],
            '&.Mui-selected': {
              color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[500],
              borderColor:
                theme.palette.mode === 'dark'
                  ? `${theme.palette.primary[700]} !important`
                  : `${theme.palette.primary[500]} !important`,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primaryDark[700]
                  : theme.palette.primary[50],
              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primaryDark[600]
                    : theme.palette.primary[100],
              },
            },
          },
        },
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true,
          PopperProps: {
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  padding: 8,
                },
              },
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  padding: 8,
                },
              },
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
        },
        styleOverrides: {
          arrow: ({ ownerState }) => {
            const alwaysDark = true;
            if (ownerState.componentsProps?.tooltip?.variant === 'dark' || alwaysDark) {
              return {
                color: theme.vars ? theme.vars.palette.Tooltip.bg : 'rgba(33,43,54,.85, 0.8)',
              };
            }
            return {
              color: theme.palette.background.paper,
              width: '1em',
              height: '0.71em',
              overflow: 'hidden',
              position: 'absolute',
            };
          },
          popper: ({ ownerState }) => {
            return {
              // transitionTimingFunction: 'cubic-bezier(.54,1.5,.38,1.11)',
              ...(ownerState.arrow && {
                [`&[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
                  boxShadow: '0px -2px 20px rgba(40, 78, 102, 0.15)',
                },
                [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                  boxShadow: '0px 10px 20px rgba(40, 78, 102, 0.15)',
                },
                [`&[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
                  boxShadow: '-10px 0px 20px rgba(40, 78, 102, 0.15)',
                },
                [`&[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
                  boxShadow: '10px 0px 20px rgba(40, 78, 102, 0.15)',
                },
              }),
            };
          },
          tooltip: ({ ownerState }) => {
            const alwaysDark = true;
            if (ownerState.componentsProps?.tooltip?.variant === 'dark' || alwaysDark) {
              return {
                padding: '10px 20px',
                backgroundColor: theme.vars
                  ? theme.vars.palette.Tooltip.bg
                  : 'rgba(33,43,54,.85, 0.8)',
              };
            }
            return {
              // padding: '5px 9px',
              // boxShadow: theme.shadows[1],

              color: theme.palette.text.primary,
              padding: '10px 20px',
              fontSize: 13,
              wordWrap: 'break-word',
              boxShadow: '0px 10px 20px rgba(40, 78, 102, 0.15)',
              fontWeight: 500,
              lineHeight: '20px',
              borderRadius: 4,
              backgroundColor: theme.palette.background.paper,
              borderColor: theme.palette.background.paper,
            };
          },
          tooltipPlacementBottom: {
            boxShadow: '0px 0px 20px rgba(40, 78, 102, 0.15)',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            fontSize: 13,
          },
          body2: {
            fontSize: 13,
          },
          caption: {
            fontSize: 10,
          },
          button: {
            fontSize: 13,
          },
          h1: {
            // fontSize: 20,
          },
          h2: {
            // fontSize: 20,
          },
          h3: {
            fontSize: 20,
          },
          h4: {
            fontSize: 16,
          },
          h5: {
            fontSize: '1.5rem',
          },
          h6: {
            fontSize: 16,
          },
          subtitle1: {
            fontSize: 12,
          },
          subtitle2: {
            fontSize: 11,
          },
          overline: {
            fontSize: '0.75rem',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          sizeSmall: {
            fontSize: 13,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          sizeSmall: {
            fontSize: 13,
          },
        },
      },
      MuiPopper: {
        defaultProps: {
          modifiers: [
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [0, 10],
              },
            },
          ],
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            marginTop: 10,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          sizeSmall: {
            padding: 0,
            width: 32,
            height: 20,
            '& .MuiSwitch-switchBase': {
              padding: '3px',
              '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
              },
            },
            '& .MuiSwitch-track': {
              borderRadius: 22 / 2,
              opacity: 1,
              backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[400],
              '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 10,
                height: 10,
              },
              '&:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                  palettePrimaryMainContrastText
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 3,
                top: 9,
              },
              '&:after': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                  palettePrimaryMainContrastText
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 5,
                top: 8,
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              flexShrink: 0,
              width: '14px',
              height: '14px',
            },
          },
          sizeMedium: {
            padding: 8,
            '& .MuiSwitch-track': {
              borderRadius: 22 / 2,
              '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
              },
              '&:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                  palettePrimaryMainContrastText
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
              },
              '&:after': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                  palettePrimaryMainContrastText
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 12,
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width: 16,
              height: 16,
              margin: 2,
            },
          },
          // root: {
          //   width: 32,
          //   height: 20,
          //   padding: 0,
          //   '& .MuiSwitch-switchBase': {
          //     '&.Mui-checked': {
          //       transform: 'translateX(11px)',
          //       color: '#fff',
          //     },
          //   },
          // },
          // switchBase: {
          //   height: 20,
          //   width: 20,
          //   padding: 0,
          //   color: '#fff',
          //   '&.Mui-checked + .MuiSwitch-track': {
          //     opacity: 1,
          //   },
          // },
          // track: {
          //   opacity: 1,
          //   borderRadius: 32,
          //   backgroundColor:
          //     theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[400],
          // },
          // thumb: {
          //   flexShrink: 0,
          //   width: '14px',
          //   height: '14px',
          // },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 700,
            color:
              theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[700],
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primaryDark[500]
                : theme.palette.grey[200],
            '&.Mui-selected': {
              color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[500],
              borderColor:
                theme.palette.mode === 'dark'
                  ? `${theme.palette.primary[700]} !important`
                  : `${theme.palette.primary[500]} !important`,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primaryDark[700]
                  : theme.palette.primary[50],
              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primaryDark[600]
                    : theme.palette.primary[100],
              },
            },
          },
        },
      },
    },
  };
}

export const getMetaThemeColor = (mode: 'light' | 'dark') => {
  const themeColor = {
    light: grey[50],
    dark: blueDark[800],
  };
  return themeColor[mode];
};

const darkTheme = createMuiTheme(getDesignTokens('dark'));
export const brandingDarkTheme = deepmerge(darkTheme, getThemedComponents(darkTheme));

const lightTheme = createMuiTheme(getDesignTokens('light'));
export const brandingLightTheme = deepmerge(lightTheme, getThemedComponents(lightTheme));

const cache = new Map();

// const themes = {
//   dark: brandingDarkTheme,
//   light: brandingLightTheme,
// };
// Configure JSS
// @ts-expect-error ts-migrate(2322) FIXME: Type '(Plugin | { onProcessRule: (rule: any) => vo... Remove this comment to see the full error message
const jss = create({ plugins: [...jssPreset().plugins] });
// @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Jss'.
jss.options.insertionPoint = process.browser && document.getElementById('insertion-point-jss');

function createPageContext(uiTheme: UiTheme) {
  if (typeof uiTheme.paletteMode === 'object') {
    uiTheme.paletteMode = 'system';
  }
  const tokens = getDesignTokens(uiTheme.paletteMode === 'system' ? 'light' : uiTheme.paletteMode);
  tokens.direction = uiTheme.direction;
  const newTheme = createMuiTheme(tokens);
  const brandingNewTheme = deepmerge(newTheme, getThemedComponents(newTheme));

  // const theme = themes[uiTheme.paletteMode];
  const theme = brandingNewTheme;

  const generateClassName = createGenerateClassName({
    productionPrefix: 'j', // Reduce the bandwidth usage.
  });

  return {
    jss,
    theme,
    sheetsCache: cache,
    // This is needed in order to deduplicate the injection of CSS in the page.
    // sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new ServerStyleSheets({ serverGenerateClassName: generateClassName, jss }),
    generateClassName,
  };
}

export const updatePageContext = (uiTheme: UiTheme): PageContext => {
  const tokens = getDesignTokens(uiTheme.paletteMode);
  tokens.direction = uiTheme.direction;
  const newTheme = createMuiTheme(tokens);
  const brandingNewTheme = deepmerge(newTheme, getThemedComponents(newTheme));
  // const newTheme = themes[uiTheme.paletteMode];
  const pageContext = {
    // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
    ...global.__MUI_PAGE_CONTEXT__,
    theme: brandingNewTheme,
  };
  // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  global.__MUI_PAGE_CONTEXT__ = pageContext;

  return pageContext;
};

export default function getPageContext(
  uiTheme = {
    direction: 'ltr',
    paletteMode: 'light',
  }
) {
  // Make sure to create a new store for every server-side request so that data
  // isn't shared between connections (which would be bad)
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'browser' does not exist on type 'Process... Remove this comment to see the full error message
  if (!process.browser) {
    return createPageContext(uiTheme);
  }

  // Reuse context on the client-side
  // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  if (!global.__MUI_PAGE_CONTEXT__) {
    // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
    global.__MUI_PAGE_CONTEXT__ = createPageContext(uiTheme);
  }

  // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  return global.__MUI_PAGE_CONTEXT__;
}
