import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const RatesServicesRulesTypesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-RatesServicesRulesTypes' */ './RatesServicesRulesTypes'),
  {
    fallback: <LoadingComponent />,
  }
);

const RatesServicesRulesTypes = (prop: any) => <RatesServicesRulesTypesLoadable {...prop} />;

export default RatesServicesRulesTypes;
