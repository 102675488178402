import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(6133) FIXME: 'LoadingComponent' is declared but its value is ne... Remove this comment to see the full error message
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ParcelToolsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-ParcelTools' */ './ParcelTools'),
  {
    // @ts-expect-error ts-migrate(2749) FIXME: 'LoadingComponent' refers to a value, but is being... Remove this comment to see the full error message
    fallback: <LoadingComponent />,
  }
);

export default ParcelToolsLoadable;
