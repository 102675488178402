import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const HarmonizedCodesInterchangeLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-GoodCategories' */ './HarmonizedCodesInterchange'),
  {
    fallback: <LoadingComponent />,
  }
);

const HarmonizedCodeInterchange = () => <HarmonizedCodesInterchangeLoadable />;

export default HarmonizedCodeInterchange;
