import { Helmet } from 'react-helmet-async';
import DataGrid from 'components/DataGrid/DataGrid';
import config from 'config';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { makeStyles } from '@mui/styles';
import formatDate from 'date-fns/format';
import type { ContactForm } from '../../../../api/database/models';
import columns from './columns';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '2rem',
  },
  paper: {
    padding: '1.7rem',
  },
  grid: {
    marginTop: '1rem',
  },
  statementButton: {
    marginTop: '1rem',
  },
}));

const DetailPanelContent = ({ row }: { row: ContactForm }) => {
  const classes = useStyles();
  const apiRef = useGridApiContext();
  const [width, setWidth] = useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions?.viewportInnerSize.width ?? false;
  });
  const handleViewportInnerSizeChange = useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions?.viewportInnerSize.width ?? false);
  }, [apiRef]);
  useEffect(() => {
    return apiRef.current.subscribeEvent('viewportInnerSizeChange', handleViewportInnerSizeChange);
  }, [apiRef, handleViewportInnerSizeChange]);

  const items = row.fieldsData?.totalValue?.filter((item: any) => item.quantity > 0);

  return (
    <Box sx={{ width, position: 'sticky', padding: 2 }}>
      <Container className={classes.container}>
        <Paper className={classes.paper} elevation={4}>
          <CssBaseline />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Name:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.claimName}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Ref. Number:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.claimRefNumber}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Date Prepared:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {formatDate(new Date(row.fieldsData?.datePrepared), 'MM/dd/yyyy')}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Address:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.address}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                BPS Tracking Number:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.bpsTrack}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Lost/Demaged/Stolen?
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.lostDamagedStolen}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                City, State, Zip:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.cityState}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Contact Name:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.contactName}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Contact Email Address:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.contactEmail}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Shipping insured?
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.shippingInsured === 'yes' ? 'Yes' : 'No'}
              </Typography>
            </Grid>

            {row.fieldsData?.shippingInsured === 'yes' ? (
              <Grid item xs={4}>
                <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                  Amount Insured:
                </Typography>
                <Typography variant="body1" component="p" color="inherit" gutterBottom>
                  {row.fieldsData?.amountInsured}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={4} />
            )}

            <Grid item xs={4}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Contact Phone Number:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.phoneNumber}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" component="h3" color="inherit" gutterBottom>
                Shipper and Consignee Information
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Shipper:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.shipper}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                City, State, Zip:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.shipperCityState}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                Consignee:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.consignee}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h4" component="h4" color="inherit" gutterBottom>
                City, State, Zip:
              </Typography>
              <Typography variant="body1" component="p" color="inherit" gutterBottom>
                {row.fieldsData?.consigneeCityState}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" component="h3" color="inherit" gutterBottom>
                Commodity Details
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1250 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Description / Part#</TableCell>
                      <TableCell>Weight Per Item</TableCell>
                      <TableCell>Price Per Item</TableCell>
                      <TableCell>Item Total</TableCell>
                      {/* <TableCell>Tracking Number</TableCell> */}
                      <TableCell>Declared Freight Value</TableCell>
                      <TableCell>Declared Insurance Fee Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((packageDetail: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {packageDetail.quantity}
                        </TableCell>
                        <TableCell>{packageDetail.description}</TableCell>
                        <TableCell>{packageDetail.weightPerItem}</TableCell>
                        <TableCell>{packageDetail.pricePerItem}</TableCell>
                        <TableCell>{packageDetail.itemTotal}</TableCell>
                        {/* <TableCell>{packageDetail.trackingNumber}</TableCell> */}
                        <TableCell>{packageDetail.declaredFreightValue}</TableCell>
                        <TableCell>{packageDetail.declaredInsuranceFeeValue}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

const ClaimsContactForm = () => {
  const title = 'Claims Contact Form';
  const description = 'Claims Contact Form';

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps<ContactForm>['getDetailPanelContent']>
  >(({ row }) => (row?.fieldsData ? <DetailPanelContent row={row} /> : null), []);

  const getDetailPanelHeight = useCallback(() => 400, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={`${config.domain}/`} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <DataGrid
        columns={columns}
        service="contact-form"
        title="Claims Contact Form"
        include={['Account', 'User', 'Country']}
        showAdd={false}
        disableCellSelection
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
      />
    </>
  );
};

export default ClaimsContactForm;
