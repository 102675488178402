import { GridApi, GridExportMenuItemProps, useGridApiContext } from '@mui/x-data-grid-premium';
import { DataObject as DataObjectIcon } from '@mui/icons-material';
import * as React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { defaultGetRowsToExport, getColumnsToExport } from '@mui/x-data-grid/internals';

const getJson = (apiRef: React.MutableRefObject<GridApi>, options) => {
  // Select rows and columns
  const getRowsToExport = options.getRowsToExport ?? defaultGetRowsToExport;
  const exportedRowIds = getRowsToExport({ apiRef });
  const exportedColumns = getColumnsToExport({
    apiRef,
    options,
  });

  // Format the data. Here we only keep the value
  const data = exportedRowIds.map((id) => {
    const row: Record<string, any> = {};
    exportedColumns.forEach((column) => {
      const { field } = column;
      row[field] = apiRef.current.getCellParams(id, field).formattedValue;
    });
    return row;
  });

  // Stringify with some indentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
  return JSON.stringify(data, null, 2);
};

const exportBlob = (blob: Blob, filename: string) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

export const exportDataAsJson = ({ apiRef, options }) => {
  const fileName =
    options.fileName || typeof window !== 'undefined' ? document.title : 'export.json';
  const jsonString = getJson(apiRef, options);
  const blob = new Blob([jsonString], {
    type: 'text/json',
  });
  exportBlob(blob, fileName);
};

export const JsonExportMenuItem = (props: GridExportMenuItemProps<any>) => {
  const apiRef = useGridApiContext();

  const { hideMenu, options } = props;

  return (
    <MenuItem
      onClick={() => {
        exportDataAsJson({
          apiRef,
          options,
        });
        hideMenu?.();
      }}
    >
      <ListItemIcon>
        <DataObjectIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Export JSON</ListItemText>
    </MenuItem>
  );
};
