import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const CN33LoadReceptacleLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-CN33LoadReceptacle' */ './CN33LoadReceptacle'),
  {
    fallback: <LoadingComponent />,
  }
);

export default CN33LoadReceptacleLoadable;
