export default function clientMiddleware(helpers: any) {
  return ({ dispatch, getState }: any) =>
    (next: any) =>
    (action: any) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action;
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      const actionPromise = promise(helpers, dispatch, getState);
      actionPromise
        .then(
          (result: any) => next({ ...rest, result, type: SUCCESS }),
          (error: any) => next({ ...rest, error, type: FAILURE })
        )
        .catch((error: any) => {
          console.error('MIDDLEWARE ERROR:', error);
          next({ ...rest, error, type: FAILURE });
        });

      return actionPromise;
    };
}
