import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const AccountHsCodesCategorizationLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'admin-AccountHsCodesCategorization' */ './AccountHsCodesCategorization'
    ),
  {
    fallback: <LoadingComponent />,
  }
);

const AccountHsCodesCategorization = () => <AccountHsCodesCategorizationLoadable />;

export default AccountHsCodesCategorization;
