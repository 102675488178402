/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable  no-case-declarations */
import { useMethod } from 'hooks/useMethod';
import { useEffect, useMemo, useRef } from 'react';
import { atom, selector, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFigbird } from 'store';
import { recoilPersist } from 'store/recoil-persist';
import { ResponseError, Action } from './defaults';

const LOAD = 'recoil/settings/LOAD';
const LOAD_FAIL = 'recoil/settings/LOAD_FAIL';
const LOAD_SUCCESS = 'recoil/settings/LOAD_SUCCESS';
const CHANGE_THEME = 'recoil/settings/CHANGE_THEME';
const RESET_COLORS = 'recoil/settings/RESET_COLORS';
const RESET_DENSITY = 'recoil/settings/RESET_DENSITY';
const SET_DENSE = 'recoil/settings/SET_DENSE';
const DECREASE_SPACING = 'recoil/settings/DECREASE_SPACING';
const SET_SPACING = 'recoil/settings/SET_SPACING';
const INCREASE_SPACING = 'recoil/settings/INCREASE_SPACING';
const SET = 'recoil/settings/SET';

export interface initialState {
  theme: 'light' | 'dark';
  uiTheme: {
    dense: boolean;
    paletteColors: any;
    spacing: number;
    direction: 'ltr';
    paletteMode: 'light' | 'dark' | 'system';
  };
  lang: 'en' | 'es' | 'pt';
  loaded: boolean;
  drawerServiceOpen: boolean;
  drawerServiceOpenMobile?: boolean;
  loading?: boolean;
  error?: ResponseError;
  account?: {
    id: number;
    uid: string;
    name: string;
  };
}

interface SettingsAction extends Action<any> {
  uiTheme: {
    dense: boolean;
    paletteColors: any;
    spacing: number;
    direction: 'ltr';
    paletteMode: 'light' | 'dark' | 'system';
  };
  data: any;
  key: string;
}

export const themeInitialOptions = {
  dense: false,
  direction: 'ltr',
  paletteColors: {},
  spacing: 8, // spacing unit
  paletteMode: 'light',
};

export const initialState: initialState = {
  theme: 'light',
  uiTheme: themeInitialOptions,
  lang: 'en',
  loaded: false,
  drawerServiceOpen: true,
};

export default function reducer(
  state: initialState = initialState,
  action: SettingsAction = {} as any
): initialState {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        ...action.result,
        loading: false,
        loaded: true,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case SET_SPACING:
      return {
        ...state,
        uiTheme: {
          ...state.uiTheme,
          spacing: state.uiTheme.spacing,
        },
      };
    case INCREASE_SPACING: {
      return {
        ...state,
        uiTheme: {
          ...state.uiTheme,
          spacing: state.uiTheme.spacing + 1,
        },
      };
    }
    case DECREASE_SPACING: {
      return {
        ...state,
        uiTheme: {
          ...state.uiTheme,
          spacing: state.uiTheme.spacing - 1,
        },
      };
    }
    case SET_DENSE:
      return {
        ...state,
        uiTheme: {
          ...state.uiTheme,
          dense: themeInitialOptions.dense,
        },
      };
    case RESET_DENSITY:
      return {
        ...state,
        uiTheme: {
          ...state.uiTheme,
          dense: themeInitialOptions.dense,
          spacing: themeInitialOptions.spacing,
        },
      };
    case RESET_COLORS:
      return {
        ...state,
        uiTheme: {
          ...state.uiTheme,
          paletteColors: themeInitialOptions.paletteColors,
        },
      };
    case CHANGE_THEME:
      return {
        ...state,
        uiTheme: {
          ...state.uiTheme,
          paletteMode: action.uiTheme.paletteMode || state.uiTheme.paletteMode,
          direction: action.uiTheme.direction || state.uiTheme.direction,
          paletteColors: action.uiTheme.paletteColors || state.uiTheme.paletteColors,
        },
      };
    case SET: {
      const { data } = action;
      const result = {
        ...state,
        ...data,
      };
      return result;
    }
    default:
      return state;
  }
}

function setAction(data: any): any {
  return {
    type: SET,
    data,
  };
}

function changeThemeAction(uiTheme: any): any {
  return {
    type: CHANGE_THEME,
    uiTheme,
  };
}

const { persistAtom } = recoilPersist();

export const settingsAtom = atom({
  key: 'settings',
  default: initialState,
  effects_UNSTABLE: [persistAtom],
});

interface ShoulUpdate {
  toJSON: () => any;
  test: (prev: any, next: any) => boolean;
}

interface AuthParams {
  shouldUpdate?: ShoulUpdate;
}

const defaultSelector = selector({
  key: `defaultSelectorSettings`,
  get: ({ get }) => {
    return get(settingsAtom);
  },
});
const map = new Map();
const selectorFam = selectorFamily({
  key: 'settingsSelector',
  get:
    (shouldUpdate: ShoulUpdate) =>
    ({ get }) => {
      const key = `settingsSelectorMap-${JSON.stringify(shouldUpdate.toJSON())}`;
      const prevState = map.get(key);
      const state = get(settingsAtom);
      if (shouldUpdate.test(prevState || state, state)) {
        map.set(key, state);
        return state;
      }

      if (!prevState) {
        map.set(key, state);
      }
      return prevState || state;
    },
});

export const useSettings = ({ shouldUpdate }: AuthParams = {}) => {
  const { feathers } = useFigbird();
  const setState = useSetRecoilState(settingsAtom);

  const dispatch = (action: any) => {
    setState((prev) => {
      return reducer(prev, action);
    });
  };

  const mountedRef = useRef<boolean>(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const state = useRecoilValue(shouldUpdate ? selectorFam(shouldUpdate) : defaultSelector);

  const set = useMethod(setAction, dispatch, feathers, mountedRef);
  const changeTheme = useMethod(changeThemeAction, dispatch, feathers, mountedRef);

  return useMemo(() => {
    return {
      set,
      changeTheme,
      state,
    };
  }, [set, changeTheme, state]);
};
