import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ReportsLoadable = loadable(
  () => import(/* webpackChunkName: 'rates-calculator' */ './RatesCalculator'),
  {
    fallback: <LoadingComponent />,
  }
);

export default ReportsLoadable;
