import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error hide this
import provideHooks from 'redial/lib/provideHooks';
import { find as findAirportRoutes } from 'redux/modules/airportRoutes';
import { find as findServiceRoutes } from 'redux/modules/serviceRoutes';
import { batch } from 'react-redux';

const ServicesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-service-route-legs' */ './ServiceRouteLegs'),
  {
    fallback: <LoadingComponent />,
  }
);

const Services = (prop: any) => <ServicesLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises: any = [];

    const state = getState();
    const airportRoutes = state.airportRoutes.result.all;
    const serviceRoutes = state.serviceRoutes.result.all;

    if (!airportRoutes) {
      promises.push(() =>
        dispatch(
          findAirportRoutes('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }
    if (!serviceRoutes) {
      promises.push(() =>
        dispatch(
          findServiceRoutes('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    await new Promise((resolve) => {
      batch(async () => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
        const finalPromises = promises.map((func) => func());
        await Promise.all(finalPromises);
        resolve(undefined);
      });
    });
  },
})(Services);
