import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers-pro';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { TextField } from '@mui/material';
import { ShowErrorFunc, showErrorOnChange } from './Util';

import pickerProviderWrapper from './PickerProvider';

export interface DatePickerProps extends Partial<Omit<MuiDatePickerProps, 'onChange'>> {
  name: string;
  dateFunsUtils?: any;
  size?: 'small' | 'medium';
  locale?: any;
  fieldProps?: Partial<FieldProps<any, any>>;
  showError?: ShowErrorFunc;
}

interface DatePickerWrapperProps extends FieldRenderProps<MuiDatePickerProps, HTMLElement> {
  dateFunsUtils?: any;
  locale?: any;
}

function DatePickerWrapper(props: DatePickerWrapperProps) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    dateFunsUtils,
    locale,
    showError = showErrorOnChange,
    size,
    onChange: onChangeProp,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  const { helperText, ...lessrest } = rest;

  const onChangeHelper = (v: any) => {
    onChange(v);
    if (onChangeProp) {
      onChangeProp(v);
    }
  };

  return pickerProviderWrapper(
    dateFunsUtils,
    <MuiDatePicker
      onChange={onChangeHelper}
      value={(value as any) === '' ? null : value}
      {...lessrest}
      InputProps={{
        ...restInput,
        fullWidth: true,
        size,
        name,
        error: isError,
      }}
      renderInput={(params) => {
        params.error = isError;
        return (
          <TextField
            {...params}
            fullWidth
            size={size}
            helperText={isError ? error || submitError : helperText || params.helperText}
          />
        );
      }}
    />,
    locale
  );
}

export function DatePicker(props: DatePickerProps): React.ReactElement {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      render={(fieldRenderProps) => <DatePickerWrapper {...fieldRenderProps} {...rest} />}
      {...fieldProps}
    />
  );
}
