import { matchRoutes } from 'react-router';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import propName from 'redial/lib/propName';

const getComponents = (match: any) =>
  match
    .map((v: any) => v.route.element)
    .reduce((result: any, element: any) => {
      if (element.type) {
        // console.log(element, 'element');
        result.push({ ...element, [propName]: element.type[propName] });
      }
      return result;
    }, []);

const getParams = (match: any) =>
  match.reduce((result: any, element: any) => {
    if (element.params) {
      return { ...result, ...element.params };
    }
    return result;
  }, {});

const asyncMatchRoutes = (routes: any, pathname: any) => {
  const match = matchRoutes(routes, pathname);
  const params = getParams(match);
  const components = getComponents(match);
  return { components, match, params };
};

export default asyncMatchRoutes;
