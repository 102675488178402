import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error hide this
import provideHooks from 'redial/lib/provideHooks';
import { find as findServiceRoutes } from 'redux/modules/serviceRoutes';
import { find as findServiceConsignees } from 'redux/modules/serviceConsignees';

const ServicesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-service-options' */ './ServiceOptions'),
  {
    fallback: <LoadingComponent />,
  }
);

const Services = (prop: any) => <ServicesLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();
    const serviceRoutes = state.serviceRoutes.result.all;
    const serviceConsignees = state.serviceConsignees.result.all;

    if (!serviceConsignees) {
      promises.push(
        dispatch(
          findServiceConsignees('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (!serviceRoutes) {
      promises.push(
        dispatch(
          findServiceRoutes('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    await Promise.all(promises);
  },
})(Services);
