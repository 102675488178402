const LOAD = 'redux-bringer/retailer/LOAD';
const LOAD_SUCCESS = 'redux-bringer/retailer/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-bringer/retailer/LOAD_FAIL';

const CREATE = 'redux-bringer/retailer/CREATE';
const CREATE_SUCCESS = 'redux-bringer/retailer/CREATE_SUCCESS';
const CREATE_FAIL = 'redux-bringer/retailer/CREATE_FAIL';

const REMOVE = 'redux-bringer/retailer/REMOVE';
const REMOVE_SUCCESS = 'redux-bringer/retailer/REMOVE_SUCCESS';
const REMOVE_FAIL = 'redux-bringer/retailer/REMOVE_FAIL';

const initialState = {
  loaded: false,
  result: {},
  values: {},
  loaders: {},
  loadings: {},
  loadeds: {},
};

export default function reducer(state = initialState, action = {}) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loadings: {
          ...state.loadings,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: true,
        },
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loadings: {
          ...state.loadings,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: false,
        },
        loaded: true,
        loadeds: {
          ...state.loadeds,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: true,
        },
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
        result: { ...state.result, [action.key]: action.result },
      };
    case LOAD_FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: false,
        },
        loading: false,
        loaded: false,
        loadeds: {
          ...state.loadeds,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: false,
        },
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
        error: action.error,
      };

    case CREATE:
      return {
        ...state,
        loading: true,
        loadings: {
          ...state.loadings,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: true,
        },
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loadings: {
          ...state.loadings,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: false,
        },
        loaded: true,
        loadeds: {
          ...state.loadeds,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: true,
        },
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
        createResult: { ...state.result, [action.key]: action.result },
      };
    case CREATE_FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: false,
        },
        loading: false,
        loaded: false,
        loadeds: {
          ...state.loadeds,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: false,
        },
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
        error: action.error,
      };

    case REMOVE:
      return {
        ...state,
        loading: true,
        loadings: {
          ...state.loadings,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: true,
        },
      };
    case REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        loadings: {
          ...state.loadings,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: false,
        },
        loaded: true,
        loadeds: {
          ...state.loadeds,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: true,
        },
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
        createResult: { ...state.result, [action.key]: action.result },
      };
    case REMOVE_FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: false,
        },
        loading: false,
        loaded: false,
        loadeds: {
          ...state.loadeds,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{}'.
          [action.key]: false,
        },
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
        error: action.error,
      };
    default:
      return state;
  }
}

export function get(key: any, params: any) {
  return {
    key,
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: any) => {
      const { timeout } = app.service('retailer');
      app.service('retailer').timeout = 240000;
      return app
        .service('retailer')
        .find(params)
        .then((r: any) => {
          app.service('retailer').timeout = timeout;
          return r;
        });
    },
  };
}

export function create(key: any, data: any) {
  return {
    key,
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    promise: ({ app }: any) => {
      const { timeout } = app.service('retailer');
      app.service('retailer').timeout = 240000;
      if (data.id) {
        return app
          .service('retailer')
          .patch(data.id, data)
          .then((r: any) => {
            app.service('retailer').timeout = timeout;
            return r;
          });
      }
      return app
        .service('retailer')
        .create(data)
        .then((r: any) => {
          app.service('retailer').timeout = timeout;
          return r;
        });
    },
  };
}

export function patch(key: any, id: any, data: any) {
  return {
    key,
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    promise: ({ app }: any) => {
      const { timeout } = app.service('retailer');
      app.service('retailer').timeout = 240000;
      return app
        .service('retailer')
        .patch(id, data)
        .then((r: any) => {
          app.service('retailer').timeout = timeout;
          return r;
        });
    },
  };
}

export function remove(key: any, data: any) {
  return {
    key,
    types: [REMOVE, REMOVE_SUCCESS, REMOVE_FAIL],
    promise: ({ app }: any) => {
      const { timeout } = app.service('retailer');
      app.service('retailer').timeout = 240000;
      return app
        .service('retailer')
        .remove(data.id, { query: data })
        .then((r: any) => {
          app.service('retailer').timeout = timeout;
          return r;
        });
    },
  };
}
