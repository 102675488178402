import type { Application } from '@feathersjs/feathers';
import { useCallback } from 'react';

const useMethod = (
  method: any,
  dispatch: any,
  feathers: Application,
  mountedRef: { current: boolean }
) => {
  return useCallback(
    (...args: any[]) => {
      const action = method(...args);

      if (action.type) {
        dispatch(action);
        return;
      }

      dispatch({ type: action.types[0] });
      if (action.promise) {
        return action
          .promise({ app: feathers })
          .then((result: any) => {
            if (mountedRef.current) {
              dispatch({ ...action, type: action.types[1], result });
            }
            return result;
          })
          .catch((error: Error) => {
            if (mountedRef.current) {
              dispatch({ ...args, type: action.types[2], error });
            }
          });
      }
    },
    [method, dispatch]
  );
};

export { useMethod };
