import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import NotFound from 'containers/NotFound/Loadable';

import { Typography, Button } from '@mui/material';

/**
 * @extends {React.Component<Props, {}>}
 */
export default class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, location: props.location };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const { location: prevLocation } = prevState;

    if (prevState.hasError && nextProps.location !== prevLocation) {
      return {
        hasError: false,
        location: nextProps.location,
      };
    }

    return {
      location: nextProps.location,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: error };
  }

  componentDidCatch(error: any) {
    // Display fallback UI
    this.setState({ hasError: error });

    if (process.env.NODE_ENV !== 'production') {
      console.info(error);
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
      const { location = {} } = this.props;
      ReactGA.exception({
        description: `${error.toString()} => ${location.pathname}`,
        fatal: true,
      });
    }
  }

  clearError = (e: any) => {
    e.preventDefault();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'navigate' does not exist on type 'Readon... Remove this comment to see the full error message
    const { navigate } = this.props;
    navigate(`${window.location.pathname}?redirect_for_error=1`);
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasError' does not exist on type 'Readon... Remove this comment to see the full error message
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      let renderedError = null;
      if (process.env.NODE_ENV !== 'production') {
        renderedError = (
          <pre
            style={{
              margin: 100,
              padding: 100,
              whiteSpace: 'pre-wrap',
              color: 'red',
            }}
          >
            <code style={{ color: '#393318', background: '#eff0f1' }}>{hasError.toString()}</code>
            <br />
            <br />
            {hasError.stack}
          </pre>
        );
      }

      // You can render any custom fallback UI
      return (
        <>
          <NotFound error={{ code: 500 }} />
          <Typography align="center" component="div">
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              component="a"
              href="/?redirect_for_error=1"
              onClick={this.clearError}
            >
              Try to reload the page for clean the error
            </Button>
          </Typography>
          {renderedError}
        </>
      );
    }
    return children;
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ErrorBoundary.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  navigate: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ErrorBoundary.defaultProps = {
  children: null,
};
