import loadable from '@loadable/component';
import { find as findStates } from 'redux/modules/states';
// @ts-expect-error hide this
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const RatesPostalcodesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-RatesPostalcodes' */ './RatesPostalcodes'),
  {
    fallback: <LoadingComponent />,
  }
);

const RatesPostalcodes = (prop: any) => <RatesPostalcodesLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();

    const states = state.states.result.allStates;

    if (!states) {
      promises.push(
        dispatch(
          findStates('allStates', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    await Promise.all(promises);
  },
})(RatesPostalcodes);
