import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const DispacthesReportLoadable = loadable(
  () => import(/* webpackChunkName: 'dispacthes-report' */ './DispacthesReport'),
  {
    fallback: <LoadingComponent />,
  }
);

const DispacthesReport = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <DispacthesReportLoadable {...prop} />
);

export default DispacthesReport;
