import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { find as findUnitizers } from 'redux/modules/unitizers';

const LabelUnitizerLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-LabelUnitizer' */ './LabelUnitizer'),
  {
    fallback: <LoadingComponent />,
  }
);

const LabelUnitizer = (prop: any) => <LabelUnitizerLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises = [];
    promises.push(
      dispatch(
        findUnitizers(`unitizerId-${params.id}`, {
          query: {
            $paginate: false,
            uid: params.id,
            include: [
              'AccountExtended',
              'LabelComplete',
              'ParcelCompleteUnscope',
              'Service',
              'DispatchExtended',
              'DispatchComplete',
            ],
          },
        })
      )
    );
    await Promise.all(promises);
  },
})(LabelUnitizer);
