export const requestIdleCallback = (cb: any) => {
  if (!process.browser) {
    return;
  }
  if (window.requestIdleCallback) {
    window.requestIdleCallback(cb);
  } else {
    // Safari doesn't support requestIdleCallback
    // https://caniuse.com/requestidlecallback
    window.setTimeout(cb, 1);
  }
};
