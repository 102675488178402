import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const RatesServiceProvidersLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-RatesServiceProviders' */ './RatesServiceProviders'),
  {
    fallback: <LoadingComponent />,
  }
);

const RatesServiceProviders = (prop: any) => <RatesServiceProvidersLoadable {...prop} />;

export default RatesServiceProviders;
