import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const UsersLoadable = loadable(() => import(/* webpackChunkName: 'admin-users' */ './Users'), {
  fallback: <LoadingComponent />,
});

const Users = (prop: any) => <UsersLoadable {...prop} />;

export default Users;
