import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ParcelScanV3Loadable = loadable(
  () => import(/* webpackChunkName: 'admin-ParcelScanV3' */ './ParcelScanV3'),
  {
    fallback: <LoadingComponent />,
  }
);

export default ParcelScanV3Loadable;
