import loadable from '@loadable/component';
import { find as findWeights } from 'redux/modules/weights';
import { find as findZones } from 'redux/modules/tariffs';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
// @ts-expect-error ts-migrate(6133) FIXME: 'LoadingComponent' is declared but its value is ne... Remove this comment to see the full error message
import LoadingComponent from 'components/Loaders/LoadingComponent';

const MarkupLoadable = loadable(() => import(/* webpackChunkName: 'admin-markup' */ './Markup'), {
  fallback: <LoadingComponent />,
});

// @ts-expect-error ts-migrate(2749) FIXME: 'MarkupLoadable' refers to a value, but is being u... Remove this comment to see the full error message
const Markup = (prop: any) => <MarkupLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();

    const weights = state.weights.result && state.weights.result.all;

    const tariffs = state.tariffs.result && state.tariffs.result.all;

    if (!weights) {
      promises.push(
        dispatch(
          findWeights('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    if (!tariffs) {
      promises.push(
        dispatch(
          findZones('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    await Promise.all(promises);
  },
})(Markup);
