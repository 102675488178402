import { useRef } from 'react';
import { useQuery } from './useQuery';
import type { UseQueryOptions } from './useQuery';

const selectData = (data: any) => data;
const transformResponse = (data: any) => {
  // nop normal pagination response treat
  if (!data.data || !Array.isArray(data.data)) {
    return {
      data,
    };
  }
  return data;
};

export const useFind = <D>(
  serviceName: string,
  options: UseQueryOptions & {
    keepBeforeDataUntileFetchFinish?: boolean;
  } = {}
) => {
  const beforeData = useRef(null);

  const { keepBeforeDataUntileFetchFinish, ...rest } = options;

  const result = useQuery<D[] | undefined>(serviceName, rest, {
    method: 'find',
    selectData,
    transformResponse,
  });

  result.data =
    keepBeforeDataUntileFetchFinish && result.isFetching ? beforeData.current : result.data;
  beforeData.current = result.data;
  return result;
};
