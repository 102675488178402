import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ComposeCarrierCostsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-compose-carrier-costs' */ './ComposeCarrierCosts'),
  {
    fallback: <LoadingComponent />,
  }
);

const ComposeACarrierCosts = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <ComposeCarrierCostsLoadable {...prop} />
);

export default ComposeACarrierCosts;
