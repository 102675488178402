/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
import _rawCountries from './rawCountries';
import _rawTerritories from './rawTerritories';

function getMask(
  prefix: any,
  dialCode: any,
  predefinedMask: any,
  defaultMask: any,
  alwaysDefaultMask: any
) {
  if (!predefinedMask || alwaysDefaultMask) {
    return `${prefix + ''.padEnd(dialCode.length, '.')} ${defaultMask}`;
  }
  return `${prefix + ''.padEnd(dialCode.length, '.')} ${predefinedMask}`;
}

// enableAreaCodes: boolean || array of iso2 codes
function initCountries(
  countries: any,
  enableAreaCodes: any,
  prefix: any,
  defaultMask: any,
  alwaysDefaultMask: any
) {
  let hiddenAreaCodes: any = [];

  let enableAllCodes: any;
  if (enableAreaCodes === true) {
    enableAllCodes = true;
  } else {
    enableAllCodes = false;
  }

  const initializedCountries = [].concat(
    ...countries.map((country: any) => {
      const countryItem = {
        name: country[0],
        regions: country[1],
        iso2: country[2],
        countryCode: country[3],
        dialCode: country[3],
        format: getMask(prefix, country[3], country[4], defaultMask, alwaysDefaultMask),
        priority: country[5] || 0,
      };

      const areaItems: any = [];

      // eslint-disable-next-line no-unused-expressions
      country[6] &&
        country[6].forEach((areaCode: any) => {
          const areaItem = { ...countryItem };
          areaItem.dialCode = country[3] + areaCode;
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'isAreaCode' does not exist on type '{ na... Remove this comment to see the full error message
          areaItem.isAreaCode = true;
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'areaCodeLength' does not exist on type '... Remove this comment to see the full error message
          areaItem.areaCodeLength = areaCode.length;

          areaItems.push(areaItem);
        });

      if (areaItems.length > 0) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'mainCode' does not exist on type '{ name... Remove this comment to see the full error message
        countryItem.mainCode = true;
        if (
          enableAllCodes ||
          (enableAreaCodes.constructor.name === 'Array' && enableAreaCodes.includes(country[2]))
        ) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasAreaCodes' does not exist on type '{ ... Remove this comment to see the full error message
          countryItem.hasAreaCodes = true;
          return [countryItem, ...areaItems];
        }
        hiddenAreaCodes = hiddenAreaCodes.concat(areaItems);
        return [countryItem];
      }
      return [countryItem];
    })
  );

  return [initializedCountries, hiddenAreaCodes];
}

function extendUserContent(
  userContent: any,
  contentItemIndex: any,
  extendingObject: any,
  firstExtension: any
) {
  if (extendingObject === null) return;

  const keys = Object.keys(extendingObject);
  const values = Object.values(extendingObject);

  keys.forEach((iso2, index) => {
    if (firstExtension) {
      // masks
      return userContent.push([iso2, values[index]]);
    }

    const countryIndex = userContent.findIndex((arr: any) => arr[0] === iso2);
    if (countryIndex === -1) {
      const newUserContent = [iso2];
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'string'.
      newUserContent[contentItemIndex] = values[index];
      userContent.push(newUserContent);
    } else {
      userContent[countryIndex][contentItemIndex] = values[index];
    }
  });
}

function initUserContent(masks: any, priority: any, areaCodes: any) {
  const userContent: any = [];
  extendUserContent(userContent, 1, masks, true);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  extendUserContent(userContent, 3, priority);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  extendUserContent(userContent, 2, areaCodes);
  return userContent;
}

function extendRawCountries(countries: any, userContent: any) {
  if (userContent.length === 0) return countries;

  // userContent index -> rawCountries index of country array to extend
  // [iso2 (0 -> 2), mask (1 -> 4), priority (3 -> 5), areaCodes (2 -> 6)]

  return countries.map((o: any) => {
    const userContentIndex = userContent.findIndex((arr: any) => arr[0] === o[2]); // find by iso2
    if (userContentIndex === -1) return o; // if iso2 not in userContent, return source country object
    const userContentCountry = userContent[userContentIndex];
    if (userContentCountry[1]) o[4] = userContentCountry[1]; // mask
    if (userContentCountry[3]) o[5] = userContentCountry[3]; // priority
    if (userContentCountry[2]) o[6] = userContentCountry[2]; // areaCodes
    return o;
  });
}

export default class CountryData {
  hiddenAreaCodes: any;

  onlyCountries: any;

  preferredCountries: any;

  constructor(
    enableAreaCodes: any,
    enableTerritories: any,
    regions: any,
    onlyCountries: any,
    preferredCountries: any,
    excludeCountries: any,
    preserveOrder: any,
    masks: any,
    priority: any,
    areaCodes: any,
    localization: any,
    prefix: any,
    defaultMask: any,
    alwaysDefaultMask: any
  ) {
    const userContent = initUserContent(masks, priority, areaCodes);
    const rawCountries = extendRawCountries(JSON.parse(JSON.stringify(_rawCountries)), userContent);
    const rawTerritories = extendRawCountries(
      JSON.parse(JSON.stringify(_rawTerritories)),
      userContent
    );

    let [initializedCountries, hiddenAreaCodes] = initCountries(
      rawCountries,
      enableAreaCodes,
      prefix,
      defaultMask,
      alwaysDefaultMask
    );
    if (enableTerritories) {
      const [initializedTerritories, _hiddenAreaCodes] = initCountries(
        rawTerritories,
        enableAreaCodes,
        prefix,
        defaultMask,
        alwaysDefaultMask
      );
      hiddenAreaCodes = _hiddenAreaCodes;
      initializedCountries = this.sortTerritories(initializedTerritories, initializedCountries);
    }
    if (regions) initializedCountries = this.filterRegions(regions, initializedCountries);

    this.onlyCountries = this.localizeCountries(
      this.excludeCountries(
        this.getFilteredCountryList(
          onlyCountries,
          initializedCountries,
          preserveOrder.includes('onlyCountries')
        ),
        excludeCountries
      ),
      localization,
      preserveOrder.includes('onlyCountries')
    );

    this.preferredCountries =
      preferredCountries.length === 0
        ? []
        : this.localizeCountries(
            this.getFilteredCountryList(
              preferredCountries,
              initializedCountries,
              preserveOrder.includes('preferredCountries')
            ),
            localization,
            preserveOrder.includes('preferredCountries')
          );

    // apply filters to hiddenAreaCodes
    this.hiddenAreaCodes = this.excludeCountries(
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
      this.getFilteredCountryList(onlyCountries, hiddenAreaCodes),
      excludeCountries
    );
  }

  filterRegions = (regions: any, countries: any) => {
    if (typeof regions === 'string') {
      const region = regions;
      return countries.filter((country: any) => {
        return country.regions.some((element: any) => {
          return element === region;
        });
      });
    }

    return countries.filter((country: any) => {
      const matches = regions.map((region: any) => {
        return country.regions.some((element: any) => {
          return element === region;
        });
      });
      return matches.some((el: any) => el);
    });
  };

  sortTerritories = (initializedTerritories: any, initializedCountries: any) => {
    const fullCountryList = [...initializedTerritories, ...initializedCountries];
    fullCountryList.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return fullCountryList;
  };

  getFilteredCountryList = (countryCodes: any, sourceCountryList: any, preserveOrder: any) => {
    if (countryCodes.length === 0) return sourceCountryList;

    let filteredCountries;
    if (preserveOrder) {
      // filter using iso2 user-defined order
      filteredCountries = countryCodes
        .map((countryCode: any) => {
          const country = sourceCountryList.find((_country: any) => _country.iso2 === countryCode);
          if (country) return country;
          return false;
        })
        .filter((country: any) => country); // remove any not found
    } else {
      // filter using alphabetical order
      filteredCountries = sourceCountryList.filter((country: any) => {
        return countryCodes.some((element: any) => {
          return element === country.iso2;
        });
      });
    }

    return filteredCountries;
  };

  localizeCountries = (countries: any, localization: any, preserveOrder: any) => {
    for (let i = 0; i < countries.length; i += 1) {
      if (localization[countries[i].iso2] !== undefined) {
        countries[i].localName = localization[countries[i].iso2];
      } else if (localization[countries[i].name] !== undefined) {
        countries[i].localName = localization[countries[i].name];
      }
    }
    if (!preserveOrder) {
      countries.sort((a: any, b: any) => {
        if (a.localName < b.localName) {
          return -1;
        }
        if (a.localName > b.localName) {
          return 1;
        }
        return 0;
      });
    }
    return countries;
  };

  getCustomAreas = (country: any, areaCodes: any) => {
    const customAreas = [];
    for (let i = 0; i < areaCodes.length; i += 1) {
      const newCountry = JSON.parse(JSON.stringify(country));
      newCountry.dialCode += areaCodes[i];
      customAreas.push(newCountry);
    }
    return customAreas;
  };

  excludeCountries = (onlyCountries: any, excludedCountries: any) => {
    if (excludedCountries.length === 0) {
      return onlyCountries;
    }
    return onlyCountries.filter((country: any) => {
      return !excludedCountries.includes(country.iso2);
    });
  };
}
