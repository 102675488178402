/* eslint-disable no-use-before-define */
function cleanQuery(query, operators, filters) {
  if (Array.isArray(query)) {
    return query.map((value) => cleanQuery(value, operators, filters));
  }
  if (isObject(query)) {
    const result = {};

    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (key[0] === '$') {
        if (
          filters.includes(key) ||
          // is a search nested field
          key.indexOf('.') !== -1
        ) {
          return;
        }

        if (!operators.includes(key)) {
          throw new Error(`Invalid query parameter ${key}`, query);
        }
      }

      result[key] = cleanQuery(value, operators, filters);
    });

    return result;
  }
  return query;
}

export const FILTERS = ['$sort', '$limit', '$skip', '$select', '$paginate', '$include'];
export const OPERATORS = ['$in', '$nin', '$lt', '$lte', '$gt', '$gte', '$ne', '$or', '$like'];

// Removes special filters from the `query` parameters
export default function filterQuery(query, options = {}) {
  if (!query) return query;
  const { filters: additionalFilters = [], operators: additionalOperators = [] } = options;
  const clean = cleanQuery(
    query,
    OPERATORS.concat(additionalOperators),
    FILTERS.concat(additionalFilters)
  );
  return clean;
}

function isObject(obj) {
  return typeof obj === 'object' && obj !== null;
}
