import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const DashboardLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-dashboard' */ './Dashboard'),
  {
    fallback: <LoadingComponent />,
  }
);

const Dashboard = () => <DashboardLoadable />;

export default Dashboard;
