import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const TrackingCodesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-tracking-codes' */ './TrackingCodes'),
  {
    fallback: <LoadingComponent />,
  }
);

const TrackingCodes = (prop: any) => <TrackingCodesLoadable {...prop} />;

export default TrackingCodes;
