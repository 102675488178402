/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { createSvgIcon } from '@mui/material';

const useStyles = makeStyles(
  {
    icon: {
      width: 160,
      height: 220,
    },
  },
  { name: 'CareIcon' }
);

// eslint-disable-next-line react/prop-types
export default ({ className, ...props }: any) => {
  const classes = useStyles();
  const Icon = createSvgIcon(
    <>
      <defs>
        <linearGradient
          id="fa33e88e-dbf0-4663-84a0-206a47aa2cd8"
          x1="54.51"
          y1="176.82"
          x2="69.89"
          y2="175.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#120667" />
          <stop offset="0.26" stopColor="#11055e" />
          <stop offset="0.67" stopColor="#0f0447" />
          <stop offset="1" stopColor="#0c022f" />
        </linearGradient>
        <linearGradient
          id="b588447b-74c9-40ae-abe7-0fc070633204"
          x1="79.17"
          y1="189.05"
          x2="92.02"
          y2="179.82"
          xlinkHref="#fa33e88e-dbf0-4663-84a0-206a47aa2cd8"
        />
        <linearGradient
          id="ee089051-d720-42d8-9499-a04590ca30c9"
          x1="56.03"
          y1="163.46"
          x2="61.08"
          y2="163.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#5333ed" />
          <stop offset="0.18" stopColor="#4d4bea" />
          <stop offset="0.57" stopColor="#3e89e2" />
          <stop offset="1" stopColor="#2cd4d9" />
        </linearGradient>
        <linearGradient
          id="0b4534e5-acae-444b-95ff-5c93f3867015"
          x1="43.42"
          y1="158.76"
          x2="50.27"
          y2="158.76"
          xlinkHref="#ee089051-d720-42d8-9499-a04590ca30c9"
        />
      </defs>
      <g style={{ isolation: 'isolate' }}>
        <g>
          <g>
            <ellipse cx={80} cy="179.64" rx="51.51" ry="29.75" style={{ fill: '#dfe9f8' }} />

            <g>
              <path
                d="M105.61,170.82a9.94,9.94,0,0,1,2.31-4.4c1.3-1.39,2.81-.35,3.58.38a3.5,3.5,0,0,0,2,1,4,4,0,0,0,2.54-.62c1.44-.83,4.44,1.21,3.1,2s-9.15,4.75-9.15,4.75-.85.21-1.18,0Z"
                style={{ fill: '#0c022f' }}
              />
              <path
                d="M88.82,123.74s-6.87,3-7.62,5.3c-6.71,20.68-24.56,34.2-24.56,34.2s1.84,2.74,1.95,2.8C83.8,154.38,88.82,123.74,88.82,123.74Z"
                style={{ fill: '#ffc398' }}
              />

              <g>
                <path
                  d="M49.81,171.31l-1.32,1.89c1.05.09,1.79-1.18,1.79-1.18l2.13-1.6A20.29,20.29,0,0,0,58.58,166h0a2.4,2.4,0,0,0,.55-1.86,1.31,1.31,0,0,0-1.51-1.33.72.72,0,0,0-.32.11h0l-7.39,3.5c-.25.12-3.49,1.83-3.49,1.83l-2,1.74a3.41,3.41,0,0,0,2.4-.86l2.82-1.28-2.44,1.69-1.83,2.39a3.85,3.85,0,0,0,2.49-1.65L50.31,169,48,170.64l-2.15,1.85a3.18,3.18,0,0,0,2.64-1.06l3.32-1.92Z"
                  style={{ fill: '#eba87c' }}
                />
                <path
                  d="M60.58,163.25,59,160.94c-.21.11-2.12,2.16-2.33,2.31h0s-3,.27-3.88.83a18.26,18.26,0,0,0-2,2.54l-.57,1.65a3.16,3.16,0,0,0,1.95-1.71l.72-.52a4.75,4.75,0,0,0,2.83,1.36,2.84,2.84,0,0,0,2-.33l1.39-1.26Z"
                  style={{ fill: '#ffc398' }}
                />
              </g>
              <path
                d="M83.21,120.18a3.21,3.21,0,0,0-.32,1.11c-.52,3-2,10.06-4.15,13.82l6.11,4.38A69.7,69.7,0,0,0,90,130.79Z"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M78.42,135.82a2.86,2.86,0,0,1,.77.13,10.73,10.73,0,0,1,4.74,3.43c.14.2.47.85.47.85l-.22.4a1.33,1.33,0,0,0,.72-.45,9.66,9.66,0,0,0,.89-1.47.52.52,0,0,0,0-.45,6.71,6.71,0,0,0-2.62-2.82,11.23,11.23,0,0,0-3.86-1.6.43.43,0,0,0-.46.23L78,135.73a.49.49,0,0,0,.12.52Z"
                style={{ fill: '#dfe9f8' }}
              />
              <path
                d="M84.19,141.82s-2.69,7.85-7,12.49,10.35,2.32,10.35,2.32l7.61,7.06,9.83,6.56a22.38,22.38,0,0,0,1.52-13.08c-.36-1.6-.72-3.19-.94-4.81l-.75-5.42Z"
                style={{ fill: '#120667' }}
              />
              <ellipse
                cx="94.57"
                cy="144.75"
                rx="6.56"
                ry="11.36"
                transform="translate(-78.07 154.27) rotate(-60)"
                style={{ fill: '#120667' }}
              />
              <ellipse
                cx="89.25"
                cy="169.45"
                rx="1.92"
                ry="3.32"
                transform="translate(-99.89 139.34) rotate(-53.18)"
                style={{ fill: '#0c022f' }}
              />
              <path
                d="M91.45,171.79l-4.32-4.72L61.26,177.2c-.11-.09-.21-.19-.33-.28-2.69-2-5.81-2.39-7-.83s.07,4.44,2.76,6.46c2.18,1.63,4.63,2.16,6.1,1.49v0Z"
                style={{ fill: 'url(#fa33e88e-dbf0-4663-84a0-206a47aa2cd8)' }}
              />
              <path
                d="M87.84,155.58c-4.12-3.09-8.91-3.66-10.69-1.28L54.5,175.58l7.71,5.11,28.53-14.16.11-.05.07,0h0a3.32,3.32,0,0,0,1.16-1C93.86,163.1,92,158.67,87.84,155.58Z"
                style={{ fill: '#120667' }}
              />
              <ellipse
                cx="108.23"
                cy="171.8"
                rx="1.37"
                ry="2.37"
                transform="translate(-94.67 179.63) rotate(-60)"
                style={{ fill: '#ffc398' }}
              />
              <polygon
                points="105.79 175.62 110.01 173.26 106.4 170.38 102.18 172.75 105.79 175.62"
                style={{ fill: '#ffc398' }}
              />
              <ellipse
                cx="103.91"
                cy="174.24"
                rx="1.37"
                ry="2.37"
                transform="translate(-98.94 177.11) rotate(-60)"
                style={{ fill: '#ffc398' }}
              />
              <ellipse
                cx="73.95"
                cy="188.12"
                rx="3.53"
                ry="6.12"
                transform="translate(-125.95 158.1) rotate(-60)"
                style={{ fill: '#120667' }}
              />
              <polygon
                points="78.1 192.18 105.06 176.6 100.22 172.43 74.77 186.07 78.1 192.18"
                style={{ fill: 'url(#b588447b-74c9-40ae-abe7-0fc070633204)' }}
              />
              <polygon
                points="68.83 184.74 88.79 160.94 103.91 171.34 77.09 188.9 68.83 184.74"
                style={{ fill: '#120667' }}
              />
              <ellipse
                cx="96.86"
                cy="165.6"
                rx="5.38"
                ry="9.32"
                transform="translate(-94.98 166.69) rotate(-60)"
                style={{ fill: '#120667' }}
              />
              <path
                d="M102.22,125.34,88.9,117.66h0a2.54,2.54,0,0,0-1.81-.28,5.75,5.75,0,0,0-3.49,2,3,3,0,0,0-.57,2l.93,17.8c-.23,2.55,2.09,5.87,5.87,8.06,4.52,2.61,9.4,2.61,10.91,0a3.43,3.43,0,0,0,.44-1.74h0l3.19-17C104.59,127.54,103.72,126.2,102.22,125.34Z"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M83.26,135.41a48.72,48.72,0,0,1-.24-8.19l.65-.38s-.15,7.05.12,9Z"
                style={{ fill: '#2cd4d9', mixBlendMode: 'multiply' }}
              />
              <path
                d="M99.64,124.07a12.88,12.88,0,0,0-2.07,1.48,8.06,8.06,0,0,0-2.63,6.08v4.1"
                style={{
                  fill: 'none',
                  stroke: '#5333ed',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: 2,
                }}
              />
              <path
                d="M90.11,118.36A12.88,12.88,0,0,0,88,119.84a8.06,8.06,0,0,0-2.63,6.08V130"
                style={{
                  fill: 'none',
                  stroke: '#5333ed',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: 2,
                }}
              />

              <g>
                <g>
                  <ellipse
                    cx="93.87"
                    cy="123.53"
                    rx="3.68"
                    ry="2.13"
                    transform="translate(-49.19 63.48) rotate(-30)"
                    style={{ fill: '#eba87c' }}
                  />
                  <ellipse
                    cx="93.87"
                    cy="118.33"
                    rx="3.68"
                    ry="2.13"
                    transform="translate(-46.59 62.79) rotate(-30)"
                    style={{ fill: '#eba87c' }}
                  />
                  <polygon
                    points="90.51 124.66 90.51 119.58 97.22 117.23 97.22 122.27 90.51 124.66"
                    style={{ fill: '#eba87c' }}
                  />
                  <ellipse
                    cx="93.87"
                    cy="123.53"
                    rx="3.68"
                    ry="2.13"
                    transform="translate(-49.19 63.48) rotate(-30)"
                    style={{ fill: '#eba87c' }}
                  />
                  <path
                    d="M97.22,113.1l-6.72,2.35v5.08h0a1.35,1.35,0,0,0,.16.72c.59,1,2.49,1,4.25,0,1.46-.84,2.34-2.11,2.28-3.1h0Z"
                    style={{ fill: '#eba87c', mixBlendMode: 'multiply' }}
                  />
                  <path
                    d="M97.21,120c.06,1-.82,2.26-2.28,3.1-1.76,1-3.66,1-4.25,0a1.34,1.34,0,0,1-.16-.68h0v2.21a1.36,1.36,0,0,0,.17.72c.59,1,2.49,1,4.25,0,1.46-.84,2.36-2.11,2.29-3.1V120Z"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M97.21,120c.06,1-.82,2.26-2.28,3.1-1.76,1-3.66,1-4.25,0a1.34,1.34,0,0,1-.16-.68h0v2.21a1.36,1.36,0,0,0,.17.72c.59,1,2.49,1,4.25,0,1.46-.84,2.36-2.11,2.29-3.1V120Z"
                    style={{ fill: '#2cd4d9', mixBlendMode: 'multiply' }}
                  />
                </g>
                <path
                  d="M88.15,119.61c1,1.76,4.31,1.76,7.36,0a7.35,7.35,0,0,0,3.54-4.72c.06-.3-.34-.22-.36-.49a3.21,3.21,0,0,1,.5-1.16c-1-1.76-4.31-1.76-7.36,0S87.14,117.85,88.15,119.61Z"
                  style={{ fill: '#ffc398' }}
                />
                <path
                  d="M99.34,106.22H87s-1.19,8.25,1.06,13.2l10.64-5.11Z"
                  style={{ fill: '#ffc398' }}
                />
                <path
                  d="M99.41,115.11l.39-4a2.27,2.27,0,0,0,.74-.73c1-1.75-.63-4.6-3.67-6.35s-6.32-1.75-7.33,0l0,.05a4.2,4.2,0,0,0-2.16.61c-1.39.8-2.14,2.08-1.75,2.91a2.23,2.23,0,0,0,.08,2c.48.82,1.27.7,2.7-.13a3.93,3.93,0,0,0,1.75-1.86,9.39,9.39,0,0,0,3.07,2.77,8.5,8.5,0,0,0,4.17,1.29c0,.68,1.61,3,1.61,3Z"
                  style={{ fill: '#0c022f' }}
                />
                <path
                  d="M100.4,112.85a3.3,3.3,0,0,1-1.09,2.78c-.74.59-1.46.31-1.6-.63a3.3,3.3,0,0,1,1.09-2.78C99.54,111.63,100.26,111.91,100.4,112.85Z"
                  style={{ fill: '#ffc398' }}
                />
                <circle cx="97.68" cy="103.7" r="3.22" style={{ fill: '#0c022f' }} />
                <path
                  d="M94.53,103.06a11.18,11.18,0,0,1,5,3.24,7.79,7.79,0,0,0,.62-.59,6.55,6.55,0,0,0-5.4-3.43Z"
                  style={{ fill: '#2cd4d9' }}
                />
              </g>
              <path
                d="M84.47,128.17l11.48,6.59s0,10.81,1,15.19c.74,3.22-10.49-3.43-11.48-6.59C83.8,138.07,84.47,128.17,84.47,128.17Z"
                style={{ fill: '#5333ed' }}
              />
              <path
                d="M105.06,148.76l.24,2.06s-1.51.75-5.66.32c0,0,1,4.87-.39,7.87s-15.69,17.31-15.69,17.31a10.35,10.35,0,0,0-5.93-2.08c-4-.11-5.82-1.22-7.05-2.61-1.6-1.81-2.87-4.19-6.88-4.69l11.41-10.71a30.46,30.46,0,0,0,3.93-4.5c.89-1.25,3.14-5.32,3.55-6,2.34-4,2.13-6.63,2.13-6.63A20.76,20.76,0,0,0,105.06,148.76Z"
                style={{ fill: '#5333ed' }}
              />
              <path
                d="M104.86,149.44c-1.89,3.27-8,3.27-13.67,0-3.49-2-6-4.8-7-7.41"
                style={{
                  fill: 'none',
                  stroke: '#5333ed',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '1.1318482854146px',
                  mixBlendMode: 'multiply',
                }}
              />
              <path
                d="M103.73,127s-5.44,5.3-5.15,7.73c2.9,23.75-24.1,27.16-24.1,27.16s.79,3.69.91,3.7C117.64,164.19,103.73,127,103.73,127Z"
                style={{ fill: '#ffc398' }}
              />
              <path
                d="M96.62,147.8s1.76-10.07,1.26-14.48l5.59-7s7.1,6.65,1.26,23.28Z"
                style={{ fill: '#2cd4d9' }}
              />
              <path
                d="M96.95,145.74A14.48,14.48,0,0,0,98.8,138l-.59,2-2,6A.6.6,0,0,1,96.95,145.74Z"
                style={{ fill: '#2cd4d9', mixBlendMode: 'multiply' }}
              />
              <path
                d="M96.25,146a.55.55,0,0,1,.7-.23l-.08.55a25.16,25.16,0,0,0,8.32,2l.13-.42a.53.53,0,0,1,.29.45l-1.2,3.31s-6.16-.3-9-3Z"
                style={{ fill: '#dfe9f8' }}
              />

              <ellipse cx="62.09" cy="156.89" rx="0.43" ry="0.25" style={{ fill: '#5333ed' }} />

              <g>
                <circle
                  cx="58.55"
                  cy="163.46"
                  r="2.52"
                  style={{ fill: 'url(#ee089051-d720-42d8-9499-a04590ca30c9)' }}
                />
                <path
                  d="M57.59,161.13s-.47.85.11,1.05,2.11.42,2.5.55a1.42,1.42,0,0,1,.86.61,2.79,2.79,0,0,0-.44-1.32,2.62,2.62,0,0,0-1.49-1A2.58,2.58,0,0,0,57.59,161.13Z"
                  style={{ fill: '#2cd4d9', mixBlendMode: 'multiply' }}
                />
                <circle
                  cx="46.84"
                  cy="158.76"
                  r="3.42"
                  style={{ fill: 'url(#0b4534e5-acae-444b-95ff-5c93f3867015)' }}
                />
                <path
                  d="M49,156.13a3.33,3.33,0,0,1-2.46.75A2.21,2.21,0,0,1,45,158c-1.15.15-1.37-.27-1.37-.27a3.55,3.55,0,0,1,2-2.11A3.44,3.44,0,0,1,49,156.13Z"
                  style={{ fill: '#2cd4d9', mixBlendMode: 'multiply' }}
                />
                <rect
                  x="46.69"
                  y="153.37"
                  width="0.3"
                  height="2.46"
                  transform="translate(93.69 309.19) rotate(180)"
                  style={{ fill: '#120667' }}
                />
                <ellipse cx="46.84" cy="155.83" rx="0.15" ry="0.09" style={{ fill: '#120667' }} />
                <rect
                  x="58.4"
                  y="159.02"
                  width="0.3"
                  height="2.46"
                  transform="translate(117.11 320.51) rotate(180)"
                  style={{ fill: '#120667' }}
                />
                <ellipse cx="58.55" cy="161.49" rx="0.15" ry="0.09" style={{ fill: '#120667' }} />
                <ellipse cx="53.52" cy="173.64" rx="8.92" ry="5.11" style={{ fill: '#0c022f' }} />
                <ellipse cx="53.52" cy="188.55" rx="7.71" ry="4.45" style={{ fill: '#5333ed' }} />
                <ellipse cx="53.52" cy="177.67" rx="7.71" ry="4.45" style={{ fill: '#2cd4d9' }} />
                <path
                  d="M62.44,173.53c0,2.84-4,5.15-8.92,5.15s-8.92-2.31-8.92-5.15h-.06l1.25,15.14H61.23l1.25-15.14Z"
                  style={{ fill: '#5333ed' }}
                />
                <ellipse cx="53.52" cy="175.83" rx="1.04" ry="0.6" style={{ fill: '#5333ed' }} />
                <rect x="52.48" y="144.04" width="2.08" height="31.8" style={{ fill: '#5333ed' }} />
                <ellipse cx="53.52" cy="144.04" rx="1.04" ry="0.6" style={{ fill: '#120667' }} />
                <rect
                  x="48.84"
                  y="148.51"
                  width="0.6"
                  height="6.26"
                  transform="translate(98.29 303.28) rotate(180)"
                  style={{ fill: '#5333ed' }}
                />
                <ellipse cx="49.15" cy="154.77" rx="0.3" ry="0.17" style={{ fill: '#5333ed' }} />
                <ellipse cx="49.15" cy="148.51" rx="0.3" ry="0.17" style={{ fill: '#120667' }} />
                <polygon
                  points="48.94 154.9 52.91 157.21 52.91 156.49 48.94 154.19 48.94 154.9"
                  style={{ fill: '#5333ed' }}
                />
                <polygon
                  points="45.35 152.81 49.32 155.12 49.32 154.4 46.04 152.5 45.35 152.81"
                  style={{ fill: '#5333ed' }}
                />
                <ellipse cx="45.69" cy="152.65" rx="0.43" ry="0.25" style={{ fill: '#5333ed' }} />
                <polygon
                  points="62.42 157.05 54.2 161.81 54.2 161.09 61.73 156.74 62.42 157.05"
                  style={{ fill: '#5333ed' }}
                />
                <polygon
                  points="48.97 167.94 48.97 167.94 39.25 162.28 38.56 162.59 48.59 168.43 48.59 168.43 52.56 170.74 52.56 170.03 48.97 167.94"
                  style={{ fill: '#5333ed' }}
                />
                <ellipse cx="38.9" cy="162.43" rx="0.43" ry="0.25" style={{ fill: '#5333ed' }} />

                <g>
                  <ellipse
                    cx="36.07"
                    cy="152.33"
                    rx="1.67"
                    ry="0.97"
                    transform="translate(-71.33 38.44) rotate(-30)"
                    style={{ fill: '#120667' }}
                  />
                  <path
                    d="M34.81,152.42a1.94,1.94,0,0,1,2.62-.66l1.8,1,2.32,1.56a2.13,2.13,0,0,0,2.27-.19c1-.55,1.48-1.43,1.24-2,.39.49,1.42.47,2.37-.08s1.19-1.53.85-1.83a16.14,16.14,0,0,0-2.93-2l-.05,0a6.32,6.32,0,0,0-6.56-.28l-2.08,1.22a4.22,4.22,0,0,0-2.07,3.31l0,.45Z"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M45.13,152.35s-5-4.14-7.66-3.73c0,0,0-.28.48-.28,0,0,1.5-.63,7.5,3.81,0,0,.37.22.36.37s-.17.15-.36,0Z"
                    style={{ fill: '#120667' }}
                  />
                </g>
                <g>
                  <ellipse
                    cx="34.56"
                    cy="162.55"
                    rx="0.88"
                    ry="0.51"
                    transform="translate(-76.65 39.06) rotate(-30)"
                    style={{ fill: '#120667' }}
                  />
                  <path
                    d="M33.9,162.6a1,1,0,0,1,1.38-.35l.95.54,1.32.72a1.12,1.12,0,0,0,1.19-.1c.5-.29.78-.75.65-1.06.21.26.75.25,1.25,0s.63-.81.45-1-.63-.39-1.64-1l0,0a3.33,3.33,0,0,0-3.45-.15l-1.1.64a2.22,2.22,0,0,0-1.09,1.74l0,.24Z"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M39.43,162.46s-2.76-2.08-4.13-1.86c0,0,0-.15.25-.15,0,0,.89-.43,4,1.91C39.64,162.39,39.62,162.53,39.43,162.46Z"
                    style={{ fill: '#120667' }}
                  />
                </g>
                <polygon
                  points="49.15 168.41 46.28 170.07 46.28 169.36 48.56 168.04 49.15 168.41"
                  style={{ fill: '#5333ed' }}
                />

                <g>
                  <ellipse
                    cx="45.27"
                    cy="168.71"
                    rx="1.29"
                    ry="2.23"
                    transform="translate(-123.47 123.56) rotate(-60)"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <ellipse
                    cx="48.56"
                    cy="170.77"
                    rx="1.29"
                    ry="2.23"
                    transform="translate(-123.61 127.44) rotate(-60)"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M43.5,167.4a7.93,7.93,0,0,0-2.32,7.16l.35,1.34a.75.75,0,0,0,1.1.49c.92-.32,1.48-1.23,2.44-1.59A10.85,10.85,0,0,0,50.39,172Z"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M47.28,169.19a9.55,9.55,0,0,0-4.93,5.42s-.16.17-.16-.16a9.09,9.09,0,0,1,4.92-5.5.32.32,0,0,1,.32,0,.72.72,0,0,1,.16.19,3,3,0,0,0-.31,0Z"
                    style={{ fill: '#120667' }}
                  />
                </g>
                <path
                  d="M37.55,163.52a4.17,4.17,0,0,1,1.55-1.9,2.2,2.2,0,0,1,1.17.1,2.34,2.34,0,0,1,.63.4,2.33,2.33,0,0,1-.39.27l-2.58,1.26S37.62,163.65,37.55,163.52Z"
                  style={{ fill: '#2cd4d9', mixBlendMode: 'multiply' }}
                />

                <g>
                  <ellipse
                    cx="40.02"
                    cy="162.96"
                    rx="0.76"
                    ry="1.31"
                    transform="translate(-121.12 116.14) rotate(-60)"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <ellipse
                    cx="41.95"
                    cy="164.18"
                    rx="0.76"
                    ry="1.31"
                    transform="translate(-121.21 118.42) rotate(-60)"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M39,162.19a4.67,4.67,0,0,0-1.37,4.22l.21.79a.44.44,0,0,0,.65.29c.54-.19.88-.72,1.44-.93A6.39,6.39,0,0,0,43,164.92Z"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M41.2,163.25a5.63,5.63,0,0,0-2.9,3.2s-.1.1-.1-.09a5.35,5.35,0,0,1,2.9-3.24.19.19,0,0,1,.19,0,.43.43,0,0,1,.09.11l-.18,0Z"
                    style={{ fill: '#120667' }}
                  />
                </g>
                <g>
                  <ellipse
                    cx="62.17"
                    cy="156.3"
                    rx="2.14"
                    ry="1.24"
                    transform="translate(-69.82 52.02) rotate(-30)"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <ellipse
                    cx="59.01"
                    cy="158.28"
                    rx="2.14"
                    ry="1.24"
                    transform="translate(-71.23 50.71) rotate(-30)"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M63.87,155a7.62,7.62,0,0,1,2.23,6.89l-.34,1.29a.73.73,0,0,1-1.05.48c-.88-.31-1.43-1.18-2.35-1.53a10.44,10.44,0,0,1-5.12-2.69Z"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M60.24,156.76A9.19,9.19,0,0,1,65,162s.16.17.16-.15a8.74,8.74,0,0,0-4.74-5.29.31.31,0,0,0-.31,0,.69.69,0,0,0-.15.18l.3,0Z"
                    style={{ fill: '#120667' }}
                  />
                </g>
                <path
                  d="M54.56,153.81v1a4.7,4.7,0,0,1-1.56-1,1.7,1.7,0,0,1-.24-1.22l1.8.35Z"
                  style={{ fill: '#5333ed', mixBlendMode: 'multiply' }}
                />

                <g>
                  <ellipse
                    cx="55.9"
                    cy="150.96"
                    rx="1.31"
                    ry="0.76"
                    transform="translate(-67.99 48.17) rotate(-30)"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <ellipse
                    cx="53.96"
                    cy="152.17"
                    rx="1.31"
                    ry="0.76"
                    transform="translate(-68.86 47.37) rotate(-30)"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M56.94,150.19a4.67,4.67,0,0,1,1.37,4.22l-.21.79a.44.44,0,0,1-.65.29c-.54-.19-.87-.72-1.44-.93a6.39,6.39,0,0,1-3.14-1.65Z"
                    style={{ fill: '#2cd4d9' }}
                  />
                  <path
                    d="M54.71,151.24a5.63,5.63,0,0,1,2.9,3.2s.1.1.1-.09a5.35,5.35,0,0,0-2.9-3.24.19.19,0,0,0-.19,0,.43.43,0,0,0-.09.11l.18,0Z"
                    style={{ fill: '#120667' }}
                  />
                </g>
                <path
                  d="M54.56,160.88v.71l.46-.27a.55.55,0,0,0,.09-.4.3.3,0,0,0-.27-.21Z"
                  style={{ fill: '#5333ed', mixBlendMode: 'multiply' }}
                />
                <path
                  d="M52.48,156.24V157l-.49-.29a.47.47,0,0,1,0-.35c.08-.16.17-.2.26-.21Z"
                  style={{ fill: '#5333ed', mixBlendMode: 'multiply' }}
                />
                <path
                  d="M52.48,170v.71l-.49-.29a.47.47,0,0,1,0-.35c.08-.16.17-.2.26-.21Z"
                  style={{ fill: '#5333ed', mixBlendMode: 'multiply' }}
                />
              </g>
              <path
                d="M63.88,162.24s4.57-.44,4.86-.44l7.39-.16.49,3.76a16.56,16.56,0,0,1-7.3,1.41l-2.38.54a2.57,2.57,0,0,1-1.9.88l1.22-1.51,2.94-1-3.62.32a3.35,3.35,0,0,1-3.21.12L65,165l3-.56-3,.12a3.09,3.09,0,0,1-2.92.78l2.63-1.8L67.8,163l-4,.22a2.35,2.35,0,0,1-2.33,0Z"
                style={{ fill: '#ffc398' }}
              />
              <path
                d="M109.3,170.88a4.14,4.14,0,0,0-1.08-.27c-.31,0-.91.1-1.35.13s-1-.13-1-.3a7.15,7.15,0,0,1,1-.85l2.52,1v.29"
                style={{ fill: '#0c022f' }}
              />
            </g>
          </g>
        </g>
      </g>
    </>,
    'Care'
  );
  return <Icon className={clsx(classes.icon, className)} {...props} viewBox="0 0 160 220" />;
};
