import { TablePaginationProps, Pagination as MuiPagination } from '@mui/material';
import {
  gridRowCountSelector,
  gridPaginationModelSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import GoToPage from './GoToPage';

function PaginationAction({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  const pageCount =
    paginationModel.pageSize > 0 && rowCount > 0
      ? Math.ceil(rowCount / paginationModel.pageSize)
      : 0;

  return (
    <MuiPagination
      className={className}
      size="small"
      count={pageCount}
      page={page + 1}
      variant="outlined"
      shape="rounded"
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

function Pagination(props: any) {
  return (
    <>
      <GoToPage />
      <GridPagination
        sx={(theme) => ({
          '& .MuiToolbar-root': {
            display: 'flex',
            flexWrap: 'wrap',
            padding: 0,
            justifyContent: 'center',
          },
          [theme.breakpoints.down('sm')]: {
            margin: 0,
            '& .MuiToolbar-root': {
              columnGap: theme.spacing(2),
            },
            '& .MuiToolbar-root .MuiTablePagination-selectLabel + div': {
              mr: 0,
            },
            '& .MuiTablePagination-actions.MuiPagination-root': {
              margin: 0,
              marginLeft: 0,
              flexShrink: 1,
            },
            '& .MuiTablePagination-actions .MuiPagination-ul': {
              rowGap: theme.spacing(1),
            },
          },
        })}
        ActionsComponent={PaginationAction}
        {...props}
      />
    </>
  );
}

export default Pagination;
