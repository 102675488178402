import { createSvgIcon } from '@mui/material';

export default (props: any) => {
  const Icon = createSvgIcon(
    <g>
      <g>
        <polygon
          points="20.98 13.25 11.03 13.25 6.05 18.88 11.03 24.5 20.98 24.5 25.95 18.88 20.98 13.25"
          style={{
            fill: 'none',
            stroke: '#2cd4d9',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
          }}
        />
        <polygon
          points="20.98 7.5 11.03 7.5 6.05 13.13 11.03 18.75 20.98 18.75 25.95 13.13 20.98 7.5"
          style={{
            fill: 'none',
            stroke: '#5333ed',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3,
          }}
        />
      </g>
    </g>,
    'Infrastructure'
  );
  return <Icon {...props} viewBox="0 0 32 32" />;
};
