import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  darken,
  lighten,
  Collapse,
  Paper,
  Typography,
  Card,
  CardActions,
  IconButton,
  capitalize,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandMore as ExpandMoreIcon, Close as CloseIcon } from '@mui/icons-material';
import clsx from 'clsx';
import SuccessOutlinedIcon from 'components/Icons/SuccessOutlined';
import ReportProblemOutlinedIcon from 'components/Icons/ReportProblemOutlined';
import ErrorOutlineIcon from 'components/Icons/ErrorOutline';
import InfoOutlinedIcon from 'components/Icons/InfoOutlined';
import { useSnackbar } from 'components/notistack';

const useStyles = makeStyles(
  (theme) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    return {
      /* Styles applied to the root element if `variant="standard"` and `color="success"`. */
      standardSuccess: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: getColor(theme.palette.success.main, 0.6),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
        '& $icon, $icons': {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
          color: theme.palette.success.main,
        },
      },
      /* Styles applied to the root element if `variant="standard"` and `color="info"`. */
      standardInfo: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: getColor(theme.palette.info.main, 0.6),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
        '& $icon, $icons': {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
          color: theme.palette.info.main,
        },
      },
      /* Styles applied to the root element if `variant="standard"` and `color="warning"`. */
      standardWarning: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: getColor(theme.palette.warning.main, 0.6),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
        '& $icon, $icons': {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
          color: theme.palette.warning.main,
        },
      },
      /* Styles applied to the root element if `variant="standard"` and `color="error"`. */
      standardError: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: getColor(theme.palette.error.main, 0.6),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        backgroundColor: getBackgroundColor(theme.palette.error.main, 0.9),
        '& $icon, $icons': {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
          color: theme.palette.error.main,
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="success"`. */
      outlinedSuccess: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: getColor(theme.palette.success.main, 0.6),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        border: `1px solid ${theme.palette.success.main}`,
        '& $icon, $icons': {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
          color: theme.palette.success.main,
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="info"`. */
      outlinedInfo: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: getColor(theme.palette.info.main, 0.6),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        border: `1px solid ${theme.palette.info.main}`,
        '& $icon, $icons': {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
          color: theme.palette.info.main,
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="warning"`. */
      outlinedWarning: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: getColor(theme.palette.warning.main, 0.6),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        border: `1px solid ${theme.palette.warning.main}`,
        '& $icon, $icons': {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
          color: theme.palette.warning.main,
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="error"`. */
      outlinedError: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        color: getColor(theme.palette.error.main, 0.6),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        border: `1px solid ${theme.palette.error.main}`,
        '& $icon, $icons': {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
          color: theme.palette.error.main,
        },
      },
      /* Styles applied to the root element if `variant="filled"` and `color="success"`. */
      filledSuccess: {
        color: '#fff',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'typography' does not exist on type 'Defa... Remove this comment to see the full error message
        fontWeight: theme.typography.fontWeightMedium,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        backgroundColor: theme.palette.success.main,
      },
      /* Styles applied to the root element if `variant="filled"` and `color="info"`. */
      filledInfo: {
        color: '#fff',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'typography' does not exist on type 'Defa... Remove this comment to see the full error message
        fontWeight: theme.typography.fontWeightMedium,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        backgroundColor: theme.palette.info.main,
      },
      /* Styles applied to the root element if `variant="filled"` and `color="warning"`. */
      filledWarning: {
        color: '#fff',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'typography' does not exist on type 'Defa... Remove this comment to see the full error message
        fontWeight: theme.typography.fontWeightMedium,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        backgroundColor: theme.palette.warning.main,
      },
      /* Styles applied to the root element if `variant="filled"` and `color="error"`. */
      filledError: {
        color: '#fff',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'typography' does not exist on type 'Defa... Remove this comment to see the full error message
        fontWeight: theme.typography.fontWeightMedium,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
        backgroundColor: theme.palette.error.main,
      },
      /* Styles applied to the icon wrapper element. */
      icon: {
        marginRight: 12,
        padding: '7px 0',
        display: 'flex',
        fontSize: 22,
        opacity: 0.9,
      },
      icons: {
        marginLeft: 'auto',
      },
      card: {
        // maxHeight: '80vh',
        maxWidth: '80vw',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        flexGrow: 1,
        // backgroundColor: '#fddc6c',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
        [theme.breakpoints.up('sm')]: {
          flexGrow: 'initial',
          minWidth: 344,
        },
        '& $icons': {
          marginLeft: 'auto',
        },
      },
      typography: {
        fontWeight: 'bold',
      },
      actionRoot: {
        padding: '8px 8px 8px 16px',
      },
      expand: {
        color: 'inherit',
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        transition: theme.transitions.create('transform', {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      collapse: {
        padding: 16,
        overflow: 'scroll',
        maxWidth: '80vw',
        maxHeight: '60vh',
        '& pre': {
          borderRadius: '4px',
          color: '#fff',
          fontFamily: 'monospace',
          fontSize: 12,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
          marginTop: theme.spacing(2),
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
          padding: theme.spacing(2),
          maxHeight: '70vh',
          backgroundColor: '#272c34',
          overflow: 'scroll',
        },
      },
    };
  },
  { name: 'SnackDropdown' }
);

const defaultIconMapping = {
  success: <SuccessOutlinedIcon fontSize="inherit" />,
  warning: <ReportProblemOutlinedIcon fontSize="inherit" />,
  error: <ErrorOutlineIcon fontSize="inherit" />,
  info: <InfoOutlinedIcon fontSize="inherit" />,
};

const SnackDropdown = forwardRef(
  (
    {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{ children?:... Remove this comment to see the full error message
      id,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type '{ child... Remove this comment to see the full error message
      message,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'content' does not exist on type '{ child... Remove this comment to see the full error message
      content,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'icon' does not exist on type '{ children... Remove this comment to see the full error message
      icon,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'iconMapping' does not exist on type '{ c... Remove this comment to see the full error message
      iconMapping = defaultIconMapping,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ childre... Remove this comment to see the full error message
      color,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'role' does not exist on type '{ children... Remove this comment to see the full error message
      role = 'alert',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'severity' does not exist on type '{ chil... Remove this comment to see the full error message
      severity = 'success',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'variant' does not exist on type '{ child... Remove this comment to see the full error message
      variant = 'standard',
      action,
    },
    ref
  ) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const handleDismiss = () => {
      closeSnackbar(id);
    };

    return (
      <Card
        role={role}
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        className={clsx(classes.card, classes[`${variant}${capitalize(color || severity)}`])}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        ref={ref}
      >
        <CardActions classes={{ root: classes.actionRoot }}>
          {icon !== false ? (
            <div className={classes.icon}>
              {icon || iconMapping[severity] || defaultIconMapping[severity]}
            </div>
          ) : null}
          <Typography variant="subtitle2" className={classes.typography}>
            {message}
          </Typography>
          <div className={classes.icons}>
            {!!action && action}
            {!!content && (
              <IconButton
                aria-label="Show more"
                className={clsx(classes.expand, { [classes.expandOpen]: expanded })}
                onClick={handleExpandClick}
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
            <IconButton className={classes.expand} onClick={handleDismiss} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse} elevation={0}>
            {content}
          </Paper>
        </Collapse>
      </Card>
    );
  }
);

SnackDropdown.propTypes = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: PropTypes.Validator<number>; message: ... Remove this comment to see the full error message
  id: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  message: PropTypes.any,
  content: PropTypes.node,
  /**
   * Override the icon displayed before the children.
   * Unless provided, the icon is mapped to the value of the `severity` prop.
   */
  icon: PropTypes.node,
  /**
   * The component maps the `severity` prop to a range of different icons,
   * for instance success to `<SuccessOutlined>`.
   * If you wish to change this mapping, you can provide your own.
   * Alternatively, you can use the `icon` prop to override the icon displayed.
   */
  iconMapping: PropTypes.shape({
    error: PropTypes.node,
    info: PropTypes.node,
    success: PropTypes.node,
    warning: PropTypes.node,
  }),
  /**
   * The main color for the alert. Unless provided, the value is taken from the `severity` prop.
   */
  color: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  role: PropTypes.string,
  /**
   * The severity of the alert. This defines the color and icon used.
   */
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  /**
   * The variant to use.
   */
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default SnackDropdown;
