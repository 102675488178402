import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const RatesServicesProvidersDestinationsLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'admin-RatesServicesProvidersDestinations' */
      './RatesServicesProvidersDestinations'
    ),
  {
    fallback: <LoadingComponent />,
  }
);
export default RatesServicesProvidersDestinationsLoadable;
