/* eslint-disable max-len */
import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const InternalServicesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-internal-services' */ './InternalServices'),
  {
    fallback: <LoadingComponent />,
  }
);

const InternalServices = (prop: any) => <InternalServicesLoadable {...prop} />;

export default InternalServices;
