import { useMediaQuery } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  useColorScheme,
} from '@mui/material/styles';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/material/utils';
import { deepmerge } from '@mui/utils';
import * as React from 'react';
// import useLazyCSS from 'hooks/useLazyCSS';
import { enUS, esES, ptBR } from '@mui/material/locale';
import cookie from 'js-cookie';
import { useIntl } from 'react-intl';
import { useSettings } from 'redux/modules/settings';
import { StylesProvider } from '@mui/styles';
import { getDesignTokens, getMetaThemeColor, getThemedComponents } from './brandingTheme';

const languageMap = {
  en: enUS,
  pt: ptBR,
  es: esES,
};

/**
 * Get the value of a cookie
 * Source: https://vanillajstoolkit.com/helpers/getcookie/
 * @param name - The name of the cookie
 * @return The cookie value\
 */
export function getCookie(name: string): string | undefined {
  if (typeof document === 'undefined') {
    throw new Error(
      'getCookie() is not supported on the server. Fallback to a different value when rendering on the server.'
    );
  }

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts[1].split(';').shift();
  }

  return undefined;
}

export const highDensity = {
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
      },
    },
  },
};

export const DispatchContext = React.createContext(() => {
  throw new Error('Forgot to wrap component in `ThemeProvider`');
});

if (process.env.NODE_ENV !== 'production') {
  DispatchContext.displayName = 'ThemeDispatchContext';
}

const tryJsonParse = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return string;
  }
};

export function ThemeProvider(props: any) {
  const { children, pageContext } = props;
  const palleteModeCookie = pageContext.paletteMode;

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const preferredMode = prefersDarkMode ? 'dark' : 'light';
  const { state, changeTheme: _changeTheme } = useSettings({
    shouldUpdate: {
      toJSON: () => 'ThemeContext',
      test: (prev, next) => {
        return prev.uiTheme !== next.uiTheme;
      },
    },
  });

  const { setMode } = useColorScheme();
  const changeTheme = (...args: any[]) => {
    setMode(args[0].paletteMode);
    cookie.set('paletteMode', args[0].paletteMode);
    _changeTheme(...args);
  };

  const userLanguage = useIntl().locale;
  const themeOptions = state.uiTheme;

  const { dense, direction, paletteColors, paletteMode: palleteModeRedux, spacing } = themeOptions;

  const palleteModes: any = {
    light: true,
    dark: true,
  };

  const palleteModeUser =
    typeof window === 'undefined'
      ? palleteModeCookie || palleteModeRedux
      : palleteModeRedux || palleteModeCookie;

  const paletteMode = palleteModes[palleteModeUser] ? palleteModeUser : preferredMode;

  // useLazyCSS('/static/styles/prism-okaidia.css', '#prismjs');

  const dispatch = React.useCallback(
    (...args) => {
      changeTheme(...args);
    },
    [changeTheme]
  );

  React.useEffect(() => {
    const nextPaletteColors = tryJsonParse(themeOptions.paletteColors || 'null');
    const nextPaletteMode = themeOptions.paletteMode || preferredMode;
    changeTheme({
      paletteColors: nextPaletteColors,
      paletteMode: nextPaletteMode,
    });
  }, [preferredMode]);

  useEnhancedEffect(() => {
    document.body.dir = direction;
  }, [direction]);

  React.useEffect(() => {
    const metas = document.querySelectorAll('meta[name="theme-color"]');
    metas.forEach((meta) => {
      meta.setAttribute('content', getMetaThemeColor(paletteMode));
    });
  }, [paletteMode]);

  const theme = React.useMemo(() => {
    const brandingDesignTokens = getDesignTokens(paletteMode);
    const nextPalette = deepmerge(brandingDesignTokens.palette, paletteColors);
    let nextTheme = createTheme(
      {
        direction,
        ...brandingDesignTokens,
        nprogress: {
          color: brandingDesignTokens.palette.primary.main,
        },
        palette: {
          ...nextPalette,
          mode: paletteMode,
        },
        // v5 migration
        props: {
          MuiBadge: {
            overlap: 'rectangular',
          },
        },
        spacing,
      },
      dense ? highDensity : null,
      {
        components: {
          MuiCssBaseline: {
            defaultProps: {
              enableColorScheme: true,
            },
          },
        },
      },
      languageMap[userLanguage]
    );

    nextTheme = deepmerge(nextTheme, getThemedComponents(nextTheme));

    return nextTheme;
  }, [dense, direction, paletteColors, paletteMode, spacing, userLanguage]);

  React.useEffect(() => {
    // Expose the theme as a global variable so people can play with it.
    if (typeof window !== 'undefined') {
      window.theme = theme;
      window.createTheme = createTheme;
    }
  }, [theme]);

  useEnhancedEffect(() => {
    if (theme.palette.mode === 'dark') {
      document.body.classList.remove('mode-light');
      document.body.classList.add('mode-dark');
    } else {
      document.body.classList.remove('mode-dark');
      document.body.classList.add('mode-light');
    }
  }, [theme.palette.mode]);

  const paramsToTheme =
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'browser' does not exist on type 'Process... Remove this comment to see the full error message
    process.browser || process.env.NODE_ENV !== 'production'
      ? {}
      : { sheetsCache: pageContext.jssCache };

  return (
    <StylesProvider
      generateClassName={pageContext.generateClassName}
      jss={pageContext.jss}
      {...paramsToTheme}
    >
      <MuiThemeProvider theme={theme}>
        <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

/**
 * @returns {(nextOptions: Partial<typeof themeInitialOptions>) => void}
 */
export function useChangeTheme() {
  const dispatch = React.useContext(DispatchContext);
  return React.useCallback(
    (options) => {
      dispatch(options);
    },
    [dispatch]
  );
}
