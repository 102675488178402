/* eslint-disable max-len */
import loadable from '@loadable/component';
import { find as findDocs } from 'redux/modules/docs';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const DocsLoadable = loadable(() => import(/* webpackChunkName: 'admin-Docs' */ './Docs'), {
  fallback: <LoadingComponent />,
});

const Docs = (prop: any) => <DocsLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();
    const { docs } = state.docs.result;

    if (!docs) {
      promises.push(
        dispatch(
          findDocs('docs', {
            query: {},
          })
        ).catch(console.error)
      );
    }

    await Promise.all(promises);
  },
})(Docs);
