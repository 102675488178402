import { makeStyles } from '@mui/styles';
import Container from 'components/Container/Container';
import config from 'config';
import { memo, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography } from '@mui/material';
import { useSettings } from 'redux/modules/settings';

import { find } from '../../../redux/modules/duties-cost-calculator';
import DutiesCostCalculatorForm from './DutiesCostCalculatorForm';
import type { Service } from '../../../../api/database/models';

export type DutiesTaxes = {
  federalTax: number | null;
  stateTax: number | null;
  dutiesTaxes: number | null;
  destinationFee: number | null;
  administrationFee: number | null;
  totalDutiesTaxes: number | null;
};

const useStyles = makeStyles(
  (theme) => ({
    bg: {
      display: 'flex',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Default... Remove this comment to see the full error message
      backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : 'rgb(10, 25, 41)',
      paddingTop: 84,
      paddingBottom: 20,
      minHeight: '100vh',
    },
  }),
  { name: 'BringerDutiesCostCalculatoe' }
);

const DutiesCostCalculator = memo(() => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [dutiesTaxes, setDutiesTaxes] = useState<DutiesTaxes>({
    federalTax: null,
    stateTax: null,
    dutiesTaxes: null,
    destinationFee: null,
    administrationFee: null,
    totalDutiesTaxes: null,
  });
  const [error, setError] = useState('');

  const { state: settings } = useSettings();
  const { account: userAccount } = settings;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'countries' does not exist on type 'Defau... Remove this comment to see the full error message
  const countries = useSelector((state) => state.countries.result.allCountries);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'services' does not exist on type 'Defaul... Remove this comment to see the full error message
  const services: Service[] = useSelector((state) => state.services.result.allServices);

  const internalServices = useSelector((state) => state.internalServices.result.all);

  const serviceCountryAddressTypes = useSelector(
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'countryAddressTypes' does not exist on t... Remove this comment to see the full error message
    (state) => state.countryAddressTypes.result.serviceCountryAddressTypes
  );

  const { recipientCountries, senderCountries, countriesByIsoCode } = useMemo(
    () =>
      countries.reduce(
        (acc: any, curr: any) => {
          if (curr.allowShipFrom) {
            acc.senderCountries.push({ value: curr.isoCode, label: curr.name });
          }
          if (curr.allowShipTo && curr.hasDDPCalculatorRates) {
            acc.recipientCountries.push({ value: curr.isoCode, label: curr.name });
          }
          acc.countriesByIsoCode[curr.isoCode] = curr;
          return acc;
        },
        { recipientCountries: [], senderCountries: [], countriesByIsoCode: {} }
      ),
    []
  );

  const onSubmit = async (values: any) => {
    console.log({ values });
    const response = await dispatch(
      find('duties-cost-calculator', {
        query: values,
      })
    );

    // @ts-expect-error hide this
    const data = response as DutiesTaxes;

    if (response.code >= 400) {
      setError(response.message);
      setDutiesTaxes(data);
    } else {
      setError('');
      setDutiesTaxes(data);
    }
  };

  const title = 'Duties & Tax Calculator';
  // eslint-disable-next-line
  const description = 'Duties & Tax Calculator';

  const ldjson = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: config.domain,
    name: config.app.title,
    alternateName: 'Bringer',
    image: `${config.domain}/bps-logo-white.png`,
    sameAs: [
      'https://www.facebook.com/Bringer',
      'https://instagram.com/Bringer',
      'https://www.linkedin.com/company/Bringer/',
      'https://twitter.com/@Bringer',
    ],
  };

  const ldjsonOrg = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: config.app.title,
    legalName: 'Bringer CORP',
    email: `mailto:contact@${config.host}`,
    url: config.domain,
    logo: `${config.domain}/bps-logo-white.png`,
    sameAs: [
      'https://www.facebook.com/Bringer',
      'https://instagram.com/Bringer',
      'https://www.linkedin.com/company/Bringer/',
      'https://twitter.com/@Bringer',
    ],
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={`${config.domain}/`} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">{JSON.stringify(ldjson)}</script>
        <script type="application/ld+json">{JSON.stringify(ldjsonOrg)}</script>
      </Helmet>
      <div className={classes.bg}>
        <Container>
          <div className={classes.containerHeader}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs="auto">
                <Typography variant="h5" gutterBottom paragraph>
                  Duties & Tax Calculator
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <DutiesCostCalculatorForm
                countries={countries}
                userAccount={userAccount}
                services={services}
                internalServices={internalServices}
                recipientCountries={recipientCountries}
                senderCountries={senderCountries}
                countriesByIsoCode={countriesByIsoCode}
                serviceCountryAddressTypes={serviceCountryAddressTypes}
                onSubmit={onSubmit}
                dutiesTaxes={dutiesTaxes}
                error={error}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
});

export default DutiesCostCalculator;
