import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ServiceCountriesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-AddressDetails' */ './AddressDetails'),
  {
    fallback: <LoadingComponent />,
  }
);

export default ServiceCountriesLoadable;
