import set from 'lodash.set';
import type { GridColDef } from '@mui/x-data-grid-premium';

const typesToDefaultValue: any = {
  boolean: false,
  date: null,
  dateTime: null,
};

type Definition = GridColDef & { defaultHide?: boolean; initialValue?: any };
const getInitialValues = (columns: Definition[]) => {
  const initialValues = columns.reduce((acc, curr) => {
    if (curr.editable) {
      const initialValueDefault = typesToDefaultValue[curr.type || ''] || '';
      const initialValue =
        typeof curr.initialValue !== undefined ? curr.initialValue : initialValueDefault;
      set(acc, curr.field.replaceAll('$', ''), initialValue);
    }
    return acc;
  }, {} as any);
  return { ...initialValues };
};

export { getInitialValues };
