/* eslint-disable @typescript-eslint/ban-ts-comment */
import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
import { find as findParcels } from 'redux/modules/parcels';
import { find as findPickups } from 'redux/modules/pickups';
import { batch } from 'react-redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';

const NewPickupLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-NewPickup' */ './NewPickup'),
  {
    fallback: <LoadingComponent />,
  }
);
const NewPickup = (prop: any) => <NewPickupLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises: any[] = [];

    if (params.id) {
      promises.push(() =>
        dispatch(
          findParcels('newPickupParcel', {
            query: {
              $paginate: false,
              uid: params.id,
              include: [
                'Account',
                'Label',
                'Sender',
                'Recipient',
                'Dispatch',
                'Unitizer',
                'Service',
              ],
            },
          })
        ).catch(() => null)
      );
    }

    if (params.pickupId) {
      promises.push(() =>
        dispatch(
          findPickups('pickupEdit', {
            query: {
              $paginate: false,
              uid: params.pickupId,
              include: ['Account', 'ParcelExtra'],
            },
          })
        ).catch(console.error)
      );
    }

    await new Promise((resolve) => {
      batch(async () => {
        const finalPromises = promises.map((func) => func());
        await Promise.all(finalPromises);
        resolve(null);
      });
    });
  },
})(NewPickup);
