import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ParcelScanLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-ParcelScan' */ './ParcelScan'),
  {
    fallback: <LoadingComponent />,
  }
);

export default ParcelScanLoadable;
