/* eslint-disable max-len */
import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { batch } from 'react-redux';
import { find as findMarkets } from 'redux/modules/currencyMarkets';

const StoreOrdersLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-StoreOrders' */ './StoreOrders'),
  {
    fallback: <LoadingComponent />,
  }
);

const StoreOrders = (prop: any) => <StoreOrdersLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises: any = [];

    const state = getState();

    // external services
    if (!state.currencyMarkets.result.all) {
      promises.push(() =>
        dispatch(
          findMarkets('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    await new Promise((resolve) => {
      batch(async () => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
        const finalPromises = promises.map((func) => func());
        await Promise.all(finalPromises);
        // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      });
    });
  },
})(StoreOrders);
