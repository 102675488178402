/* eslint-disable max-len */
import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { find as findAirlines } from 'redux/modules/airlines';

const ServicesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-service-routes' */ './ServiceRoutes'),
  {
    fallback: <LoadingComponent />,
  }
);

const Services = (prop: any) => <ServicesLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();
    const airlines = state.airlines.result.all;

    if (!airlines) {
      promises.push(
        dispatch(
          findAirlines('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    await Promise.all(promises);
  },
})(Services);
