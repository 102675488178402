/* eslint-disable max-len */
import { AddBox, Delete, Edit, MoreHoriz, Save } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { GridInitialState } from '@mui/x-data-grid-premium';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import Tooltip from 'components/TooltipClick/TooltipClick';
import useIsMounted from 'components/Utils/useIsMounted';
import { cloneElement, useCallback, useEffect, useRef, useState } from 'react';
import { useSyncedSettings } from 'redux/modules/syncedSettings';

interface StateView {
  label: string;
  value: GridInitialState;
}

function ViewListItem(props: {
  view: StateView;
  viewId: string;
  selected: boolean;
  onDelete: (viewId: string) => void;
  onSelect: (viewId: string) => void;
  onEdit: (viewId: string) => void;
}) {
  const { view, viewId, selected, onDelete, onEdit, onSelect, ...other } = props;

  return (
    <MenuItem selected={selected} onClick={() => onSelect(viewId)} {...other}>
      <Grid alignItems="center" container spacing={1}>
        <Grid xs={8}>{viewId}</Grid>
        <Grid xs={4} sx={{ textAlign: 'right' }}>
          {!!onEdit && (
            <Tooltip
              placement="top"
              componentsProps={{
                tooltip: {
                  variant: 'dark',
                },
              }}
              disableInteractive
              title="Edit view details"
            >
              <IconButton
                edge="end"
                aria-label="edit"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(viewId);
                }}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                sx={{
                  '&:hover .MuiSvgIcon-root': {
                    color: (theme) => {
                      return theme.palette.action.active;
                    },
                  },
                }}
              >
                <Edit fontSize="small" color="disabled" />
              </IconButton>
            </Tooltip>
          )}
          {!!onDelete && (
            <Tooltip
              placement="top"
              componentsProps={{
                tooltip: {
                  variant: 'dark',
                },
              }}
              disableInteractive
              title="Delete view"
            >
              <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(viewId);
                }}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                sx={{
                  '&:hover .MuiSvgIcon-root': {
                    color: (theme) => {
                      return theme.palette.action.active;
                    },
                  },
                }}
              >
                <Delete fontSize="small" color="disabled" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </MenuItem>
  );
}

const TitleMenu = ({
  title,
  apiRef,
  storageKey,
  children,
}: {
  storageKey: string;
  apiRef: { current: GridApiPremium };
  title: any;
  children: any;
}) => {
  const storeKey = `datagrid-custom-views-${storageKey}`;
  const storeKeyDefault = `datagrid-custom-views-${storageKey}-default`;

  const [skip, setSkip] = useState(true);
  const { state, setSyncedSettings, deleteSyncedSettings } = useSyncedSettings({ key: storageKey });

  const savedViews = state[storeKey] || {};
  const defaultView = state[storeKeyDefault] || {};

  const refRestoration = useRef(false);

  const [activeView, setActiveView] = useState();

  const isMount = useIsMounted();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handlePopperAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const [isAddingView, setIsAddingView] = useState(false);
  const [label, setLabel] = useState('');
  const [currentAsDefaultView, setCurrentAsDefaultView] = useState(false);
  const [editingView, setEditingView] = useState('');
  const onLabelChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLabel(event.target.value);
  };

  const handleClosePopper = ({ isEditingView }: { isEditingView?: boolean } = {}) => {
    // dispatch({ type: 'closePopper' });
    if (!isEditingView) {
      setEditingView('');
    }
    setAnchorEl(null);
  };

  const handleSubmitForm: React.FormEventHandler = (e) => {
    setIsAddingView(false);
    e.preventDefault();
    const isCreatingANewOne = !savedViews[editingView];
    if (editingView) {
      deleteSyncedSettings(storeKey, editingView);
    }

    if (isCreatingANewOne) {
      setSyncedSettings(storeKey, {
        [label]: apiRef.current.exportState(),
      });
    } else {
      // persisting something in existing view
      setSyncedSettings(storeKey, {
        [label]: savedViews[editingView] || apiRef.current.exportState(),
      });
    }

    if (currentAsDefaultView) {
      setSyncedSettings(storeKeyDefault, { viewId: label });
    } else if (defaultView.viewId === editingView) {
      setSyncedSettings(storeKeyDefault, { viewId: '' });
    }
  };

  const handleSave = () => {
    if (activeView) {
      setSyncedSettings(storeKey, {
        [activeView]: apiRef.current.exportState(),
      });
    }
  };

  const savedViewsKeys = Object.keys(savedViews || {});

  const handleChangeDefault = (event) => {
    setCurrentAsDefaultView(event.target.checked);
  };
  const handleEditView = (viewId: string) => {
    setEditingView(viewId);
    setIsAddingView(true);
    setLabel(viewId);
    handleClosePopper({ isEditingView: true });
  };
  const handleDeleteView = useCallback((viewId: string) => {
    deleteSyncedSettings(storeKey, viewId);
    // dispatch({ type: 'deleteView', id: viewId });
  }, []);

  const handleSetActiveView = (viewId: string) => {
    setActiveView(viewId);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      // dispatch({ type: 'closePopper' });
      handleClosePopper();
    } else if (event.key === 'Escape') {
      handleClosePopper();
      // dispatch({ type: 'closePopper' });
    }
  };

  useEffect(() => {
    if (state.synced !== false && !refRestoration.current) {
      setSkip(false);
    }
  }, [storageKey, defaultView.viewId, state.synced]);

  useEffect(() => {
    if (!activeView && defaultView.viewId) {
      setActiveView(defaultView.viewId);
    } else {
      setActiveView(undefined);
    }
  }, [defaultView.viewId]);

  // improve this
  useEffect(() => {
    if (isMount && !defaultView.viewId) {
      handleSetActiveView(undefined);
    }
  }, [storageKey]);

  const isValid = true;

  const isMenuOpened = Boolean(anchorEl);
  const canBeMenuOpened = isMenuOpened;
  const popperId = canBeMenuOpened ? 'transition-popper' : undefined;

  return (
    <div>
      <Stack sx={{ mb: 3, mt: 3 }} spacing={1} direction="row" alignItems="center">
        <Button
          aria-describedby={popperId}
          type="button"
          size="small"
          id="custom-view-button"
          aria-controls={isMenuOpened ? 'custom-view-menu' : undefined}
          aria-expanded={isMenuOpened ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handlePopperAnchorClick}
          endIcon={<MoreHoriz />}
        >
          <Typography variant="h3">{activeView || `All ${title}`}</Typography>
        </Button>
        <Tooltip title="If you have altered the search keywords, filters or sorting preference, you can add those changes as a new custom view.">
          <span>
            <IconButton onClick={() => setIsAddingView(true)} size="small">
              <AddBox fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="If you alter the search keywords, filters or sorting preference, you can save those changes to the current view.">
          <span>
            <IconButton onClick={handleSave} size="small" disabled={!activeView}>
              <Save fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>

      <ClickAwayListener onClickAway={handleClosePopper}>
        <Popper
          id={popperId}
          open={isMenuOpened}
          anchorEl={anchorEl}
          role={undefined}
          transition
          placement="bottom-start"
          sx={{ zIndex: 'modal' }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ maxWidth: 'calc(100vw - 32px)', minWidth: 300 }} elevation={2}>
                <MenuList
                  autoFocusItem={isMenuOpened}
                  id="custom-view-menu"
                  aria-labelledby="custom-view-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ '& li': { display: 'block', whiteSpace: 'normal' } }}
                >
                  <MenuItem>
                    <Typography variant="caption">
                      Default: {defaultView.viewId || title}
                    </Typography>
                  </MenuItem>
                  <ViewListItem
                    key={`All ${title}`}
                    viewId={`All ${title}`}
                    selected={
                      `All ${title}` === (activeView || defaultView.viewId) ||
                      (!activeView && !defaultView.viewId)
                    }
                    onSelect={handleSetActiveView}
                  />
                  {Object.entries(savedViews).map(([viewId, view]) => (
                    <ViewListItem
                      key={viewId}
                      view={view}
                      viewId={viewId}
                      selected={viewId === (activeView || defaultView.viewId)}
                      onDelete={handleDeleteView}
                      onSelect={handleSetActiveView}
                      onEdit={handleEditView}
                    />
                  ))}
                  {!savedViewsKeys.length && (
                    <MenuItem disabled>Search or filter to create a new custom view</MenuItem>
                  )}
                </MenuList>
              </Paper>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>

      <Dialog onClose={() => setIsAddingView(false)} open={isAddingView}>
        <form onSubmit={handleSubmitForm}>
          <DialogTitle>Save your custom view</DialogTitle>
          <DialogContent>
            <Typography variant="caption">Enter a unique name that describes your view.</Typography>
            <TextField
              autoFocus
              value={label}
              onChange={onLabelChange}
              margin="dense"
              size="small"
              label="Custom view label"
              variant="standard"
              placeholder="e.g. Ready To Ship"
              fullWidth
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked={defaultView.viewId === editingView} />}
                onChange={handleChangeDefault}
                label="Set as a default view"
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={() => setIsAddingView(false)}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isValid}>
              {editingView ? 'Save' : 'Create view'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {cloneElement(children, {
        skipQuery: skip,
        skipStateChanges: skip,
        currentViewId: activeView === `All ${title}` ? undefined : activeView,
        currentViewState: activeView && savedViews[activeView],
      })}
    </div>
  );
};

export { TitleMenu };
