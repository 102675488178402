/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { createSvgIcon } from '@mui/material';

const useStyles = makeStyles(
  {
    icon: {
      width: 160,
      height: 220,
    },
  },
  { name: 'PeaceIcon' }
);

export default ({ className, ...props }: any) => {
  const classes = useStyles();
  const Icon = createSvgIcon(
    <>
      <defs>
        <linearGradient
          id="20ed5016-baf7-4829-a36e-abc7d14e31da"
          x1="1738.07"
          y1="3359.93"
          x2="1766.12"
          y2="3359.93"
          gradientTransform="translate(3891.81 330.91) rotate(120)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#dfe9f8" />
          <stop offset="0.33" stopColor="#dfe9f8" stopOpacity="0.2" />
          <stop offset="0.63" stopColor="#dfe9f8" />
          <stop offset={1} stopColor="#dfe9f8" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="a609b9ff-9046-4c93-9c8a-5b33172c85c4"
          x1="1745.26"
          y1="3359.93"
          x2="1758.92"
          y2="3359.93"
          xlinkHref="#20ed5016-baf7-4829-a36e-abc7d14e31da"
        />
        <linearGradient
          id="e49a4222-9e7f-400d-bdc7-2b064a16111b"
          x1="1748.3"
          y1="3359.93"
          x2="1755.89"
          y2="3359.93"
          xlinkHref="#20ed5016-baf7-4829-a36e-abc7d14e31da"
        />
        <linearGradient
          id="871f734e-ef30-47ae-b851-eada35b5d84b"
          x1="1749.92"
          y1="3359.93"
          x2="1754.27"
          y2="3359.93"
          xlinkHref="#20ed5016-baf7-4829-a36e-abc7d14e31da"
        />
        <linearGradient
          id="d33ecd54-f70f-4f92-aee4-2319a88fe291"
          x1={1689}
          y1="3400.02"
          x2="1743.57"
          y2="3400.02"
          gradientTransform="matrix(0.86, 0.5, -0.5, 0.86, 284.96, -3616.8)"
          xlinkHref="#20ed5016-baf7-4829-a36e-abc7d14e31da"
        />
        <linearGradient
          id="d128226b-a565-4297-b480-76e5136e5598"
          x1="1701.11"
          y1="3404.28"
          x2="1728.93"
          y2="3404.28"
          gradientTransform="translate(3857.31 404.82) rotate(120)"
          xlinkHref="#20ed5016-baf7-4829-a36e-abc7d14e31da"
        />
        <linearGradient
          id="e9733e59-4b6b-4be1-b72c-d105b1a6c057"
          x1="47.02"
          y1="180.44"
          x2="50.11"
          y2="185.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5333ed" />
          <stop offset="0.23" stopColor="#472ad5" />
          <stop offset="0.73" stopColor="#281498" />
          <stop offset={1} stopColor="#150773" />
        </linearGradient>
        <linearGradient
          id="612314c7-c63b-4579-be7b-d6cca10fdf96"
          x1="56.41"
          y1="180.44"
          x2="53.24"
          y2="185.36"
          xlinkHref="#e9733e59-4b6b-4be1-b72c-d105b1a6c057"
        />
        <linearGradient
          id="a20555c0-7a0c-4993-b322-b1208030ca1e"
          x1="41.26"
          y1="186.07"
          x2="47.01"
          y2="187.82"
          xlinkHref="#e9733e59-4b6b-4be1-b72c-d105b1a6c057"
        />
        <linearGradient
          id="03559110-41ad-492a-ac73-fb058aedcf53"
          x1="61.3"
          y1="186.62"
          x2="56.51"
          y2="187.54"
          xlinkHref="#e9733e59-4b6b-4be1-b72c-d105b1a6c057"
        />
        <linearGradient
          id="434a00ac-ba72-4df6-9843-46c19f5eee3d"
          x1="51.74"
          y1="190.03"
          x2="51.67"
          y2="184.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5333ed" />
          <stop offset="0.18" stopColor="#4d4bea" />
          <stop offset="0.57" stopColor="#3e89e2" />
          <stop offset={1} stopColor="#2cd4d9" />
        </linearGradient>
        <linearGradient
          id="e6101bff-ee29-4e0f-9414-cf8adc90df68"
          x1="78.98"
          y1="103.96"
          x2="108.02"
          y2="103.96"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#150773" />
          <stop offset="0.03" stopColor="#2e2384" />
          <stop offset="0.07" stopColor="#534c9c" />
          <stop offset="0.12" stopColor="#7572b2" />
          <stop offset="0.18" stopColor="#9292c5" />
          <stop offset="0.24" stopColor="#aaaed5" />
          <stop offset="0.3" stopColor="#bec4e2" />
          <stop offset="0.38" stopColor="#cdd4ec" />
          <stop offset="0.48" stopColor="#d7e0f3" />
          <stop offset="0.62" stopColor="#dde7f7" />
          <stop offset={1} stopColor="#dfe9f8" />
        </linearGradient>
        <linearGradient
          id="edcb9aba-be0a-43cb-acb7-bd55a0ef73ac"
          x1="46.19"
          y1="106.28"
          x2="85.54"
          y2="106.28"
          xlinkHref="#e6101bff-ee29-4e0f-9414-cf8adc90df68"
        />
      </defs>
      <g style={{ isolation: 'isolate' }}>
        <g>
          <g>
            <g>
              <ellipse cx={80} cy="179.64" rx="51.51" ry="29.75" style={{ fill: '#dfe9f8' }} />
              <ellipse
                cx="105.97"
                cy="168.3"
                rx="8.77"
                ry="15.18"
                transform="translate(-62.79 273.54) rotate(-89.75)"
                style={{
                  fill: 'none',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.316828578710556px',
                  opacity: '0.5',
                  mixBlendMode: 'multiply',
                  stroke: 'url(#20ed5016-baf7-4829-a36e-abc7d14e31da)',
                }}
              />
              <ellipse
                cx="105.97"
                cy="168.3"
                rx="4.22"
                ry="7.31"
                transform="translate(-62.79 273.54) rotate(-89.75)"
                style={{
                  fill: 'none',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.316828578710556px',
                  opacity: '0.7000000000000001',
                  mixBlendMode: 'multiply',
                  stroke: 'url(#a609b9ff-9046-4c93-9c8a-5b33172c85c4)',
                }}
              />
              <ellipse
                cx="105.97"
                cy="168.3"
                rx="2.3"
                ry="3.98"
                transform="translate(-62.79 273.54) rotate(-89.75)"
                style={{
                  fill: 'none',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.316828578710556px',
                  mixBlendMode: 'multiply',
                  stroke: 'url(#e49a4222-9e7f-400d-bdc7-2b064a16111b)',
                }}
              />
              <ellipse
                cx="105.97"
                cy="168.3"
                rx="1.28"
                ry="2.21"
                transform="translate(-62.79 273.54) rotate(-89.75)"
                style={{
                  fill: 'none',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.316828578710556px',
                  mixBlendMode: 'multiply',
                  stroke: 'url(#871f734e-ef30-47ae-b851-eada35b5d84b)',
                }}
              />
              <path
                d="M28.93,175.73c5.66-3.51,13.76-5.69,22.75-5.65,17.07.07,30.88,8.13,30.84,18,0,9.1-11.86,16.56-27.1,17.6"
                style={{
                  fill: 'none',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.645175651723019px',
                  opacity: '0.5',
                  mixBlendMode: 'multiply',
                  stroke: 'url(#d33ecd54-f70f-4f92-aee4-2319a88fe291)',
                }}
              />
              <ellipse
                cx="51.6"
                cy="187.93"
                rx="8.59"
                ry="14.88"
                transform="translate(-136.55 238.73) rotate(-89.75)"
                style={{
                  fill: 'none',
                  strokeMiterlimit: 10,
                  strokeWidth: '0.645175651723019px',
                  opacity: '0.7000000000000001',
                  mixBlendMode: 'multiply',
                  stroke: 'url(#d128226b-a565-4297-b480-76e5136e5598)',
                }}
              />
              <g>
                <path
                  d="M61.76,192.23c2.91-2.75,2.31-6.4-1.81-8.78-4.84-2.79-12.69-2.79-17.53,0s-4.84,7.33,0,10.12c4.12,2.38,10.4,2.72,15.17,1.06v-.78H57l-2.58-3.39Z"
                  style={{ fill: '#5333ed' }}
                />
                <path
                  d="M54.41,189.68l7.35,1.77c2.91-2.75,2.31-6.4-1.81-8.78-4.84-2.79-12.69-2.79-17.53,0s-4.84,7.33,0,10.12c4.12,2.38,10.4,2.72,15.17,1.06Z"
                  style={{ fill: '#2cd4d9' }}
                />
                <path
                  d="M51.15,185.4a5.41,5.41,0,0,0-.61-1.49,6.86,6.86,0,0,0-3-2.83,6.82,6.82,0,0,0,3.37,6.59,3.56,3.56,0,0,0,.46.26A16.92,16.92,0,0,0,51.15,185.4Z"
                  style={{ fill: 'url(#e9733e59-4b6b-4be1-b72c-d105b1a6c057)' }}
                />
                <path
                  d="M52.07,185.4a5.41,5.41,0,0,1,.61-1.49,6.86,6.86,0,0,1,3-2.83,6.82,6.82,0,0,1-3.37,6.59,3.56,3.56,0,0,1-.46.26A16.92,16.92,0,0,1,52.07,185.4Z"
                  style={{ fill: 'url(#612314c7-c63b-4579-be7b-d6cca10fdf96)' }}
                />
                <path
                  d="M56.86,187.06a8.62,8.62,0,0,0,2.4-4.63,8.4,8.4,0,0,0-5.87,4h0l-.72,1h0a8.53,8.53,0,0,0-1-8,8.54,8.54,0,0,0-1,7.93h0l-.71-1,0,0a8.4,8.4,0,0,0-5.87-4,8.56,8.56,0,0,0,6.28,6.85,4.47,4.47,0,0,0,.64.16l.11,0v0a3.14,3.14,0,0,0,1.25,0v0l.11,0a4.47,4.47,0,0,0,.64-.16A8.58,8.58,0,0,0,56.86,187.06Z"
                  style={{ fill: '#150773' }}
                />
                <path
                  d="M47.46,186.79a5.41,5.41,0,0,0-1.49-.6,6.86,6.86,0,0,0-4.15.21,6.82,6.82,0,0,0,7.07,2.18,3.56,3.56,0,0,0,.51-.15A16.92,16.92,0,0,0,47.46,186.79Z"
                  style={{ fill: 'url(#a20555c0-7a0c-4993-b322-b1208030ca1e)' }}
                />
                <path
                  d="M55.4,186.79a5.41,5.41,0,0,1,1.49-.6,6.86,6.86,0,0,1,4.15.21A6.82,6.82,0,0,1,54,188.58a3.56,3.56,0,0,1-.51-.15A16.92,16.92,0,0,1,55.4,186.79Z"
                  style={{ fill: 'url(#03559110-41ad-492a-ac73-fb058aedcf53)' }}
                />
                <path
                  d="M55.46,188.39a6.28,6.28,0,0,0-1.47.19h0l-.88.15h0a6.26,6.26,0,0,0,3.58-4.66,6.33,6.33,0,0,0-3.22,1.61c0-.14,0-.28,0-.42a6.33,6.33,0,0,0-1.16-3.65,6.42,6.42,0,0,0-.57,1,6.42,6.42,0,0,0-.57-1A6.33,6.33,0,0,0,50,185.25c0,.14,0,.28,0,.42a6.33,6.33,0,0,0-3.22-1.61,6.27,6.27,0,0,0,3.58,4.66h0l-.88-.15h0a6.17,6.17,0,0,0-5.12,1,6.28,6.28,0,0,0,6.82.29,3.29,3.29,0,0,0,.42-.25l.07,0h0l.09-.07.09.07h0l.07,0a3.28,3.28,0,0,0,.42.25,6.28,6.28,0,0,0,6.82-.29A6.33,6.33,0,0,0,55.46,188.39Z"
                  style={{ fill: 'url(#434a00ac-ba72-4df6-9843-46c19f5eee3d)' }}
                />
              </g>
            </g>
            <g>
              <path
                d="M116.58,141.27a.76.76,0,0,0,.87-.72.79.79,0,0,0-.69-.79l-1.84-.28-1.52,0a9.86,9.86,0,0,0-1.31,0l-7.7.79-4.93.51v3.7l7.62.43,6.39.36,1.26.19a.45.45,0,0,0,.14-.89l-1.17-.18,0-.14,1.59.24a.45.45,0,0,0,.14-.89l-1.5-.23,0-.16,1.63.25a.45.45,0,0,0,.14-.89l-1.55-.23,0-.17,1.92.29a.55.55,0,0,0,.61-.35.53.53,0,0,0-.42-.69l-1.84-.28,0-.16Z"
                style={{ fill: '#b7835f' }}
              />
              <path
                d="M81.44,147.79l-3.36,4.41-4.33,5.7A9.87,9.87,0,0,0,73,159l-.75,1.33L71.55,162a.79.79,0,0,0,.32,1,.76.76,0,0,0,1.07-.37l.79-1.89.15,0-.72,1.72a.53.53,0,0,0,.37.72.55.55,0,0,0,.62-.34l.75-1.79.16.05-.61,1.44a.45.45,0,0,0,.83.35l.64-1.52.16,0-.59,1.4a.45.45,0,0,0,.83.35l.62-1.48.14,0-.46,1.09a.45.45,0,0,0,.83.35l.49-1.17,4.08-7.63,2.54-4.74Z"
                style={{ fill: '#b7835f' }}
              />
              <path
                d="M104.38,140.28l-4.93.51v3.7l7.62.43,0,0C107.77,143.54,106.56,141.56,104.38,140.28Z"
                style={{ fill: '#b7835f', mixBlendMode: 'multiply' }}
              />
              <path
                d="M81.44,147.79l-3.36,4.41a5.74,5.74,0,0,0,1.73,1.5,5.63,5.63,0,0,0,2.25.75l2.54-4.74Z"
                style={{ fill: '#b7835f', mixBlendMode: 'multiply' }}
              />
              <path
                d="M60.89,74.3a2.26,2.26,0,0,0-.53.22l-.18-.09a.87.87,0,0,0,0-.49.9.9,0,1,0-1.74.46.88.88,0,0,0,.19.35,1.35,1.35,0,0,0,.6,2.22s0,.08,0,.11a2.28,2.28,0,1,0,1.63-2.79Z"
                style={{ fill: '#0c022f' }}
              />
              <path
                d="M110.37,115.42c-1-1.66-4-1.7-6.88-.16h0c-3.56,2-10.8,6-16.22,8.47a6.34,6.34,0,0,1-8.57-3.48l-2.88-7.49-19,8.92c-2.43,3.66-1.52,7-4.68,11-7,8.87,19.09,4.28,19.09,4.28s20.44,5.45,34.42-12.11a4.5,4.5,0,0,0,.54-2.89c.21-.1.42-.2.63-.32C109.75,119.93,111.36,117.14,110.37,115.42Z"
                style={{ fill: '#150773' }}
              />
              <path
                d="M102.56,140a7.91,7.91,0,0,0-2.68-.81h0s-24.24-2.49-42.32-8h0c-4.31-1.63-8.46-1.13-9.71,1.36-1.4,2.8,1.38,7,6.22,9.47a15.06,15.06,0,0,0,5.57,1.61h0s26.06,3.29,43.78,2.44h.14l.37,0,0,0a2.35,2.35,0,0,0,1.85-1.13C106.51,143.43,105.07,141.24,102.56,140Z"
                style={{ fill: '#150773' }}
              />
              <path
                d="M111.07,115.18c-2.43-1.4-5.05-1.4-5.86,0a1.9,1.9,0,0,0-.22,1.11h0L78.3,148.77h0a1.26,1.26,0,0,0-.1.11c-.6,1,.37,2.74,2.18,3.78,1.64.95,3.39,1,4.15.25h0l29.17-32.33h0a1.74,1.74,0,0,0,.29-.34C114.82,118.86,113.5,116.58,111.07,115.18Z"
                style={{ fill: '#150773' }}
              />
              <path
                d="M85.28,87.38s-.6,36.32,19.59,24.08a1.91,1.91,0,0,1,2.4.34c1,1.11.92,3.6.25,4.08C96.2,124.08,80.18,123.19,79,99Z"
                style={{ fill: 'url(#e6101bff-ee29-4e0f-9414-cf8adc90df68)' }}
              />
              <path
                d="M57.16,122.65c1.69,2.93,7.16,2.94,12.22,0s7.79-7.66,6.09-10.59l-18.71,9.59A3.66,3.66,0,0,0,57.16,122.65Z"
                style={{ fill: '#5333ed' }}
              />
              <polygon
                points="61.33 121.74 59.49 122.64 59.49 125.35 61.33 125.35 61.33 121.74"
                style={{ fill: '#120667' }}
              />
              <polygon
                points="75.24 114.9 73.79 115.32 73.79 120.57 75.24 120.57 75.24 114.9"
                style={{ fill: '#120667' }}
              />
              <polygon
                points="72.13 115.31 68.49 117.33 68.49 123.55 72.13 123.55 72.13 115.31"
                style={{ fill: '#120667' }}
              />
              <path
                d="M85.05,91.2A8.93,8.93,0,0,0,76.6,79.36a19.73,19.73,0,0,0-4,.89c-4.59,1.69-11.32,5.32-15.46,7.27a23.4,23.4,0,0,0-7.75,5.81l0,0-.21.28-.14.19c-3.53,4.81-.17,27.31-2.86,37.29-.26,1,1.37,2.35,3,2.13,6.19-.82,5.19-17.76,5.28-16.33,0,0,0,.08,0,.12,0,.24-.22,2.45.62,3.44,2.57,3,8.56,3.31,14.52-.13,5.74-3.32,9-8.59,7.56-12.12a5.9,5.9,0,0,1,.11-1.68C78,102.26,85.05,91.2,85.05,91.2Z"
                style={{ fill: 'url(#edcb9aba-be0a-43cb-acb7-bd55a0ef73ac)' }}
              />
              <ellipse
                cx="66.58"
                cy="88.2"
                rx="3.31"
                ry="5.74"
                transform="translate(-43.89 91.43) rotate(-54.63)"
                style={{
                  fill: 'none',
                  stroke: '#fff',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '1.24023004689982px',
                }}
              />
              <circle
                cx="67.44"
                cy="71.85"
                r="3.38"
                transform="translate(-21.94 30.36) rotate(-21.92)"
                style={{ fill: '#0c022f' }}
              />
              <path
                d="M62.19,87.67l0-9.4,8.75,0V91.06C70.87,93.89,63.48,91.39,62.19,87.67Z"
                style={{ fill: '#9e684d' }}
              />
              <path
                d="M70.89,83.92l0-4.49-8.69.28v2.5c1.25,2.19,3.13,3.15,7,2.5A3.67,3.67,0,0,0,70.89,83.92Z"
                style={{ fill: '#b7835f', mixBlendMode: 'multiply' }}
              />
              <path
                d="M60.12,80.17a1.72,1.72,0,0,1,.76-2.31s.06,0,.09,0l-.56-1.94A5.51,5.51,0,0,1,71,72.85l1.28,4.41c1.14,3.95-.79,5.27-3.42,6a6,6,0,0,1-6.6-2.35A1.69,1.69,0,0,1,60.12,80.17Z"
                style={{ fill: '#b7835f' }}
              />
              <path
                d="M59.38,75.67c0-.14-1-2.34-.59-3.48s1.83-1.13,1.83-1.13.28-2.32,2.22-2.28c0-.42,1.32-1.73,3-2.21a2.26,2.26,0,0,1,2.47,1.23,2.82,2.82,0,0,1-1.56,3.67,2.77,2.77,0,0,1-1.92,0l0,.06s-1.37-.57-2,.23a1.3,1.3,0,0,0,.31,1.9,3.5,3.5,0,0,1,.21.39,2.14,2.14,0,0,1-1.19,2.78l-.09,0c.18.15,1.12,1,.88,1.61l-.64.37a1.32,1.32,0,0,0-1.35-1,.64.64,0,0,0-.37.16A6.93,6.93,0,0,1,59.38,75.67Z"
                style={{ fill: '#0c022f' }}
              />
              <path
                d="M71.24,91.52c-1.06,1.49-4,1.21-6.6-.62-1.94-1.38-3.12-3.24-3.14-4.71"
                style={{
                  fill: 'none',
                  stroke: '#fff',
                  strokeLinecap: 'round',
                  strokeMiterlimit: 10,
                  strokeWidth: '1.24023004689982px',
                }}
              />
              <path
                d="M54.62,118.89s.12-4.23.21-9.3l1.08,1.31A56.53,56.53,0,0,1,55,120Z"
                style={{ fill: '#dfe9f8', mixBlendMode: 'multiply' }}
              />
              <path
                d="M48.06,133.47s.79-3.59-.2-4.21A42.28,42.28,0,0,0,43,127.87l-2.51-.16s0,1.49,2.15,1.5L43.84,131Z"
                style={{ fill: '#9e684d' }}
              />
              <path
                d="M41.82,124.85l-.7,3,.79,3.62-1.44-1.41a3,3,0,0,0,.43-1.2c-.15-1.23-.55-1.46-.55-1.46l-1.21,3.08,2.18,2.73s3.09,1.45,4,1.4a6,6,0,0,0,5.9-4.88s-.95-3.46-5.24.41L45,128s0-.66,0-1.09a1.09,1.09,0,0,0-.51-.75l-.29,1.66.57,2.78-1.27-3.3s.73-1.8,0-2.44l-1,2.61.66,3.55-1.15-3.46S42.72,125,41.82,124.85Z"
                style={{ fill: '#b7835f' }}
              />
              <path
                d="M62.18,132a4.2,4.2,0,0,0-1.77,0s19.93,4.91,24.8,5.17L86,137C85.71,136.08,76.3,136,62.18,132Z"
                style={{ fill: '#120667', mixBlendMode: 'multiply' }}
              />
              <path
                d="M88.42,136.44,101,121.08a24.66,24.66,0,0,1-2.74,1.23s-2.16,4.56-10.78,14.37Z"
                style={{ fill: '#120667', mixBlendMode: 'multiply' }}
              />
              <path
                d="M87.52,137.54l9.74,1.32.33-.37a26.13,26.13,0,0,0-9.69-1.42Z"
                style={{ fill: '#120667', mixBlendMode: 'multiply' }}
              />
              <path
                d="M80.89,145.61l9.84.49-1.18,1.31s-2.46-1.43-9.4-.9Z"
                style={{ fill: '#120667', mixBlendMode: 'multiply' }}
              />
              <path
                d="M108.32,116.59s-1.5-3.35-.66-4.16a42.28,42.28,0,0,1,4.5-2.35l2.42-.66s.26,1.47-1.8,1.9l-.83,2Z"
                style={{ fill: '#9e684d' }}
              />
              <path
                d="M112.69,106.88l1.3,2.84,0,3.7,1.12-1.67a3,3,0,0,1-.66-1.09c-.1-1.24.25-1.54.25-1.54l1.81,2.77L114.88,115s-2.73,2-3.6,2.17a6,6,0,0,1-6.77-3.59s.23-3.58,5.21-.65l.44-2.31s-.15-.65-.2-1.07a1.09,1.09,0,0,1,.35-.84l.62,1.56v2.84l.58-3.49s-1.07-1.62-.53-2.38l1.52,2.35.07,3.61.42-3.62S111.84,107.23,112.69,106.88Z"
                style={{ fill: '#b7835f' }}
              />
            </g>
          </g>
        </g>
      </g>
    </>,
    'Peace'
  );
  return <Icon className={clsx(classes.icon, className)} {...props} viewBox="0 0 160 220" />;
};
