/* eslint-disable max-len */
import axios from 'axios';
import config from 'config';

export default function apiClient(req: any) {
  const instance = axios.create({
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__SERVER__'.
    baseURL: __SERVER__ ? `http://${config.apiHost}:${config.apiPort}` : '/api',
  });

  let token: any;

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'setJwtToken' does not exist on type 'Axi... Remove this comment to see the full error message
  instance.setJwtToken = (newToken: any) => {
    token = newToken;
  };

  instance.interceptors.request.use(
    (conf) => {
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__SERVER__'.
      if (__SERVER__) {
        if (req.header('cookie')) {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          conf.headers.Cookie = req.header('cookie');
        }
        if (req.header('authorization')) {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          conf.headers.authorization = req.header('authorization');
        }
      }

      if (token) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        conf.headers.authorization = token;
      }

      return conf;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error.response ? error.response.data : error)
  );

  return instance;
}
