import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(6133) FIXME: 'LoadingComponent' is declared but its value is ne... Remove this comment to see the full error message
import LoadingComponent from 'components/Loaders/LoadingComponent';
import { find as findAirports } from 'redux/modules/airports';
import { find as findAirlines } from 'redux/modules/airlines';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';

const AirportsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-airport-routes' */ './AirportRoutes'),
  {
    // @ts-expect-error ts-migrate(2749) FIXME: 'LoadingComponent' refers to a value, but is being... Remove this comment to see the full error message
    fallback: <LoadingComponent />,
  }
);

// @ts-expect-error ts-migrate(2749) FIXME: 'AirportsLoadable' refers to a value, but is being... Remove this comment to see the full error message
const AirportRoutes = (prop: any) => <AirportsLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();
    const airports = state.airports.result.allAirports;
    const airlines = state.airlines.result.all;

    if (!airports) {
      promises.push(
        dispatch(
          findAirports('allAirports', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }
    if (!airlines) {
      promises.push(
        dispatch(
          findAirlines('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }
    await Promise.all(promises);
  },
})(AirportRoutes);
