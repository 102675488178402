import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const RatesStatesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-RatesStates' */ './RatesStates'),
  {
    fallback: <LoadingComponent />,
  }
);
export default RatesStatesLoadable;
