import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from '../../../components/Loaders/LoadingComponent';

import { find as findReceptacleTypes } from '../../../redux/modules/receptacleTypes';
import { find as findServices } from '../../../redux/modules/services';
import { find as findCountries } from '../../../redux/modules/countries';

const NewReceptacleLoadable = loadable(() => import('./NewReceptacle'), {
  fallback: <LoadingComponent />,
});

const NewReceptacle = (prop: any) => <NewReceptacleLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { dispatch, getState } }: any) => {
    const promises = [];

    const state = getState();

    if (!state.countries.result.allCountries) {
      promises.push(
        dispatch(
          findCountries('allCountries', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    if (!state.services.result.allActiveServices) {
      promises.push(
        dispatch(
          findServices('allActiveServices', {
            query: {
              $paginate: false,
              active: 1,
            },
          })
        ).catch(() => null)
      );
    }

    promises.push(
      dispatch(
        findReceptacleTypes('allReceptacleTypes', {
          query: {
            $paginate: false,
          },
        })
      ).catch(() => null)
    );

    await Promise.all(promises);
  },
})(NewReceptacle);
