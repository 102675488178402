/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable  no-case-declarations */
import { deepmerge } from '@mui/utils';
import type {
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterModel,
  GridPinnedColumns,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import clone from 'clone';
import { useMethod } from 'hooks/useMethod';
import { useEffect, useMemo, useRef } from 'react';
import {
  atom,
  AtomEffect,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useFigbird } from 'store';
import { recoilPersist } from 'store/recoil-persist';
import { ssrCompletedState } from 'store/useSsrComplectedState';
import { Action } from './defaults';

const { persistAtom } = recoilPersist({ key: 'recoil-persist-datagrid' });

const SET = 'we-ship-you/settings/SET';
const SET_DATA_GRID = 'we-ship-you/settings/SET_DATA_GRID';

export interface initialState {
  [x: string]: {
    sortModel?: GridSortModel;
    filterModel?: GridFilterModel;
    columnsOrder?: string[];
    columnVisibilityModel?: GridColumnVisibilityModel;
    page: number;
    pageSize: number;
    density: GridDensity;
    pinnedColumns: GridPinnedColumns;
    columnsSize: {
      [x: string]: number;
    };
  };
}

interface SettingsAction extends Action<any> {
  data: any;
  key: string;
}

const initialState: initialState = {};

const reducer = (
  state: initialState = initialState,
  action: SettingsAction = {} as any
): initialState => {
  switch (action.type) {
    case SET_DATA_GRID:
      const DataGrid = state || {};
      const currentKeyData = clone(DataGrid[action.key] || {});
      // override this key always
      if (action.data.columnVisibilityModel) {
        currentKeyData.columnVisibilityModel = action.data.columnVisibilityModel;
      }
      const newData = deepmerge(currentKeyData, action.data);
      return {
        ...state,
        [action.key]: newData,
      };
    case SET: {
      const { data } = action;
      const result = {
        ...state,
        ...data,
      };
      return result;
    }
    default:
      return state;
  }
};

function setAction(data: any): any {
  return {
    type: SET,
    data,
  };
}

function setDataGridAction(key: string, data: any): any {
  return {
    type: SET_DATA_GRID,
    data,
    key,
  };
}
// const persistConfig = {
//   key: 'datagrid',
//   storage: storage('redux-datagrid'),
// };

export const persistAtomEffect = <T>(param: Parameters<AtomEffect<T>>[0]) => {
  param.getPromise(ssrCompletedState).then(() => {
    persistAtom(param);
  });
};

const datagridAtom = atom({
  key: 'datagrid',
  default: null,
  effects_UNSTABLE: [persistAtomEffect],
});

interface ShoulUpdate {
  toJSON: () => any;
  test: (prev: any, next: any) => boolean;
}

interface AuthParams {
  shouldUpdate?: ShoulUpdate;
}

const defaultSelector = selector({
  key: `defaultSelectorDatagrid`,
  get: ({ get }) => {
    return get(datagridAtom);
  },
});
const map = new Map();
const selectorFam = selectorFamily({
  key: 'datagridSelector',
  get:
    (shouldUpdate: ShoulUpdate) =>
    ({ get }) => {
      const key = `datagridSelectorMap-${JSON.stringify(shouldUpdate.toJSON())}`;
      const prevState = map.get(key);
      const state = get(datagridAtom);
      if (shouldUpdate.test(prevState, state)) {
        map.set(key, state);
        return state;
      }

      if (!prevState) {
        map.set(key, state);
      }
      return prevState || state;
    },
});

export const useDatagrid = ({ shouldUpdate }: AuthParams = {}) => {
  const { feathers } = useFigbird();
  const setState = useSetRecoilState(datagridAtom);

  const dispatch = (action: any) => {
    setState((prev) => {
      return reducer(prev, action);
    });
  };

  const state = useRecoilValue(shouldUpdate ? selectorFam(shouldUpdate) : defaultSelector);

  const mountedRef = useRef<boolean>(false);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getState = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const result = await snapshot.getPromise(datagridAtom);
        return result;
      },
    []
  );

  const set = useMethod(setAction, dispatch, feathers, mountedRef);
  const setDataGrid = useMethod(setDataGridAction, dispatch, feathers, mountedRef);

  return useMemo(() => {
    return {
      set,
      setDataGrid,
      state,
      storageSynced: Boolean(state),
      getState,
    };
  }, [set, setDataGrid, state, getState]);
};
