import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { find as findAccountTypes } from 'redux/modules/accountTypes';
import { find as findBillingFactors } from 'redux/modules/billingFactors';
import { find as findBillingTiers } from 'redux/modules/billingTiers';
import { find as findRoles } from 'redux/modules/userRoles';
import { find as findUsers } from 'redux/modules/users';

const NewUserLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-new-user' */ './NewUser'),
  {
    fallback: <LoadingComponent />,
  }
);

const NewUser = (prop: any) => <NewUserLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();

    const roles = state.userRoles.result.allRoles;

    const accountTypes = state.accountTypes.result.allAccountTypes;
    const billingFactors = state.billingFactors.result.allBillingFactors;
    const billingTiers = state.billingTiers.result.allBillingTiers;

    if (!roles) {
      promises.push(
        dispatch(
          findRoles('allRoles', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (!accountTypes) {
      promises.push(
        dispatch(
          findAccountTypes('allAccountTypes', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (!billingFactors) {
      promises.push(
        dispatch(
          findBillingFactors('allBillingFactors', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (!billingTiers) {
      promises.push(
        dispatch(
          findBillingTiers('allBillingTiers', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (params.id) {
      promises.push(
        dispatch(
          findUsers('editUser', {
            query: {
              $paginate: false,
              uid: params.id,
            },
          })
        ).catch(console.error)
      );
    }
    await Promise.all(promises);
  },
})(NewUser);
