/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import { enqueueSnackbar } from 'redux/modules/notifier';
import SnackDropdown from 'components/Notifier/SnackDropdown';
import Prism from 'prismjs';
import { objectErrorToString } from 'utils/helpers';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-okaidia.css';
import { useEffect, useRef, useState } from 'react';
import {
  Typography,
  Button,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BugReport as BugReportIcon, EmailOutlined as EmailIcon } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ContainerErrors = styled('div', { name: 'StackContainerErrors' })(() => ({
  display: 'flex',
  maxHeight: 200,
  overflow: 'auto',
  flexFlow: 'column',
  flexDirection: 'column',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    '& pre': {
      borderRadius: '4px',
      color: '#fff',
      fontFamily: 'monospace',
      fontSize: 12,
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      maxHeight: '30vh',
      backgroundColor: '#272c34',
      overflow: 'auto',
    },
  },
}));

function DialogDetails({ dialogRef, dialogContent, mailTo }: any) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    dialogRef.current = { handleClickOpen };
    return () => {
      dialogRef.current = null;
    };
  }, []);

  if (dialogRef) {
    dialogRef.current = { handleClickOpen };
  }

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        className={classes.root}
        fullWidth
        maxWidth="md"
        sx={{ zIndex: 9999 }}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-error-dialog-title"
        aria-describedby="scroll-error-dialog-description"
      >
        <DialogTitle id="scroll-error-dialog-title">Error Details</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-error-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
          <Button
            size="small"
            startIcon={<EmailIcon />}
            component="a"
            href={mailTo}
            onClick={handleClose}
          >
            Send To Developer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const snackbarHelper = ({
  error,
  message,
  severity = 'success',
  variant = 'filled',
  persist = undefined,
  extraContent,
  dispatch,
  autoHideDuration = 2000,
  action = null,
  useDefaultContentComponent = false,
  key,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
}: {
  error?: any;
  message?: string;
  severity?: 'success' | 'error' | 'warning' | 'info';
  variant?: 'filled' | 'outlined' | 'standard';
  persist?: boolean;
  extraContent?: any;
  dispatch: any;
  autoHideDuration?: boolean | number;
  action?: any;
  useDefaultContentComponent?: boolean;
  key?: string;
  anchorOrigin?: {
    vertical: 'bottom' | 'top';
    horizontal: 'left' | 'right' | 'center';
  };
}) => {
  const ref = { current: null };
  if ((!severity || severity === 'success') && error) {
    severity = 'error';
  }

  let content: any = null;

  if (error) {
    persist = typeof persist === 'undefined' ? true : persist;
    if (persist) {
      autoHideDuration = false;
    }
    const errorString = objectErrorToString(error);
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const stringifyError = JSON.stringify(error, 0, 3);
    const html = Prism.highlight(stringifyError, Prism.languages.json, 'json');
    const stackBeatify =
      error.stack && Prism.highlight(error.stack, Prism.languages.javascript, 'javsacript');
    const htmlImproved = html && html.replaceAll('\\n', '<br>');

    const dialogContent = (
      <div>
        <pre>
          <code dangerouslySetInnerHTML={{ __html: htmlImproved }} />
        </pre>
        {!!stackBeatify && (
          <pre>
            <code dangerouslySetInnerHTML={{ __html: stackBeatify }} />
          </pre>
        )}
      </div>
    );

    // console.log(stringifyError, 'stringifyError');
    const mailTo = `mailto:dev@bringer.com?subject=${encodeURIComponent(
      'BPS Debug Error'
    )}&body=${encodeURIComponent(stringifyError)}${encodeURIComponent(
      '\n\nStack Trace:\n'
    )}${encodeURIComponent(error.stack)}`;

    content = (
      <Typography gutterBottom component="div">
        <ContainerErrors>{errorString}</ContainerErrors>
        {typeof error !== 'string' && (
          <Box
            sx={{
              marginTop: 2,
              pl: 1,
              pr: 1,
            }}
          >
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs="auto">
                <Button
                  sx={{
                    fontSize: 11,
                  }}
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    console.log(ref, 'ref');
                    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                    ref.current.handleClickOpen();
                  }}
                  startIcon={<BugReportIcon />}
                >
                  Show More Details
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  sx={{
                    fontSize: 11,
                  }}
                  startIcon={<EmailIcon />}
                  size="small"
                  variant="outlined"
                  component="a"
                  href={mailTo}
                >
                  Send To Developers
                </Button>
              </Grid>
            </Grid>
            <DialogDetails dialogRef={ref} dialogContent={dialogContent} mailTo={mailTo} />
          </Box>
        )}
      </Typography>
    );
  } else {
    persist = typeof persist === 'undefined' ? false : persist;
    content = extraContent;
  }

  const extraProps = useDefaultContentComponent
    ? {}
    : {
        content: (_key: any, _message: any) => (
          <SnackDropdown
            id={_key}
            message={_message}
            severity={severity}
            variant={variant}
            content={content}
            action={action}
          />
        ),
      };

  dispatch(
    enqueueSnackbar({
      message,
      options: {
        action,
        key,
        persist,
        autoHideDuration,
        severity,
        variant,
        anchorOrigin,
        ...extraProps,
      },
    })
  );
};

export default snackbarHelper;
