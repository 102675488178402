/* eslint-disable max-len */
import loadable from '@loadable/component';
import { find as findWeights } from 'redux/modules/weights';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ServicesAreasWeightsCostsLoadable = loadable(
  () =>
    import(/* webpackChunkName: 'admin-ServicesAreasWeightsCosts' */ './ServicesAreasWeightsCosts'),
  {
    fallback: <LoadingComponent />,
  }
);

const ServicesAreasWeightsCosts = (prop: any) => <ServicesAreasWeightsCostsLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();

    const weights = state.weights.result && state.weights.result.all;

    if (!weights) {
      promises.push(
        dispatch(
          findWeights('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    await Promise.all(promises);
  },
})(ServicesAreasWeightsCosts);
