import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ServiceCountriesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-service-restrictions' */ './AccountServiceRestrictions'),
  {
    fallback: <LoadingComponent />,
  }
);

export default ServiceCountriesLoadable;
