import loadable from '@loadable/component';
import { find as findAccounts } from 'redux/modules/accounts';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
// @ts-expect-error ts-migrate(6133) FIXME: 'LoadingComponent' is declared but its value is ne... Remove this comment to see the full error message
import LoadingComponent from 'components/Loaders/LoadingComponent';

const AssignAirwaybillsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-AssignAirwaybills' */ './AssignAirwaybills'),
  {
    // @ts-expect-error ts-migrate(2749) FIXME: 'LoadingComponent' refers to a value, but is being... Remove this comment to see the full error message
    fallback: <LoadingComponent />,
  }
);

// @ts-expect-error ts-migrate(2749) FIXME: 'AssignAirwaybillsLoadable' refers to a value, but... Remove this comment to see the full error message
const AssignAirwaybills = (prop: any) => <AssignAirwaybillsLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();
    const countries = state.retailer.result.countries && state.retailer.result.countries.data;

    if (!countries) {
      promises.push(
        dispatch(
          findAccounts('wholesallerAccounts', {
            query: {
              $paginate: false,
              'account_role.name': 'wholesaler',
            },
          })
        ).catch(console.error)
      );
    }
    await Promise.all(promises);
  },
})(AssignAirwaybills);
