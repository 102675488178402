/* eslint-disable max-len */
import loadable from '@loadable/component';
import { find as findServices } from 'redux/modules/externalServices';
import { find as findServiceRuleTypes } from 'redux/modules/externalServiceRuleTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const RatesServicesRulesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-RatesServicesRules' */ './RatesServicesRules'),
  {
    fallback: <LoadingComponent />,
  }
);

const RatesServicesRules = (prop: any) => <RatesServicesRulesLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();

    const ratesServices = state.externalServices.result.allExternalServices;
    const ratesServicesRulesTypes =
      state.externalServiceRuleTypes.result.allExternalServiceRuleTypes;

    if (!ratesServices) {
      promises.push(
        dispatch(
          findServices('allExternalServices', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    if (!ratesServicesRulesTypes) {
      promises.push(
        dispatch(
          findServiceRuleTypes('allExternalServiceRuleTypes', {
            query: {
              $paginate: false,
            },
          })
        ).catch(console.error)
      );
    }

    await Promise.all(promises);
  },
})(RatesServicesRules);
