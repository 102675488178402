import loadable from '@loadable/component';
import { find as findDispatches } from 'redux/modules/dispatches';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
// @ts-expect-error ts-migrate(6133) FIXME: 'LoadingComponent' is declared but its value is ne... Remove this comment to see the full error message
import LoadingComponent from 'components/Loaders/LoadingComponent';

const EditDispatchLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-EditDispatch' */ './EditDispatch'),
  {
    // @ts-expect-error ts-migrate(2749) FIXME: 'LoadingComponent' refers to a value, but is being... Remove this comment to see the full error message
    fallback: <LoadingComponent />,
  }
);

// @ts-expect-error ts-migrate(2749) FIXME: 'EditDispatchLoadable' refers to a value, but is b... Remove this comment to see the full error message
const EditDispatch = (prop: any) => <EditDispatchLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises = [];

    promises.push(
      dispatch(
        findDispatches('dispatchEdit', {
          query: {
            uid: params.id,
            $paginate: false,
            include: ['Account', 'Label', 'Unitizer', 'UnitizerItems', 'AirwayBill'],
          },
        })
      ).catch(console.error)
    );

    await Promise.all(promises);
  },
})(EditDispatch);
