import { Component } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import reducerAuthManagement, {
  verifySignUp,
  verifyChanges,
} from 'redux/modules/account/authManagement';
import loadable from '@loadable/component';

const Login = loadable(() => import(/* webpackChunkName: 'login' */ './Login'));

@provideHooks({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'params' implicitly has an 'any' t... Remove this comment to see the full error message
  fetch: async ({ params, store: { dispatch, inject, asyncReducers, getState } }) => {
    const promises = [];

    if (!asyncReducers.authManagement) {
      inject({ authManagement: reducerAuthManagement });
    }

    const state = getState();

    switch (params.type) {
      case 'verify':
        if (
          params.slug &&
          !state.authManagement.verifySignUpResult &&
          !state.authManagement.verifySignUpResultError
        ) {
          promises.push(dispatch(verifySignUp(params.slug)).catch(() => null));
        }
        break;
      case 'verifyChanges':
        if (
          params.slug &&
          !state.authManagement.verifyChangesResult &&
          !state.authManagement.verifyChangesResultError
        ) {
          promises.push(dispatch(verifyChanges(params.slug)).catch(() => null));
        }
        break;
      default:
        break;
    }
    await Promise.all(promises);
  },
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'inject' implicitly has an 'any' t... Remove this comment to see the full error message
  defer: async ({ store: { inject, asyncReducers } }) => {
    if (!asyncReducers.authManagement) {
      inject({ authManagement: reducerAuthManagement });
    }
  },
})
export default class LoginContainer extends Component {
  render() {
    return <Login {...this.props} />;
  }
}
