import localforageClient from 'localforage';

let localforage;

// @ts-expect-error hide this
if (!__SERVER__) {
  localforageClient.config({
    driver: localforageClient.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name: 'weshipyou',
    version: 1.0,
    size: 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName: 'rootState', // Should be alphanumeric, with underscores.
    description: 'RootState',
  });
  localforage = localforageClient;
}

export { localforage };
