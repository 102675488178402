/* eslint-disable max-len */
import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ServicesLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-ServiceServicePickup' */ './ServiceServicePickup'),
  {
    fallback: <LoadingComponent />,
  }
);

const ServiceServicePickup = (prop: any) => <ServicesLoadable {...prop} />;

export default ServiceServicePickup;
