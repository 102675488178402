import * as React from 'react';
import clsx from 'clsx';
import {
  LinkProps as ReactRouterLinkProps,
  Link as ReactRouterLink,
  useLocation,
} from 'react-router-dom';
import { Link as MuiLink, styled, LinkProps as MuiLinkProps } from '@mui/material';

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({});

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<ReactRouterLinkProps, 'href' | 'as'> {
  to: ReactRouterLinkProps['to'];
  linkAs?: string;
  href?: ReactRouterLinkProps['to'];
}

const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(
  function NextLinkComposed(props, ref) {
    // eslint-disable-next-line
    const { to, linkAs, href, replace, /* scroll, shallow, prefetch, locale, */ ...other } = props;

    return (
      <ReactRouterLink
        to={to}
        // prefetch={prefetch}
        // as={linkAs}
        replace={replace}
        // scroll={scroll}
        // shallow={shallow}
        // passHref
        // locale={locale}
      >
        <Anchor ref={ref} {...other} />
      </ReactRouterLink>
    );
  }
);

export type LinkProps = {
  activeClassName?: string;
  as?: ReactRouterLinkProps['as'];
  href: ReactRouterLinkProps['href'];
  noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const {
    activeClassName = 'active',
    as: linkAsProp,
    className: classNameProps,
    href,
    noLinkStyle,
    role, // Link don't have roles.
    ...other
  } = props;

  const router = useLocation();
  const pathname = typeof href === 'string' ? href : href?.pathname || '';
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });

  const isExternal =
    typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

  if (isExternal) {
    if (noLinkStyle) {
      return <Anchor className={className} href={href} ref={ref} {...other} />;
    }

    return <MuiLink className={className} href={href} ref={ref} {...other} />;
  }

  if (noLinkStyle) {
    return <NextLinkComposed className={className} ref={ref} to={href || ''} {...other} />;
  }

  let linkAs = linkAsProp || (href as string);
  if (typeof href === 'string' && href.indexOf('/') === 0 && href.indexOf('/blog') !== 0) {
    linkAs = `/${linkAs}`;
  }

  return (
    <MuiLink
      component={NextLinkComposed}
      linkAs={linkAs}
      className={className}
      ref={ref}
      to={href || ''}
      {...other}
    />
  );
});

export default Link;
