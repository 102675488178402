import * as React from 'react';
import { SxProps } from '@mui/system';
import testSvg from 'components/Img/care.svg';
import MercadoLibre from 'components/Img/mercado-livre.png';
import AmazonDark from 'components/Img/amazon-dark.svg';
import AmazonLight from 'components/Img/amazon-light.svg';
import NasaLight from 'components/Img/nasa-light.svg';
import NasaDark from 'components/Img/nasa-dark.svg';
import { useTheme, Theme, Tooltip, styled } from '@mui/material';

const companies = {
  'mercado-libre-light': MercadoLibre,
  'mercado-libre-dark': MercadoLibre,
  'amazon-light': AmazonLight,
  'amazon-dark': AmazonDark,
  'nasa-light': NasaLight,
  'nasa-dark': NasaDark,
};

export type IconImageProps = {
  name:
    | 'product-ups'
    | 'product-dhl'
    | 'product-api'
    | 'product-documentation'
    | 'product-correios';
  sx?: SxProps<Theme>;
  ref?: React.Ref<HTMLImageElement>;
  title?: string;
} & Omit<JSX.IntrinsicElements['img'], 'ref'>;

const Img = styled('img')({ display: 'inline-block', verticalAlign: 'bottom' });

export default function IconImage({ name, title, ...props }: IconImageProps) {
  const theme = useTheme();
  let width = '';
  let height = '';
  // let category = '';
  let mode = `-${theme.palette.mode}`;
  let image = testSvg;
  if (name.startsWith('product-')) {
    width = '36';
    height = '36';
  }
  if (name.startsWith('block-')) {
    // category = 'pricing/';
    mode = '';
    width = '13';
    height = '15';
  }
  if (['yes', 'no', 'time'].indexOf(name) !== -1) {
    // category = 'pricing/';
    width = '18';
    height = '18';
  }
  if (['mercado-libre', 'amazon', 'nasa', 'southwest', 'boeing'].indexOf(name) !== -1) {
    // category = 'companies/';
    image = companies[name + mode];
  }
  const element = (
    <Img src={image} alt="" loading="lazy" width={width} height={height} {...props} />
  );
  if (!title) {
    return element;
  }
  return <Tooltip title={title}>{element}</Tooltip>;
}
