import * as React from 'react';
import BringerLogo from 'components/Img/logo-1.png';
import BringerLogoWhite from 'components/Img/bpswhite.png';
import Link from 'components/Link/Link';
import { styled, useTheme, useMediaQuery, Box, Container, alpha } from '@mui/material';
import { useSettings } from 'redux/modules/settings';
import ThemeModeToggle from './ThemeModeToggle';
import HeaderNavDropdown from './HeaderNavDropdown';
import HeaderNavBar from './HeaderNavBar';

const ROUTES = {};

const Header = styled('header')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  transition: theme.transitions.create('top'),
  zIndex: theme.zIndex.appBar,
  backdropFilter: 'blur(20px)',
  boxShadow: `inset 0px -1px 1px ${
    theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : theme.palette.grey[100]
  }`,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? alpha(theme.palette.primaryDark[900], 0.72)
      : 'rgba(255,255,255,0.72)',
}));

const AppHeader = ({ changeTheme }: any) => {
  const [mode, setMode] = React.useState<string | null>(null);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const preferredMode = prefersDarkMode ? 'dark' : 'light';
  const theme = useTheme();

  const { state: settings } = useSettings();
  const { uiTheme } = settings;

  React.useEffect(() => {
    setMode(uiTheme.paletteMode || 'system');
  }, [setMode]);

  const handleChangeThemeMode = (checked: boolean) => {
    let paletteMode = 'system';
    paletteMode = checked ? 'dark' : 'light';
    if (paletteMode === null) {
      return;
    }

    setMode(paletteMode);

    changeTheme({ paletteMode });
  };
  return (
    <Header>
      <Container sx={{ display: 'flex', alignItems: 'center', minHeight: 64 }}>
        <Box
          component={Link}
          href={ROUTES.home}
          aria-label="Go to homepage"
          sx={{ lineHeight: 0, mr: 2 }}
        >
          <img
            src={theme.palette.mode === 'light' ? BringerLogo : BringerLogoWhite}
            alt="bringer parcel"
            width={80}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'initial' } }}>
          <HeaderNavBar />
        </Box>
        <Box sx={{ ml: 'auto' }} />
        <Box sx={{ mr: { xs: 1, md: 2 } }}>{/* <DeferredAppSearch /> */}</Box>
        <Box sx={{ display: { md: 'none' }, mr: 1 }}>
          <HeaderNavDropdown />
        </Box>
        {mode !== null ? (
          <ThemeModeToggle
            checked={mode === 'system' ? prefersDarkMode : mode === 'dark'}
            onChange={handleChangeThemeMode}
          />
        ) : null}
      </Container>
    </Header>
  );
};
export default AppHeader;
