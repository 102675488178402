import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const TariffsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-Tariffs' */ './Tariffs'),
  {
    fallback: <LoadingComponent />,
  }
);

const Tariffs = (prop: any) => <TariffsLoadable {...prop} />;

export default Tariffs;
