import pluralize from 'pluralize';
import capitalize from 'lodash.capitalize';
import lowercase from 'lodash.lowercase';
import ErrorIcon from '@mui/icons-material/Error';
import * as styles from '@mui/material';
import cache from './globalCache';

const { styled } = styles;

const DivStyled = styled('div', { name: 'DivErrorStacks' })(({ theme }) => ({
  display: 'flex',
  ...theme.typography.body2,
  color: theme.palette.info.main,
  flexDirection: 'column',
  flexFlow: 'column',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: 4,
  paddingTop: 4,
  '&>div': {
    flexDirection: 'row',
    '&+div': {
      flexDirection: 'row',
    },
  },
  '&:last-child': {
    borderBottom: 0,
    flexDirection: 'column',
    [`& > svg`]: {
      color: theme.palette.error.light,
      marginRight: theme.spacing(1),
      '&+span': {
        marginTop: -22,
        marginLeft: 32,
      },
    },
  },
  // '&:not(:last-child)': {
  //   flexDirection: 'row',
  // },
  [`& svg`]: {
    color: theme.palette.error.light,
    marginRight: theme.spacing(1),
  },
}));

const _objectErrorToString = (errorData: any, headerTitle = '') => {
  if (!errorData) {
    return (
      <>
        <ErrorIcon />
        {headerTitle}
      </>
    );
  }

  if (typeof errorData === 'string') {
    return (
      <DivStyled>
        <ErrorIcon /> {headerTitle + errorData}
      </DivStyled>
    );
  }

  if (Array.isArray(errorData)) {
    return errorData.map((error) => <DivStyled>{_objectErrorToString(error)}</DivStyled>);
  }
  const { message, data, errors } = errorData || {};
  if (message) {
    if (data && Object.keys(data).length) {
      return <DivStyled>{_objectErrorToString(data, `${message}:`)}</DivStyled>;
    }

    if (errors && Object.keys(errors).length) {
      return <DivStyled>{_objectErrorToString(errors, `${message}:`)}</DivStyled>;
    }
    return (
      <DivStyled>
        <ErrorIcon /> <span>{headerTitle + message}</span>
      </DivStyled>
    );
  }
  if (errors) {
    if (Array.isArray(errors)) {
      return errors.map((error) => <DivStyled>{_objectErrorToString(error.message)}</DivStyled>);
    }
    if (Object.keys(errors).length) {
      return <DivStyled>{_objectErrorToString(errors, `${message}:`)}</DivStyled>;
    }
  }

  const keys = Object.keys(errorData);
  if (!keys.length) {
    return (
      <>
        <ErrorIcon />
        {headerTitle}
      </>
    );
  }
  const string = keys
    .map((key) => {
      if (key === 'raw') {
        return;
      }
      const titleError = `${headerTitle} ${pluralize.singular(capitalize(lowercase(key)))}`;
      let errorString = errorData[key];
      if (typeof errorData[key] === 'object') {
        errorString = _objectErrorToString(errorData[key], `${titleError} `);
      } else {
        errorString = (
          <>
            <ErrorIcon />
            <span>
              {titleError}: {errorString}
            </span>
          </>
        );
      }
      return errorString && <DivStyled>{errorString}</DivStyled>;
    })
    .filter((i) => i);
  return string;
};

const objectErrorToString = (errorData: any) => {
  if (typeof errorData === 'string') {
    return errorData;
  }
  try {
    if (Array.isArray(errorData)) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(errorData: any, headerTitle?: s... Remove this comment to see the full error message
      return errorData.map(_objectErrorToString).join('\n');
    }
    return _objectErrorToString(errorData);
  } catch (error) {
    console.error(error);
    return 'Error parsing errors to string';
  }
};

const sleep = (ms: any) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const r = (w: any, pos: any, dot: any) => {
  return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`);
};

/** ****************************************************************
 * Converts e-Notation Numbers to Plain Numbers
 ******************************************************************
 * @function eToNumber(number)
 * @version  1.00
 * @param   {e nottation Number} valid Number in exponent format.
 *          pass number as a string for very large 'e' numbers or with large fractions
 *          (none 'e' number returned as is).
 * @return  {string}  a decimal number string.
 * @author  Mohsen Alyafei
 *
 **************************************************************** */
const eToNumber = (num: any) => {
  let sign = '';
  // eslint-disable-next-line no-unused-expressions
  (num += '').charAt(0) === '-' && ((num = num.substring(1)), (sign = '-'));
  const arr = num.split(/[e]/gi);
  if (arr.length < 2) return sign + num;
  const dot = (0.1).toLocaleString().substr(1, 1);
  let n = arr[0];
  const exp = +arr[1];
  let w = (n = n.replace(/^0+/, '')).replace(dot, '');
  const pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp;
  const L = pos - w.length;
  // eslint-disable-next-line no-undef
  const s = `${BigInt(w)}`;
  w =
    // eslint-disable-next-line no-nested-ternary
    exp >= 0
      ? L >= 0
        ? s + '0'.repeat(L)
        : r(w, pos, dot)
      : pos <= 0
      ? `0${dot}${'0'.repeat(Math.abs(pos))}${s}`
      : r(w, pos, dot);
  if (!+w) w = 0;
  return sign + w;
};

const truncate = (str: any, maxLength: any) => {
  if (!str) {
    return null;
  }
  str = String(str);
  str = str.trim();
  if (!str) {
    return null;
  }
  if (str.length > maxLength) {
    return `${str.slice(0, maxLength - 4)}...`;
  }
  return str;
};

const multipliersUnits = {
  inches: 2.54,
  cm: 1,
};

/**
 *
 * @param {('inches'|'cm')} measurementUnit
 * @param {number} value
 * @param {('inches'|'cm')} from
 */
const convertDimensions = (measurementUnit: 'inches' | 'cm', value: any, from = 'cm') => {
  if (!from) {
    return value;
  }
  if (!measurementUnit) {
    return value;
  }

  if (!value) {
    return value;
  }

  value = Number(value);

  if (Number.isNaN(value)) {
    value = 0;
  }

  if (from !== 'cm') {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    value *= multipliersUnits[from];
  }

  return (value / multipliersUnits[measurementUnit]).toFixed(2);
};

const multipliersUnitsWeights = {
  ounce: 28.34952,
  gram: 1,
  pound: 453.592,
  kilogram: 1000,
};

/**
 *
 * @param {('ounce'|'gram'|'pound'|'kilogram')} unit
 * @param {number} value
 * @param {('ounce'|'gram'|'pound'|'kilogram')} from
 */
const convertWeights = (
  unit: 'ounce' | 'gram' | 'pound' | 'kilogram',
  value: any,
  from: 'ounce' | 'gram' | 'pound' | 'kilogram' = 'gram'
) => {
  if (!from) {
    return value;
  }

  if (!unit) {
    return value;
  }

  if (!value) {
    return value;
  }

  value = Number(value);

  if (Number.isNaN(value)) {
    value = 0;
  }

  if (from !== 'gram') {
    value *= multipliersUnitsWeights[from];
  }

  return (value / multipliersUnitsWeights[unit]).toFixed(2);
};

const zipsOnlyNumbersByCountry = {
  CL: true,
  US: true,
  MX: true,
};

const normalizeZip =
  ({ country }: any) =>
  (value: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (value && zipsOnlyNumbersByCountry[country]) {
      const onlyNums = value.replace(/[^\d]/g, '');
      return onlyNums;
    }
    if (value && country === 'BR') {
      const onlyNums = value.replace(/[^\d]/g, '');
      const stringValue = String(onlyNums);
      if (stringValue.length > 5) {
        return `${stringValue.slice(0, 5)}-${stringValue.slice(5, 8)}`;
      }
    }

    return value;
  };

const requester = {
  // one second
  timeToWaitNextRequest: 100,
  lastRequest: new Date(2000, 0, 1),
  intervals: [],
  clearIntervals() {
    this.intervals.forEach((interval) => {
      if (interval) {
        clearInterval(interval);
      }
    });
    this.intervals = [];
  },
  async makeRequest({ keyCache: key, action }: any) {
    const value = cache.get(key);
    if (value !== undefined && value !== 'pending') {
      return value;
    }

    if (value === 'pending') {
      const resolvedResult = await new Promise((resolve) => {
        const intervalChecker = setInterval(() => {
          const valueResolved = cache.get(key);
          if (valueResolved !== 'pending') {
            this.intervals = this.intervals.filter((interval) => interval !== intervalChecker);
            clearInterval(intervalChecker);
            resolve(valueResolved);
          }
        }, 100);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Timer' is not assignable to para... Remove this comment to see the full error message
        this.intervals.push(intervalChecker);
      });
      return resolvedResult;
    }

    cache.set(key, 'pending');
    // Check when last request was made
    if (this.timeToWaitNextRequest) {
      const timeSinceLast = new Date().getTime() - this.lastRequest.getTime();
      this.lastRequest = new Date();
      if (timeSinceLast < this.timeToWaitNextRequest) {
        this.lastRequest = new Date(
          this.lastRequest.getTime() + (this.timeToWaitNextRequest - timeSinceLast)
        );
        await sleep(this.timeToWaitNextRequest);
      }
    }

    const response = await action().catch((error: any) => {
      // do not cached errorded request
      cache.set(key, undefined);
      throw error;
    });
    cache.set(key, response);
    return response;
  },
};

// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
const checkDeepHasErrors = (object: any) => {
  if (!object) {
    return false;
  }
  if (Array.isArray(object)) {
    return object.some((item) => checkDeepHasErrors(item));
  }
  const keys = Object.keys(object);
  if (!keys.length) {
    return false;
  }
  return keys.some((key) => {
    if (typeof object[key] === 'string') {
      return true;
    }
    if (typeof object[key] === 'object') {
      // @ts-expect-error ts-migrate(7022) FIXME: 'result' implicitly has type 'any' because it does... Remove this comment to see the full error message
      // eslint-disable-next-line no-unused-vars
      const result = checkDeepHasErrors(object[key]);
      return result;
    }
    return false;
  });
};

const currencyExchange = (from: any, to: any, value: any, markets: any) => {
  const marketsBySymbol = markets.reduce((acc: any, curr: any) => {
    acc[curr.symbol] = curr;
    return acc;
  }, {});
  // console.log(marketsBySymbol, 'marketsBySymbol');
  // console.log(from, 'from');
  // console.log(to, 'to');
  let currencyMarketModel = marketsBySymbol[`${from}/${to}`];
  if (currencyMarketModel) {
    const price = value * currencyMarketModel.price;
    return price;
  }
  currencyMarketModel = marketsBySymbol[`${to}/${from}`];
  const price = value / currencyMarketModel.price;
  return price;
};

const getExchangeRate = (from: any, to: any, markets: any) => {
  const marketsBySymbol = markets.reduce((acc: any, curr: any) => {
    acc[curr.symbol] = curr;
    return acc;
  }, {});
  let currencyMarketModel = marketsBySymbol[`${from}/${to}`];
  if (currencyMarketModel) {
    return currencyMarketModel.price;
  }
  currencyMarketModel = marketsBySymbol[`${to}/${from}`];
  return currencyMarketModel.price;
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE11
function countryToFlag(isoCode: any) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char: any) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

export {
  convertDimensions,
  convertWeights,
  normalizeZip,
  sleep,
  requester,
  truncate,
  checkDeepHasErrors,
  eToNumber,
  currencyExchange,
  countryToFlag,
  objectErrorToString,
  getExchangeRate,
};
