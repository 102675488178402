import loadable from '@loadable/component';
import { find as findPickup } from 'redux/modules/pickups';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const LabelPickupLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-LabelPickup' */ './LabelPickup'),
  {
    fallback: <LoadingComponent />,
  }
);

const LabelPickup = (prop: any) => <LabelPickupLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ params, store: { dispatch } }: any) => {
    const promises = [];

    promises.push(
      dispatch(
        findPickup(`pickupId-${params.id}`, {
          query: {
            $paginate: false,
            uid: params.id,
            include: ['Account', 'Label', 'ParcelExtra', 'Service'],
          },
        })
      ).catch(console.error)
    );
    await Promise.all(promises);
  },
})(LabelPickup);
