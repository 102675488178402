import * as React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import { useSelector } from 'react-redux';

import {
  styled,
  useMediaQuery,
  Drawer,
  Box,
  Typography,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  useTheme,
} from '@mui/material';

const Heading = styled(Typography)({
  margin: '20px 0 10px',
});

const IconToggleButton = styled(ToggleButton)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '& > *': {
    marginRight: '8px',
  },
});

function AppSettingsDrawer(props) {
  const { onClose, open = false, changeTheme, ...other } = props;

  const { uiTheme } = useSelector((state: any) => ({
    uiTheme: state.settings.uiTheme,
  }));

  const theme = useTheme();
  const [mode, setMode] = React.useState(null);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const preferredMode = prefersDarkMode ? 'dark' : 'light';

  React.useEffect(() => {
    let initialMode = uiTheme.paletteMode || 'system';
    if (typeof initialMode === 'object') {
      initialMode = 'system';
    }
    setMode(initialMode);
  }, [setMode, preferredMode]);

  const handleChangeThemeMode = (event, paletteMode) => {
    if (paletteMode === null) {
      return;
    }

    setMode(paletteMode);

    const data = {
      ...uiTheme,
    };
    data.paletteMode = paletteMode;
    changeTheme({ paletteMode });
  };

  const handleChangeDirection = (event, direction) => {
    if (direction === null) {
      direction = theme.direction;
    }

    changeTheme({ direction, paletteMode: mode });
  };

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      PaperProps={{ elevation: 0, sx: { width: 360 } }}
      {...other}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <Typography variant="h6">Settings</Typography>
        <IconButton color="inherit" onClick={onClose} edge="end">
          <CloseIcon color="primary" fontSize="small" />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ pl: 2, pr: 2 }}>
        <Heading gutterBottom id="settings-mode">
          Mode
        </Heading>
        <ToggleButtonGroup
          exclusive
          value={mode}
          color="primary"
          onChange={handleChangeThemeMode}
          aria-labelledby="settings-mode"
          fullWidth
        >
          <IconToggleButton
            value="light"
            aria-label="Light"
            data-ga-event-category="settings"
            data-ga-event-action="light"
          >
            <LightModeIcon fontSize="small" />
            Lght
          </IconToggleButton>
          <IconToggleButton
            value="system"
            aria-label="System"
            data-ga-event-category="settings"
            data-ga-event-action="system"
          >
            <SettingsBrightnessIcon fontSize="small" />
            System
          </IconToggleButton>
          <IconToggleButton
            value="dark"
            aria-label="Dark"
            data-ga-event-category="settings"
            data-ga-event-action="dark"
          >
            <DarkModeOutlinedIcon fontSize="small" />
            Dark
          </IconToggleButton>
        </ToggleButtonGroup>
        <Heading gutterBottom id="settings-direction">
          Direction
        </Heading>
        <ToggleButtonGroup
          exclusive
          value={theme.direction}
          onChange={handleChangeDirection}
          aria-labelledby="settings-direction"
          color="primary"
          fullWidth
        >
          <IconToggleButton
            value="ltr"
            aria-label="LTR"
            data-ga-event-category="settings"
            data-ga-event-action="ltr"
          >
            <FormatTextdirectionLToRIcon />
            Left to right
          </IconToggleButton>
          <IconToggleButton
            value="rtl"
            aria-label="RTL"
            data-ga-event-category="settings"
            data-ga-event-action="rtl"
          >
            <FormatTextdirectionRToLIcon />
            Right to left
          </IconToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Drawer>
  );
}

AppSettingsDrawer.propTypes = {
  changeTheme: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default AppSettingsDrawer;
