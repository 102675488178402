import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const AccountsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-DropoffStations' */ './DropoffStations'),
  {
    fallback: <LoadingComponent />,
  }
);

const Accounts = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <AccountsLoadable {...prop} />
);

export default Accounts;
