import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const AirwayBillLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-AirwayBill' */ './AirwayBill'),
  {
    fallback: <LoadingComponent />,
  }
);

const AirwayBill = (prop: any) => <AirwayBillLoadable {...prop} />;

export default AirwayBill;
