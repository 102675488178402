import {
  DataObject as DataObjectIcon,
  Done as DoneIcon,
  Print as PrintIcon,
} from '@mui/icons-material';
import {
  Divider,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  type ButtonProps,
} from '@mui/material';
import {
  useGridApiContext,
  type GridCsvExportOptions,
  type GridPrintExportOptions,
} from '@mui/x-data-grid-premium';
import { GrDocumentCsv } from '@react-icons/all-files/gr/GrDocumentCsv';
import { SiMicrosoftexcel } from '@react-icons/all-files/si/SiMicrosoftexcel';
import { formatDistanceToNowStrict } from 'date-fns';
import * as React from 'react';
import { useFetcher } from 'store/useFetcher';
import { GridToolbarExportContainer } from './GridToolbarExportContainer';
import { exportDataAsJson, JsonExportMenuItem } from './JsonExportMenuItem';

export interface GridExportDisplayOptions {
  /**
   * If `true`, this export option will be removed from the GridToolbarExport menu.
   * @default false
   */
  disableToolbarButton?: boolean;
}

export interface GridExportMenuItemProps<Options> {
  hideMenu?: () => void;
  options?: Options & GridExportDisplayOptions;
}

export interface GridToolbarExportProps extends ButtonProps {
  csvOptions?: GridCsvExportOptions & GridExportDisplayOptions;
  printOptions?: GridPrintExportOptions & GridExportDisplayOptions;
  [key: string]: any;
}
export type GridCsvExportMenuItemProps = GridExportMenuItemProps<GridCsvExportOptions>;

export type GridPrintExportMenuItemProps = GridExportMenuItemProps<GridPrintExportOptions>;

export type GridExcelExportMenuItemProps = GridExportMenuItemProps<GridExcelExportOptions>;

export const GridExcelExportMenuItem = (props: GridExcelExportMenuItemProps) => {
  const apiRef = useGridApiContext();
  const { hideMenu, options, ...other } = props;

  return (
    <MenuItem
      onClick={() => {
        apiRef.current.exportDataAsExcel(options);
        hideMenu?.();
      }}
      {...other}
    >
      <ListItemIcon>
        <SiMicrosoftexcel fontSize="small" />
      </ListItemIcon>
      <ListItemText>{apiRef.current.getLocaleText('toolbarExportExcel')}</ListItemText>
    </MenuItem>
  );
};

export function GridCsvExportMenuItem(props: GridCsvExportMenuItemProps) {
  const apiRef = useGridApiContext();
  const { hideMenu, options, ...other } = props;

  return (
    <MenuItem
      onClick={() => {
        apiRef.current.exportDataAsCsv(options);
        hideMenu?.();
      }}
      {...other}
    >
      <ListItemIcon>
        <GrDocumentCsv fontSize="small" />
      </ListItemIcon>
      <ListItemText>{apiRef.current.getLocaleText('toolbarExportCSV')}</ListItemText>
    </MenuItem>
  );
}

export function GridPrintExportMenuItem(props: GridPrintExportMenuItemProps) {
  const apiRef = useGridApiContext();
  const { hideMenu, options, ...other } = props;

  return (
    <MenuItem
      onClick={() => {
        apiRef.current.exportDataAsPrint(options);
        hideMenu?.();
      }}
      {...other}
    >
      <ListItemIcon>
        <PrintIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{apiRef.current.getLocaleText('toolbarExportPrint')}</ListItemText>
    </MenuItem>
  );
}

const ExportAllCSV = ({
  fetcher,
  hideMenu,
  options,
  progress,
  activeDownloadsRef,
}: GridExportMenuItemProps<any> & { activeDownloadsRef: { current: string[] } }) => {
  const apiRef = useGridApiContext();
  const [loading, setLoading] = React.useState(() =>
    activeDownloadsRef.current.some((item) => item === 'ExportAllCSV')
  );
  const getAllRows = async () => {
    setLoading(true);
    activeDownloadsRef.current.push('ExportAllCSV');
    const curretnRowIds = apiRef.current.getAllRowIds();

    // update data to set all the rows
    const allRows = await fetcher();
    apiRef.current.updateRows(allRows.data);
    await apiRef.current.exportDataAsCsv(options); // notice that file generation is an async function

    // Delete rows added for the file generation
    const idsToDelete = allRows.data
      .map((row) => row.id)
      .filter((id) => !curretnRowIds.includes(id));
    apiRef.current.updateRows(idsToDelete.map((rowId) => ({ id: rowId, _action: 'delete' })));
    activeDownloadsRef.current = activeDownloadsRef.current.filter(
      (item) => item !== 'ExportAllCSV'
    );
    setTimeout(() => {
      setLoading(false);
      if (!activeDownloadsRef.current.length) {
        hideMenu?.();
      }
    }, 5000);
  };
  return (
    <MenuItem onClick={getAllRows} disabled={loading}>
      <ListItemIcon>
        <GrDocumentCsv fontSize="small" />
      </ListItemIcon>
      <ListItemText
        sx={{
          position: 'relative',
        }}
      >
        {loading && (
          <Typography
            sx={{
              position: 'absolute',
              top: -9,
              left: 0,
              right: 0,
              pr: 2,
              textAlign: 'left',
            }}
            fontWeight="bolder"
            variant="caption"
          >
            ETA:{' '}
            {formatDistanceToNowStrict(new Date(new Date().getTime() + progress.etaInMs), {
              addSuffix: true,
            })}
          </Typography>
        )}
        Export all Pages as CSV
      </ListItemText>
      <Typography
        variant="body2"
        sx={{
          ml: 2,
          width: 20,
          textAlign: 'right',
          ...(progress.percent === 100 && { lineHeight: 0 }),
        }}
        color="text.secondary"
      >
        {loading && progress.percent !== 100 ? `${progress.percent}%` : ''}
        {loading && progress.percent === 100 && <DoneIcon fontSize="small" color="success" />}
      </Typography>
      {loading && (
        <LinearProgress
          color={progress.percent === 100 ? 'success' : 'primary'}
          sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
          variant="determinate"
          value={progress.percent}
        />
      )}
    </MenuItem>
  );
};
const ExportAllExcel = ({
  fetcher,
  hideMenu,
  options,
  progress,
  activeDownloadsRef,
}: GridExportMenuItemProps<any> & { activeDownloadsRef: { current: string[] } }) => {
  const apiRef = useGridApiContext();
  const [loading, setLoading] = React.useState(() =>
    activeDownloadsRef.current.some((item) => item === 'ExportAllExcel')
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getAllRows = async () => {
    setLoading(true);
    activeDownloadsRef.current.push('ExportAllExcel');
    const curretnRowIds = apiRef.current.getAllRowIds();

    // update data to set all the rows
    const allRows = await fetcher();
    apiRef.current.updateRows(allRows.data);
    await apiRef.current.exportDataAsExcel(options); // notice that file generation is an async function

    // Delete rows added for the file generation
    const idsToDelete = allRows.data
      .map((row) => row.id)
      .filter((id) => !curretnRowIds.includes(id));
    apiRef.current.updateRows(idsToDelete.map((rowId) => ({ id: rowId, _action: 'delete' })));
    activeDownloadsRef.current = activeDownloadsRef.current.filter(
      (item) => item !== 'ExportAllExcel'
    );
    setTimeout(() => {
      setLoading(false);
      if (!activeDownloadsRef.current.length) {
        hideMenu?.();
      }
    }, 5000);
  };
  return (
    <MenuItem onClick={getAllRows} disabled={loading}>
      <ListItemIcon>
        <SiMicrosoftexcel fontSize="small" />
      </ListItemIcon>
      <ListItemText sx={{ position: 'relative' }}>
        {loading && (
          <Typography
            sx={{
              position: 'absolute',
              top: -9,
              left: 0,
              right: 0,
              pr: 2,
              textAlign: 'left',
            }}
            fontWeight="bolder"
            variant="caption"
          >
            ETA:{' '}
            {formatDistanceToNowStrict(new Date(new Date().getTime() + progress.etaInMs), {
              addSuffix: true,
            })}
          </Typography>
        )}
        Export all Pages as Excel
      </ListItemText>
      <Typography
        variant="body2"
        sx={{
          ml: 2,
          width: 25,
          textAlign: 'right',
          ...(progress.percent === 100 && { lineHeight: 0 }),
        }}
        color="text.secondary"
      >
        {loading && progress.percent !== 100 ? `${progress.percent}%` : ''}
        {loading && progress.percent === 100 && <DoneIcon fontSize="small" color="success" />}
      </Typography>
      {loading && (
        <LinearProgress
          color={progress.percent === 100 ? 'success' : 'primary'}
          sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
          variant="determinate"
          value={progress.percent}
        />
      )}
    </MenuItem>
  );
};
const ExportAllPrint = ({
  fetcher,
  hideMenu,
  options,
  progress,
  activeDownloadsRef,
}: GridExportMenuItemProps<any> & { activeDownloadsRef: { current: string[] } }) => {
  const apiRef = useGridApiContext();
  const [loading, setLoading] = React.useState(() =>
    activeDownloadsRef.current.some((item) => item === 'ExportAllPrint')
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getAllRows = async () => {
    setLoading(true);
    activeDownloadsRef.current.push('ExportAllPrint');
    const curretnRowIds = apiRef.current.getAllRowIds();

    // update data to set all the rows
    const allRows = await fetcher();
    apiRef.current.updateRows(allRows.data);
    await apiRef.current.exportDataAsPrint(options); // notice that file generation is an async function

    // Delete rows added for the file generation
    const idsToDelete = allRows.data
      .map((row) => row.id)
      .filter((id) => !curretnRowIds.includes(id));
    apiRef.current.updateRows(idsToDelete.map((rowId) => ({ id: rowId, _action: 'delete' })));
    activeDownloadsRef.current = activeDownloadsRef.current.filter(
      (item) => item !== 'ExportAllPrint'
    );
    setTimeout(() => {
      setLoading(false);
      if (!activeDownloadsRef.current.length) {
        hideMenu?.();
      }
    }, 5000);
  };
  return (
    <MenuItem onClick={getAllRows} disabled={loading}>
      <ListItemIcon>
        <PrintIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText
        sx={{
          position: 'relative',
        }}
      >
        {loading && (
          <Typography
            sx={{
              position: 'absolute',
              top: -9,
              left: 0,
              right: 0,
              pr: 2,
              textAlign: 'left',
            }}
            fontWeight="bolder"
            variant="caption"
          >
            ETA:{' '}
            {formatDistanceToNowStrict(new Date(new Date().getTime() + progress.etaInMs), {
              addSuffix: true,
            })}
          </Typography>
        )}
        Print all Pages
      </ListItemText>
      <Typography
        variant="body2"
        sx={{
          ml: 2,
          width: 20,
          textAlign: 'right',
          ...(progress.percent === 100 && { lineHeight: 0 }),
        }}
        color="text.secondary"
      >
        {loading && progress.percent !== 100 ? `${progress.percent}%` : ''}
        {loading && progress.percent === 100 && <DoneIcon fontSize="small" color="success" />}
      </Typography>
      {loading && (
        <LinearProgress
          color={progress.percent === 100 ? 'success' : 'primary'}
          sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
          variant="determinate"
          value={progress.percent}
        />
      )}
    </MenuItem>
  );
};
const ExportAllJSON = ({
  fetcher,
  hideMenu,
  options,
  progress,
  activeDownloadsRef,
}: GridExportMenuItemProps<any> & { activeDownloadsRef: { current: string[] } }) => {
  const apiRef = useGridApiContext();
  const [loading, setLoading] = React.useState(() =>
    activeDownloadsRef.current.some((item) => item === 'ExportAllJSON')
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getAllRows = async () => {
    setLoading(true);
    activeDownloadsRef.current.push('ExportAllJSON');
    const curretnRowIds = apiRef.current.getAllRowIds();
    // update data to set all the rows
    const allRows = await fetcher();
    apiRef.current.updateRows(allRows.data);
    await exportDataAsJson({
      apiRef,
      options,
    }); // notice that file generation is an async function

    // Delete rows added for the file generation
    const idsToDelete = allRows.data
      .map((row) => row.id)
      .filter((id) => !curretnRowIds.includes(id));
    apiRef.current.updateRows(idsToDelete.map((rowId) => ({ id: rowId, _action: 'delete' })));
    activeDownloadsRef.current = activeDownloadsRef.current.filter(
      (item) => item !== 'ExportAllJSON'
    );
    setTimeout(() => {
      setLoading(false);
      if (!activeDownloadsRef.current.length) {
        hideMenu?.();
      }
    }, 5000);
  };
  return (
    <MenuItem onClick={getAllRows} disabled={loading}>
      <ListItemIcon>
        <DataObjectIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText
        sx={{
          position: 'relative',
        }}
      >
        {loading && (
          <Typography
            sx={{
              position: 'absolute',
              top: -9,
              left: 0,
              right: 0,
              pr: 2,
              textAlign: 'left',
            }}
            fontWeight="bolder"
            variant="caption"
          >
            ETA:{' '}
            {formatDistanceToNowStrict(new Date(new Date().getTime() + progress.etaInMs), {
              addSuffix: true,
            })}
          </Typography>
        )}
        Export all Pages as JSON
      </ListItemText>
      <Typography
        variant="body2"
        sx={{
          ml: 2,
          width: 20,
          textAlign: 'right',
          ...(progress.percent === 100 && { lineHeight: 0 }),
        }}
        color="text.secondary"
      >
        {loading && progress.percent !== 100 ? `${progress.percent}%` : ''}
        {loading && progress.percent === 100 && <DoneIcon fontSize="small" color="success" />}
      </Typography>
      {loading && (
        <LinearProgress
          color={progress.percent === 100 ? 'success' : 'primary'}
          sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
          variant="determinate"
          value={progress.percent}
        />
      )}
    </MenuItem>
  );
};

export const GridToolbarExport: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  GridToolbarExportProps
> = React.forwardRef((props, ref) => {
  const { csvOptions = {}, printOptions = {}, excelOptions, jsonOptions = {}, ...other } = props;

  const fetcherRef = React.useRef();
  const activeDownloadsRef = React.useRef([]);
  const [loading, setLoading] = React.useState(false);
  const apiRef = useGridApiContext();
  const query = {
    $include: apiRef.current.include,
    queryOptions: apiRef.current.queryOptions,
    $limit: 200,
    timezoneOffset: new Date().getTimezoneOffset(),
    ...apiRef.current.query,
  };

  const { fetcher: _fetcher, progress } = useFetcher(
    apiRef.current.service,
    {
      allPages: true,
      query,
    },
    {
      method: 'find',
    }
  );

  const fetcher = () => {
    setLoading(true);
    // there is a current fetcher in progress
    if (fetcherRef.current) {
      return fetcherRef.current;
    }
    fetcherRef.current = _fetcher()
      .then((result) => {
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        fetcherRef.current = false;
        return result;
      })
      .catch(() => {
        fetcherRef.current = false;
        setLoading(false);
      });
    return fetcherRef.current;
  };

  return (
    <GridToolbarExportContainer progress={progress} loading={loading} {...other} ref={ref}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
      <GridPrintExportMenuItem options={printOptions} />
      <Divider />
      <JsonExportMenuItem options={jsonOptions} />
      <Divider />
      <ExportAllCSV
        fetcher={fetcher}
        progress={progress}
        activeDownloadsRef={activeDownloadsRef}
        options={csvOptions}
      />
      <ExportAllExcel
        fetcher={fetcher}
        progress={progress}
        activeDownloadsRef={activeDownloadsRef}
        options={excelOptions}
      />
      <ExportAllPrint
        fetcher={fetcher}
        progress={progress}
        activeDownloadsRef={activeDownloadsRef}
        options={excelOptions}
      />
      <Divider />
      <ExportAllJSON
        fetcher={fetcher}
        progress={progress}
        activeDownloadsRef={activeDownloadsRef}
        options={jsonOptions}
      />
    </GridToolbarExportContainer>
  );
});
