/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
import { useMemo } from 'react';
import { atom as createAtom, AtomEffect, RecoilRoot } from 'recoil';
import { recoilPersist } from 'store/recoil-persist';
import deepmerge from 'deepmerge';
import { requestIdleCallback } from 'utils/requestIdleCallback';
import { __DEVELOPMENT__ } from 'global-env';
import { namespace } from './namespace';
import { getIn, setIn, unsetIn, matcher as defaultMatcher, forEachObj } from './helpers';
import { localforage } from './storage';
import { ssrCompletedState } from './useSsrComplectedState';

const log = __DEVELOPMENT__
  ? console.log
  : () => {
      // empty
    };

const customMerge = (key) => {
  if (key === 'errors') {
    return (a, b) => {
      return b;
    };
  }
};

const combineMerge = (target, source /* , options */) => {
  // const destination = target.slice();

  // dont merge regular array that not contains entities
  // eslint-disable-next-line no-constant-condition
  return source;

  // source.forEach((item, index) => {
  //   if (typeof destination[index] === 'undefined') {
  //     destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
  //   } else if (options.isMergeableObject(item)) {
  //     destination[index] = deepmerge(target[index], item, options);
  //   } else if (target.indexOf(item) === -1) {
  //     destination.push(item);
  //   }
  // });
  // return destination;
};

const initialState = () => ({
  [namespace]: {
    entities: {},
    queries: {},
    refs: {},
    index: {},
  },
});

// function setWithExpiry(key, value, ttl) {
//   const now = new Date();

//   // `item` is an object which contains the original value
//   // as well as the time when it's supposed to expire
//   const item = {
//     value,
//     expiry: now.getTime() + ttl,
//   };
//   return localforage.setItem(key, JSON.stringify(item));
// }

// const getWithExpiry = async (key) => {
//   const itemStr = await localforage.getItem(key, (err, value) => {
//     // if err is non-null, we got an error. otherwise, value is the value
//   });
//   // if the item doesn't exist, return null
//   if (!itemStr) {
//     return null;
//   }
//   const item = JSON.parse(itemStr);
//   const now = new Date();
//   // compare the expiry time of the item with the current time
//   if (now.getTime() > item.expiry) {
//     // If the item is expired, delete the item from storage
//     // and return null
//     localforage.removeItem(key);
//     return null;
//   }
//   return item.value;
// };

const customStorage = () => {
  return {
    setItem: (key: any, value: any) => {
      // handle setItem
      return localforage.setItem(key, value);
      // if err is non-null, we got an error
    },
    getItem: async (key: any) => {
      // handle getItem
      // this function should return something
      const a = await localforage.getItem(key, (err) => {
        if (err) {
          console.error(err, 'err');
        }

        // if err is non-null, we got an error. otherwise, value is the value
      });
      return a || undefined;
    },
  };
};

const { persistAtom } = recoilPersist({
  key: 'recoil-persist-data',
  storage: customStorage(),
});

export const persistAtomEffect = <T>(param: Parameters<AtomEffect<T>>[0]) => {
  param.getPromise(ssrCompletedState).then(() => {
    requestIdleCallback(() => {
      persistAtom(param);
    });
  });
};

export const rootAtom = createAtom({
  key: 'rootState', // unique ID (with respect to other atoms/selectors)
  default: initialState(),
  effects_UNSTABLE: [persistAtomEffect],
});

const actions = ({ idField, updatedAtField }) => ({
  feathersFetched: fetched(idField, updatedAtField),
  feathersCreated: created(idField, updatedAtField),
  feathersUpdated: updated(idField, updatedAtField),
  feathersPatched: updated(idField, updatedAtField),
  feathersRemoved: removed(idField, updatedAtField),
  feathersUpdateQueries: updateQuery(idField, updatedAtField),
});

function fetchedServer({ idField, updatedAtField }) {
  return ({ serviceName, data, method, params, queryId, store, realtime }) => {
    const curr = getIn(store, [namespace]);
    let next = curr;

    const { data: items, ...meta } = data;
    const entities = realtime === 'merge' ? { ...getIn(curr, ['entities', serviceName]) } : {};
    const index = realtime === 'merge' ? { ...getIn(curr, ['index', serviceName]) } : {};
    for (const item of items) {
      const itemId = idField(item);
      entities[itemId] = item;
      if (realtime === 'merge') {
        const itemIndex = { ...index[itemId] };
        itemIndex.queries = { ...itemIndex.queries, [queryId]: true };
        itemIndex.size = itemIndex.size ? itemIndex.size + 1 : 1;
        index[itemId] = itemIndex;
      }
    }
    if (realtime === 'merge') {
      // update entities
      next = setIn(next, ['entities', serviceName], entities);
      next = setIn(next, ['index', serviceName], index);
    }

    // update queries
    next = setIn(next, ['queries', serviceName, queryId], {
      params,
      data: items.map((x) => idField(x)),
      meta,
      method,
      realtime,
      ...(realtime === 'merge' ? {} : { entities }),
    });

    const l = items.length;
    const msg = `${serviceName} ${l} item${l === 1 ? '' : 's'}`;
    log(msg, 'msg');
    store[namespace] = next;
  };
}

export const setStore = fetchedServer({
  idField: (d) => d.uid,
  updatedAtField: (d) => d.updatedAt,
});

function fetched(idField, updatedAtField) {
  return ({ set, actions }, { serviceName, data, method, params, queryId, realtime, matcher }) => {
    set((currentState) => {
      const curr = getIn(currentState, [namespace]);
      let next = curr;

      const { data: items, ...meta } = data;
      const entities = realtime === 'merge' ? { ...getIn(curr, ['entities', serviceName]) } : {};
      const index = realtime === 'merge' ? { ...getIn(curr, ['index', serviceName]) } : {};
      for (const item of items) {
        const itemId = idField(item);
        entities[itemId] = !entities[itemId]
          ? item
          : deepmerge(entities[itemId], item, { arrayMerge: combineMerge, customMerge });
        if (realtime === 'merge') {
          const itemIndex = { ...index[itemId] };
          itemIndex.queries = { ...itemIndex.queries, [queryId]: true };
          itemIndex.size = itemIndex.size ? itemIndex.size + 1 : 1;
          index[itemId] = itemIndex;
        }
      }

      if (realtime === 'merge') {
        // update entities
        next = setIn(next, ['entities', serviceName], entities);
        next = setIn(next, ['index', serviceName], index);
      }

      // update queries
      next = setIn(next, ['queries', serviceName, queryId], {
        params,
        data: items.map((x) => idField(x)),
        meta,
        method,
        realtime,
        matcher,
        ...(realtime === 'merge' ? {} : { entities }),
      });

      const l = items.length;
      const msg = `${serviceName} ${l} item${l === 1 ? '' : 's'}`;
      log(msg, 'msg');
      return { [namespace]: next };
    });
  };
}

function created(idField, updatedAtField) {
  return ({ get, set, actions }, { serviceName, item }) => {
    actions.feathersUpdateQueries({ get, set, actions }, { serviceName, method: 'create', item });
  };
}

// applies to both update and patch
function updated(idField: any, updatedAtField: any) {
  return ({ set, actions }, { serviceName, item }) => {
    const itemId = idField(item);
    set((currentState) => {
      const curr = getIn(currentState, [namespace]);

      const currItem = getIn(curr, ['entities', serviceName, itemId]);

      // check to see if we should discard this update
      if (currItem) {
        const currUpdatedAt = updatedAtField(currItem);
        const nextUpdatedAt = updatedAtField(item);
        if (nextUpdatedAt && nextUpdatedAt < currUpdatedAt) {
          return currentState;
        }
      }

      let next;
      if (currItem) {
        const incrementalItem = deepmerge(currItem, item, {
          arrayMerge: combineMerge,
          customMerge,
        });
        next = setIn(curr, ['entities', serviceName, itemId], incrementalItem);
      } else {
        const index = { queries: {}, size: 0 };
        next = setIn(curr, ['entities', serviceName, itemId], item);
        next = setIn(next, ['index', serviceName, itemId], index);
      }
      const msg = `${serviceName} ${itemId} updated`;
      let newState = { [namespace]: next };

      const seTter = (callback) => {
        const tryNew = callback(newState);
        if (tryNew) {
          newState = tryNew;
        }
      };
      actions.feathersUpdateQueries(
        { set: seTter, actions },
        { serviceName, method: 'update', item }
      );
      return newState;
    });
  };
}

function removed(idField) {
  return ({ get, set, actions }, { serviceName, item: itemOrItems }) => {
    const items = Array.isArray(itemOrItems) ? itemOrItems : [itemOrItems];
    set((currentState) => {
      let curr = getIn(currentState, [namespace]);

      const exists = items.some((item) => getIn(curr, ['entities', serviceName, idField(item)]));
      if (!exists) return;

      const setter = (callback) => {
        currentState = callback(currentState);
      };
      // remove this item from all the queries that reference it
      actions.feathersUpdateQueries(
        { set: setter, actions },
        { serviceName, method: 'remove', item: itemOrItems }
      );

      // updating queries updates state, get a fresh copy
      curr = getIn(currentState, [namespace]);

      // now remove it from entities
      const serviceEntities = { ...getIn(curr, ['entities', serviceName]) };
      let next = curr;
      const removedIds = [];
      for (const item of items) {
        delete serviceEntities[idField(item)];
        next = setIn(next, ['entities', serviceName], serviceEntities);
        removedIds.push(idField(item));
      }
      const msg = `removed ${serviceName} ${removedIds.join(',')}`;
      log(msg, 'msg');
      return { [namespace]: next };
    });
  };
}

function updateQuery(idField, updatedAtField) {
  return function feathersUpdateQueries({ set }, { serviceName, method, item }) {
    set((currentState) => {
      const curr = getIn(currentState, [namespace]);
      const items = Array.isArray(item) ? item : [item];
      for (const item of items) {
        const itemId = idField(item);
        const queries = { ...getIn(curr, ['queries', serviceName]) };
        const index = { ...getIn(curr, ['index', serviceName, itemId]) };
        index.queries = { ...index.queries };
        index.size = index.size || 0;

        let updateCount = 0;

        forEachObj(queries, (query, queryId) => {
          let matches;

          // do not update non realtime queries
          // those get updated/refetched in a different way
          if (query.realtime !== 'merge') {
            return;
          }

          if (method === 'remove') {
            // optimisation, if method is remove, we want to immediately remove the object
            // from cache, which means we don't need to match using matcher
            matches = false;
          } else if (!query.params.query || Object.keys(query.params.query).length === 0) {
            // another optimisation, if there is no query, the object matches
            matches = true;
          } else {
            const matcher = query.matcher ? query.matcher(defaultMatcher) : defaultMatcher;
            matches = matcher(query.params.query)(item);
          }

          if (index.queries[queryId]) {
            if (!matches) {
              updateCount += 1;
              queries[queryId] = {
                ...query,
                meta: { ...query.meta, total: query.meta.total - 1 },
                data: query.data.filter((id) => id !== itemId),
              };
              delete index.queries[queryId];
              index.size -= 1;
            }
          } else {
            // only add if query has fetched all of the data..
            // if it hasn't fetched all of the data then leave this
            // up to the consumer of the figbird to decide if data
            // should be refetched
            if (matches && query.data.length <= query.meta.total) {
              updateCount += 1;
              // TODO - sort
              queries[queryId] = {
                ...query,
                meta: { ...query.meta, total: query.meta.total + 1 },
                data: query.data.concat(itemId),
              };
              index.queries[queryId] = true;
              index.size += 1;
            }
          }
        });

        if (updateCount > 0) {
          let next = curr;

          next = setIn(next, ['queries', serviceName], queries);
          next = setIn(next, ['index', serviceName, itemId], index);

          // in case of create, only ever add it to the cache if it's relevant for any of the
          // queries, otherwise, we might end up piling in newly created objects into cache
          // even if the app never uses them
          if (!getIn(next, ['entities', serviceName, itemId])) {
            next = setIn(next, ['entities', serviceName, itemId], item);
          }

          // this item is no longer relevant to any query, garbage collect it
          if (index.size === 0) {
            next = unsetIn(next, ['entities', serviceName, itemId]);
            next = unsetIn(next, ['index', serviceName, itemId]);
          }

          const msg = `updated ${updateCount} ${updateCount === 1 ? 'query' : 'queries'}`;
          log(msg, 'msg');
          const newSTate = { [namespace]: next };
          currentState = newSTate;
        }
      }
      return currentState;
    });
  };
}

export const useCacheInstance = (config) => {
  return useMemo(() => {
    // Create an atom context and a set of hooks separate from the
    // main context used in tiny-atom. This way our store and actions
    // and everything do not interfere with the main atom. Use this
    // secondary context even if we use an existing atom – there is no
    // issue with that.

    // const useSelector = (callback, deps) => {
    //   const setState = useSetRecoilState(atom);
    //   const state = useRecoilValue(atom);
    //   return useMemo(() => {
    //     const changes = callback(state, setState);
    //     return changes;
    //   }, deps);
    // };

    return { AtomProvider: RecoilRoot, actions: actions(config) };
  }, []);
};
