import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const HarmonizeCodesRestrictionsAuthLoadable = loadable(
  () =>
    import(
      /* webpackChunkName: 'admin-HarmonizeCodesRestrictionsAuth' */ './HarmonizeCodesRestrictionsAuth'
    ),
  {
    fallback: <LoadingComponent />,
  }
);

const RatesServices = (prop: any) => <HarmonizeCodesRestrictionsAuthLoadable {...prop} />;

export default RatesServices;
