/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import Container from 'components/Container/Container';
import config from 'config';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Img/404-hero.svg' o... Remove this comment to see the full error message
import BgSvg from 'components/Img/401-hero.svg';
import { Link } from 'react-router-dom';

import { Typography, Grid } from '@mui/material';

const useStyles = makeStyles(
  (theme) => ({
    '@keyframes peaceicon': {
      '0%': {
        animationTimingFunction: 'ease-in, ease-in-out',
        transform: 'matrix(1,0,0,1,3,8)',
      },
      '50%': {
        animationTimingFunction: 'ease-in, ease-in-out',
        transform: 'matrix(1,0,0,1,3,17)',
      },
      '100%': {
        animationTimingFunction: 'ease-in, ease-in-out',
        transform: 'matrix(1,0,0,1,3,8)',
      },
    },
    bg: {
      display: 'flex',
      color: '#fff',
      backgroundImage: `url(${BgSvg})`,
      fallbacks: [
        {
          backgroundImage: 'linear-gradient(-30deg, #00004d 0%, #5333ed 49%, #2cd4d9 100%)',
        },
      ],
      height: '100vh',
      width: 'calc(100% + 2px)',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
    container100: {
      height: '100%',
    },
    containerFeatures: {
      position: 'relative',
      top: -100,
      marginBottom: -100,
    },
    paperFeatures: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(10),
    },
    typoWithIcon: {
      display: 'inline-flex',
    },
    iconsWithText: {
      fontSize: 28,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginRight: theme.spacing(3),
    },
    containerSecond: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginTop: theme.spacing(12),
    },
    containerContentSecond: {
      textAlign: 'center',
    },
    peaceicon: {
      '& g > g > g > g:nth-child(2)': {
        animationName: '$peaceicon',
        animationDuration: '3s',
        animationIterationCount: 'infinite',
      },
    },
  }),
  { name: 'BringerNotFound' }
);

const Unauthorized = memo(
  () => {
    const classes = useStyles();
    const title = 'Unauthorized';
    // eslint-disable-next-line
    const description =
      'Ship anywhere in the world with a click. Our online tools let you ship with confidence without downloading software';

    const ldjson = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.domain,
      name: config.app.title,
      alternateName: 'Bringer',
      image: `${config.domain}/bps-logo-white.png`,
      sameAs: [
        'https://www.facebook.com/Bringer',
        'https://instagram.com/Bringer',
        'https://www.linkedin.com/company/Bringer/',
        'https://twitter.com/@Bringer',
      ],
    };

    const ldjsonOrg = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: config.app.title,
      legalName: 'Bringer CORP',
      email: `mailto:contact@${config.host}`,
      url: config.domain,
      logo: `${config.domain}/bps-logo-white.png`,
      sameAs: [
        'https://www.facebook.com/Bringer',
        'https://instagram.com/Bringer',
        'https://www.linkedin.com/company/Bringer/',
        'https://twitter.com/@Bringer',
      ],
    };

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={`${config.domain}/`} />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="twitter:title" content={title} />
          <meta name="og:description" content={description} />
          <meta name="twitter:description" content={description} />
          <meta property="og:type" content="website" />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">{JSON.stringify(ldjson)}</script>
          <script type="application/ld+json">{JSON.stringify(ldjsonOrg)}</script>
        </Helmet>
        <div className={classes.bg}>
          <Container>
            <Grid container alignItems="center" className={classes.container100}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h3" component="h1" color="inherit" gutterBottom>
                  Unauthorized
                </Typography>
                <Typography variant="h5">
                  You don't have permission to access this page <br />
                  <Link to="/admin/dashboard">Click here to return to the dashboard</Link>.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  },
  () => true
);

export default Unauthorized;
