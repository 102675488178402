import { Breakpoint, Theme, useTheme } from '@mui/material';

/**
 * By default, returns true if screen width is the same or less than the given breakpoint.
 * @param screenWidth
 * @param breakpoint
 * @param inclusive - defaults to true
 */
const useIsWidthDown = (
  breakpoint: Breakpoint,
  screenWidth: Breakpoint,
  inclusive = true
): boolean => {
  const theme: Theme = useTheme();
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys];
  if (inclusive) {
    return keys.indexOf(screenWidth) <= keys.indexOf(breakpoint);
  }
  return keys.indexOf(screenWidth) < keys.indexOf(breakpoint);
};

export default useIsWidthDown;
