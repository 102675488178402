import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const OriginScanLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-ParcelScan' */ './OriginScan'),
  {
    fallback: <LoadingComponent />,
  }
);

export default OriginScanLoadable;
