import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const UnitizersDispacthesReportLoadable = loadable(
  () => import(/* webpackChunkName: 'unitizers-dispacthes-report' */ './UnitizersDispacthesReport'),
  {
    fallback: <LoadingComponent />,
  }
);

const UnitizersDispacthesReport = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <UnitizersDispacthesReportLoadable {...prop} />
);

export default UnitizersDispacthesReport;
