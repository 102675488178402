import { useMethod } from 'hooks/useMethod';
import { useEffect, useMemo, useRef } from 'react';
import { atom, selector, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFigbird } from 'store';
import type { OptionsObject } from 'components/notistack/types';

const ENQUEUE_SNACKBAR = 'recoil/notifier/ENQUEUE_SNACKBAR';
const CLOSE_SNACKBAR = 'recoil/notifier/CLOSE_SNACKBAR';
const REMOVE_SNACKBAR = 'recoil/notifier/REMOVE_SNACKBAR';

interface NotiOptions {
  message: any;
  options: OptionsObject;
}

const enqueueSnackbarAction = (notification: NotiOptions) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

const closeSnackbarAction = (key: any) => ({
  type: CLOSE_SNACKBAR,

  // dismiss all if no key has been defined
  dismissAll: !key,

  key,
});

const removeSnackbarAction = (key: any) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export {
  enqueueSnackbarAction as enqueueSnackbar,
  closeSnackbarAction as closeSnackbar,
  removeSnackbarAction as removeSnackbar,
};

const defaultState = {
  notifications: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = defaultState, action: any) => {
  const notifications = state.notifications || [];
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: notifications.map((notification) =>
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'never'.
          action.dismissAll || notification.key === action.key
            ? // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
              { ...notification, dismissed: true }
            : // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
              { ...notification }
        ),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: notifications.filter(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'never'.
          (notification) => notification.key !== action.key
        ),
      };

    default:
      return state;
  }
};

const notifierAtom = atom({
  key: 'notifier',
  default: defaultState,
});

interface ShoulUpdate {
  toJSON: () => any;
  test: (prev: any, next: any) => boolean;
}

interface AuthParams {
  shouldUpdate?: ShoulUpdate;
}

const defaultSelector = selector({
  key: `defaultSelectorNotifier`,
  get: ({ get }) => {
    return get(notifierAtom);
  },
});
const map = new Map();
const selectorFam = selectorFamily({
  key: 'notifierSelector',
  get:
    (shouldUpdate: ShoulUpdate) =>
    ({ get }) => {
      const key = `notifierSelectorMap-${JSON.stringify(shouldUpdate.toJSON())}`;
      const prevState = map.get(key);
      const state = get(notifierAtom);
      if (shouldUpdate.test(prevState || state, state)) {
        map.set(key, state);
        return state;
      }

      if (!prevState) {
        map.set(key, state);
      }
      return prevState || state;
    },
});

export const useNotifier = ({ shouldUpdate }: AuthParams = {}) => {
  const { feathers } = useFigbird();
  const setState = useSetRecoilState(notifierAtom);

  const dispatch = (action: any) => {
    setState((prev) => {
      return reducer(prev, action);
    });
  };

  const mountedRef = useRef<boolean>(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const state = useRecoilValue(shouldUpdate ? selectorFam(shouldUpdate) : defaultSelector);

  const enqueueSnackbar = useMethod(enqueueSnackbarAction, dispatch, feathers, mountedRef);
  const closeSnackbar = useMethod(closeSnackbarAction, dispatch, feathers, mountedRef);
  const removeSnackbar = useMethod(removeSnackbarAction, dispatch, feathers, mountedRef);

  return useMemo(() => {
    return {
      enqueueSnackbar,
      closeSnackbar,
      removeSnackbar,
      state,
      dispatch,
    };
  }, [enqueueSnackbar, state, dispatch]);
};
