/* eslint-disable prefer-const */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import { useReducer, useEffect, useRef, useCallback, useMemo } from 'react';
import type { Service } from '@feathersjs/feathers';
import { useFigbird } from './core';
import { useRealtime } from './useRealtime';
import { useCache } from './useCache';
import { hashObject, inflight } from './helpers';
import logger from './logger';

const get = inflight(
  (service, id, params, options) => `${service.path}/${options.queryId}`,
  getter
);
const find = inflight(
  (service: string, params: any, options: any) => `${service.path}/${options.queryId}`,
  finder
);

const fetchPolicies = ['swr', 'cache-first', 'network-only'];
const realtimeModes = ['merge', 'refetch', 'disabled'];

export interface UseQueryParams {
  [x: string]: {
    $in?: string[] | number[] | null;
    $nin?: string[] | number[] | null;
    $lt?: any;
    $lte?: any;
    $gt?: any;
    $gte?: any;
    $ne?: any;
    $or?: any[];
    $like?: string;
  } & any;
  $in?: string[] | number[] | null;
  $nin?: string[] | number[] | null;
  $lt?: any;
  $lte?: any;
  $gt?: any;
  $gte?: any;
  $ne?: any;
  $or?: any[];
  $like?: string;
  $limit?: number;
  $skip?: number;
  $sort?: any;
  $paginate?: boolean | number;
  $select?: string[];
  $include?: string[];
}

interface ReducerState {
  reloading: boolean;
  fetched: boolean;
  fetchedCount: number;
  refetchSeq: number;
  error: Error | null;
}
export interface UseQueryOptions {
  shouldUpdate?: (prev: ReducerState, next: ReducerState) => boolean;
  skip?: boolean;
  allPages?: boolean;
  parallel?: boolean;
  query?: UseQueryParams;
  fetchPolicy?: 'swr' | 'cache-first' | 'network-only';
  realtime?: 'merge' | 'refetch' | 'disabled';
  matcher?: (data: any) => any;
}
export interface UseQueryHookOptions {
  method: string;
  id?: string | number;
  selectData?: (data: any) => any;
  transformResponse?: (data: any) => any;
}

/**
 * A generic abstraction of both get and find
 */
export const useQuery = <D>(
  serviceName: string,
  options: UseQueryOptions,
  queryHookOptions: UseQueryHookOptions
): {
  data: D;
  status: 'loading' | 'error' | 'success';
  refetch: () => any;
  isFetching: boolean;
  error: Error | string;
} => {
  const shouldUpdate = options.shouldUpdate || ((prev, next) => prev !== next);
  const { method, id, selectData, transformResponse } = queryHookOptions;

  const { feathers } = useFigbird();
  const disposed = useRef(false);
  const isInitialMount = useRef(true);
  const fetchedCount = useRef(0);

  const { skip, allPages, parallel, fetchPolicy = 'swr', matcher, ...params } = options;
  let { realtime = 'merge' } = options;

  realtime = realtime || 'disabled';
  if (realtime !== 'disabled' && realtime !== 'merge' && realtime !== 'refetch') {
    throw new Error(`Bad realtime option, must be one of ${[realtimeModes].join(', ')}`);
  }

  if (!fetchPolicies.includes(fetchPolicy)) {
    throw new Error(`Bad fetchPolicy option, must be one of ${[fetchPolicies].join(', ')}`);
  }

  const queryId = `${method.slice(0, 1)}:${hashObject({
    serviceName,
    method,
    id,
    params,
    realtime,
  })}`;

  let [cachedData, updateCache] = useCache({
    serviceName,
    queryId,
    method,
    id,
    params,
    realtime,
    selectData,
    transformResponse,
    matcher,
  });

  let hasCachedData = !!cachedData.data;
  const fetched = fetchPolicy === 'cache-first' && hasCachedData;

  const [state, dispatch] = useReducer(reducer({ shouldUpdate, fetchedCount, fetchPolicy }), {
    reloading: false,
    fetched,
    fetchedCount: 0,
    refetchSeq: 0,
    error: null,
  });

  if (fetchPolicy === 'network-only' && fetchedCount.current === 0) {
    cachedData = { data: null };
    hasCachedData = false;
  }

  const handleRealtimeEvent = useCallback(() => {
    if (disposed.current) return;
    if (realtime !== 'refetch') return;

    dispatch({ type: 'refetch' });
  }, [dispatch, realtime, disposed]);

  useEffect(() => {
    disposed.current = false;
    return () => {
      disposed.current = true;
    };
  }, []);

  useEffect(() => {
    let _disposed = false;

    if (state.fetched) return;
    if (skip) return;
    if (hasCachedData && fetchPolicy === 'cache-first') return;

    dispatch({ type: 'fetching' });

    const service = feathers.service(serviceName);
    const result =
      method === 'get'
        ? get(service, id, params, { queryId })
        : find(service, params, { queryId, allPages, parallel });
    result
      .then((res: any) => {
        // no res means we've piggy backed on an in flight request
        if (res && !res.inflight) {
          updateCache(res);
        }
        if (!_disposed) {
          dispatch({ type: 'success' });
        }
      })
      .catch((error: Error | any) => {
        if (error.toString().indexOf('No record found for id') !== -1) {
          updateCache(null);
          if (!_disposed) {
            dispatch({ type: 'success' });
          }
        } else if (!_disposed) {
          return dispatch({ type: 'error', payload: error });
        }
      });

    return () => {
      _disposed = true;
    };
  }, [serviceName, queryId, state.fetched, state.refetchSeq, skip, allPages, parallel]);

  // If serviceName or queryId changed, we should refetch the data
  useEffect(() => {
    if (!isInitialMount.current) {
      dispatch({ type: 'reset' });
    }
  }, [serviceName, queryId]);

  // realtime hook will make sure we're listening to all of the
  // updates to this service
  useRealtime(serviceName, realtime, handleRealtimeEvent);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, []);

  // derive the loading/reloading state from other substates
  const loading = !skip && !hasCachedData && !state.error;

  const reloading = loading || state.reloading;
  const refetch = useCallback(() => dispatch({ type: 'refetch' }), [dispatch]);

  const memoValues = useMemo(
    () => ({
      ...(skip ? { data: null } : cachedData),
      status: loading ? 'loading' : state.error ? 'error' : 'success',
      refetch,
      isFetching: reloading,
      error: state.error,
    }),
    [skip, cachedData.data, loading, state.error, refetch, reloading]
  );

  return memoValues;
};

const reducer =
  ({ shouldUpdate, fetchedCount, fetchPolicy }: any) =>
  (state: any, action: any) => {
    let newState = {};
    switch (action.type) {
      case 'fetching':
        newState = {
          ...state,
          reloading: true,
          error: null,
        };
        break;
      case 'success':
        fetchedCount.current += 1;
        newState = {
          ...state,
          fetched: true,
          fetchedCount: fetchedCount.current,
          reloading: false,
        };
        break;
      case 'error':
        fetchedCount.current += 1;
        newState = {
          ...state,
          reloading: false,
          fetched: true,
          fetchedCount: fetchedCount.current,
          error: action.payload,
        };
        break;
      case 'refetch':
        newState = {
          ...state,
          fetched: false,
          refetchSeq: state.refetchSeq + 1,
        };
        break;
      case 'reset':
        if (state.fetched) {
          newState = {
            ...state,
            fetched: false,
            fetchedCount: 0,
          };
        } else {
          newState = state;
        }
        break;
    }

    if (
      !shouldUpdate(state, newState) &&
      action.type !== 'success' &&
      fetchPolicy !== 'network-only'
    ) {
      return state;
    }
    return newState;
  };

function getter(service, id, params) {
  logger({ type: 'Fetching get', serviceName: [id, service.find.Context.prototype.path], params });
  return service
    .get(id, params)
    .then((result) => {
      logger({
        type: 'Fetching get',
        serviceName: [id, service.find.Context.prototype.path, 'result'],
        params: result,
      });
      return result;
    })
    .catch((error) => {
      logger({
        type: 'Fetching get',
        serviceName: [
          service.find.Context.prototype.path,
          'error',
          `${error.toString().slice(0, 30)}...`,
        ],
        params: { error, msg: error.toString() },
      });
      throw error;
    });
}

function finder(service: Service, params, { allPages, parallel }) {
  if (!allPages) {
    logger({ type: 'Fetching find', serviceName: service.find.Context.prototype.path, params });
    return service
      .find(params)
      .then((r) => {
        logger({
          type: 'Fetching find',
          serviceName: [service.find.Context.prototype.path, 'result'],
          params: r,
        });
        return r;
      })
      .catch((error) => {
        logger({
          type: 'Fetching find',
          serviceName: [
            service.find.Context.prototype.path,
            'error',
            `${error.toString().slice(0, 30)}...`,
          ],
          params: { error, msg: error.toString() },
        });
        throw error;
      });
  }

  return new Promise((resolve, reject) => {
    let skip = 0;
    const result = { data: [], skip: 0 };

    fetchNext();

    function doFind(_skip) {
      const allParams = {
        ...params,
        query: {
          ...(params.query || {}),
          $skip: _skip,
        },
      };

      logger({ type: 'Fetching find', serviceName: service.find.Context.prototype.path, params });

      return service.find(allParams);
    }

    function resolveOrFetchNext(res) {
      if (res.data.length === 0 || result.data.length >= result.total) {
        resolve(result);
      } else {
        skip = result.data.length;
        fetchNext();
      }
    }

    function fetchNextParallel() {
      const requiredFetches = Math.ceil((result.total - result.data.length) / result.limit);

      if (requiredFetches > 0) {
        Promise.all(
          new Array(requiredFetches).fill().map((_, idx) => doFind(skip + idx * result.limit))
        )
          .then((results) => {
            const [lastResult] = results.slice(-1);
            result.limit = lastResult.limit;
            result.total = lastResult.total;
            result.data = result.data.concat(results.flatMap((r) => r.data));

            resolveOrFetchNext(lastResult);
          })
          .catch(reject);
      } else {
        resolve(result);
      }
    }

    function fetchNext() {
      if (
        typeof result.total !== 'undefined' &&
        typeof result.limit !== 'undefined' &&
        parallel === true
      ) {
        fetchNextParallel();
      } else {
        doFind(skip)
          .then((res) => {
            result.limit = res.limit;
            result.total = res.total;
            result.data = result.data.concat(res.data);

            resolveOrFetchNext(res);
          })
          .catch(reject);
      }
    }
  });
}
