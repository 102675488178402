import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const CountryRegulationsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-country-regulations' */ './CountryRegulations'),
  {
    fallback: <LoadingComponent />,
  }
);

const CountryRegulations = () => <CountryRegulationsLoadable />;

export default CountryRegulations;
