/* eslint-disable max-len */
import { createSvgIcon } from '@mui/material';

export default (props: any) => {
  const Icon = createSvgIcon(
    <path d="M 16 3 C 8.8321429 3 3 8.8321429 3 16 C 3 23.167857 8.8321429 29 16 29 C 23.167857 29 29 23.167857 29 16 C 29 8.8321429 23.167857 3 16 3 z M 16 5 C 22.086977 5 27 9.9130231 27 16 C 27 22.086977 22.086977 27 16 27 C 9.9130231 27 5 22.086977 5 16 C 5 9.9130231 9.9130231 5 16 5 z M 17 9 L 17 21 L 19 21 L 21 21 C 23.287457 21 25 18.896183 25 16.5 C 25 14.103817 23.287457 12 21 12 L 21 14 C 22.026543 14 23 15.030183 23 16.5 C 23 17.969817 22.026543 19 21 19 L 19 19 L 19 9 L 17 9 z M 11 11 C 8.7125434 11 7 13.103817 7 15.5 C 7 17.896183 8.7125434 20 11 20 L 11 18 C 9.9734566 18 9 16.969817 9 15.5 C 9 14.030183 9.9734566 13 11 13 L 13 13 L 13 23 L 15 23 L 15 11 L 14 11 L 13 11 L 11 11 z" />,
    'Quickbooks'
  );
  return <Icon {...props} viewBox="0 0 32 32" />;
};
