/* eslint-disable max-len */
import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { get } from 'redux/modules/retailer';
import qs from 'qs';

const ComponentLoadable = loadable(
  () => import(/* webpackChunkName: 'ParcelsUndeliverable' */ './ParcelsUndeliverable')
);

const Component = (prop: any) => <ComponentLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ location, store: { dispatch } }: any) => {
    const promises = [];

    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    promises.push(
      dispatch(
        get('retailerServiceCountryAddressTypes', {
          query: {
            path: 'graph/find/country_address_types.json',
            params: [''],
            values: [''],
            models: ['AddressTypes', 'Countries'],
          },
        })
      ).catch(() => null)
    );

    promises.push(
      dispatch(
        get('recipientAddreess', {
          query: {
            path: 'graph/get/parcels.json?method=getPublicUndeliverableDetails',
            id: query.parcel_id,
            one_time_token: query.code,
          },
        })
      ).catch(() => null)
    );

    await Promise.all(promises);
  },
})(Component);
