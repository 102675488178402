/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
import { useMemo, useRef } from 'react';
import { selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil';
import equal from 'fast-deep-equal';
import { useFigbird } from './core';
import { namespace } from './namespace';
import { getIn } from './helpers';
import { rootAtom } from './cache';

const map = new Map();

const selectorUseCache = selectorFamily({
  key: 'DefaultRootStateSelector',
  get:
    (comparator): any =>
    ({ get }) => {
      const state = get(rootAtom);

      const key = comparator.toJSON();
      const old = map.get(key);

      const current = comparator.getValues(state);

      if (current === state || equal(current, old)) {
        return old;
      }

      map.set(key, current);
      return current !== undefined ? current : null;
    },
});
interface ResourceDescriptor {
  serviceName: string;
  queryId: string;
  method: string;
  id: string | number;
  params: any;
  realtime: string;
  selectData: any;
  transformResponse: any;
  matcher: any;
}
export function useCache(resourceDescriptor: ResourceDescriptor) {
  const {
    serviceName,
    queryId,
    method,
    id,
    params: p,
    realtime,
    selectData,
    transformResponse,
    matcher,
  } = resourceDescriptor;

  const { ...params } = p;

  const { actions } = useFigbird();

  const setState = useSetRecoilState(rootAtom);

  // we'll use a cheeky ref to store the previous mapped data array
  // because if the underlying list of data didn't change we don't
  // want consumers of useFind to have to worry about changing reference
  const dataRef = useRef([]);

  const getValues = (state) => {
    const query = getIn(state, [namespace, 'queries', serviceName, queryId]);
    if (query) {
      const { meta } = query;
      let { data } = query;
      const entities = query.entities || getIn(state, [namespace, 'entities', serviceName]);
      data = data.map((id) => entities[id]);
      if (same(data, dataRef.current)) {
        data = dataRef.current;
      } else {
        dataRef.current = data;
      }
      data = selectData(data);
      return { ...meta, data };
    }
    return { data: null };
  };

  const state = useRecoilValue(
    selectorUseCache({ getValues, toJSON: () => `${queryId}-${serviceName}-${method}` })
  );

  const cachedData = useMemo(() => {
    return state;
  }, [serviceName, queryId, state]);

  const onFetched = (data) => {
    actions.feathersFetched(
      { set: setState },
      {
        serviceName,
        queryId,
        method,
        params,
        data: {
          ...transformResponse(data),
          ...(id ? { id } : {}),
        },
        realtime,
        matcher,
      }
    );
  };

  return [cachedData, onFetched];
}

function same(a, b) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
