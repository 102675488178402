/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from 'prop-types';
import { useRef, useReducer, useState, useEffect, useLayoutEffect } from 'react';
// import { hot } from 'react-hot-loader/root';
import routes from 'routes';
import { useRoutes, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const RenderRoutes = () => {
  const element = useRoutes(routes);
  return element;
};

const RouterTrigger = (props: any) => {
  const children = <RenderRoutes />;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'browser' does not exist on type 'Process... Remove this comment to see the full error message
  if (process.browser) {
    const historyRef = useRef();
    if (historyRef.current == null) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'BrowserHistory<State>' is not assignable to ... Remove this comment to see the full error message
      historyRef.current = createBrowserHistory({ window });
    }
    const history = historyRef.current;
    const [state, dispatch] = useReducer((_: any, action: any) => action, {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      action: history.action,
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      location: history.location,
    });
    const [_state, _setState] = useState({
      location: state.location,
    });
    useEffect(() => {
      const { trigger } = props;
      const navigated =
        // @ts-expect-error ts-migrate(2339) FIXME: Property '__PRELOADED__' does not exist on type 'W... Remove this comment to see the full error message
        window.__PRELOADED__ ||
        `${state.location.pathname}${state.location.search}` !==
          `${_state.location.pathname}${_state.location.search}`;
      if (navigated) {
        trigger(state.location.pathname)
          .catch((err: any) => console.log('Failure in RouterTrigger:', err))
          .then(() => {
            // clear previousLocation so the next screen renders
            _setState({ location: state.location });
          });
      }
    });
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    useLayoutEffect(() => history.listen(dispatch), [history]);
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Naviga... Remove this comment to see the full error message
      <Router action={state.action} location={_state.location} navigator={history}>
        {children}
      </Router>
    );
  }

  return children;
};

RouterTrigger.propTypes = {
  trigger: PropTypes.func,
};

RouterTrigger.defaultProps = {
  trigger: () => undefined,
};

export default RouterTrigger;
