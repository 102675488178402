/* eslint-disable max-len */
import loadable from '@loadable/component';
import { find as findTariffs } from 'redux/modules/tariffs';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ServicesAreasTariffsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-ServicesAreasTariffs' */ './ServicesAreasTariffs'),
  {
    fallback: <LoadingComponent />,
  }
);

const ServicesAreasTariffs = (prop: any) => <ServicesAreasTariffsLoadable {...prop} />;

export default provideHooks({
  fetch: async ({ store: { getState, dispatch } }: any) => {
    const promises = [];

    const state = getState();

    const tariffs = state.tariffs.result && state.tariffs.result.all;

    if (!tariffs) {
      promises.push(
        dispatch(
          findTariffs('all', {
            query: {
              $paginate: false,
            },
          })
        ).catch(() => null)
      );
    }

    await Promise.all(promises);
  },
})(ServicesAreasTariffs);
