import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const WeightsLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-Weights' */ './Weights'),
  {
    fallback: <LoadingComponent />,
  }
);

const Weights = (prop: any) => <WeightsLoadable {...prop} />;

export default Weights;
