/* eslint-disable max-len */

import configLocal from './config-local';

const environment = {
  development: {
    domain: 'http://127.0.0.1:3000',
    domainIframe: 'http://127.0.0.1:3000',
    domainImages: 'http://127.0.0.1:3000',
    isProduction: false,
    assetsPath: `http://${process.env.HOST || '127.0.0.1'}:${
      +(process.env.PORT || 0) + 1 || 3001
    }/dist/`,
  },
  production: {
    isProduction: true,
    assetsPath: '/dist/',
    domain: 'https://bringerparcel.com',
    domainIframe: 'https://iframe.bringerparcel.com',
    domainImages: 'https://bringerparcel.com',
  },
}[process.env.NODE_ENV || 'development'];

const config = {
  domain: 'http://127.0.0.1:3000',
  domainIframe: 'http://127.0.0.1:3000',
  domainImages: 'http://127.0.0.1:3000',
  isProduction: false,
  assetsPath: `http://${process.env.HOST || '127.0.0.1'}:${
    +(process.env.PORT || 0) + 1 || 3001
  }/dist/`,
  host: process.env.HOST || '127.0.0.1',
  port: process.env.PORT,
  apiHost: process.env.APIHOST || '127.0.0.1',
  apiPort: process.env.APIPORT,
  integrations: {
    correiosDoBrazil: {
      contractNumber: 9912466056,
    },
    correosDeChile: {
      admissionCode: 536111,
    },
  },
  app: {
    title: 'Bringer Parcel Services',
    description: 'Bringer Parcel Services',
    head: {
      titleTemplate: '%s',
      defaultTitle: 'Bringer Parcel Services',
      link: [{ type: 'text/plain', rel: 'author', href: 'https://bringerparcel.com/humans.txt' }],
      meta: [
        {
          name: 'description',
          content: 'Bringer Parcel Services - Ship anywhere in the world with a click.',
        },
        { charset: 'utf-8' },
        { property: 'fb:pages', content: '2198307663748082' },
        { property: 'fb:app_id', content: '296111991114313' },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Bringer ' },
        {
          property: 'og:image',
          content: 'https://bringerparcel.com/media/max_1200/1*NaXapbTluUAlP1Xmqib5mQ.png',
        },
        // { property: 'twitter:image:src', content: 'https://bringerparcel.com/media/max_1200/1*NaXapbTluUAlP1Xmqib5mQ.png' },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:title', content: 'Bringer ' },
        { property: 'og:description', content: 'Bringer .' },
        // { property: 'og:card', content: 'summary' }
        { property: 'twitter:site', content: '@Bringer' },
        { property: 'og:site', content: '@Bringer' },
        { property: 'og:creator', content: '@Bringer' },
        // { property: 'og:image:width', content: '200' },
        // { property: 'og:image:height', content: '200' }
      ],
    },
  },
  termsAndConditionsVersion: 1,
};

Object.assign(config, environment);
Object.assign(config, configLocal);

export default config;
