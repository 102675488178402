const NOTIF_SEND = 'redux-bringer/notifs/NOTIF_SEND';
const NOTIF_DISMISS = 'redux-bringer/notifs/NOTIF_DISMISS';
const NOTIF_CLEAR = 'redux-bringer/notifs/NOTIF_CLEAR';
const NOTIF_CLEAR_ALL = 'redux-bringer/notifs/NOTIF_CLEAR_ALL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
  switch (action.type) {
    case NOTIF_SEND:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'namespace' does not exist on type '{}'.
      return { ...state, [action.namespace]: [action.payload, ...(state[action.namespace] || [])] };
    case NOTIF_DISMISS:
      return {
        ...state,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'namespace' does not exist on type '{}'.
        [action.namespace]: (state[action.namespace] || []).filter(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
          (notif: any) => notif.id !== action.payload
        ),
      };
    case NOTIF_CLEAR:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'namespace' does not exist on type '{}'.
      return { ...state, [action.namespace]: [] };
    case NOTIF_CLEAR_ALL:
      return {};
    default:
      return state;
  }
}

export function notifSend(notif: any, namespace = 'global') {
  if (!notif.id) {
    notif.id = new Date().getTime() * Math.random();
  }
  return (dispatch: any) => {
    dispatch({ type: NOTIF_SEND, namespace, payload: notif });

    if (notif.dismissAfter) {
      setTimeout(
        () => dispatch({ type: NOTIF_DISMISS, namespace, payload: notif.id }),
        notif.dismissAfter
      );
    }
  };
}

export function notifDismiss(id: any, namespace = 'global') {
  return { type: NOTIF_DISMISS, namespace, payload: id };
}

export function notifClear(namespace = 'global') {
  return { type: NOTIF_CLEAR, namespace };
}

export function notifClearAll() {
  return { type: NOTIF_CLEAR_ALL };
}
