import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const ParcelImportsDLPacketLoadable = loadable(
  () => import(/* webpackChunkName: 'admin-ParcelImportsDLPacket' */ './ParcelImportsDLPacket'),
  {
    fallback: <LoadingComponent />,
  }
);

export default ParcelImportsDLPacketLoadable;
