const logger = console;
const tryCatch = (fn, elseFn) => {
  try {
    fn();
  } catch (e) {
    elseFn();
  }
};
const log = (...args) => logger.log(...args);
const groupStart = (...args) => {
  tryCatch(
    () => logger.groupCollapsed(...args),
    () => logger.log(...args)
  );
};
const groupEnd = () =>
  tryCatch(
    () => logger.groupEnd(),
    () => {
      return null;
    }
  );

let _logger: any = () => {
  return undefined;
};

// @ts-expect-error hdie this
if (__DEVELOPMENT__) {
  _logger = ({ type, serviceName, params }) => {
    const parts = Array.isArray(serviceName) ? serviceName : [serviceName];
    groupStart(`🙌 ${type} » ${parts.join(' » ')}`);
    log('params', params);
    groupEnd();
  };
}

export default _logger;
