import loadable from '@loadable/component';
import LoadingComponent from 'components/Loaders/LoadingComponent';

const NewPickupLoadable = loadable(
  () => import(/* webpackChunkName: 'new-pickup-v2' */ './NewPickup'),
  {
    fallback: <LoadingComponent />,
  }
);

const NewPickup = (prop: React.InsHTMLAttributes<any>): React.ReactElement => (
  <NewPickupLoadable {...prop} />
);

export default NewPickup;
