/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable  no-case-declarations */
import type { Application } from '@feathersjs/feathers';
import { deepmerge } from '@mui/utils';
import { useMethod } from 'hooks/useMethod';
import { useEffect, useMemo, useRef } from 'react';
import {
  atom,
  AtomEffect,
  selector,
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import type { RecoilValueReadOnly, RecoilState } from 'recoil';
import { recoilPersist } from 'store/recoil-persist';
import { useFigbird } from 'store';
import { ssrCompletedState } from 'store/useSsrComplectedState';
// import { storeSyncedComplectedState } from 'store/useStoreSyncedComplectedState';
import tryJsonDecode from 'utils/tryJsonDecode';
import { Action } from './defaults';

const SET = 'bringer/settings/SET';
const SET_SYNCED_SETTINGS = 'bringer/settings/SET_SYNCED_SETTINGS';
const DELETE_SYNCED_SETTINGS = 'bringer/settings/DELETE_SYNCED_SETTINGS';

export interface initialState {
  [x: string]: any;
}

interface SettingsAction extends Action<any> {
  data: any;
  id: string;
  key: string;
}

const initialState: initialState = {
  synced: false,
};

const reducer = (
  state: initialState = initialState,
  action: SettingsAction = {} as any
): initialState => {
  switch (action.type) {
    case SET_SYNCED_SETTINGS:
      const SyncedSettings = state || {};
      const newData = deepmerge(SyncedSettings[action.key] || {}, action.data);
      return {
        ...state,
        [action.key]: newData,
      };
    case DELETE_SYNCED_SETTINGS:
      const settings = { ...(state[action.key] && state[action.key]) };
      if (settings && settings[action.id]) {
        delete settings[action.id];
      }

      return {
        ...state,
        [action.key]: settings,
      };
    case SET: {
      const { data } = action;
      const result = {
        ...state,
        ...data,
      };
      return result;
    }
    default:
      return state;
  }
};

function setAction(data: any): any {
  return {
    type: SET,
    data,
  };
}

function setSyncedSettingsAction(key: string, data: any): any {
  return {
    type: SET_SYNCED_SETTINGS,
    data,
    key,
  };
}
function deleteSyncedSettingsAction(key: string, id: string): any {
  return {
    type: DELETE_SYNCED_SETTINGS,
    key,
    id,
  };
}
// const persistConfig = {
//   key: 'syncedSettings',
//   storage: storage('redux-syncedSettings'),
// };

interface ShoulUpdate {
  toJSON: () => any;
  test: (prev: any, next: any) => boolean;
}

interface AuthParams {
  shouldUpdate?: ShoulUpdate;
  key: string;
}

const atomsMap = new Map();

const getAtom = ({
  atomKey,
  app,
}: {
  atomKey: string;
  app: Application;
}): {
  syncedSettingsAtom: RecoilState<typeof initialState>;
  defaultSelector: RecoilValueReadOnly<typeof initialState>;
  selectorFam: (param: any) => RecoilValueReadOnly<typeof initialState>;
} => {
  const atomExist = atomsMap.get(atomKey);
  if (atomExist) {
    return atomExist;
  }

  let idRecord = null;
  const atomKeyComplete = `syncedSettings-${atomKey}`;
  const storageCompleteKey = `synced-settings-${atomKey}`;
  const { persistAtom } = recoilPersist({
    key: storageCompleteKey,
    storage: {
      setItem: async (key, value) => {
        const { user } = app.settings;
        await app
          .service('key-values')
          .patch(idRecord, { key: `synced-settings-${key}-${user.id}`, value });
      },
      getItem: async (key) => {
        if (app) {
          const { user } = app.settings;
          const userSettings = await app.service('key-values').find({
            query: {
              key: `synced-settings-${key}-${user.id}`,
              userId: user.id,
              $paginate: false,
            },
          });

          if (userSettings[0]) {
            idRecord = userSettings[0].uid;
            let result =
              userSettings[0].value ||
              `{"${atomKeyComplete}":{"synced":true,"${storageCompleteKey}":{}}}`;
            const resultObject = tryJsonDecode(result);
            resultObject[atomKeyComplete].synced = true;
            result = JSON.stringify(resultObject);
            return result;
          }
          const result = await app.service('key-values').create({
            key: `synced-settings-${key}-${user.id}`,
            userId: user.id,
          });
          idRecord = result.uid;
        }
        return `{"${atomKeyComplete}":{"synced":true,"${storageCompleteKey}":{}}}`;
      },
      mergeItem: async (key, value) => {
        await app.service('key-values').patch(idRecord, { value });
      },
    },
  });

  const persistAtomEffect = <T>(param: Parameters<AtomEffect<T>>[0]) => {
    param.getPromise(ssrCompletedState).then(() => {
      persistAtom(param);
    });
  };

  const syncedSettingsAtom = atom({
    key: `syncedSettings-${atomKey}`,
    default: initialState,
    effects_UNSTABLE: [persistAtomEffect],
  });

  const defaultSelector = selector({
    key: `defaultSelectorSyncedSettings-${atomKey}`,
    get: ({ get }) => {
      return get(syncedSettingsAtom);
    },
  });
  const map = new Map();
  const selectorFam = selectorFamily({
    key: `syncedSettingsSelector-${atomKey}`,
    get:
      (shouldUpdate: ShoulUpdate) =>
      ({ get }) => {
        const key = `syncedSettingsSelectorMap-${JSON.stringify(shouldUpdate.toJSON())}`;
        const prevState = map.get(key);
        const state = get(syncedSettingsAtom);
        if (shouldUpdate.test(prevState || state, state)) {
          map.set(key, state);
          return state;
        }

        if (!prevState) {
          map.set(key, state);
        }
        return prevState || state;
      },
  });

  const atomValues = { syncedSettingsAtom, selectorFam, defaultSelector };
  atomsMap.set(atomKey, atomValues);
  return atomValues;
};

export const useSyncedSettings = ({ shouldUpdate, key }: AuthParams) => {
  const { feathers } = useFigbird();

  const { syncedSettingsAtom, selectorFam, defaultSelector } = getAtom({
    atomKey: key,
    app: feathers,
  });

  const setState = useSetRecoilState(syncedSettingsAtom);

  const dispatch = (action: any) => {
    setState((prev) => {
      return reducer(prev, action);
    });
  };

  const mountedRef = useRef<boolean>(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const state = useRecoilValue(shouldUpdate ? selectorFam(shouldUpdate) : defaultSelector);

  const set = useMethod(setAction, dispatch, feathers, mountedRef);
  const setSyncedSettings = useMethod(setSyncedSettingsAction, dispatch, feathers, mountedRef);
  const deleteSyncedSettings = useMethod(
    deleteSyncedSettingsAction,
    dispatch,
    feathers,
    mountedRef
  );

  return useMemo(() => {
    return {
      set,
      setSyncedSettings,
      deleteSyncedSettings,
      state,
    };
  }, [set, setSyncedSettings, deleteSyncedSettings, state]);
};
